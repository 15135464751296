import { memo } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../assets/nodata.svg";

const Unauthorized = ({ noLogo = true }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="90vh"
    >
      {noLogo && (
        <Logo
          style={{
            width: "50%",
            maxWidth: "300px",
          }}
        />
      )}
      <Typography variant="h2" gutterBottom>
        401
      </Typography>
      <Typography variant="h6" gutterBottom>
        Unauthorized
      </Typography>
      <Typography variant="body1">
        We couldU+00b4t validate your credentials. Please ask your manager to
        send you an invite through our product
      </Typography>
      <Button
        variant="contained"
        color="aprincipal"
        style={{ marginTop: "20px" }}
        onClick={() => {
          // Redirect to home or another page
          window.location.href = process.env.REACT_APP_LOGIN_URL;
        }}
      >
        Go to Home
      </Button>
    </Box>
  );
};

export default memo(Unauthorized);
