import React from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

export default function FilterMultBases({
  basesDisponiveis,
  basesSelecionadas,
  filtrarBases,
  disabled,
}) {
  return (
    <div className="buttonBase">
      <ButtonGroup>
        {basesDisponiveis.map((item, index) => {
          return basesSelecionadas?.indexOf(item?.codBase) >= 0 ? (
            <ToggleButton
              className="bttBaseColor radius0 radius0Active"
              variant="secondary"
              disabled={disabled}
              key={item.codBase}
              onClick={() => filtrarBases(item.codBase)}
            >
              {item.base}
            </ToggleButton>
          ) : (
            <ToggleButton
              className="radius0"
              disabled={disabled}
              variant="outline-secondary"
              key={item.codBase}
              onClick={() => filtrarBases(item.codBase)}
            >
              <div>{item.base}</div>
            </ToggleButton>
          );
        })}
      </ButtonGroup>
    </div>
  );
}
