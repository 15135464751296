import { Divider, IconButton, Link, Stack, Typography } from "@mui/material";
import { Flex } from "./Flex";
import { ContentCopy } from "@mui/icons-material";
import { copyToClipboard } from "../../../../util/copyToClipboard";

export const InfoMain = ({ data }) => {
  return (
    <Stack gap={2}>
      <Flex justifyContent={"space-between"} width={"100%"}>
        <Stack>
          <Typography component={"span"} variant="subtitle2">
            Cidade
          </Typography>

          <Typography>{data.cidade}</Typography>
        </Stack>
        <Flex>
          <Divider
            sx={{
              height: "auto !important",
            }}
            orientation="vertical"
            flexItem
          />
          <Stack pl={2}>
            <Typography component={"span"} variant="subtitle2">
              Status
            </Typography>

            <Typography>{data.status}</Typography>
          </Stack>
        </Flex>

        <Flex>
          <Divider
            sx={{
              height: "auto !important",
            }}
            orientation="vertical"
            flexItem
          />
          <Stack pl={2}>
            <Typography component={"span"} variant="subtitle2">
              Cpf
            </Typography>

            <Typography>{data.cpf}</Typography>
          </Stack>
        </Flex>

        <Flex>
          <Divider
            sx={{
              height: "auto !important",
            }}
            orientation="vertical"
            flexItem
          />
          <Stack pl={2}>
            <Typography component={"span"} variant="subtitle2">
              Escolaridade
            </Typography>

            <Typography>{data.escolaridade}</Typography>
          </Stack>
        </Flex>
      </Flex>
      <Divider />
      <Flex justifyContent={"space-between"} width={"100%"}>
        <div>
          <Typography component={"span"} variant="subtitle2">
            Telefone
          </Typography>
          <Stack direction={"row"} alignItems={"center"}>
            <IconButton
              size="sm"
              aria-label="copy"
              onClick={() => copyToClipboard(data.telefone)}
            >
              <ContentCopy />
            </IconButton>
            <Link
              href={`https://wa.me/55${data.telefone?.replace(/[^0-9]/g, "")}`}
              component={"a"}
              target="blank"
              color={"#000"}
              sx={{
                ":hover": {
                  color: "blue",
                },
              }}
            >
              <Typography
                fontSize={"13px"}
                whiteSpace={"nowrap"}
                textOverflow={"ellipsis"}
                overflow={"hidden"}
              >
                {data.telefone}
              </Typography>
            </Link>
          </Stack>
        </div>

        <Flex>
          <Divider
            sx={{
              height: "auto !important",
            }}
            orientation="vertical"
            flexItem
          />
          <Stack pl={2}>
            <Typography component={"span"} variant="subtitle2">
              Email
            </Typography>

            <Typography>{data.email}</Typography>
          </Stack>
        </Flex>

        <Flex>
          <Divider
            sx={{
              height: "auto !important",
            }}
            orientation="vertical"
            flexItem
          />
          <Stack pl={2}>
            <Typography component={"span"} variant="subtitle2">
              Endereço
            </Typography>

            <Typography>{data.endereco}</Typography>
          </Stack>
        </Flex>
      </Flex>

      <Divider />
      <Stack>
        <Typography component={"span"} variant="subtitle2">
          Perfil
        </Typography>
        <Flex gap={"1rem"} width={"100%"} justifyContent={"center"}>
          {data.perfil.map((item, index) => (
            <>
              <Stack>
                <Typography component={"span"} variant="subtitle2">
                  Indice
                </Typography>
                <Typography>{item.indice}</Typography>
              </Stack>
              <Stack>
                <Typography component={"span"} variant="subtitle2">
                  Porcentagem
                </Typography>
                <Typography>{item.porcentagem} %</Typography>
              </Stack>
              {data.perfil?.length !== index + 1 && (
                <Divider
                  sx={{
                    height: "auto !important",
                  }}
                  orientation="vertical"
                  flexItem
                />
              )}
            </>
          ))}
        </Flex>
      </Stack>
    </Stack>
  );
};
