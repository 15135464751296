import { Container } from "react-bootstrap";

import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { useParams, useHistory } from "react-router-dom";

import { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import toast from "react-hot-toast";

import { LoadingTable } from "../../../../components/Loading/LoadingTable";
import { PermissionsPage } from "../../../../util/permissionsPage";
import Title from "../../../../components/title";
import { api } from "../../../../services/api.v2";

import { InputHelpTooltip } from "../../components/InputHelpTooltip";
import { InputAutoComplete } from "../../components/InputAutoComplete";
import { inputValues } from "../utils/inputvalues";
import { StyledInputNumber } from "../../components/StyledInputNumber";

export const CreateBase = () => {
  const history = useHistory();
  const params = useParams();
  const {
    formState: { errors, isSubmitting },
    register,
    control,
    reset,
    handleSubmit,
  } = useForm();

  const [isLoading, setIsLoading] = useState(true);
  const [values, setValues] = useState({
    codTabelaEstoque: [],
    codClienteVendaDireta: [],
  });
  const getInfoBase = async () => {
    setIsLoading(true);

    try {
      const response = await api.get(`/register/base/info/${params.id}`);
      const codTabelaEstoque = !!response.data.config.codTabelaEstoque
        ? response.data.config.codTabelaEstoque.split(",").map(Number)
        : [];
      const codClienteVendaDireta = !!response.data.config.codClienteVendaDireta
        ? response.data.config.codClienteVendaDireta.split(",").map(Number)
        : [];
      setValues({ codClienteVendaDireta, codTabelaEstoque });
      reset({
        ...response.data,
        config: {
          ...response.data.config,
          codTabelaEstoque,
          codClienteVendaDireta,
        },
      });
    } catch (error) {
      console.log(error);
      toast.error("Base não existe");
      goBackList();
    } finally {
      setIsLoading(false);
    }
  };

  const goBackList = () => history.push(`/cadastro/base`);

  const onSubmit = async (data) => {
    const inputAutoComp = {};
    Object.keys(values).forEach((key) => {
      inputAutoComp[key] = values[key].join(",");
    });
    try {
      setIsLoading(true);
      const dataSend = {
        ...data,
        porta: +data.porta,
        config: { ...data.config, ...inputAutoComp },
      };

      if (params?.id) {
        await api.patch(`/register/base/update/${params.id}`, dataSend);
      } else {
        await api.post("/register/base/create", dataSend);
      }

      toast.success("Informações salvas com sucesso");
      goBackList();
    } catch (error) {
      if (
        (error?.response && error?.response?.status === 400) ||
        error?.response?.status === 404
      ) {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      } else {
        toast.error("Erro ao tentar processar seus dados");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getInfoBase();
    } else {
      setIsLoading(false);
    }
  }, [params?.id]);

  return (
    <div className="container-app">
      {isLoading && (
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"90vh"}
        >
          <LoadingTable />
        </Box>
      )}
      {!isLoading && PermissionsPage("list_base") && (
        <Container>
          <div className="container-conteudo">
            <Container className="containerFlexBox">
              <Title title="Cadastar Informações da Base" />
            </Container>

            <Box
              sx={{
                px: "24px",
                display: "flex",
                alignItems: "center",
                paddingBottom: 3,
              }}
            >
              <>
                {!isLoading && (
                  <Box display={"flex"} gap={4}>
                    <Box>
                      <Typography variant="h6">
                        Por favor, preencha todos os campos do formulário com as
                        informações da Base.
                      </Typography>
                      <Typography pt={2} variant="body1">
                        É imprescindível fornecer todas as informações
                        solicitadas para garantir o correto funcionamento do BI.
                      </Typography>
                    </Box>
                    <Paper
                      sx={{
                        p: 2,
                        maxWidth: "730px",
                      }}
                      variant="outlined"
                      elevation={3}
                    >
                      <Stack
                        spacing={2}
                        component={"form"}
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <Typography>Configurações gerais</Typography>

                        <Stack direction="row" spacing={2}>
                          <FormControl
                            sx={{
                              flex: 1,
                            }}
                          >
                            <StyledInputNumber
                              {...register("base", {
                                required: true,
                                pattern: /^[0-9]*$/,
                              })}
                              id="base"
                              type="number"
                              label="Numero da Base"
                              variant="outlined"
                              size="small"
                              disabled={isSubmitting}
                              error={!!errors.base}
                            />
                          </FormControl>

                          <FormControl
                            sx={{
                              flex: 1,
                            }}
                          >
                            <StyledInputNumber
                              {...register("porta", {
                                required: true,
                                pattern: /^[0-9]*$/,
                              })}
                              id="porta"
                              label="Numero da porta"
                              variant="outlined"
                              size="small"
                              type="number"
                              disabled={isSubmitting}
                              error={!!errors.porta}
                            />
                          </FormControl>
                          <FormControl
                            sx={{
                              flex: 1,
                            }}
                          >
                            <StyledInputNumber
                              {...register("portaSql", {
                                required: true,
                                pattern: /^[0-9]*$/,
                              })}
                              id="portaSql"
                              label="Numero da porta SQL"
                              variant="outlined"
                              size="small"
                              type="number"
                              disabled={isSubmitting}
                              error={!!errors.portaSql}
                            />
                          </FormControl>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                          <FormControl
                            sx={{
                              flex: 1,
                            }}
                          >
                            <TextField
                              {...register("ip", { required: true })}
                              id="ip"
                              label="Ip da Base"
                              variant="outlined"
                              size="small"
                              disabled={isSubmitting}
                              error={!!errors.ip}
                              helperText="IP com a máscara, por exemplo: 192.168.0.1"
                            />
                          </FormControl>
                          <FormControl
                            sx={{
                              maxWidth: "180px",
                            }}
                          >
                            <TextField
                              {...register("senhaSql", { required: true })}
                              id="senhaSql"
                              label="Senha SQL"
                              variant="outlined"
                              size="small"
                              disabled={isSubmitting}
                              error={!!errors.senhaSql}
                            />
                          </FormControl>
                        </Stack>
                        <Stack direction="row" spacing={2} pb={2}>
                          <FormControl
                            sx={{
                              maxWidth: "180px",
                            }}
                          >
                            <TextField
                              {...register("nome_bd_sql", { required: true })}
                              id="nome_bd_sql"
                              label="Nome do Banco SQL"
                              variant="outlined"
                              size="small"
                              disabled={isSubmitting}
                              error={!!errors.nome_bd_sql}
                            />
                          </FormControl>
                          <FormControl
                            sx={{
                              flex: 1,
                            }}
                          >
                            <TextField
                              {...register("instancia_zapi")}
                              id="instancia_zapi"
                              label="Instancia ZAPI"
                              variant="outlined"
                              size="small"
                              disabled={isSubmitting}
                              error={!!errors.instancia_zapi}
                            />
                          </FormControl>
                        </Stack>

                        <Divider />

                        <Typography>Configurações adicionais</Typography>
                        <Box>
                          <Grid
                            container
                            spacing={{ xs: 1, md: 1 }}
                            columns={{ xs: 2, sm: 8, md: 16 }}
                          >
                            {inputValues.map((item) => (
                              <Grid key={item.id} item xs={2} sm={4} md={8}>
                                <FormControl fullWidth>
                                  <Controller
                                    name={`config.${item.id}`}
                                    rules={{
                                      pattern: /^[0-9]*$/,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        {item.type === "autocomplete" ? (
                                          <InputAutoComplete
                                            {...field}
                                            name={item.name}
                                            values={values}
                                            setValues={setValues}
                                            id={item.id}
                                            titleTooltip={item.titleTooltip}
                                            example={item.example}
                                          />
                                        ) : (
                                          <InputHelpTooltip
                                            {...field}
                                            id={item.id}
                                            type="number"
                                            label={item.name}
                                            variant="outlined"
                                            size="small"
                                            disabled={isSubmitting}
                                            titleTooltip={item.titleTooltip}
                                            helperText={item.example}
                                          />
                                        )}
                                      </>
                                    )}
                                  />
                                </FormControl>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>

                        <Box
                          display={"flex"}
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                          gap={2}
                        >
                          <Button
                            variant="outlined"
                            disabled={isSubmitting}
                            onClick={goBackList}
                            type="button"
                          >
                            Voltar
                          </Button>
                          <Button
                            disabled={isSubmitting}
                            variant="contained"
                            type="submit"
                          >
                            Salvar
                          </Button>
                        </Box>
                      </Stack>
                    </Paper>
                  </Box>
                )}
              </>
            </Box>
          </div>
        </Container>
      )}
    </div>
  );
};
