import Select from "react-select";
import makeAnimated from "react-select/animated";
import "../styles.css";
import { formatShopAvailable } from "../../../formatdata/formatter-shop-available";

export const MultiSelectLojas = ({
  lojas = [],
  isLoading = false,
  onChange,
  paramsConfig,
  shops = [],
  baseUnique = false
}) => {
  const animatedComponents = makeAnimated();

  const paramsBase = baseUnique ? [paramsConfig.base] : paramsConfig.base

  const options = formatShopAvailable(paramsBase, lojas);

  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: state.isDisabled ? " whitesmoke" : "white",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: " #eb0058",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#FFF",
      fontWeight: "bold",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "white",
      ":hover": {
        backgroundColor: "#ff0d6c",
        color: "white",
      },
    }),
  };

  return (
    <div>
      <strong className="font-weight-bold">Lojas</strong>
      <Select
        components={animatedComponents}
        name="lojas"
        isMulti
        value={shops}
        placeholder='Selecione uma loja'
        isDisabled={isLoading}
        options={options}
        onChange={(e) => onChange(e)}
        classNames={{
          control: (state) => state.isFocused && "borderIsFocus",
        }}
        styles={colourStyles}
      />
    </div>
  );
};
