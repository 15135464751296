import { Box, Stack } from "@mui/material";

import { useEffect, useState } from "react";
import boletim from "../../../services/boletim";
import toast from "react-hot-toast";
import Select from "react-select";
import { storageStoresGet } from "../../../storage/storageStores";
import { columnsTableGroupsInventory } from "../../../column/boletim/GroupsInventory";
import { DataTableBoletim } from "../DataTableBoletim/DataTableBoletim";
import { columnsTablePurchases } from "../../../column/boletim/Purchases";
import {
  columnsTableSales,
  columnsTableSalesByPaymentMethod,
} from "../../../column/boletim/Sales";
import { LoadingTable } from "../../Loading/LoadingTable";
export const DataByStore = ({ paramsConfig, basesDisponiveis }) => {
  const [dataPageDataByStore, setDataPageDataByStore] = useState([]);
  const [lojasDisponiveus, setLojasDisponiveis] = useState([]);
  const [lojasSelecionadas, setLojasSelecionadas] = useState([]);
  const [lojas, setLojas] = useState([]);
  const [isLoadingDataByStore, setIsLoadingDataByStore] = useState(false);

  async function getDataByStore() {
    try {
      setIsLoadingDataByStore(true);
      setLojasSelecionadas([]);
      const response = await boletim.getDataByStore(paramsConfig.base, {
        periodo: paramsConfig.periodo,
      });

      const data = response.data.map((item) => {
        const groupsInventoryExpand = item.estoquePorGrupo.filter(
          (item) => !item?.isTotal
        );
        const groupsInventory = [
          item.estoquePorGrupo.find((item) => !!item?.isTotal),
        ];
        groupsInventory[0].expand = groupsInventoryExpand;
        item.estoquePorGrupo = groupsInventory;
        //-------------------------

        const purchasesExpand = item.previsaoCompraPorGrupo.filter(
          (item) => !item?.isTotal
        );
        const purchases = [
          item.previsaoCompraPorGrupo.find((item) => !!item?.isTotal),
        ];
        purchases[0].expand = purchasesExpand;
        item.previsaoCompraPorGrupo = purchases;
        //-------------------------

        const salesByPaymentMethod = [item.vendasPorFormaPagamento[2]];
        salesByPaymentMethod[0].expand = [
          item.vendasPorFormaPagamento[0],
          item.vendasPorFormaPagamento[1],
        ];
        item.vendasPorFormaPagamento = salesByPaymentMethod;
        //-------------------------

        const sales = [item.vendasPorGrupo[2]];
        sales[0].expand = [item.vendasPorGrupo[0], item.vendasPorGrupo[1]];

        item.vendasPorGrupo = sales;
        //-------------------------

        return item;
      });
      const storesLocal = storageStoresGet();

      const stores = data.map((item) => ({
        value: item.codLoja,
        label: `${storesLocal.find((sto) => sto.id === item.codLoja)?.sigla} ${
          item.codLoja
        }`,
      }));
      setLojasDisponiveis(stores);
      setDataPageDataByStore(data);
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
      setIsLoadingDataByStore(false);
    }
  }
  useEffect(() => {
    if (!paramsConfig.base) return;
    getDataByStore();
  }, [basesDisponiveis, paramsConfig]);
  console.log(dataPageDataByStore);
  useEffect(() => {
    setLojasSelecionadas(
      dataPageDataByStore.filter((item) =>
        lojas.some((loja) => +loja.value === +item.codLoja)
      )
    );
  }, [lojas]);
  return (
    <>
      <Box m={2}>
        <Select
          isDisabled={isLoadingDataByStore}
          isMulti
          value={lojas}
          onChange={(value) => setLojas(value)}
          placeholder="Selecione uma loja"
          options={lojasDisponiveus}
        />
        <Stack my={1} gap={2}>
          {lojasSelecionadas.length <= 0 ? (
            <Box
              height={"50vh"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              {isLoadingDataByStore && <LoadingTable />}
              {isLoadingDataByStore ? (
                <strong>Carregando...</strong>
              ) : (
                <strong>Selecione uma loja</strong>
              )}
            </Box>
          ) : (
            <>
              {lojasSelecionadas.map((item) => (
                <Stack gap={2} key={item.codLoja}>
                  <strong>{item.nomeLoja}</strong>

                  <DataTableBoletim
                    columns={columnsTableGroupsInventory}
                    data={item.estoquePorGrupo}
                    label="Estoque por grupo"
                  />
                  <DataTableBoletim
                    columns={columnsTablePurchases}
                    data={item.previsaoCompraPorGrupo}
                    label="Compras por grupo"
                  />
                  <DataTableBoletim
                    columns={columnsTableSalesByPaymentMethod}
                    data={item.vendasPorFormaPagamento}
                    label="Vendas por Forma de Pagamento"
                  />

                  <DataTableBoletim
                    columns={columnsTableSales}
                    data={item.vendasPorGrupo}
                    label="Vendas por grupo"
                  />
                </Stack>
              ))}
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};
