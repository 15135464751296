import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";

import { SubMenuListLink } from "./SubMenuListLink";
import {
  FaBullhorn,
  FaChartLine,
  FaCog,
  FaDatabase,
  FaMoneyBill,
  FaShoppingCart,
  FaUserFriends,
} from "react-icons/fa";

const icons = [
  { id: 0, icon: <FaBullhorn /> },
  { id: 1, icon: <FaUserFriends /> },
  { id: 2, icon: <FaChartLine /> },
  { id: 3, icon: <FaMoneyBill /> },
  { id: 4, icon: <FaShoppingCart /> },
  { id: 5, icon: <FaCog /> },
  { id: 6, icon: <FaDatabase /> },
];

export const SubMenuList = ({
  menu,
  index,
  openMenu,
  setOpenMenu,
  selected,
  setSelected,
  subMenuName,
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
    if (!openMenu) {
      setOpenMenu(true);
    }
  };
  useEffect(() => {
    if (!openMenu) {
      setOpen(false);
    }
  }, [openMenu]);
  const IconMenu = icons.find((icon) => icon.id === index);

  const isSelectedMenu = subMenuName === menu.menu;
  return (
    <>
      <ListItem key={menu?.menu} disablePadding sx={{ display: "block" }}>
        <ListItemButton
          onClick={handleClick}
          sx={{
            minHeight: 48,
            justifyContent: openMenu ? "initial" : "center",
            px: 2.5,
            m: "3px",
            borderRadius: "8px",
            ...(!openMenu && {
              background: isSelectedMenu && "#ff79ae",
              ...(isSelectedMenu && {
                ":hover": {
                  background: "#ff79aee0",
                },
              }),
            }),
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: openMenu ? 3 : "auto",
              justifyContent: "center",
              color: isSelectedMenu && !openMenu && "white",
            }}
          >
            {IconMenu.icon}
          </ListItemIcon>
          {openMenu && (
            <ListItemText
              primary={menu?.menu}
              sx={{
                opacity: openMenu ? 1 : 0,
              }}
            />
          )}

          {openMenu && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
        </ListItemButton>
        <ListItem component="div" disablePadding sx={{ display: "block" }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <>
              {menu?.submenus.map((submenu) => {
                return (
                  <SubMenuListLink
                    submenu={submenu}
                    setSelected={setSelected}
                    selected={selected}
                  />
                );
              })}
            </>
          </Collapse>
        </ListItem>
      </ListItem>
    </>
  );
};
