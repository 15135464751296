import React from 'react'
import { Container, Row } from 'react-bootstrap'
import './Loading.css'
import './Name.css'
import img from '../../assets/coracao.png'

const Spin = () => {
    return (
        < >

            <div className='back'>
                <div className='name'>
                    <img className='animate' src={img} width='168' height='98' alt='logo aprincipal' />

                </div>
               
            </div>
        </>
    )
}

export default Spin
