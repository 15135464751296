import React from "react";
import { Container, Row, ToggleButton } from "react-bootstrap";

const FilterAnoMes = ({ data, filter, filterSelect, txtTipo }) => {
  return (
    <React.Fragment>
      {data && (
        <React.Fragment>
          {data.length > 1 && (
            <Container>
              {/* <div className="linha-divisoria"></div> */}
              <p className="titulo-tipo-filtro">{txtTipo}</p>
              <div className="divPadding14px">
                <Row xs={2} md={3}>
                  {data.map((item, index) => {
                    return filterSelect.indexOf(item) >= 0 ? (
                      <ToggleButton
                        className="radius0"
                        variant="secondary"
                        key={index}
                        onClick={() => filter(item)}
                      >
                        {item}
                      </ToggleButton>
                    ) : (
                      <ToggleButton
                        className="radius0"
                        variant="outline-secondary"
                        key={index}
                        onClick={() => filter(item)}
                      >
                        {item}
                      </ToggleButton>
                    );
                  })}
                </Row>
              </div>
            </Container>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default FilterAnoMes;
