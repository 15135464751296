import { CellYearContainer } from "./styles";

export const CellAmount = ({ row, name }) => {
  const lastMonthHidden = !row.lastMonth?.hidden;
  const lastYearHidden = !row.lastYear?.hidden;
  return (
    <CellYearContainer>
      <div>
        <span>{row.currency[name]}</span>
      </div>
      {lastYearHidden && (
        <div>
          <span>{row.lastYear[name]}</span>
        </div>
      )}
      {lastMonthHidden && (
        <div>
          <span>{row.lastMonth[name]}</span>
        </div>
      )}
    </CellYearContainer>
  );
};
