import { Box, Paper, Skeleton, Typography } from "@mui/material";
import DataTable from "react-data-table-component";
import { ExpandableRowsComponent } from "../ExpandableRowsComponent/ExpandableRowsComponent";
import { NoData } from "../../NoData/NoData";

export const DataTableBoletim = ({
  data = [],
  columns = [],
  label = "",
  isLoading = false,
}) => {
  return (
    <div>
      <Box component={Paper} variant="outlined">
        <Typography textAlign={"center"} fontWeight={"bold"}>
          {label}
        </Typography>
      </Box>
      <Box component={Paper} variant="outlined">
        <DataTable
          columns={columns}
          data={data}
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={(props) => (
            <ExpandableRowsComponent {...props} columns={columns} />
          )}
          progressPending={isLoading}
          dense
          progressComponent={
            <Skeleton variant="rounded" width={"100%"} height={50} />
          }
          theme="solarized"
          noDataComponent={<NoData noLogo={false} />}
        />
      </Box>
    </div>
  );
};
