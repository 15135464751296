import dayjs from "dayjs";
import { formatarNumero } from "../../util/loja";

export const formatPercent = (row, name) => {
  if (!!row?.isTotal) {
    return `${row[name].toFixed(2)} %`;
  }
  return formatarNumero(row[name]);
};

export const columnsTableSales = [
  {
    selector: "venda",
    name: <strong style={{ fontSize: "1rem" }}>Grupo</strong>,
    minWidth: "120px",
    cell: (row) => {
      if (dayjs(row.venda).isValid()) {
        return <strong>{dayjs(row.venda).format("DD/MM/YYYY")}</strong>;
      }
      return <strong>{row.venda}</strong>;
    },
  },
  {
    selector: "bebe",
    name: <strong style={{ fontSize: "1rem" }}>Bebê</strong>,
    cell: (row) => formatPercent(row, "bebe"),

    minWidth: "110px",
    maxWidth: "110px",
  },
  {
    selector: "meias",
    name: <strong style={{ fontSize: "1rem" }}>Meias e Cia</strong>,
    cell: (row) => formatPercent(row, "meias"),
    minWidth: "110px",
    maxWidth: "110px",
  },
  {
    selector: "moveis",
    name: <strong style={{ fontSize: "1rem" }}>Móveis</strong>,
    cell: (row) => formatPercent(row, "moveis"),
    minWidth: "110px",
    maxWidth: "110px",
  },
  {
    selector: "puericulturaL",
    name: <strong style={{ fontSize: "1rem" }}>Pueric. Leve</strong>,
    cell: (row) => formatPercent(row, "puericulturaL"),
    minWidth: "110px",
    maxWidth: "110px",
  },
  {
    selector: "puericulturaP",
    name: <strong style={{ fontSize: "1rem" }}>Pueric. Pesada</strong>,
    cell: (row) => formatPercent(row, "puericulturaP"),
    minWidth: "110px",
    maxWidth: "110px",
  },
  {
    selector: "infanto",
    name: <strong style={{ fontSize: "1rem" }}>Infanto</strong>,
    cell: (row) => formatPercent(row, "infanto"),
    minWidth: "110px",
    maxWidth: "110px",
  },
  {
    selector: "calcados",
    name: <strong style={{ fontSize: "1rem" }}>Calçados</strong>,
    cell: (row) => formatPercent(row, "calcados"),
    minWidth: "110px",
    maxWidth: "110px",
  },
  {
    selector: "outros",
    name: <strong style={{ fontSize: "1rem" }}>Outros</strong>,
    cell: (row) => formatPercent(row, "outros"),
    minWidth: "110px",
    maxWidth: "110px",
  },
  {
    selector: "total",
    name: <strong style={{ fontSize: "1rem" }}>Total</strong>,
    cell: (row) => formatPercent(row, "total"),
    minWidth: "110px",
    maxWidth: "110px",
  },
];
export const columnsTableSalesByPaymentMethod = [
  {
    selector: "venda",
    name: <strong style={{ fontSize: "1rem" }}>Grupo</strong>,
    cell: (row) => {
      if (dayjs(row.venda).isValid()) {
        return <strong>{dayjs(row.venda).format("DD/MM/YYYY")}</strong>;
      }
      return <strong>{row.venda}</strong>;
    },
  },
  {
    selector: "avista",
    name: <strong style={{ fontSize: "1rem" }}>AVista</strong>,
    cell: (row) => formatPercent(row, "avista"),
  },
  {
    selector: "debito",
    name: <strong style={{ fontSize: "1rem" }}>Débito</strong>,
    cell: (row) => formatPercent(row, "debito"),
  },
  {
    selector: "credito",
    name: <strong style={{ fontSize: "1rem" }}>Crédito</strong>,
    cell: (row) => formatPercent(row, "credito"),
  },
  {
    selector: "diversos",
    name: <strong style={{ fontSize: "1rem" }}>Diversos</strong>,
    cell: (row) => formatPercent(row, "diversos"),
  },
  {
    selector: "losango",
    name: <strong style={{ fontSize: "1rem" }}>Losango</strong>,
    cell: (row) => formatPercent(row, "losango"),
  },
  {
    selector: "total",
    name: <strong style={{ fontSize: "1rem" }}>Total</strong>,
    cell: (row) => formatPercent(row, "total"),
    minWidth: "110px",
  },
];
