import React, { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import ReactSpeedometer from "react-d3-speedometer";
import { preencherDados } from "../../util/metas-gerais";
import { BuntonLink } from "./Button/BuntonLink";
import { useUserData } from "../../hooks/useUserData";
import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";

export const CardHomeGraficoMetas = () => {
  const [metaTotal, setMetaTotal] = useState(0);

  const [realizadoTotal, setRealizadoTotal] = useState(0);
  const { userData } = useUserData();
  const accessLevel1 = userData?.nivelAcesso;
  const acessBase1 = userData?.baseAcesso;

  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const busca = async () => {
    if (!accessLevel1) {
      return;
    }
    const response = await preencherDados(accessLevel1, acessBase1);

    const totalMeta = response?.totalMeta || 0;
    const totalRealizada = response?.totalRealizada || 0;

    setMetaTotal(totalMeta);
    setRealizadoTotal(totalRealizada);
    setLoading(false);
  };
  useEffect(() => {
    busca();
  }, [userData]);

  return (
    <Box
      maxWidth={"430px"}
      width={"100%"}
      component={Paper}
      elevation={3}
      flexDirection={"column"}
      flex={1}
      p={2}
      variant="outlined"

      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      mb={4}
      height={"fit-content"}
    >
      {!loading ? (
        <>
          <ReactSpeedometer
            width={
              document.body.offsetWidth < 500
                ? document.body.offsetWidth - 110
                : 400
            }
            height={225}
            forceRender={true}
            value={realizadoTotal}
            segments={4}
            minValue={0}
            maxValue={
              metaTotal > 0
                ? metaTotal + metaTotal * 0.6
                : realizadoTotal + realizadoTotal * 0.5
            }
            labelFontSize={isSm ? "14px" : "10px"}
            customSegmentStops={[
              0,
              metaTotal,
              metaTotal + metaTotal * 0.15,
              metaTotal + metaTotal * 0.3,
              metaTotal > 0
                ? metaTotal + metaTotal * 0.6
                : realizadoTotal + realizadoTotal * 0.5,
            ]}
            segmentColors={["#c4302b", "#00b14f", "#FFD700", "#6395ec"]}
            currentValueText={realizadoTotal.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              style: "currency",
              currency: "brl",
            })}
            ringWidth={47}
            valueFormat={metaTotal > 1000000 ? ".3s" : ".5s"}
            needleTransitionDuration={3333}
            needleTransition="easeElastic"
            needleColor={"gray"}
            textColor={"#000"}
          />
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <BuntonLink path={`/metas/metas-gerais`}>IR PARA PAGINA</BuntonLink>
          </Box>
        </>
      ) : (
        <Spinner animation="border" />
      )}
    </Box>
  );
};
