import { ST_STORAGE } from "./storageConfig";

export const storageStoresSave = (data) => {
  localStorage.setItem(ST_STORAGE, JSON.stringify(data));
};

export const storageStoresGet = () => {
  const data = localStorage.getItem(ST_STORAGE);
  if (data) {
    return JSON.parse(data);
  }
  return [];
};
