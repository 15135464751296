import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

const TabelaNext = ({
  dadosParaTabela,

  colunas,
}) => {
  return (
    <ToolkitProvider
      keyField="id"
      data={dadosParaTabela}
      columns={colunas}
      wrapperClasses="table-responsive"
      exportCSV={{
        fileName: "tabela.csv",
        noAutoBOM: false,
        blobType: "text/csv;charset=ansi",
      }}
    >
      {(props) => (
        <div>
          <BootstrapTable {...props.baseProps} />
        </div>
      )}
    </ToolkitProvider>
  );
};

export default TabelaNext;
