import {
  Box,
  Button,
  Chip,
  Collapse,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { ButtonExpand } from "../ExpandMore";
import { useState } from "react";
import { formatarNumero } from "../../util/loja";
import { red } from "@mui/material/colors";
import { useHistory } from "react-router-dom";

export const CardBase = ({ data, idMeta }) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const history = useHistory();
  const metaAtingida = +data.metaVenda + +data.metaVenda * 0.15;
  const metaOuro = +data.metaVenda + +data.metaVenda * 0.3;
  const metaDiamante = +data.metaVenda + +data.metaVenda * 0.5;
  const meta = data.metaVenda;

  const color =
    meta >= metaAtingida
      ? "#00b14f"
      : meta >= metaOuro
      ? "#ffd700"
      : meta >= metaDiamante
      ? "#6395ec"
      : red[300];
  return (
    <Stack mt={2} p={1} component={Paper} variant="outlined">
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"}>
          <ButtonExpand
            expanded={expanded}
            handleExpandClick={handleExpandClick}
          />
          <Typography display={"flex"} alignItems={"center"}>
            Base {data.base}
          </Typography>
        </Box>
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <Button
            onClick={() => history.push(`/financeiro/metas/editar/${idMeta}`)}
            size="small"
            variant="contained"
            color="aprincipal"
          >
            Editar Cadastro
          </Button>
        </Stack>
      </Box>
      <Collapse
        sx={{
          px: 1,
        }}
        in={expanded}
      >
        <Box gap={2} display={"grid"} gridTemplateColumns={"1fr 1fr 1fr"}>
          <Chip
            sx={{
              bgcolor: color,
            }}
            label={
              <Box display={"flex"} gap={1}>
                <Typography fontWeight={"bold"}>Meta Realizada:</Typography>
                <Typography>{formatarNumero(data.vendaRealizada)}</Typography>
              </Box>
            }
          />
          <Chip
            label={
              <Box display={"flex"} gap={1}>
                <Typography fontWeight={"bold"}>Meta Projetada:</Typography>
                <Typography>{formatarNumero(meta)}</Typography>
              </Box>
            }
          />
          <Box></Box>
          <Chip
            icon={<>🚀</>}
            sx={{
              bgcolor: "#00b14f",
            }}
            label={
              <Box display={"flex"} gap={1}>
                <Typography fontWeight={"bold"}>Meta:</Typography>
                <Typography>{formatarNumero(metaAtingida)}</Typography>
              </Box>
            }
          />
          <Chip
            sx={{
              bgcolor: "#ffd700",
            }}
            icon={<>🥇</>}
            label={
              <Box display={"flex"} gap={1}>
                <Typography fontWeight={"bold"}>Meta Ouro:</Typography>
                <Typography>{formatarNumero(metaOuro)}</Typography>
              </Box>
            }
          />
          <Chip
            icon={<>💎</>}
            sx={{
              bgcolor: "#6395ec",
            }}
            label={
              <Box display={"flex"} gap={1}>
                <Typography fontWeight={"bold"}>Meta Diamante:</Typography>
                <Typography>{formatarNumero(metaDiamante)}</Typography>
              </Box>
            }
          />
        </Box>
      </Collapse>
    </Stack>
  );
};
