import {
  Box,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { converterNumero } from "../../util/loja";
import dayjs from "dayjs";
import DataTable from "react-data-table-component";
import { NoData } from "../../components/NoData/NoData";
import { CardIconHome } from "../../components/CardIconHome/CardIconHome";
import { FaHandHoldingUsd, FaMoneyBillAlt } from "react-icons/fa";
import { amber, blue, green, orange, teal } from "@mui/material/colors";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { IoCalendarOutline } from "react-icons/io5";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsArrowRepeat } from "react-icons/bs";
import { FcMoneyTransfer, FcStatistics } from "react-icons/fc";
import SwipeableViews from "react-swipeable-views";
import { a11yProps } from "../../util/a11yProps";
import { FiMoreVertical } from "react-icons/fi";
import { OptionsTable } from "../../components/componentsVendasClientes/OptionsTable";
import { ButtonCopyNumber } from "../../components/ButtonCopyNumber/ButtonCopyNumber";
dayjs.extend(advancedFormat);
dayjs.extend(utc);

export const columnsTable1 = (handleEdit, base) => {
  return [
    {
      name: <strong style={{ fontSize: "1rem" }}>Opções</strong>,
      selector: "opções",
      sortable: false,
      width: "90px",
      cell: (row) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [anchorEl, setAnchorEl] = useState(null);
        const openMenu = Boolean(anchorEl);

        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };

        return (
          <>
            <IconButton
              aria-controls={openMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              onClick={handleClick}
            >
              <FiMoreVertical />
            </IconButton>
            <OptionsTable
              anchorEl={anchorEl}
              open={openMenu}
              id={row.codCliente}
              onClose={handleClose}
              base={base}
              onEdit={() => handleEdit(row)}
            />
          </>
        );
      },
    },
    {
      name: <strong style={{ fontSize: "1rem" }}>Código</strong>,
      selector: "codCliente",
      sortable: true,
      width: "90px",
    },

    {
      name: <strong style={{ fontSize: "1rem" }}>Nome</strong>,
      selector: "nomeCliente",
      sortable: true,

      cell: (row) => {
        return (
          <Tooltip title={row.nomeCliente}>
            <Typography
              fontSize={"13px"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
            >
              {row.nomeCliente}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      name: <strong style={{ fontSize: "1rem" }}>Data cadastro</strong>,
      selector: "dataCadastro",
      width: "180px",
      sortable: true,
      cell: (row) => {
        return dayjs.utc(row.dataCadastro).isValid()
          ? dayjs.utc(row.dataCadastro).format("DD/MM/YYYY, [às] HH:mm")
          : "Data não cadastrada";
      },
    },
    {
      name: <strong style={{ fontSize: "1rem" }}>Qtd de compras</strong>,
      selector: "qtdCompras",
      sortable: true,
      width: "110px",
    },
    {
      name: <strong style={{ fontSize: "1rem" }}>Valor</strong>,
      selector: "totalComprado",
      sortable: true,
      width: "160px",
      cell: (row) => {
        return converterNumero(row.totalComprado);
      },
    },
    {
      name: <strong style={{ fontSize: "1rem" }}>Cidade</strong>,
      selector: "cidade",
      width: "130px",
      sortable: true,
    },
    {
      name: <strong style={{ fontSize: "1rem" }}>Telefone</strong>,
      selector: "fone",
      sortable: true,
      width: "170px",
      cell: (row) => {
        return <ButtonCopyNumber phone={row.fone} />;
      },
    },
  ];
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const columnsComprasPorMes = [
  {
    name: <strong>Mês</strong>,
    selector: (row) => row.mes,
    sortable: true,
  },
  {
    name: <strong>Quantidade de compras</strong>,
    selector: (row) => row.qtdCompras,
    sortable: true,
  },
  {
    name: <strong>Valor total comprado</strong>,
    selector: (row) => row.totalComprado,
    sortable: true,
    cell: (row) => {
      return converterNumero(row.totalComprado);
    },
  },
];

const columnsComprasPorGrupo = [
  {
    name: <strong>Grupo</strong>,
    selector: (row) => row.nomeGrupo,
    sortable: true,
    cell: (row) => {
      return <Tooltip title={row.nomeGrupo}>{row.nomeGrupo}</Tooltip>;
    },
  },
  {
    name: <strong>Quantidade de compras</strong>,
    selector: (row) => row.qtdCompras,
    sortable: true,
  },
  {
    name: <strong>Valor total comprado</strong>,
    selector: (row) => row.totalComprado,
    sortable: true,
    cell: (row) => {
      return converterNumero(row.totalComprado);
    },
  },
  {
    name: <strong>Ticket medio</strong>,
    selector: (row) => row.ticketMedio,
    sortable: true,
    cell: (row) => {
      return converterNumero(row.ticketMedio);
    },
  },
  {
    name: <strong>Quantidade itens</strong>,
    selector: (row) => row.qtdItens,
    sortable: true,
  },
  {
    name: <strong>Repres.</strong>,
    selector: (row) => row.representatividade,
    sortable: true,
    cell: (row) => {
      return <>{row.representatividade.toFixed(2)} %</>;
    },
  },
  {
    name: <strong>Lucro</strong>,
    selector: (row) => row.porcentagemLucro,
    sortable: true,
    cell: (row) => {
      return <>{row.porcentagemLucro.toFixed(2)} %</>;
    },
  },
];

const columnsDependentes = (data) => {
  return [
    {
      name: <strong>Código dependente</strong>,
      selector: (row) => row.codDependente,
      sortable: true,
    },
    {
      name: <strong>Nome</strong>,
      selector: (row) => row.nome,
      sortable: true,
    },
    {
      name: <strong>Sexo</strong>,
      selector: (row) => row.sexo,
      sortable: true,
    },
    {
      name: <strong>Parentesco</strong>,
      selector: (row) => row.grauParentesco,
      sortable: true,
    },
    {
      name: <strong>Data nascimento</strong>,
      selector: (row) => row.nascimento,
      sortable: true,
      cell: (row) => {
        return !!row.nascimento
          ? dayjs.utc(row.nascimento).format("DD/MM/YYYY")
          : dayjs.utc(row.dataNascimentoPrevisto).format("DD/MM/YYYY");
      },
    },
    {
      name: <strong>Semanas gestação</strong>,
      selector: (row) => row.semanasGestacao,
      sortable: true,
    },
  ];
};
export const ExpandableRowsComponent = ({ data }) => {
  const [value, setValue] = useState(0);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const columnsDeps = columnsDependentes();
  const comprasPorMes = data?.comprasPorMes || [];
  const comprasPorGrupo = data?.comprasPorGrupo || [];
  const resumo = data?.resumo || {};
  const dependentes = data?.dependentes || [];
  return (
    <>
      <Box sx={{ width: "100%" }} p={2}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Resumo" {...a11yProps(0)} />
            <Tab label="Tabela compras por mês" {...a11yProps(1)} />
            <Tab label="Tabela compras por grupo" {...a11yProps(2)} />
            <Tab label="Tabela dependentes" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          containerStyle={{
            transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
          }}
          onChangeIndex={handleChange}
        >
          <CustomTabPanel value={value} index={0} dir={theme.direction}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              direction={["column", "row"]}
            >
              <CardIconHome
                label={"Cashback Ativo"}
                value={resumo.cashBackAtivo}
                icon={<FaMoneyBillAlt fontSize="2rem" color={green[500]} />}
              />
              <CardIconHome
                label={"Cashback Usado"}
                value={resumo.cashBackUsado}
                icon={<FaHandHoldingUsd fontSize="2rem" color={amber[400]} />}
              />
              <CardIconHome
                label={"Ultima compra"}
                value={dayjs(resumo.dataUltimaCompra).format(
                  "DD/MM/YYYY, [às] HH:mm"
                )}
                icon={<IoCalendarOutline fontSize="2rem" color={blue[400]} />}
              />
              <CardIconHome
                label={"Dias da ultima compra"}
                value={resumo.diasUltimaCompra}
                icon={
                  <AiOutlineClockCircle fontSize="2rem" color={orange[900]} />
                }
              />
              <CardIconHome
                label={"Periodicidade"}
                value={resumo.periodicidade}
                icon={<BsArrowRepeat fontSize="2rem" color={teal[500]} />}
                BsArrowRepeat
              />
              <CardIconHome label={"Qtd compras"} value={resumo.qtdCompras} />
              <CardIconHome
                label={"Ticket Medio"}
                value={converterNumero(resumo.ticketMedio)}
                icon={<FcStatistics fontSize="2rem" />}
              />
              <CardIconHome
                label={"Total comprado"}
                value={converterNumero(resumo.totalComprado)}
                icon={<FcMoneyTransfer fontSize="2rem" />}
              />
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1} dir={theme.direction}>
            <Box component={Paper} elevation={1}>
              <DataTable
                columns={columnsComprasPorMes}
                data={comprasPorMes}
                pagination={comprasPorMes.length > 10}
                noDataComponent={<NoData />}
              />
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2} dir={theme.direction}>
            <Box component={Paper} elevation={1}>
              <DataTable
                columns={columnsComprasPorGrupo}
                data={comprasPorGrupo}
                pagination={comprasPorGrupo.length > 10}
                noDataComponent={<NoData />}
              />
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3} dir={theme.direction}>
            <Box component={Paper} elevation={1}>
              <DataTable
                columns={columnsDeps}
                data={dependentes}
                pagination={dependentes.length > 10}
                noDataComponent={<NoData />}
              />
            </Box>
          </CustomTabPanel>
        </SwipeableViews>
      </Box>
    </>
  );
};
