import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import "./Menu.css";
import { useHistory } from "react-router-dom";
import { useUserData } from "../hooks/useUserData";

function MenuLateral() {
  const { userData } = useUserData();

  const history = useHistory();
  const projeto = "bi";

  const handleMenu = (event, data) => {
    if (projeto === data?.project) {
      event.preventDefault();
      history.push(`${data.path}`);
    }
  };

  return (
    <>
      <ProSidebar collapsed={false} className="proSide">
        <SidebarContent>
          <Menu iconShape="square">
            <>
              {userData?.menu?.length > 0 &&
                userData?.menu?.map((menu) => (
                  <>
                    <MenuItem className="titulo-menu">{menu?.menu}</MenuItem>
                    {menu?.submenus?.length > 0 &&
                      menu?.submenus?.map((submenu) => (
                        <>
                          <SubMenu
                            title={submenu?.name}
                            icon={<FontAwesomeIcon icon={faPlus} />}
                          >
                            {submenu?.items?.length > 0 &&
                              submenu?.items?.map((itemMenu) => {
                                return (
                                  <>
                                    <MenuItem>
                                      <a
                                        className="linkMenu"
                                        href={
                                          !itemMenu?.project
                                            ? `${itemMenu?.absolutePath}?id=${userData.id}&token=${userData.token}`
                                            : projeto === itemMenu?.project
                                            ? itemMenu?.path
                                            : itemMenu?.absolutePath +
                                              userData.id +
                                              "/" +
                                              userData.token
                                        }
                                        onClick={(e) => handleMenu(e, itemMenu)}
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {itemMenu?.fieldName}
                                      </a>
                                    </MenuItem>
                                  </>
                                );
                              })}
                          </SubMenu>
                        </>
                      ))}
                  </>
                ))}
            </>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </>
  );
}

export default MenuLateral;
