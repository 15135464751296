import React from "react";
import { converterNumero } from "../util/loja";

const CellTable = ({ rowInfo }) => {
  const value = converterNumero(rowInfo);
  return (
    <>
      {+rowInfo === 0 ? (
        <span style={{ color: "#f39c12" }}>{value}</span>
      ) : rowInfo < 0 ? (
        <span style={{ color: "#ff0000", fontWeight: "bold" }}>{value}</span>
      ) : (
        <span>{value}</span>
      )}
    </>
  );
};

export default CellTable;
