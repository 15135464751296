import React, { useEffect, useState } from "react";

import {
  Col,
  Container,
  Dropdown,
  Form,
  FormControl,
  FormLabel,
  FormSelect,
  Row,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import "chartjs-plugin-datalabels";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import Title from "../../components/title";

import { baseService } from "../../services/baseServices";
import TabelaNext from "../../components/componentteste/TabelaNext";
import {
  colunasEstoquePorBase,
  colunasEstoquePorLocal,
  colunasEstoqueProdutos,
} from "../../util/tables";

import { arrayBases } from "../../util/loja";
import toast from "react-hot-toast";

import { PermissionsPage } from "../../util/permissionsPage";
import { CollapseTable } from "../../components/TableCollapse";
import FilterMultBases from "../../components/FilterMultBases";
import { useCallback } from "react";
import { ContainerIniciarBusca } from "./styles";
import ButtonFiltrar from "../../components/ButtonFiltrarSubmit";

import { ModalPromocao } from "../../components/ModalPromocao";
import { useUserData } from "../../hooks/useUserData";

export function EstoqueProdutos() {
  const { userData } = useUserData();
  const accessLevel = userData?.nivelAcesso;
  const acessBase = userData?.baseAcesso;
  const [estoque, setEstoque] = useState([]);

  const [carregando, setCarregando] = useState(false);
  const [modalPromocaoOpen, setModalPromocaoOpen] = useState(false);
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);

  const [basesSelecionadas, setBasesSelecionadas] = useState([]);

  const [orderColumn, setOrderColumn] = useState("5");
  const [orderType, setOrderType] = useState("desc");

  useEffect(() => {
    if (accessLevel?.length === 0 || basesDisponiveis?.length === 0) {
      buscarNivelAcesso();
      return;
    }
  }, [searchTerm, basesSelecionadas, userData, basesDisponiveis]);

  useEffect(() => {
    if (!searchTerm) return;

    if (!basesSelecionadas?.length) return;

    preencherDadosEstoquePorGrupo();
  }, [page]);

  const preencherDadosEstoquePorGrupo = useCallback(
    async (restart) => {
      const pageAux = restart ? 1 : page;
      const toastId = toast.loading(`Buscando os dados`);
      setCarregando(true);
      const data = await baseService.searchProduct({
        baseAcesso: JSON.stringify(basesSelecionadas),
        nivelAcesso: JSON.stringify(accessLevel),
        searchTerm,
        page: pageAux - 1,
        orderBy: orderColumn,
        orderByType: orderType,
      });
      setCarregando(false);
      if (!data) {
        toast.error(`Erro ao buscar dados`, {
          id: toastId,
        });
        // setEstadosControle({ erroReceberDadosRAD: true });
        return;
      }

      setEstoque(data.data);
      setTotalSize(data.total);
      toast.success(`Dados recebidos`, {
        id: toastId,
      });
    },
    [basesSelecionadas, accessLevel, searchTerm, page, orderColumn, orderType]
  );

  const filtrarBases = (codBase) => {
    let basesSelecionadasTemp = basesSelecionadas.filter((a) => true);
    if (
      basesSelecionadasTemp.filter((a) => Number(a) === Number(codBase))
        .length > 0
    ) {
      basesSelecionadasTemp = basesSelecionadasTemp.filter(
        (a) => Number(a) !== Number(codBase)
      );
    } else {
      basesSelecionadasTemp.push(codBase);
    }

    setBasesSelecionadas(basesSelecionadasTemp);
  };

  const buscarNivelAcesso = async () => {
    if (accessLevel) {
      setBasesDisponiveis(arrayBases(acessBase));

      if (acessBase.length > 0) {
        setBasesSelecionadas([acessBase[0]]);
      }
    } else {
      setCarregando(false);
    }
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => {
      return (
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className=""
        >
          <Tab eventKey="home" title="Por Base">
            {estoque && (
              <Row className="wrapper">
                <TabelaNext
                  dadosParaTabela={row?.dadosPorBase ? row.dadosPorBase : []}
                  colunas={colunasEstoquePorBase()}
                />
              </Row>
            )}
          </Tab>
          <Tab eventKey="profile" title="Por Local de Estoque">
            <TabelaNext
              dadosParaTabela={row?.estoquePorLocal ? row.estoquePorLocal : []}
              colunas={colunasEstoquePorLocal()}
            />
          </Tab>
        </Tabs>
      );
    },
  };

  function iniciarBusca(e) {
    e.preventDefault();
    if (!searchTerm) {
      return toast.error("Digite algo antes de buscar");
    }
    if (!basesSelecionadas?.length) {
      return toast.error("Selecione pelo menos uma base antes de buscar");
    }
    preencherDadosEstoquePorGrupo(true);
  }

  const handleTableChange = useCallback(
    (type, { page, sizePerPage, ...rest }) => {
      if (type === "pagination") {
        setPage(page);
      }
    },
    []
  );

  function openModalPromocao() {
    setModalPromocaoOpen(true);
  }

  function handleCloseModalPromocao() {
    setModalPromocaoOpen(false);
  }

  return (
    <>
      <div className="container-app">
        {PermissionsPage("estoqueProdutos") ? (
          <Container>
            <div className="container-conteudo">
              <Container className="containerFlexBox">
                <Title title="CONSULTA PRODUTOS" />
                <FilterMultBases
                  basesDisponiveis={basesDisponiveis}
                  basesSelecionadas={basesSelecionadas}
                  filtrarBases={filtrarBases}
                />
              </Container>
              <Form onSubmit={iniciarBusca}>
                <Row className="row-filter-produtos">
                  <Col xs={12} md={3}>
                    <FormLabel>Pesquisar</FormLabel>
                    <FormControl
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Nome do produto ou código"
                    />
                  </Col>
                  <Col xs={12} md={3}>
                    <FormLabel>Ordenar</FormLabel>
                    <FormSelect
                      value={orderColumn}
                      onChange={(e) => setOrderColumn(e.target.value)}
                    >
                      <option value="2">Cód. Prod.</option>
                      <option value="3">Descrição</option>
                      <option value="4">Vlr. Tab.</option>
                      <option value="5">Estoque</option>
                      <option value="10">Desc. Prom. Esp.</option>
                      <option value="12">Vlr. Promo.</option>
                      <option value="6">Grupo</option>
                      <option value="7">Subgrupo</option>
                      <option value="13">Est. Transito</option>
                    </FormSelect>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormLabel>Tipo de ordenação</FormLabel>
                    <FormSelect
                      value={orderType}
                      onChange={(e) => setOrderType(e.target.value)}
                    >
                      <option value="asc">Asc</option>
                      <option value="desc">Desc</option>
                    </FormSelect>
                    <div className="Content-buttons-filtrar-hoje ">
                      <ButtonFiltrar
                        type={carregando ? "button" : "submit"}
                        text={
                          carregando ? (
                            <Spinner animation="border" size={"sm"} />
                          ) : (
                            "Buscar"
                          )
                        }
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={2} className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="botao-filtrar-input-data"
                      >
                        Opções
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => openModalPromocao()}>
                          Adicionar promoção
                        </Dropdown.Item>
                        {/* <Dropdown.Item href="#/action-2">
                          Adicionar promoção em lote
                        </Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </Form>
              {estoque?.length ? (
                <>
                  <Container>
                    {/* <TabelaNext dadosParaTabela={estoque} colunas={colunasEstoqueContasPagar()} /> */}
                    <CollapseTable
                      rowKey="codProd"
                      dadosParaTabela={estoque}
                      colunas={colunasEstoqueProdutos()}
                      expandRow={expandRow}
                      totalSize={totalSize}
                      page={page}
                      handleTableChange={handleTableChange}
                    />
                  </Container>
                </>
              ) : (
                <ContainerIniciarBusca>
                  Escolha a(s) base(s) digite o nome ou código do produto e
                  clique em "Buscar"
                </ContainerIniciarBusca>
              )}
              <ModalPromocao
                show={modalPromocaoOpen}
                handleClose={handleCloseModalPromocao}
              />
            </div>
          </Container>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
