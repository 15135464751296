import {
  FormControl,
  InputLabel,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
const sexoOpcoes = [
  { nome: "Masculino", id: "M" },
  { nome: "Feminino", id: "F" },
  { nome: "Não sei ainda", id: "N" },
];
export const Gestante = ({ register, Controller, control }) => {
  return (
    <>
      <Stack
        spacing={1}
        component={Paper}
        elevation={1}
        variant="outlined"
        p={1}
        mb={1}
      >
        <Typography component={"h6"} fontWeight={"500"}>
          Gestante
        </Typography>
        <Stack gap={1} direction={"row"}>
          <FormControl fullWidth>
            <InputLabel size="small" id="select-pessoa">
              Grau de Parentesco
            </InputLabel>
            <Select
              labelId="grau-parentesco"
              id="simple-grau-parentesco"
              label="Grau de Parentesco"
              {...register("dadosGestante.grauParentesco")}
              size="small"
              native
              placeholder="Grau de Parentesco"
              variant="outlined"
            >
              {sexoOpcoes.map((item) => (
                <option key={item.id} value={item.id.toUpperCase()}>
                  {item.nome}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <TextField
              {...register("dadosGestante.nome")}
              size="small"
              label="Nome"
              placeholder=""
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              {...register("dadosGestante.semanasgestacao")}
              size="small"
              label="Semanas de gestação"
              placeholder=""
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Stack>

        <Stack gap={1} direction={"row"}>
          <FormControl fullWidth>
            <InputLabel size="small" id="select-pessoa">
              Sexo
            </InputLabel>
            <Select
              labelId="sexo-dependentes"
              id="simple-sexo-dependentes"
              label="Sexo"
              {...register("dadosGestante.sexo")}
              size="small"
              native
              placeholder="Sexo"
              variant="outlined"
            >
              {sexoOpcoes.map((item) => (
                <option key={item.id} value={item.id.toUpperCase()}>
                  {item.nome}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <Controller
              control={control}
              {...register("dadosGestante.nascimento")}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Data Nascimento"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth>
            <Controller
              control={control}
              {...register("dadosGestante.datanascimentoprevisto")}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Data Nascimento Prevista"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
    </>
  );
};
