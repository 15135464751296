import { Paper, Skeleton, Stack } from "@mui/material";
import { Box } from "@mui/system";

export const IsloadingFormCreate = () => {
  return (
    <Box width={"100%"} p={2}>
      <Box component={Paper} elevation={1} variant="outlined" p={2}>
        <Skeleton
          sx={{
            borderRadius: "1rem",
          }}
          variant="rounded"
          width={40}
          height={20}
        />

        <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={200} />

        <Stack spacing={1.5}>
          <Stack direction={["column", "row"]} spacing={2} gap={2}>
            <Skeleton variant="rounded" width={"100%"} height={35} />
            <Skeleton variant="rounded" width={"100%"} height={35} />
          </Stack>
          <Skeleton variant="rounded" width={"100%"} height={35} />
          <Skeleton variant="rounded" width={"100%"} height={35} />
          <Stack direction={["column", "row"]} spacing={2}>
            <Skeleton variant="rounded" width={"100%"} height={120} />
            <Skeleton variant="rounded" width={"100%"} height={120} />
          </Stack>
          <Stack direction={["column", "row"]} gap={2} spacing={2}>
            <Skeleton variant="rounded" width={"100%"} height={120} />
            <Skeleton variant="rounded" width={"100%"} height={120} />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
