import { Box, Chip, Grid, Typography } from "@mui/material";

export const GridItem = ({ label, value }) => {
  const isArray = Array.isArray(value) && value?.length > 0;
  
  return (
    <>
      {(!!value || isArray) && (
        <Grid item xs={6} {...(isArray ? { xs: 6, md: "auto" } : { xs: 6 })}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={1}
          >
            <Typography
              whiteSpace={"nowrap"}
              fontWeight={"bold"}
              component={"span"}
            >
              {label}:
            </Typography>
            {isArray ? (
              <Box
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"wrap"}
                alignItems={"center"}
                gap={1}
              >
                {value.map((item) => (
                  <Chip size="small" label={item} />
                ))}
              </Box>
            ) : (
              <Chip size="small" label={value} />
            )}
          </Box>
        </Grid>
      )}
    </>
  );
};
