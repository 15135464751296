import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ContainerCellAside } from "./styles";

export const CellNameYaer = ({ row }) => {
    const lastMonthHidden = !row.lastMonth?.hidden;
    const lastYearHidden = !row.lastYear?.hidden;
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="button-tooltip-2">
            <p>AT - Ano Atual</p>
            {lastYearHidden && <p>AN - Ano Anterior</p>}
            {lastMonthHidden && <p>PA - Período Anterior</p>}
          </Tooltip>
        }
      >
        {({ ref, ...triggerHandler }) => (
          <ContainerCellAside ref={ref} {...triggerHandler}>
            <strong>AT</strong>
            {lastYearHidden && <strong>AN</strong>}
            {lastMonthHidden && <strong>PA</strong>}
          </ContainerCellAside>
        )}
      </OverlayTrigger>
    );
  };
  