import { HelpOutlineOutlined } from "@mui/icons-material";
import { Autocomplete, FormControl, Tooltip } from "@mui/material";
import { StyledInputNumber } from "./StyledInputNumber";

export const InputAutoComplete = ({
  titleTooltip,
  values = {},
  setValues,
  ...props
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.value) {
      const newValue = event.target.value;
      if (values[props.id]?.includes(newValue)) {
        return;
      }
      setValues({
        ...values,
        [props.id]: [...values[props.id], newValue],
      });
      event.target.value = "";
    }
  };
  return (
    <FormControl
      sx={{
        display: "flex",
        alignItems: "initial",
        flexDirection: "row",
        gap: 0.5,
      }}
      fullWidth
    >
      <Autocomplete
        {...props}
        multiple
        options={[]}
        freeSolo
        size="small"
        value={values[props.id]} // Definir o valor inicialmente com um valor definido
        onChange={(event, newValue) => {
          setValues((old) => ({ ...old, [props.id]: newValue }));
        }}
        fullWidth
        renderInput={(params) => (
          <StyledInputNumber
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            size="small"
            sx={{ width: "100%", minWidth: "220px" }}
            label={props.name}
            type="number"
            onKeyDown={handleKeyDown}
            helperText={'Digite e pressione "Enter"'}
          />
        )}
      />
      <Tooltip title={titleTooltip} followCursor>
        <HelpOutlineOutlined sx={{ mt: 1, color: "#757575" }} />
      </Tooltip>
    </FormControl>
  );
};
