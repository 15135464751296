import React from "react";
import { Container } from "react-bootstrap";

import Select from "react-select";
import makeAnimated from "react-select/animated";

export const FilterLoja = ({
  labelsTable,
  infoSelect,
  functionEmpresa,
  direcionarParaMetasPorVendedorasLojaEspecifica,
  required,
}) => {
  if (labelsTable.length === 0) {
    return " ";
  }
  const animatedComponents = makeAnimated();
  const options = labelsTable.map((item) => ({
    value: item.codLoja,
    label: item.nomeLoja,
  }));

  const defaultValue = options.filter((item) => {
    if (infoSelect?.length > 0) {
      if (infoSelect.indexOf(item.value) >= 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  });

  const defaultValueVendedor = options.filter((item) => {
    if (item.value === infoSelect.codLoja) {
      return item;
    } else {
      return false;
    }
  });

  const onChange = (value) => {
    if (required) {
      let index, codLoja, codBase;
      labelsTable.map((a, i) => {
        if (a.codLoja === value.value) {
          index = i;
          codLoja = a.codLoja;
          codBase = a.codBase;
        }
      });
      // const selecteds = value.map(item => item.value)
      functionEmpresa(index, codLoja, codBase);
    } else {
      if (value.length === 0) {
        const selecteds = options.map((item) => item.value);
        return functionEmpresa(selecteds);
      }
      const selecteds = value.map((item) => item.value);
      functionEmpresa(selecteds);
    }
  };

  return (
    <Container>
      {required ? (
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          value={defaultValueVendedor}
          options={options}
          placeholder="Selecione as lojas"
          onChange={onChange}
        />
      ) : (
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          value={defaultValue}
          isMulti
          options={options}
          placeholder="Selecione as lojas"
          onChange={onChange}
        />
      )}
    </Container>
  );
};
