import styled from "styled-components";

export const ContainerConteudo = styled.div`
  max-width: 1140px;
  width: 95%;
  .linkPesquisa {
    text-decoration: none;
    color: #f74e99;
  }
`;
export const ContainerDesempenho = styled.div`
  width: 100%;
  > section {
    display: flex;
    justify-content: center;
  }
`;
export const ContainerTable = styled.div`
  display: flex;
  justify-content: center;
  > main {
    display: block;
    width: 98%;
  }
`;
export const ContainerTudo = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
`;

export const WraperContainer = styled.div`
  width: auto;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1), 0 5px 50px -15px rgba(0, 0, 0, 0.1);
  > aside {
    padding: 1rem;
  }
  & .react-bootstrap-table {
    max-width: 100vw;
    overflow-x: scroll;
  }
`;

export const DivGrafico = styled.section`
  /* display: flex; */
  transition: color 2s;
  aside {
    display: flex;
    justify-content: center;
    gap: 1rem;
    > section {
      /* max-width: 19rem; */
      display: flex;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

      > div {
        max-width: 20rem;
      }
    }
    > div {
      min-width: 12rem;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      padding: 0 1rem;
      section {
        display: block;
      }
      > div {
      }
      aside {
        margin: 1rem 0;
        height: max-content;

        button {
          background: #fff;
          padding: 0.3rem 1.5rem;
          border-radius: 0.5rem;
          width: 100%;
          /* border-color:#f74e99; */
          border: 1px solid #f74e99;
          &:hover {
            filter: brightness(0.9);
            background-color: #f74e99;
          }
        }
      }
    }
  }
`;
export const WrapperTable = styled.div`
  position: relative;
  width: auto;
  padding: 1rem;
  margin-top: 18px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1), 0 5px 50px -15px rgba(0, 0, 0, 0.1);
  > aside {
    padding: 1rem;
  }

  ${(props) =>
    props?.isApp
      ? `
        margin-top: 0;
        padding-top: 0;
        `
      : ""}
`;
export const ContainerInputPeriodo = styled.section`
  display: grid;
  justify-content: center;
  gap: 0.24rem;
  div {
    display: flex;
    justify-content: space-between;
    gap: 0.24rem;
    input {
      width: 132px;
    }
  }
`;
export const ContainerPeriodo = styled.div`
  display: flex;
  justify-content: center;
`;
export const ContainerLegendaMetas = styled.section`
  padding: 1rem;
  small {
    padding: 5px 10px;
    color: #fff;

    margin-right: 10px;
  }
  .cor-diamante {
    background-color: #6395ec;
    color: #fff;
  }

  .cor-ouro {
    background-color: #ffd700;
    color: #000;
  }

  .cor-meta {
    background-color: #00b14f;
    color: #fff;
  }

  .cor-abaixo-meta {
    background-color: #ff0000;
    color: #fff;
  }
`;
export const ContainerFiltros = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
