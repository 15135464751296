import { useEffect, useState } from "react";
import boletim from "../../../services/boletim";
import toast from "react-hot-toast";

import { columnsTablePurchases } from "../../../column/boletim/Purchases";
import { DataTableBoletim } from "../DataTableBoletim/DataTableBoletim";
export const Puchases = ({ paramsConfig, basesDisponiveis }) => {
  const [dataPagePurchases, setDataPagePurchases] = useState([]);
  const [isLoadingPurchases, setIsLoadingPurchases] = useState(false);

  async function getPurchases() {
    try {
      setIsLoadingPurchases(true);
      const response = await boletim.getPurchases(paramsConfig.base, {
        periodo: paramsConfig.periodo,
      });

      const data = response.data;
      const purchasesExpand = data.filter((item) => !item?.isTotal);
      const purchases = [data.find((item) => !!item?.isTotal)];
      purchases[0].expand = purchasesExpand;

      setDataPagePurchases(purchases);
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
      setIsLoadingPurchases(false);
    }
  }
  useEffect(() => {
    if (!paramsConfig.base) return;
    getPurchases();
  }, [basesDisponiveis, paramsConfig]);

  return (
    <DataTableBoletim
      columns={columnsTablePurchases}
      data={dataPagePurchases}
      label="Compras por grupo"
      isLoading={isLoadingPurchases}
    />
  );
};
