import { api } from "./api.v2";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: async (base, params) =>
    await api.get(`/planning/${base}/budget`, {
      params,
    }),
  getRealized: async (base, params) =>
    await api.get(`/planning/${base}/budgeted/realized`, {
      params,
    }),
};
