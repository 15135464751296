import { Box, Paper, Skeleton, Stack } from "@mui/material";

export const LoadingViewInfluencer = () => {
  return (
    <Box
      display={"flex"}
      flexDirection="row"
      component={Paper}
      py={2}
      px={4}
      variant="outlined"
      elevation={1}
      gap={4}
    >
      <Skeleton variant="circular" width={120} height={120} />
      <Stack flex={1}>
        <Skeleton variant="rounded" height={35} width={480} />
        <Box mt={2} gap={0.5} display={"grid"} gridTemplateColumns={"1fr 1fr"}>
          <Skeleton variant="text" width={180} sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" width={280} sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" width={260} sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" width={180} sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" width={180} sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" width={160} sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Box>
      </Stack>
    </Box>
  );
};
