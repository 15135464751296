import {
  Box,
  Collapse,
  IconButton,
  Link,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import toast from "react-hot-toast";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { FiMoreVertical } from "react-icons/fi";
import { StyledTableCell } from "./StyledTableCell";
import { useHistory } from "react-router-dom";
import DenseMenu from "./DenseMenu";
import { SimpleDialog } from "./SimpleDialog";
import { ButtonCopyNumber } from "../../../components/ButtonCopyNumber/ButtonCopyNumber";
export function Row(props) {
  const { row, optionsProfile, handleDelete } = props;
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  const notInfoUsers = row.user.length > 0;
  const handleOpen = () => {
    if (notInfoUsers) {
      setOpen(!open);
    } else {
      toast.error("Esse influencer não possui nenhum usuario indicado");
    }
  };
  const profile_indicate = optionsProfile.find(
    (item) => item.id === row.profile_indicate
  ).name;

  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const link = `/franquia/lista-interresados/${row?.id}`;
  const handleClickId = (e) => {
    e.preventDefault();
    history.push(link);
  };
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const visibleRows = useMemo(
    () => row.user.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, row]
  );

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    handleDelete(row.id);
  };
  const cellRow = [
    <Box>
      <IconButton aria-label="expand row" size="small" onClick={handleOpen}>
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    </Box>,
    <IconButton
      aria-controls={openMenu ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={openMenu ? "true" : undefined}
      onClick={handleClick}
    >
      <FiMoreVertical />
    </IconButton>,
    <Link onClick={handleClickId} href={link}>
      {row.id}
    </Link>,
    row?.name,
    profile_indicate,
    <>{!!row?.phone && <ButtonCopyNumber phone={row?.phone} />}</>,
    row?.cpf,
    row?.email,
    row?.user?.length,
  ];

  return (
    <>
      <TableRow>
        {cellRow.map((cell, index) => (
          <TableCell key={index}>{cell}</TableCell>
        ))}
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Interessados
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Nome</StyledTableCell>
                      <StyledTableCell>Cidade</StyledTableCell>
                      <StyledTableCell align="right">Estado</StyledTableCell>
                      <StyledTableCell align="right">Telefone</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleRows.map((userRow) => (
                      <TableRow
                        sx={{ bgcolor: "#f9f9f9" }}
                        key={userRow.created_at}
                      >
                        <TableCell component="th" scope="row">
                          {userRow.name}
                        </TableCell>
                        <TableCell>{userRow.city}</TableCell>
                        <TableCell align="right">{userRow.uf}</TableCell>
                        <TableCell align="right">{userRow.phone}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[
                    10,
                    25,
                    50,
                    100,
                    { label: "Todos", value: row.user.length },
                  ]}
                  labelRowsPerPage={"Linhas por pagina"}
                  component="div"
                  count={row.user.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                />
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <DenseMenu
        anchorEl={anchorEl}
        open={openMenu}
        edit
        id={row.id}
        link={link}
        onClose={handleClose}
        onDelete={handleClickOpenDialog}
      />
      <SimpleDialog open={openDialog} onClose={handleCloseDialog} />
    </>
  );
}
