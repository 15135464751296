import { converterNumero } from "../../../util/loja";
import { CellYearContainer } from "./styles";
import React from "react";

export const Money = ({ rowInfo }) => {
  const value = converterNumero(rowInfo);
  return (
    <>
      {+rowInfo === 0 ? (
        <span style={{ color: "#f39c12" }}>{value}</span>
      ) : rowInfo < 0 ? (
        <span style={{ color: "#ff0000", fontWeight: "bold" }}>{value}</span>
      ) : (
        <span>{value}</span>
      )}
    </>
  );
};


export const CellMoney = ({ row, name }) => {
  const lastMonthHidden = !row.lastMonth?.hidden;
  const lastYearHidden = !row.lastYear?.hidden;
  return (
    <CellYearContainer>
      <div>
        <span>{<Money rowInfo={row.currency[name]} />}</span>
      </div>
      {lastYearHidden && (
        <div>
          <span>{<Money rowInfo={row.lastYear[name]} />}</span>
        </div>
      )}
      {lastMonthHidden && (
        <div>
          <span>{<Money rowInfo={row.lastMonth[name]} />}</span>
        </div>
      )}
    </CellYearContainer>
  );
};
