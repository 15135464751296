import { converterNumero } from "../../../util/loja";
import { TotalSalesGroup } from "./styles";

export const CellTotalSalesGroup = ({ row, name, type }) => {
  const value = row.Venda_Total_Grupo[name];
  return (
    <>
      {value > 0 && (
        <TotalSalesGroup>
          <strong>
            {type}: {converterNumero(value)}
          </strong>
        </TotalSalesGroup>
      )}
    </>
  );
};
