import { Divider, List } from "@mui/material";
import { SubMenuList } from "./SubMenuList";
import { useEffect, useState } from "react";
import { isEmptyObject } from "../../../util/isEmptyObject";

export const ListMenu = ({ userData, open, setOpen }) => {
  const [selected, setSelected] = useState({});
  const [subMenuName, setSubMenuName] = useState("");
  useEffect(() => {
    if (!isEmptyObject(selected)) {
      userData?.menu?.forEach((item, i) =>
        item.submenus.forEach((it) =>
          it.items.forEach((data) => {
            if (data.fieldName === selected.fieldName) {
              setSubMenuName(userData?.menu[i].menu);
            }
          })
        )
      );
    }
  }, [selected]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    userData?.menu?.forEach((item, i) =>
      item.submenus.forEach((it) =>
        it.items.forEach((data) => {
          if (
            data.path?.replace(/\//g, "") === currentPath.replace(/\//g, "")
          ) {
            setSubMenuName(userData?.menu[i].menu);
            setSelected(data);
          }
        })
      )
    );
  }, [userData]);
  return (
    <List
      sx={{
        py: 0,
      }}
    >
      {userData?.menu?.map((menu, index) => {
        return (
          <>
            <SubMenuList
              selected={selected}
              setSelected={setSelected}
              subMenuName={subMenuName}
              key={menu.menu}
              menu={menu}
              index={index}
              openMenu={open}
              setOpenMenu={setOpen}
            />
            {index !== userData?.menu?.length - 1 && <Divider />}
          </>
        );
      })}
    </List>
  );
};
