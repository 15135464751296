import styled from "styled-components";

export const CellYearContainer = styled.aside`
    display: grid;
    div{
        display: flex;
        justify-content: end;
        &:not(last-child) {
            border-bottom: 1px dashed #ABA8A7;
        }
    }
    
`
export const ContainerCellAside = styled.aside`
    display: grid;
    strong{
        &:not(last-child) {
            border-bottom: 1px dashed #ABA8A7;
        }
    }
`