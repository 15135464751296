import Chart from "chartjs-plugin-funnel/dist/chart.funnel.bundled";
import React, { useEffect, useRef } from "react";
const CLIENTES = "Clientes";
const SEMCADASTRO = "Sem cadastro";
const PRIMEIRACOMPRA = "1ª Compra";
const RECOMPRAS = "Recompras";
const Funnel2 = ({ dataPage }) => {
  const totalClientesCompras = dataPage.count_customers_shopping;
  const totalClientesPrimeiraCompras = dataPage.count_customers_first_purchase;
  const clientesSemCadastros = dataPage.count_sales_without_customer_registered;
  const calcPorcentagem = (valorPorcentagem) => {
    let porcentagem = 0;
    porcentagem = (valorPorcentagem * 100) / dataChart[0];
    return porcentagem.toFixed(2);
  };

  let clientesRecompras = totalClientesCompras - totalClientesPrimeiraCompras;
  let totalClientesCompras2 = [
    {
      title: CLIENTES,
      data: totalClientesCompras,
      legend: "",
      statusInformações: CLIENTES,
    },
    {
      title: SEMCADASTRO,
      data: clientesSemCadastros,
      legend: "Clientes que compraram sem cadastro",
      statusInformações: SEMCADASTRO,
    },
    {
      title: PRIMEIRACOMPRA,
      data: totalClientesPrimeiraCompras,
      legend: "Realizaram a primeira compra ",
      statusInformações: PRIMEIRACOMPRA,
    },
    {
      title: RECOMPRAS,
      data: clientesRecompras,
      legend: "Realizaram a recompra ",
      statusInformações: RECOMPRAS,
    },
  ];

  totalClientesCompras2.sort((a, b) => b.data - a.data);
  let dataChart = totalClientesCompras2.map((item) => item.data);
  let titleChart = totalClientesCompras2.map((item) => item.title);

  let statusInformações = totalClientesCompras2.map(
    (item) => item.statusInformações
  );
  let arrayPorcentagem = [];
  totalClientesCompras2.forEach((item) => {
    if (item.data === dataChart[0]) {
      return arrayPorcentagem.push(
        parseFloat((item.data * 100) / dataChart[0])
      );
    }
    arrayPorcentagem.push(
      parseFloat(((item.data * 100) / dataChart[0]).toFixed(0))
    );
  });
  const chartRef = useRef();
  const myChartRef0 = useRef();

  useEffect(() => {
    const myChartRef = chartRef.current.getContext("2d");
    if (myChartRef0.current) {
      myChartRef0.current.destroy();
    }

    myChartRef0.current = new Chart(myChartRef, {
      type: "funnel",
      title: "Gratis",
      data: {
        labels: titleChart,
        datasets: [
          {
            label: "clientes",
            borderColor: "#b2dbed",
            backgroundColor: [
              "#67b7dc",
              "#6771dc",
              "#a367dc",
              //'#dc67ce'
            ],
            data: dataChart,
          },
        ],
      },
      options: {
        sort: "desc",
        legend: {
          position: "bottom",
          align: "start",
        },
        layout: {
          padding: {
            right: 80,
            left: 40,
          },
        },
        scales: {
          yAxes: [
            {
              beginAtZero: true,

              drawOnChartArea: false,
              position: "right",
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                fontSize: 15,
                beginAtZero: true,
                callback: (value, index) => {
                  if (index === 0) {
                    return [
                      [value],
                      [dataChart[index]],
                      arrayPorcentagem[index] + " % ",
                    ];
                  } else if (index === 1) {
                    return [
                      [value],
                      [dataChart[index]],
                      arrayPorcentagem[index] + " % ",
                    ];
                  } else if (index === 2) {
                    return [
                      [value],
                      [dataChart[index]],
                      arrayPorcentagem[index] + " % ",
                    ];
                  } else if (index === 3) {
                    return [
                      [value],
                      [dataChart[index]],
                      arrayPorcentagem[index] + " % ",
                    ];
                  }
                },
              },
            },
          ],
        },
        title: {
          display: true,
          text: "Analise de Compras",
        },
        tooltips: {
          displayColors: true,
          callbacks: {
            title: (tooltipItem, data) => {
              let dataIndex = tooltipItem[0].index;
              if (tooltipItem[0].index === dataIndex) {
                return "Status: " + statusInformações[dataIndex];
              }
            },
            label: (tooltipItem, data) => {
              let arraydata = data.datasets[0].data;
              let dataIndex = tooltipItem.index;
              if (tooltipItem.index === dataIndex) {
                return "Conversão: " + arraydata[dataIndex];
              }
            },
            footer: (tooltipItem, data) => {
              console.log(data);
              let dataD = data.datasets[0].data[tooltipItem[0].index];
              if (tooltipItem[0]?.label === CLIENTES) {
                return "Total de clientes que realizaram compras";
              }
              if (tooltipItem[0]?.label === SEMCADASTRO) {
                return "Total de clientes sem cadastro";
              }
              if (tooltipItem[0]?.label === RECOMPRAS) {
                return (
                  "Percentual de " + calcPorcentagem(dataD) + " % dos clientes"
                );
              }
              if (tooltipItem[0]?.label === PRIMEIRACOMPRA) {
                return [
                  [
                    "Percentual de " +
                      calcPorcentagem(dataD) +
                      " % do total de clientes",
                  ],
                ];
              }
              return "";
            },
          },
        },
        elements: {
          animation: true,
        },
      },
    });
  }, [dataPage]);

  return (
    <div>
      <canvas id="myChart" ref={chartRef} />
    </div>
  );
};

export default Funnel2;
