import React, { useEffect, useState } from "react";
import "chartjs-plugin-datalabels";
import Title from "../../components/title";

import "react-data-table-component-extensions/dist/index.css";
import { arrayBases } from "../../util/loja";

import { Col, Container, Row, Stack } from "react-bootstrap";
import toast from "react-hot-toast";

import { PermissionsPage } from "../../util/permissionsPage";
import { vendasService } from "../../services/vendas";
import dayjs from "dayjs";
import DataTable from "react-data-table-component";
import { LoadingTable } from "../../components/Loading/LoadingTable";
import { DatePikerFilter } from "../../components/DatePikerFilter/DatePikerFilter";

import {
  ExpandableRowsComponent,
  columnsTable,
} from "../../column/vendas-por-vendedor-grupo/vendas-por-vendedor-grupo";
import { MultiSelectBase } from "../../components/MultiSelect/MultiSelectBase/MultiSelectBase";
import { MultiSelectLojas } from "../../components/MultiSelect/MultiSelectLojas/MultiSelectLojas";
import { formatShopAvailable } from "../../formatdata/formatter-shop-available";
import { SubTituloTabela } from "../../components/SubTituloTabela/TituloTabela";
import { NoData } from "../../components/NoData/NoData";
import { useUserData } from "../../hooks/useUserData";
export const VendasPorVendedorGrupo = () => {
  const { userData } = useUserData();
  const accessLevel = userData?.nivelAcesso || [];
  const acessBase = userData?.baseAcesso || [];
  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);

  const [isLoadingData, setIsLoadingData] = useState(true);

  const [dataTable, setDataTable] = useState([]);
  const [dataTableBackup, setDataTableBackup] = useState([]);
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const firstDayOfMonth = dayjs().startOf("month").format("YYYY-MM-DD");
  const currencyDay = dayjs().format("YYYY-MM-DD");

  const [filterShop, setFilterShop] = useState([]);
  const [paramsConfig, setParamsConfig] = useState({
    base: [],
    first_period: firstDayOfMonth,
    final_period: currencyDay,
    stores: [],
  });
  const columns = columnsTable;
  useEffect(() => {
    if (!!userData) {
      buscarNivelAcesso();
    }
  }, [userData]);

  const buscarNivelAcesso = () => {
    if (accessLevel) {
      const bases = arrayBases(acessBase);
      setBasesDisponiveis(bases);
      const fristBase = bases.find((item) => item.codBase);
      const selectBaseInitial = [fristBase].map((item) => item.codBase);
      const shopsAvailableForBase = formatShopAvailable(
        selectBaseInitial,
        accessLevel
      );
      setLojasDisponiveis(accessLevel);
      setParamsConfig((old) => ({
        ...old,
        base: selectBaseInitial,
        stores: shopsAvailableForBase.map((item) => item.value),
      }));
      setFilterShop(shopsAvailableForBase);
    }
  };

  async function preencherDados() {
    window.scrollTo(0, 0);
    const toastId = toast.loading("Esperando dados do RAD");

    setIsLoadingData(true);
    const arrayBases = paramsConfig.base;

    const shopsAvailableForBase = formatShopAvailable(arrayBases, accessLevel);
    setFilterShop(shopsAvailableForBase);
    const response = await vendasService.listaVendasPorVendedorGrupo({
      ...paramsConfig,
      stores: shopsAvailableForBase.map((item) => item.value),
    });
    if (response.status === 200) {
      const data = response.data;

      setDataTable(data);
      setDataTableBackup(data);
      toast.success("Dados recebidos", {
        duration: 5000,
        id: toastId,
      });
    } else {
      toast.error("Ocorreu um erro ao carregar, por favor tente novamente", {
        id: toastId,
      });
    }
    setIsLoadingData(false);
  }

  useEffect(() => {
    if (paramsConfig.base.length <= 0) return;
    preencherDados();
  }, [paramsConfig]);

  const onChangeDatePicker = (value) => {
    setParamsConfig((old) => ({ ...old, ...value }));
  };
  const onChangeMultiSelect = (value, name) => {
    let filterId = value.map((val) => val.value);
    setParamsConfig((old) => ({ ...old, [name]: filterId }));
  };

  const handleMultiSelectFilterShop = (value) => {
    setFilterShop(value);
  };

  useEffect(() => {
    const filterShopIds = filterShop.map((item) => +item.value);
    const dataFilter = dataTableBackup?.filter((func) => {
      if (filterShopIds.includes(func.codloja)) {
        return func;
      }
    });
    setDataTable(dataFilter);
  }, [filterShop]);

  return (
    <>
      <div className="container-app">
        <div className="container">
          {PermissionsPage("vendGrupo") && (
            <>
              <div className="container-conteudo">
                <Container className="containerFlexBox">
                  <div className="containerFlexBoxRow">
                    <Title title="Vendas Por Vendedor/Grupo" />
                  </div>
                </Container>

                <Row
                  className="justify-content-md-center"
                  style={{ padding: "0 1rem" }}
                >
                  <DatePikerFilter
                    paramsConfig={paramsConfig}
                    onChange={onChangeDatePicker}
                    isLoading={isLoadingData}
                  />
                  <Col>
                    <Stack gap={2}>
                      <MultiSelectBase
                        bases={basesDisponiveis}
                        onChange={onChangeMultiSelect}
                        paramsConfig={paramsConfig}
                        isLoading={isLoadingData}
                      />
                      <MultiSelectLojas
                        lojas={lojasDisponiveis}
                        onChange={handleMultiSelectFilterShop}
                        paramsConfig={paramsConfig}
                        isLoading={isLoadingData}
                        shops={filterShop}
                      />
                    </Stack>
                  </Col>
                </Row>
              </div>
              <div className="container-conteudo" style={{ padding: "1rem" }}>
                <SubTituloTabela>
                  Legenda: <strong>AT</strong>- Ano atual. <strong>AN</strong>-
                  Ano anterior. <strong>PA</strong>- Período anterior.
                </SubTituloTabela>
                <DataTable
                  columns={columns}
                  data={dataTable}
                  expandableRows
                  expandableRowsComponent={ExpandableRowsComponent}
                  progressPending={isLoadingData}
                  progressComponent={<LoadingTable />}
                  pagination
                  noDataComponent={<NoData />}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
