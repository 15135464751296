import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { DateRange, DateRangePicker } from "react-date-range";
import { pt } from "date-fns/locale";
import { useContext, useState } from "react";
import { FormContext } from "../../pages/financeiro/metas/cadastro-metas";
import { addDays } from "date-fns";
import { useParams } from "react-router-dom";

export const DateRages = () => {
  const { setPeriodsSelects, periodsSelects, isLoadingData } =
    useContext(FormContext);
  const params = useParams();
  const [state, setState] = useState(() => {
    const date = new Date();

    const primeiroDiaDoMes = new Date(date.getFullYear(), date.getMonth(), 1);

    const proximoMes = new Date(date);
    proximoMes.setMonth(proximoMes.getMonth() + 1);
    const ultimoDiaDoMes = new Date(proximoMes);
    ultimoDiaDoMes.setDate(0);

    return [
      {
        startDate: primeiroDiaDoMes,
        endDate: ultimoDiaDoMes,
        key: "selection",
      },
    ];
  });
  const handleAddPeriod = () => {
    setPeriodsSelects((oldValue) => ({
      ...oldValue,
      [`selection${Object.values(periodsSelects).length + 1}`]: {
        startDate: addDays(new Date(), 8),
        endDate: addDays(new Date(), 10),
        key: `selection${Object.values(periodsSelects).length + 1}`,
      },
    }));
  };
  const handleRemovePeriod = () => {
    const copiaDoEstado = { ...periodsSelects }; // Crie uma cópia do estado
    const chaves = Object.keys(copiaDoEstado);
    const ultimoIndice = chaves[chaves.length - 1];
    delete copiaDoEstado[ultimoIndice]; // Remova o último elemento da cópia

    setPeriodsSelects(copiaDoEstado);
  };
  return (
    <Stack justifyContent={"center"} alignItems={"center"} direction={"row"}>
      <Stack position={"relative"} direction={"row"} mb={2} gap={2}>
        {!!params?.id && (
          <Box
            right={0}
            top={0}
            left={0}
            bottom={0}
            zIndex={9999}
            position={"absolute"}
          ></Box>
        )}
        <Box
          component={"section"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Typography component={"label"} fontWeight={"500"}>
            Seleção do mês
          </Typography>
          <Box
            height={"fit-content"}
            component={Paper}
            variant="outlined"
            sx={{
              ".rdrCalendarWrapper": {
                width: "100%",
                display: "flex",
                flexDirection: "column-reverse",
              },
              ".rdrDateDisplayWrapper": {
                display: !!params?.id ? "none" : "block",
              },
            }}
          >
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              direction="vertical"
              locale={pt}
              ranges={state}
            />
          </Box>
        </Box>

        <Box
          component={"section"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography component={"label"} fontWeight={"500"}>
            Seleção das semanas
          </Typography>
          <Box
            sx={{
              ".rdrDefinedRangesWrapper": {
                display: "none",
              },
              ".rdrCalendarWrapper": {
                width: "100%",
                display: "flex",
                flexDirection: "column-reverse",
              },
              ".rdrDateRangePickerWrapper": {
                display: "flex",
              },
              ".rdrDateDisplayWrapper": {
                display: !!params?.id ? "none" : "block",
              },
            }}
            component={Paper}
            variant="outlined"
          >
            <DateRangePicker
              onChange={(item) => {
                setPeriodsSelects({ ...periodsSelects, ...item });
              }}
              displayMode="dateRange"
              months={1}
              locale={pt}
              rangeColors={[
                "#3d91ff",
                "#3ecf8e",
                "#fed14c",
                "#F56767",
                "#E567F5",
                "#ff7aae",
              ]}
              ranges={Object.values(periodsSelects)}
            />
          </Box>
          <Stack
            mt={1}
            display={!!params?.id ? "none" : "flex"}
            direction={"row"}
            gap={1}
          >
            <Button
              variant="outlined"
              onClick={handleAddPeriod}
              disabled={isLoadingData}
              color="success"
            >
              Adicionar Periodo
            </Button>
            <Button
              onClick={handleRemovePeriod}
              variant="outlined"
              disabled={isLoadingData}
              color="error"
            >
              Remover Periodo
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};
