import {
  FormControlLabel,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import { useState } from "react";

import { FiMoreVertical } from "react-icons/fi";

import { SimpleDialogDelete } from "../../../../components/SimpleDialogDelete/SimpleDialogDelete";
import { OptionsMenu } from "../../components/OptionsMenu";
import { useHistory } from "react-router-dom";
import { IOSSwitch } from "./IOSSwitch";

export function Rows(props) {
  const { row, handleDelete, toggleActive } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    handleDelete(row.id);
  };
  const handleTogle = () => {
    toggleActive(row.id);
  };
  const handleEdit = () => {
    history.push(`/cadastro/perfil-indicador/editar/${row.id}`);
  };
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-controls={openMenu ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            onClick={handleClick}
          >
            <FiMoreVertical />
          </IconButton>
        </TableCell>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>
          <FormControlLabel
            control={<IOSSwitch checked={!!row.active} onClick={handleTogle} />}
          />
        </TableCell>
      </TableRow>

      <OptionsMenu
        anchorEl={anchorEl}
        open={openMenu}
        id={row.id}
        onClose={handleClose}
        onDelete={handleClickOpenDialog}
        onEdit={handleEdit}
      />
      <SimpleDialogDelete open={openDialog} onClose={handleCloseDialog} />
    </>
  );
}
