import { useState } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  FormLabel,
  FormSelect,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import ReactDatePicker, { registerLocale } from "react-datepicker";

import ptBR from "date-fns/locale/pt-BR";
import { format } from "date-fns";
import BootstrapTable from "react-bootstrap-table-next";
import ButtonFiltrar from "../ButtonFiltrarSubmit";
import { useEffect } from "react";
import { useLevelAcess } from "../../hooks/usePermissions";
import { estoqueService } from "../../services/estoqueServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";
import cellEditFactory from "react-bootstrap-table2-editor";

registerLocale("pt-BR", ptBR);

export function ModalPromocao({ show, handleClose }) {
  const { acessBase, accessLevel } = useLevelAcess();

  const { selectTabelasProduto, buscaProduto, salvaPromocao } = estoqueService;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [tabelasDisponiveis, setTabelasDisponiveis] = useState([]);
  const [tabelasSelecionadas, setTabelasSelecionadas] = useState([]);
  const [produtosAdicionados, setProdutosAdicionados] = useState([]);
  const [base, setBase] = useState(null);
  const [tabelaBase, setTabelaBase] = useState(null);
  const [codProd, setCodProd] = useState(null);
  const [desconto, setDesconto] = useState(null);
  const [carregando, setCarregando] = useState(false);

  async function buscaBases() {
    setBasesDisponiveis(acessBase);
  }

  async function buscaTabelas() {
    setCarregando(true);
    const response = await selectTabelasProduto({ base });
    console.log(response);
    if (response.status === 200 && response?.data?.data?.tabelasProduto) {
      setTabelasDisponiveis(response?.data?.data?.tabelasProduto);
      setTabelasSelecionadas(
        response?.data?.data?.tabelasProduto?.map((item) => item.codTabela)
      );
    } else {
      setTabelasDisponiveis([]);
      setTabelasSelecionadas([]);
    }
    setCarregando(false);
  }

  function removerProduto(cell, row, rowIndex, formatExtraData) {
    console.log(cell);
    console.log(row);
    console.log(rowIndex);
    console.log(formatExtraData);

    const newProdutosAdicionados = formatExtraData[0]?.filter(
      (item) => item.codProd !== row.codProd
    );
    setProdutosAdicionados(newProdutosAdicionados);
  }

  async function handleAddProdutoToList(event) {
    event.preventDefault();
    console.log("base -> ", base);
    console.log("startDate -> ", startDate);
    console.log("endDate -> ", endDate);
    console.log("tabelaBase -> ", tabelaBase);
    console.log("codProd -> ", codProd);

    setCarregando(true);
    const response = await buscaProduto({
      entityData: JSON.stringify({
        baseAcesso: JSON.stringify(acessBase),
        nivelAcesso: accessLevel,
        base,
        tabelaBase,
        codProd,
      }),
    });
    console.log(response);
    if (response.status === 200 && response?.data?.data) {
      if (response?.data?.data?.length > 0) {
        console.log("here");
        const newProd = response?.data?.data[0];
        console.log(newProd);
        setProdutosAdicionados((prev) => [
          ...prev,
          {
            codProd: newProd.CodProd,
            descricao: newProd.Descricao,
            vlrVenda: newProd.PrecoVenda,
            desconto: newProd.DescontoPromEspecial,
            vlrPromocao: newProd.PrecoFinalPromocaoTemporaria,
            tipoDesconto: newProd.TipoDecontoPromEspecial,
          },
        ]);
      } else {
        toast.error("Não encontramos o produto", {
          duration: 5000,
          style: {
            borderRadius: "0.25rem",
            background: "#333",
            color: "#fff",
          },
        });
      }
    }
    setCarregando(false);
  }

  function handleChangeBase(value) {
    if (value !== base) {
      setBase(value);
      setProdutosAdicionados([]);
    }
  }

  function handleAplicarDesconto() {
    console.log(desconto);
    console.log(produtosAdicionados);

    const newProdutosAdicionados = produtosAdicionados.map((item) => {
      const vlrVenda = !isNaN(Number(item.vlrVenda))
        ? Number(item.vlrVenda)
        : 0;
      const descontoTemp = Number(desconto.replace(".", "").replace(",", "."));
      const vlrPromocao = vlrVenda * (1 - descontoTemp / 100);
      console.log(descontoTemp);
      return {
        ...item,
        tipoDesconto: "P",
        vlrPromocao,
        desconto: descontoTemp,
      };
    });

    setProdutosAdicionados(newProdutosAdicionados);
  }

  function alterarValorPromocaoProduto(oldValue, newValue, row, column) {
    console.log(oldValue);
    console.log(newValue);
    console.log(row);
    console.log(column);

    const newProdutosAdicionados = produtosAdicionados.map((item) => {
      if (item.codProd === row.codProd) {
        const vlrVenda = !isNaN(Number(item.vlrVenda))
          ? Number(item.vlrVenda)
          : 0;
        const vlrPromocao = Number(
          newValue?.replace(".", "").replace(",", ".")
        );
        const descontoTemp = (1 - vlrPromocao / vlrVenda) * 100;
        console.log(descontoTemp);
        return {
          ...item,
          vlrPromocao,
          desconto: descontoTemp,
        };
      } else {
        return item;
      }
    });

    setProdutosAdicionados(newProdutosAdicionados);
  }

  useEffect(() => {
    if (acessBase) {
      buscaBases();
    }
  }, [acessBase]);

  useEffect(() => {
    if (base) {
      buscaTabelas();
    }
  }, [base]);

  const cellEdit = cellEditFactory({
    mode: "dbclick",
    afterSaveCell: (oldValue, newValue, row, column) => {
      alterarValorPromocaoProduto(oldValue, newValue, row, column);
    },
  });

  const selectRow = (selectedRows) => ({
    mode: "checkbox",
    clickToSelect: true,
    selected: selectedRows,
    onSelect: (row, isSelected) => {
      console.log(row);
      console.log(isSelected);
      console.log(tabelasSelecionadas);
      if (isSelected) {
        let tabelasSelecionadasTemp = [...tabelasSelecionadas];
        tabelasSelecionadasTemp.push(row.codTabela);
        setTabelasSelecionadas(tabelasSelecionadasTemp);
      } else {
        let tabelasSelecionadasTemp = tabelasSelecionadas.filter(
          (item) => item !== row.codTabela
        );
        console.log(tabelasSelecionadasTemp);
        setTabelasSelecionadas(tabelasSelecionadasTemp);
      }
    },
    onSelectAll: (isSelected, rows) => {
      console.log(isSelected);
      console.log(rows);
      console.log(tabelasSelecionadas);
      if (isSelected) {
        let tabelasSelecionadasTemp = tabelasDisponiveis.map(
          (item) => item.codTabela
        );
        setTabelasSelecionadas(tabelasSelecionadasTemp);
      } else {
        setTabelasSelecionadas([]);
      }
    },
  });

  async function handleSubmit() {
    // console.log(produtosAdicionados);
    // console.log(tabelasSelecionadas);
    // console.log(base);
    const endDateFormatted = format(endDate, "yyyy-MM-dd");
    const startDateFormatted = format(startDate, "yyyy-MM-dd");
    // console.log(endDateFormatted);
    // console.log(startDateFormatted);
    setCarregando(true);
    console.log({
      dataInicio: startDateFormatted,
      dataFim: endDateFormatted,
      produtos: produtosAdicionados,
      base,
      tabelas: tabelasSelecionadas,
    });
    // return;
    const response = await salvaPromocao({
      dataInicio: startDateFormatted,
      dataFim: endDateFormatted,
      produtos: produtosAdicionados,
      base,
      tabelas: tabelasSelecionadas,
    });
    setCarregando(false);

    console.log(response);
    if (response.status === 200 && response?.data?.success) {
      toast.success("Promoção criada");
      handleClose();
      setStartDate(new Date());
      setEndDate(new Date());
      setBasesDisponiveis([]);
      setTabelasDisponiveis([]);
      setTabelasSelecionadas([]);
      setProdutosAdicionados([]);
      setBase(null);
      setTabelaBase(null);
      setCodProd(null);
      setDesconto(null);
    }
  }

  return (
    <Modal show={show} fullscreen onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Criar promoção</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="row-filter-produtos">
            <Col xs={12} md={8}>
              <Row>
                <Col xs={12} md={4}>
                  <FormLabel>Base</FormLabel>
                  <FormSelect
                    value={base}
                    onChange={(e) => handleChangeBase(e.target.value)}
                  >
                    <option value="">Selecione...</option>
                    {basesDisponiveis.map((item) => (
                      <option value={item}>{`Base ${item}`}</option>
                    ))}
                  </FormSelect>
                </Col>
                <Col xs={12} md={4}>
                  <FormLabel>Inicio da promoção</FormLabel>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="dd/MM/yyyy"
                    locale="pt-BR"
                  />
                </Col>
                <Col xs={12} md={4}>
                  <FormLabel>Fim da promoção</FormLabel>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="dd/MM/yyyy"
                    locale="pt-BR"
                  />
                </Col>
                <Col xs={12} md={4}>
                  <FormLabel>Tabela base</FormLabel>
                  <FormSelect
                    value={tabelaBase}
                    onChange={(e) => setTabelaBase(e.target.value)}
                  >
                    <option value="">Selecione...</option>
                    {tabelasDisponiveis.map((item) => (
                      <option value={item.codTabela}>{item.nomeTabela}</option>
                    ))}
                  </FormSelect>
                </Col>
                <Col xs={12} md={4}>
                  <FormLabel>Cód. Prod.</FormLabel>
                  <FormControl
                    value={codProd}
                    onChange={(e) => setCodProd(e.target.value)}
                    placeholder="Código do produto"
                  />
                </Col>
                <Col xs={12} md={4} className="align-items-end d-flex">
                  <ButtonFiltrar
                    type="button"
                    onClick={handleAddProdutoToList}
                    disabled={carregando || !base || !codProd || !tabelaBase}
                    title={
                      !base || !codProd
                        ? "Selecione a base e digite o código do produto antes de adicioná-lo a lista"
                        : ""
                    }
                    text={
                      carregando ? (
                        <Spinner animation="border" size={"sm"} />
                      ) : (
                        "Adicionar na lista"
                      )
                    }
                  />
                </Col>
                <Col xs={12} md={4}>
                  <FormLabel>Desconto (%)</FormLabel>
                  <FormControl
                    value={desconto}
                    onChange={(e) => setDesconto(e.target.value)}
                    placeholder="Desconto aplicado"
                  />
                </Col>

                <Col xs={12} md={4} className="align-items-end d-flex">
                  <ButtonFiltrar
                    title=""
                    onClick={handleAplicarDesconto}
                    type={
                      produtosAdicionados?.length === 0
                        ? "Adicione um produto antes de aplicar o desconto"
                        : ""
                    }
                    disabled={carregando || produtosAdicionados?.length === 0}
                    text={
                      carregando ? (
                        <Spinner animation="border" size={"sm"} />
                      ) : (
                        "Aplicar desconto"
                      )
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={4}>
              <Col xs={12}>
                <FormLabel>Tabelas de preço</FormLabel>
                <BootstrapTable
                  bootstrap4
                  remote
                  keyField="codTabela"
                  selectRow={selectRow(tabelasSelecionadas)}
                  data={tabelasDisponiveis}
                  columns={[
                    {
                      dataField: "codTabela",
                      text: "Cod",
                    },
                    {
                      dataField: "nomeTabela",
                      text: "Tabela",
                    },
                  ]}
                />
              </Col>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Col xs={12} className="mt-4">
                <FormLabel>Produtos adicionados</FormLabel>
                <BootstrapTable
                  bootstrap4
                  keyField="codProd"
                  data={produtosAdicionados}
                  cellEdit={cellEdit}
                  columns={[
                    {
                      dataField: "remover",
                      text: "Remover",
                      isDummyField: true,
                      editable: false,
                      formatExtraData: [produtosAdicionados],
                      formatter: (cell, row, rowIndex, formatExtraData) => (
                        <FontAwesomeIcon
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            removerProduto(cell, row, rowIndex, formatExtraData)
                          }
                          color="#dc3545"
                          icon={faMinus}
                          size={"lg"}
                        />
                      ),
                      headerStyle: { width: "70px" },
                    },
                    {
                      dataField: "codProd",
                      editable: false,
                      text: "CodProd",
                    },
                    {
                      dataField: "descricao",
                      editable: false,
                      text: "Descrição",
                    },
                    {
                      dataField: "vlrVenda",
                      editable: false,
                      text: "Vlr Venda",
                      formatter: (cell, row) => {
                        console.log(cell, row);
                        return new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(cell);
                      },
                    },
                    {
                      dataField: "desconto",
                      editable: false,
                      text: "Desconto",
                      formatter: (cell, row) =>
                        `${new Intl.NumberFormat("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(cell)} ${row.tipoDesconto ? "%" : ""}`,
                    },
                    {
                      dataField: "vlrPromocao",
                      text: "Vlr Promoção",
                      editable: true,
                      formatter: (cell) =>
                        new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(cell),
                    },
                    {
                      dataField: "tipoDesconto",
                      editable: false,
                      text: "Tipo",
                    },
                  ]}
                />
              </Col>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={
            !produtosAdicionados?.length ||
            !tabelasSelecionadas?.length ||
            !base
          }
        >
          {carregando ? (
            <Spinner animation="border" size={"sm"} />
          ) : (
            "Salvar promoção"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
