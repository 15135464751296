import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
import Title from "../../../../components/title";
import { PermissionsPage } from "../../../../util/permissionsPage";
import { useEffect, useRef, useState } from "react";
import { LoadingTable } from "../../../../components/Loading/LoadingTable";
import { NoData } from "../../../../components/NoData/NoData";
import { StyledTableCell } from "../../../franchise/components/StyledTableCell";
import SearchIcon from "@mui/icons-material/Search";

import AddBoxIcon from "@mui/icons-material/AddBox";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { Rows } from "../components/Rows";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../../components/SearchComponent/SearchComponent";
import { debounce } from "throttle-debounce";
import { useUserData } from "../../../../hooks/useUserData";
import { isEmptyObject } from "../../../../util/isEmptyObject";
import vacancies from "../../../../services/vacancies";
import { a11yProps } from "../../../../util/a11yProps";
import { CustomTabPanel } from "../../../../components/componentsAnaliseClientes/CustomTabPanel";
import ErrorToastHandler from "../../../../util/ErrorToastHandler";

export const VacanciesList = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { userData } = useUserData();
  const [total, setTotal] = useState(0);
  const history = useHistory();
  const defaultValues = {
    page: 1,
    perPage: 10,
    searchTerm: "",
    status: 1,
    orderBy: "id",
    orderByType: "desc",
  };
  const handleClick = () => {
    history.push("/rh/vagas/criar");
  };
  const [value, setValue] = useState(1);
  const [params, setParams] = useState(defaultValues);
  const [data, setData] = useState([]);
  const inputRef = useRef(null);

  const handleChangePage = (event, newPage) => {
    inputRef.current.value = "";

    setParams((old) => ({ ...old, page: newPage + 1 }));
  };
  const handleSearch = (ev) => {
    setParams((old) => ({ ...old, searchTerm: ev, page: 1, per_page: 10 }));
  };
  const handleChangeRowsPerPage = (event) => {
    setParams((old) => ({ ...old, perPage: +event.target.value, page: 1 }));
  };
  const handleDelete = async (id) => {
    try {
      await vacancies.delete(id);
      toast.success("Deletada com sucesso");
      const configParams = { ...params };
      setParams(configParams);
    } catch (error) {
      toast.error("Ocorreu um erro ao deletar");
      console.log(error);
    }
  };
  const debounceFunc = debounce(500, handleSearch);

  const getData = async () => {
    setIsLoadingData(true);
    try {
      const response = await vacancies.list({
        params,
      });
      setData(response.data.data);
      setTotal(response.data.total);
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
    } finally {
      setIsLoadingData(false);
    }
  };
  useEffect(() => {
    if (!isEmptyObject(userData)) {
      getData();
    }
  }, [params, userData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setParams({ ...defaultValues, status: newValue });
  };
  const handleTogle = async (row) => {
    try {
      const response = await vacancies.togle(row.id);
      setParams((old) => ({ ...old }));
      toast.success(response.data.msg);
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
    } finally {
    }
  };

  return (
    <>
      <>
        <Title title="LISTA DE VAGAS" />
        {PermissionsPage("list_store") && (
          <>
            <Box
              px={2}
              marginY={2}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  sx={{
                    borderRadius: "4px",
                    borderColor: "#d6d6d6 !important",
                    border: "1px solid",
                  }}
                  disabled={isLoadingData}
                  placeholder="Pesquisar  "
                  inputProps={{
                    ref: inputRef,
                    "aria-label": "search",
                  }}
                  onChange={(e) => debounceFunc(e.target.value)}
                />
              </Search>

              <Tooltip title="Cadstrar nova loja">
                <IconButton
                  size="small"
                  color="aprincipal"
                  sx={{ p: 0 }}
                  onClick={handleClick}
                >
                  <AddBoxIcon sx={{ width: 50, height: 50 }} />
                </IconButton>
              </Tooltip>
            </Box>

            {isLoadingData ? (
              <LoadingTable />
            ) : (
              <>
                <Box
                  mx={2}
                  overflow={"hidden"}
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    sx={{
                      "& .MuiTabs-flexContainer": {
                        width: "100%",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <Tab label="Inativo" {...a11yProps(0)} />
                    <Tab label="Ativo" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <Stack>
                    {data.length <= 0 ? (
                      <Box
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <NoData />
                      </Box>
                    ) : (
                      <Box
                        component={Paper}
                        variant="outlined"
                        sx={{ width: "100%", overflow: "hidden" }}
                      >
                        <TableContainer>
                          <Table
                            stickyHeader
                            size={"small"}
                            aria-label="collapsible table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Opções</StyledTableCell>
                                <StyledTableCell>Desativar</StyledTableCell>
                                <StyledTableCell>Nome</StyledTableCell>
                                <StyledTableCell>Cidade</StyledTableCell>
                                <StyledTableCell>Departamento</StyledTableCell>
                                <StyledTableCell>Data Cadastro</StyledTableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {data.length > 0 &&
                                data.map((row) => (
                                  <Rows
                                    handleDelete={handleDelete}
                                    row={row}
                                    handleTogle={handleTogle}
                                  />
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[
                            10,
                            25,
                            50,
                            100,
                            { label: "Todos", value: total },
                          ]}
                          component="div"
                          count={total}
                          rowsPerPage={params.perPage}
                          page={params.page - 1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage={"Linhas por pagina"}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                        />
                      </Box>
                    )}
                  </Stack>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Box>
                    {data.length <= 0 ? (
                      <Box
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <NoData />
                      </Box>
                    ) : (
                      <Box
                        component={Paper}
                        variant="outlined"
                        sx={{ width: "100%", overflow: "hidden" }}
                      >
                        <TableContainer>
                          <Table
                            stickyHeader
                            size={"small"}
                            aria-label="collapsible table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Opções</StyledTableCell>
                                <StyledTableCell>Ativar</StyledTableCell>
                                <StyledTableCell>Nome</StyledTableCell>
                                <StyledTableCell>Cidade</StyledTableCell>
                                <StyledTableCell>Departamento</StyledTableCell>
                                <StyledTableCell>Data Cadastro</StyledTableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {data.length > 0 &&
                                data.map((row) => (
                                  <Rows
                                    handleDelete={handleDelete}
                                    row={row}
                                    handleTogle={handleTogle}
                                  />
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[
                            10,
                            25,
                            50,
                            100,
                            { label: "Todos", value: total },
                          ]}
                          component="div"
                          count={total}
                          rowsPerPage={params.perPage}
                          page={params.page - 1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage={"Linhas por pagina"}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </CustomTabPanel>
              </>
            )}
          </>
        )}
      </>
    </>
  );
};
