import { useEffect, useState } from "react";
import boletim from "../../../services/boletim";
import toast from "react-hot-toast";

import {
  columnsTableSales,
  columnsTableSalesByPaymentMethod,
} from "../../../column/boletim/Sales";
import { DataTableBoletim } from "../DataTableBoletim/DataTableBoletim";

export const Sales = ({ paramsConfig, basesDisponiveis }) => {
  const [dataPageSales, setDataPageSales] = useState([]);
  const [dataSalesByPaymentMethod, setDataPageSalesByPaymentMethod] = useState(
    []
  );
  const [isLoadingSales, setIsLoadingSales] = useState(false);
  async function getSales() {
    try {
      setIsLoadingSales(true);
      const response = await boletim.getSales(paramsConfig.base, {
        periodo: paramsConfig.periodo,
      });

      const data = response.data;
      const salesByPaymentMethod = [data.formaPagamento[2]];
      salesByPaymentMethod[0].expand = [
        data.formaPagamento[0],
        data.formaPagamento[1],
      ];

      const sales = [data.grupo[2]];
      sales[0].expand = [data.grupo[0], data.grupo[1]];
      setDataPageSalesByPaymentMethod(salesByPaymentMethod);
      setDataPageSales(sales);
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
      setIsLoadingSales(false);
    }
  }
  useEffect(() => {
    if (!paramsConfig.base) return;
    getSales();
  }, [basesDisponiveis, paramsConfig]);

  return (
    <>
      <DataTableBoletim
        columns={columnsTableSalesByPaymentMethod}
        data={dataSalesByPaymentMethod}
        label="Vendas por Forma de Pagamento"
        isLoading={isLoadingSales}
      />

      <DataTableBoletim
        columns={columnsTableSales}
        data={dataPageSales}
        label="Vendas por grupo"
        isLoading={isLoadingSales}
      />
    </>
  );
};
