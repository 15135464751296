export const CellPerformace = ({ row }) => {
  const { AT_x_AN, AT_x_PA } = row.performace;
  const formatter = (value) => {
    return (
      value.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + "%"
    );
  };
  const colorAT_x_AN = AT_x_AN > 0 ? "green" : "red";
  const colorAT_x_PA = AT_x_PA > 0 ? "green" : "red";
  return (
    <div
      style={{
        display: "grid",
      }}
    >
      {!!AT_x_AN && (
        <span
          style={{
            color: colorAT_x_AN,
            fontWeight: "bold",
          }}
        >
          {formatter(AT_x_AN)}{" "}
          <small
            dangerouslySetInnerHTML={{
              __html: colorAT_x_AN === "green" ? "&#9652;" : "&#9662;",
            }}
          />
        </span>
      )}
      {!!AT_x_AN && !!AT_x_PA && (
        <small style={{ borderBottom: "1px dashed #aba8a7" }} />
      )}
      {!!AT_x_PA && (
        <span
          style={{
            color: colorAT_x_PA,
            fontWeight: "bold",
          }}
        >
          {formatter(AT_x_PA)}
          <small
            dangerouslySetInnerHTML={{
              __html: colorAT_x_PA === "green" ? "&#9652;" : "&#9662;",
            }}
          />
        </span>
      )}
    </div>
  );
};
