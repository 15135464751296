import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useContext, useState } from "react";

import { useFormContext } from "react-hook-form";
import { FormContext } from "../../pages/financeiro/metas/cadastro-metas";
import dayjs from "dayjs";
import { CustomTabPanel } from "../componentsAnaliseClientes/CustomTabPanel";
import { a11yProps } from "../../util/a11yProps";
import { FormSenanal } from "./FormSemana";
import {
  storageDataCadastroMetasGet,
  storageDataCadastroMetasSave,
} from "../../storage/storageCadastroMetas";

export const FormEditarMetasSemanal = ({ data, open, onClickSave }) => {
  const { getValues, setValue } = useFormContext();

  const { periodsSelects, setDataPage } = useContext(FormContext);

  const [valueTab, setValueTab] = useState(0);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };
  const calcularMetaEspecial = (valuePeridos, key, dataLoja) => {
    if (valuePeridos.metaEspecial) {
      let somaMetaEspecial = 0;
      let someMetaVenda = 0;
      Object.values(dataLoja.periodos).forEach((item) => {
        if (!!item?.metaEspecial) {
          somaMetaEspecial = somaMetaEspecial + 1;
          someMetaVenda = someMetaVenda + +item.metaVenda;
        }
      });

      const qtdNaoEspecial =
        Object.values(periodsSelects).length - somaMetaEspecial;
      const vendaRestanteParaDistribuir = dataLoja.metaVenda - someMetaVenda;

      Object.keys(dataLoja.periodos).forEach((keyPeriod) => {
        if (keyPeriod !== key && !dataLoja.periodos[keyPeriod].metaEspecial) {
          dataLoja.periodos[keyPeriod].metaVenda = Math.ceil(
            vendaRestanteParaDistribuir / qtdNaoEspecial
          );
        }
      });
    }
    return dataLoja;
  };
  const handleSave = (key) => {
    const valuePeridos = { ...getValues(`${data.CodLoja}.periodos.${key}`) };
    const valueLoja = getValues(`${data.CodLoja}`);

    calcularMetaEspecial(valuePeridos, key, valueLoja);
    const dataPageStorage = storageDataCadastroMetasGet();
    console.log(dataPageStorage);
    const formatterData = dataPageStorage.map((item) => {
      if (item.CodLoja === data.CodLoja) {
        if (+item.periodos[key].metaTM !== +valuePeridos.metaTM) {
          const qtdAjustado = parseInt(
            valuePeridos.metaVenda / valuePeridos.metaTM
          );
          valuePeridos.metaQtdPedidos = qtdAjustado;
          item.periodos[key].metaQtdPedidos = qtdAjustado;
        }

        if (+item.periodos[key].metaVenda !== +valuePeridos.metaVenda) {
          const metaTMAjustada = parseInt(
            valuePeridos.metaVenda / valuePeridos.metaQtdPedidos
          );
          valuePeridos.metaTM = metaTMAjustada;
          item.periodos[key].metaTM = metaTMAjustada;
        }
        if (
          +item.periodos[key].metaQtdPedidos !== +valuePeridos.metaQtdPedidos
        ) {
          const metaTMAjustada = parseInt(
            valuePeridos.metaVenda / valuePeridos.metaQtdPedidos
          );
          valuePeridos.metaTM = metaTMAjustada;
          item.periodos[key].metaTM = metaTMAjustada;
        }
      }
      return item;
    });
    setDataPage(formatterData);
    storageDataCadastroMetasSave(formatterData);
    setValue(`${data.CodLoja}.periodos.${key}`, valuePeridos);
    setDataPage(Object.values(getValues()).filter((item) => !!item));
    if (valueTab + 1 === Object.values(periodsSelects).length) {
      setValueTab(0);
      onClickSave();
    } else {
      setValueTab((old) => old + 1);
    }
  };

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          flexGrow: 1,
          bgcolor: "background.paper",

          display: open ? "flex" : "none",
        }}
      >
        <Tabs
          value={valueTab}
          onChange={handleChange}
          aria-label="basic tabs example"
          orientation="vertical"
          variant="scrollable"
        >
          {Object.values(periodsSelects).map((item, index) => (
            <Tab
              sx={{
                borderRight: 1,
                borderColor: "divider",
              }}
              key={index}
              label={`${dayjs(item.startDate).format("DD/MM")} - ${dayjs(
                item.endDate
              ).format("DD/MM")}`}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>

        <Box flex={1} p={2} pt={0}>
          {Object.keys(periodsSelects).map((key, index) => {
            return (
              <CustomTabPanel
                key={periodsSelects[key]?.startDate}
                value={valueTab}
                index={index}
              >
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  fontWeight={"500"}
                  component={"span"}
                  gutterBottom
                >
                  Semana {valueTab + 1} -
                  {`${dayjs(periodsSelects[key]?.startDate).format(
                    "DD/MM/YYYY"
                  )} - ${dayjs(periodsSelects[key]?.endDate).format(
                    "DD/MM/YYYY"
                  )}`}
                </Typography>

                <FormSenanal
                  valueTab={valueTab}
                  data={data}
                  handleSave={handleSave}
                  chave={key}
                />
              </CustomTabPanel>
            );
          })}
        </Box>
      </Box>
    </>
  );
};
