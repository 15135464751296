import { green } from "@mui/material/colors";
import { formatarNumero } from "../util/loja";
const DIAMANTE = `#6395ec`;
const OURO = "#FFD700";
export const dataChart = ({ isApp, dataPage }) => {
  const labelsChart = dataPage?.map((item) => item.nomeFuncionario) || [];
  const previsaoFinalVendas =
    dataPage?.map((item) => item.previsaoFinalVendas) || [];
  const metaProjetada = dataPage?.map((item) => item.metaProjetada) || [];
  const metaRealizada = dataPage?.map((item) => item.metaRealizada) || [];
  const descontoAplicado =
    dataPage?.map((item) => item.metaRealizada + item.descontoAplicado) || [];
  const metaOuro = dataPage?.map((item) => item.metaOuro) || [];
  const metaDiamante = dataPage?.map((item) => item.metaDiamante) || [];

  return {
    labels: labelsChart,
    datasets: [
      {
        type: "line",
        label: "Projetada",
        borderColor: "rgb(00, 00, 00)",
        borderWidth: 2,
        fill: false,
        clip: true,
        data: previsaoFinalVendas,
        tension: 0,
        borderDash: [10, 5],
        xAxisID: "A",
        datalabels: {
          anchor: "start",
          display: "auto",
          color: "#000",
          align: (context) => {
            if (
              metaProjetada[context.dataIndex] <
              previsaoFinalVendas[context.dataIndex]
            ) {
              return isApp ? "0" : "-45";
            } else {
              return isApp ? "" : "135";
            }
          },
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
          formatter: function (value) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        },
      },
      {
        type: "line",
        label: "Meta",
        borderColor: green[700],
        borderWidth: 2,
        fill: false,
        data: metaProjetada,
        tension: 0,
        showLine: false,
        clip: true,
        xAxisID: "A",
        datalabels: {
          anchor: "start",
          display: true,
          color: green[700],
          align: (context) => {
            if (
              metaProjetada[context.dataIndex] <
              previsaoFinalVendas[context.dataIndex]
            ) {
              return isApp ? 0 : "left";
            } else {
              return isApp ? 0 : "right";
            }
            // console.log(context);
          },
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
          formatter: function (value) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        },
      },
      {
        type: "line",
        label: "Diamante",
        borderColor: DIAMANTE,
        borderWidth: 5,
        fill: false,
        data: metaDiamante,
        tension: 0,
        showLine: false,
        clip: true,
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          display: false,
          color: DIAMANTE,
          align: (context) => {
            if (
              metaProjetada[context.dataIndex] <
              previsaoFinalVendas[context.dataIndex]
            ) {
              return isApp ? 0 : "90";
            } else {
              return isApp ? 0 : "-90";
            }
          },
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
        },
      },
      {
        type: "line",
        label: "Ouro",
        borderColor: OURO,
        borderWidth: 5,
        fill: false,
        data: metaOuro,
        tension: 0,
        showLine: false,
        clip: true,
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          display: false,
          color: OURO,
          align: (context) => {
            if (
              metaProjetada[context.dataIndex] <
              previsaoFinalVendas[context.dataIndex]
            ) {
              return isApp ? 0 : "90";
            } else {
              return isApp ? 0 : "-90";
            }
          },
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
        },
      },
      {
        type: "bar",
        label: "Realizada",
        backgroundColor: "#03a9f485",
        borderColor: "#03a9f4",
        borderWidth: 0,
        data: metaRealizada,
        xAxisID: "B",
        datalabels: {
          anchor: "start",
          display: true,
          color: "#03a9f4c7",
          align: "start",
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
          formatter: function (value) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        },
      },
      {
        type: "bar",
        label: "Desconto",
        backgroundColor: "rgba(255, 102, 0, 0.3)",
        borderColor: "rgba(255, 102, 0, 0.3)",
        borderWidth: 1,
        borderDash: [10, 5],
        data: descontoAplicado,
        xAxisID: "B",
        datalabels: {
          anchor: "end",
          display: "auto",
          color: "rgba(255, 255, 255, 1)",
          align: "start",
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 12,
              },
              padding: 0,
            },
          },
          formatter: function (value) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        },
      },
    ],
  };
};

export const optionsChartBar = ({ dataPage, isApp }) => {
  const metaRealizada = dataPage?.map((item) => item.metaRealizada) || [];

  const descontoAplicado =
    dataPage?.map((item) => item.metaRealizada + item.descontoAplicado) || [];
  return {
    maintainAspectRatio: isApp ? false : true,
    responsive: isApp ? false : true,
    legend: {
      position: "bottom",
      reverse: true,
      labels: {
        filter: function (item) {
          return (
            !item.text.includes("Desconto") &&
            !item.text.includes("Ouro") &&
            !item.text.includes("Diamante")
          );
        },
      },
    },
    layout: {
      padding: {
        top: 30,
        right: 50,
        bottom: 30,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          id: "A",
          ticks: {
            padding: 25,
            display: true,
          },
        },
        {
          id: "B",
          stacked: true,
          ticks: {
            padding: 25,
            display: false,
          },
          offset: true,
          offsetGridLines: false,
        },
      ],
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        title: (tooltipItem, data) => {
          tooltipItem = tooltipItem[0];
          if (+tooltipItem.datasetIndex === 0) {
            return "Valor Projetado Para " + tooltipItem.xLabel;
          } else if (+tooltipItem.datasetIndex === 1) {
            return "Meta Para " + tooltipItem.xLabel;
          } else if (+tooltipItem.datasetIndex === 2) {
            return "Meta Diamante para " + tooltipItem.xLabel;
          } else if (+tooltipItem.datasetIndex === 3) {
            return "Meta Ouro para " + tooltipItem.xLabel;
          } else if (+tooltipItem.datasetIndex === 4) {
            return "Valor Realizado Por " + tooltipItem.xLabel;
          } else if (+tooltipItem.datasetIndex === 5) {
            let valorDesconto = formatarNumero(
              descontoAplicado[tooltipItem.index]
            );
            return "Total sem desconto " + valorDesconto;
          }
        },
        label: (tooltipItem, data) => {
          if (+tooltipItem.datasetIndex !== 5) {
            return (
              "Valor Total: " + formatarNumero(parseFloat(tooltipItem.value))
            );
          } else {
            let valorDesconto =
              descontoAplicado[tooltipItem.index] -
              metaRealizada[tooltipItem.index];
            return "Valor do Desconto: " + formatarNumero(valorDesconto);
          }
        },
        footer: (tooltipItem, data) => {
          tooltipItem = tooltipItem[0];
          if (+tooltipItem.datasetIndex === 5) {
            let valorDesconto =
              descontoAplicado[tooltipItem.index] -
              metaRealizada[tooltipItem.index];
            let valorDescontoPorcentagem =
              (valorDesconto / descontoAplicado[tooltipItem.index]) * 100;
            return (
              "Porcentagem " +
              formatarNumero(valorDescontoPorcentagem, false) +
              "%"
            );
          } else if (
            tooltipItem.datasetIndex === 3 ||
            tooltipItem.datasetIndex === 2
          ) {
            let totalRealizado = metaRealizada[tooltipItem.index];
            return (
              "Valor realizado " + formatarNumero(parseFloat(totalRealizado))
            );
          }
        },
      },
    },
  };
};
