import { useEffect, useState } from "react";
import boletim from "../../../services/boletim";
import {
  columnsTableBanksProvisions,
  columnsTableBanksProvisionsPredictions,
} from "../../../column/boletim/BanksPrevisions";
import { DataTableBoletim } from "../DataTableBoletim/DataTableBoletim";
import ErrorToastHandler from "../../../util/ErrorToastHandler";

export const BanksProvisions = ({ paramsConfig, basesDisponiveis }) => {
  const [dataPageBanksProvisions, setDataPageBanksProvisions] = useState([]);
  const [
    dataPageBanksProvisionsPredictions,
    setDataPageBanksProvisionsPredictions,
  ] = useState([]);

  const [isLoadingBanksProvisions, setIsLoadingBanksProvisions] =
    useState(false);
  async function getBanksProvisions() {
    setIsLoadingBanksProvisions(true);
    try {
      const response = await boletim.getBanksProvisions(paramsConfig.base, {
        periodo: paramsConfig.periodo,
      });

      if (response.status === 200) {
        const dataPredictions = response.data.provisoes;
        const banksProvisionsPredictionsExpand = dataPredictions.filter(
          (item) => !item?.isTotal
        );
        const banksProvisionsPredictions = [
          dataPredictions.find((item) => !!item?.isTotal),
        ];
        banksProvisionsPredictions[0].expand = banksProvisionsPredictionsExpand;
        setDataPageBanksProvisionsPredictions(banksProvisionsPredictions);

        const data = response.data.contas;
        const banksProvisionsExpand = data.filter(
          (item) => item?.codConta !== "total"
        );
        const banksProvisions = [
          data.find((item) => item?.codConta === "total"),
        ];
        banksProvisions[0].expand = banksProvisionsExpand;
        setDataPageBanksProvisions(banksProvisions);
      }
    } catch (error) {
      console.log({ error });
      new ErrorToastHandler(error).showErrorToast();
    }
    setIsLoadingBanksProvisions(false);
  }
  useEffect(() => {
    if (!paramsConfig.base) return;
    getBanksProvisions();
  }, [basesDisponiveis, paramsConfig]);

  return (
    <>
      <DataTableBoletim
        columns={columnsTableBanksProvisions}
        data={dataPageBanksProvisions}
        label="Resumo de contas"
        isLoading={isLoadingBanksProvisions}
      />
      <DataTableBoletim
        columns={columnsTableBanksProvisionsPredictions}
        data={dataPageBanksProvisionsPredictions}
        label="Resumo de vencimentos"
        isLoading={isLoadingBanksProvisions}
      />
    </>
  );
};
