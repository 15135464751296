import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert, Row } from "react-bootstrap";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { converterLoja } from "../../util/loja";
import { Container } from "./styles";

export const FilterLoja = ({
  labelsTable,
  infoSelect,
  functionEmpresa,
  direcionarParaMetasPorVendedorasLojaEspecifica,
  required,
}) => {
  if (labelsTable.length === 0) {
    return " ";
  }
  return (
    <Container>
      <div className="alert-filterloja">
        <Alert className="fInfo ">
          <FontAwesomeIcon
            color="#f00"
            styled={{ width: "8px" }}
            size="sm"
            icon={faExclamation}
          />{" "}
          - Quantidade de vendedoras abaixo da meta
        </Alert>
      </div>
      <section>
        {required ? (
          <Row md={6} xs={2}>
            {labelsTable.map((item, index) =>
              item.codLoja == infoSelect.codLoja ? (
                <button
                  key={item.codLoja}
                  className="lojaSelecionada button-nome-loja"
                  onClick={() =>
                    functionEmpresa(index, item.codLoja, item.CodBase)
                  }
                >
                  <div className="d-block">
                    <div>{converterLoja(item.codLoja)}</div>
                    <div>
                      {item.quantidadeVendedorasAbaixoMeta > 0
                        ? item.metaAlcancada.map((a) => {
                            if (a === 0) {
                              return (
                                <FontAwesomeIcon
                                  color="#f00"
                                  styled={{ width: "8px" }}
                                  size="sm"
                                  icon={faExclamation}
                                />
                              );
                            }
                          })
                        : ""}
                    </div>
                  </div>
                </button>
              ) : (
                <button
                  key={item.codLoja}
                  className="button-nome-loja"
                  onClick={() =>
                    functionEmpresa(index, item.codLoja, item.CodBase)
                  }
                >
                  <div className="d-block">
                    <div>{converterLoja(item.codLoja)}</div>
                    <div>
                      {item.quantidadeVendedorasAbaixoMeta > 0
                        ? item.metaAlcancada.map((a) => {
                            if (a === 0) {
                              return (
                                <FontAwesomeIcon
                                  color="#f00"
                                  styled={{ width: "8px" }}
                                  size="sm"
                                  icon={faExclamation}
                                />
                              );
                            }
                          })
                        : ""}
                    </div>
                  </div>
                </button>
              )
            )}
          </Row>
        ) : (
          <Row md={6} xs={2}>
            {labelsTable.map((item) => {
              return infoSelect.indexOf(item.codLoja) >= 0 ? (
                <button
                  className="lojaSelecionada button-nome-loja"
                  key={item.codLoja}
                  onClick={() => functionEmpresa(item.codLoja)}
                >
                  <div className=" d-block">
                    <div className="no-border itemNomeLoja">
                      {converterLoja(item.codLoja)}
                    </div>
                    <div className="no-border itemQtdVendedorasAbaixoMetaLoja">
                      {item.quantidadeVendedorasAbaixoMeta.length > 0
                        ? item.quantidadeVendedorasAbaixoMeta.map((a) => {
                            return (
                              <FontAwesomeIcon
                                color="#f00"
                                styled={{ width: "8px" }}
                                size="sm"
                                icon={faExclamation}
                                onClick={() =>
                                  direcionarParaMetasPorVendedorasLojaEspecifica(
                                    item.codLoja
                                  )
                                }
                              />
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </button>
              ) : (
                <button
                  className="button-nome-loja"
                  key={item.codLoja}
                  onClick={() => functionEmpresa(item.codLoja)}
                >
                  <div className="d-block">
                    <div className="no-border itemNomeLoja">
                      {converterLoja(item.codLoja)}
                    </div>
                    <div className="no-border itemQtdVendedorasAbaixoMetaLoja">
                      {item.quantidadeVendedorasAbaixoMeta.length > 0
                        ? item.quantidadeVendedorasAbaixoMeta.map((a) => {
                            return (
                              <FontAwesomeIcon
                                color="#f00"
                                styled={{ width: "8px" }}
                                size="sm"
                                icon={faExclamation}
                                onClick={() =>
                                  direcionarParaMetasPorVendedorasLojaEspecifica(
                                    item.codLoja
                                  )
                                }
                              />
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </button>
              );
            })}
          </Row>
        )}
      </section>
    </Container>
  );
};
