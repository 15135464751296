import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { converterNumero } from "../../util/loja";
import { ButtonExpand } from "../ExpandMore";
import { useContext, useState } from "react";

import { FormRowFuncionario } from "./FormRowFuncionario";
import { BiSolidTrash } from "react-icons/bi";
import { FaCircle, FaEdit } from "react-icons/fa";
import { FormContext } from "../../pages/financeiro/metas/cadastro-metas";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";

export const TableFuncionarios = ({
  data,
  handleOpenEditFunc,
  handleRemove,
  CodLoja,
}) => {
  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{
        mt: 0.5,
        mb: 1,
      }}
    >
      <Table size="small" aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Valor Vendas</TableCell>
            <TableCell>Ticket Medio</TableCell>
            <TableCell>Item por venda</TableCell>
            <TableCell>Clientes cadastrados</TableCell>
            <TableCell>Qtd Pedidos</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(data).map((item) => (
            <Rows
              data={item}
              handleRemove={handleRemove}
              handleOpenEditFunc={handleOpenEditFunc}
              CodLoja={CodLoja}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Rows = ({ data, handleRemove, CodLoja }) => {
  const [expanded, setExpanded] = useState(false);
  const [openEditFunc, setOpenEditFunc] = useState(false);
  const { periodsSelects } = useContext(FormContext);
  const { getValues } = useFormContext();
  const handleExpandClick = () => {
    if (!expanded) {
      setOpenEditFunc(true);
    }
    setExpanded(!expanded);
  };

  const handleOpenEditFunc = () => {
    if (!expanded) {
      setExpanded(true);
      setOpenEditFunc(true);
      return;
    }
    setOpenEditFunc((old) => !old);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <ButtonExpand
            handleExpandClick={handleExpandClick}
            expanded={expanded}
          />
        </TableCell>
        <TableCell>{converterNumero(data.NomeFunc)}</TableCell>
        <TableCell>{converterNumero(data.metaVenda)}</TableCell>
        <TableCell>{converterNumero(data.metaTM)}</TableCell>
        <TableCell>{data.metaQtdPedidos}</TableCell>
        <TableCell>{data.metaItensPorVenda}</TableCell>
        <TableCell>{data.metaClientesCadastrados}</TableCell>
        <TableCell>
          <Stack direction={"row"}>
            <IconButton onClick={() => handleRemove(data)} size="small">
              <BiSolidTrash color="red" />
            </IconButton>
            <IconButton onClick={handleOpenEditFunc} size="small">
              <FaEdit />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse
            in={expanded}
            sx={{
              display: openEditFunc ? "none" : "block",
            }}
            timeout="auto"
          >
            <FormRowFuncionario
              data={data}
              expanded={expanded}
              handleExpandClick={handleExpandClick}
            />
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse
            in={expanded}
            sx={{
              display: openEditFunc ? "block" : "none",
            }}
            timeout="auto"
          >
            <Table size="small" aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>Periodo</TableCell>
                  <TableCell>Valor Vendas</TableCell>
                  <TableCell>Ticket Medio</TableCell>
                  <TableCell>Item por venda</TableCell>
                  <TableCell>Clientes cadastrados</TableCell>
                  <TableCell>Qtd Pedidos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(periodsSelects).map((key) => {
                  return (
                    <TableRow>
                      <TableCell>
                        {`${dayjs(periodsSelects[key]?.startDate).format(
                          "DD/MM"
                        )} - ${dayjs(periodsSelects[key]?.endDate).format(
                          "DD/MM"
                        )}`}
                        <Box component={"span"} ml={1}>
                          <FaCircle
                            fontSize={"10px"}
                            color={
                              !!getValues(
                                `${CodLoja}.funcionariosDaLoja.${data.CodFunc}.periodos.${key}.metaEspecial`
                              )
                                ? "green"
                                : "red"
                            }
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        {converterNumero(
                          +getValues(
                            `${CodLoja}.funcionariosDaLoja.${data.CodFunc}.periodos.${key}.metaVenda`
                          )
                        )}
                      </TableCell>
                      <TableCell>
                        {converterNumero(
                          +getValues(
                            `${CodLoja}.funcionariosDaLoja.${data.CodFunc}.periodos.${key}.metaTM`
                          )
                        )}
                      </TableCell>
                      <TableCell>
                        {getValues(
                          `${CodLoja}.funcionariosDaLoja.${data.CodFunc}.periodos.${key}.metaQtdPedidos`
                        )}
                      </TableCell>
                      <TableCell>
                        {getValues(
                          `${CodLoja}.funcionariosDaLoja.${data.CodFunc}.periodos.${key}.metaItensPorVenda`
                        )}
                      </TableCell>
                      <TableCell>
                        {getValues(
                          `${CodLoja}.funcionariosDaLoja.${data.CodFunc}.periodos.${key}.metaClientesCadastrados`
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
