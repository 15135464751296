import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useState } from "react";
import { useUserData } from "../../../hooks/useUserData";
import { useHistory } from "react-router-dom";

export const SubMenuListLink = ({ submenu, setSelected, selected }) => {
  const [open, setOpen] = useState(false);
  const { userData } = useUserData();
  const history = useHistory();

  const projeto = "bi";

  const handleMenu = (event, data) => {
    event.preventDefault();
    if (projeto === data?.project) {
      setSelected(data);
      history.push(`${data.path}`);
    } else {
      if (!data?.project) {
        window.location.href = `${data?.absolutePath}?id=${userData.id}&token=${userData.token}`;
      } else {
        window.location.href = `${data?.absolutePath}${userData.id}/${userData.token}`;
      }
    }
  };
  return (
    <>
      <ListItemButton
        key={submenu.name}
        sx={{
          pl: 4,
          fontSize: "14px",
          borderRadius: "8px",
          mx: "3px",
          my: "3px",
        }}
        onClick={() => setOpen(!open)}
      >
        <ListItemIcon>
          <RadioButtonCheckedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          sx={{ "> span": { fontSize: "14px" } }}
          primary={submenu.name}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <ListItem component="div" disablePadding sx={{ display: "block" }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {submenu?.items?.map((itemMenu) => {
            const itemSelected = selected?.field === itemMenu.field;
            return (
              <ListItemButton
                href={
                  !itemMenu?.project
                    ? `${itemMenu?.absolutePath}?id=${userData.id}&token=${userData.token}`
                    : projeto === itemMenu?.project
                    ? itemMenu?.path
                    : itemMenu?.absolutePath +
                      userData.id +
                      "/" +
                      userData.token
                }
                onClick={(e) => handleMenu(e, itemMenu)}
                component="a"
                key={itemMenu.fieldName}
                sx={{
                  pl: 6,
                  borderRadius: "8px",
                  mx: "3px",
                  my: "3px",
                  background: itemSelected && "#ff79ae",
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                  ":hover": {
                    color: "initial",
                  },
                  ...(itemSelected && {
                    ":hover": {
                      background: "#ff79aee0",
                    },
                  }),
                }}
              >
                <ListItemIcon>
                  {itemSelected ? (
                    <RadioButtonCheckedIcon
                      fontSize="small"
                      style={{
                        color: "white",
                      }}
                    />
                  ) : (
                    <RadioButtonUncheckedIcon fontSize="small" />
                  )}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    textTransform: "capitalize",
                    "> span": {
                      fontSize: "14px",
                      ...(itemSelected && {
                        color: "white",
                        fontWeight: "bold",
                      }),
                    },
                  }}
                  primary={itemMenu.fieldName}
                />
              </ListItemButton>
            );
          })}
        </Collapse>
      </ListItem>
    </>
  );
};
