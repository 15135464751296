import dayjs from "dayjs";
import { converterNumero } from "../../util/loja";
import { red } from "@mui/material/colors";
export const valueNegative = (value) => {
  if (value < 0) {
    return (
      <strong style={{ color: red[500] }}>{converterNumero(value)}</strong>
    );
  }
  return converterNumero(value);
};
export const columnsTableBanksProvisions = [
  {
    selector: "banco",
    name: <strong style={{ fontSize: "1rem" }}>Banco</strong>,
    cell: (row) => {
      if (dayjs(row.banco).isValid()) {
        return <strong>{dayjs(row.banco).format("DD/MM/YYYY")}</strong>;
      }
      return <strong>{row.banco}</strong>;
    },
  },
  {
    selector: "saldoInterno",
    name: <strong style={{ fontSize: "1rem" }}>Saldo interno</strong>,
    cell: (row) => {
      return valueNegative(row.saldoInterno);
    },
  },
  {
    selector: "saldoAnterior",
    name: <strong style={{ fontSize: "1rem" }}>Saldo anterior</strong>,
    cell: (row) => valueNegative(row.saldoAnterior),
  },
  {
    selector: "entrada",
    name: <strong style={{ fontSize: "1rem" }}>Entradas</strong>,
    cell: (row) => valueNegative(row.entrada),
  },
  {
    selector: "saida",
    name: <strong style={{ fontSize: "1rem" }}>Saídas</strong>,
    cell: (row) => valueNegative(row.saida),
  },
];
export const columnsTableBanksProvisionsPredictions = [
  {
    selector: "descricao",
    name: <strong style={{ fontSize: "1rem" }}>Provisões (Posições)</strong>,
    cell: (row) => {
      if (dayjs(row.descricao).isValid()) {
        return <strong>{dayjs(row.descricao).format("DD/MM/YYYY")}</strong>;
      }
      return <strong>{row.descricao}</strong>;
    },
  },
  {
    selector: "aPagar",
    name: <strong style={{ fontSize: "1rem" }}>À pagar</strong>,
    cell: (row) => valueNegative(row.aPagar),
  },

  {
    selector: "aReceber",
    name: <strong style={{ fontSize: "1rem" }}>À receber</strong>,
    cell: (row) => {
      if (row.hasOwnProperty("groupId")) {
        return row.aReceber?.toFixed(2) + "%";
      }
      return valueNegative(row.aReceber);
    },
  },
  {
    selector: "saldo",
    name: <strong style={{ fontSize: "1rem" }}>Saldo</strong>,
    cell: (row) => valueNegative(row.saldo),
  },
];
