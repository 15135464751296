export const formatterNumberPhoneMask = (phone) => {
    phone = phone?.replace(/[^0-9]/g, "");
    let formattedPhoneNumber = "";
    if (!!phone) {
      formattedPhoneNumber = `(${phone.slice(0, 2)}) ${phone.slice(
        2,
        7
      )}-${phone.slice(7)}`;
    }
    return formattedPhoneNumber;
  };