import { ContainerLegendaMetas } from "../../styles/global";

export const LegandaMetas = () => {
  return (
    <>
      <ContainerLegendaMetas
        style={{
          whiteSpace: "nowrap",
          flexWrap: "wrap",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",gap: '.4rem'
        }}
      >
        <small className="cor-diamante">
          Diamante <span>💎</span>
        </small>
        <small className="cor-ouro">
          Ouro <span>🥇</span>
        </small>
        <small className="cor-meta">
          Atingiu a Meta <span>🚀</span>
        </small>
        <small className="cor-abaixo-meta">
          Não atingiu <span>🤷🏻‍♀️</span>
        </small>
      </ContainerLegendaMetas>
    </>
  );
};
