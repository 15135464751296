import { Box } from "@mui/material";
import React from "react";

function Title({ title }) {
  return (
    <Box color={"#fc68a2"}>
      <h1>{title}</h1>
    </Box>
  );
}

export default Title;
