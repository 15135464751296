import { Box, Grid, Paper, Typography } from "@mui/material";
import { formatarNumero } from "../../../util/loja";
const allResume = [
  { name: "totalPagar", label: "Total a pagar", type: "money" },
  { name: "custoEstoque", label: "Custo por estoque", type: "money" },
  { name: "custoVendas", label: "Custo por vendas", type: "money" },
  { name: "diferenca", label: "Diferença", type: "money" },
  { name: "mediaMesCusto", label: "Media de custo no mês", type: "toFixed" },
  { name: "mediaMesQtd", label: "Media de qtd no mês", type: "toFixed" },
  { name: "porcentagem", label: "Porcentagem", type: "percente" },
];
export const ResumeContasPagar = ({ estoque }) => {
  return (
    <Box component={Paper} elevation={1} p={2} mx={2} mb={2}>
      <Grid
        container
        spacing={{ xs: 1, md: 2 }}
        columns={{ xs: 2, sm: 8, md: 16 }}>
        {Object.keys(estoque.summary).map((key) => {
          const currency = allResume.find((item) => item.name === key);
          const isTotal = currency.name === "totalPagar";
          return (
            <Grid key={key} item xs={2} sm={4} md={isTotal ? 8 : 4}>
              <Box bgcolor={"#d3d3d314"} p={1} component={Paper} elevation={1}>
                <Typography fontWeight={"bold"}>{currency.label}</Typography>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Typography>
                    {currency.type === "toFixed" &&
                      (estoque.summary[key]
                        ? estoque.summary[key].toFixed(2)
                        : "0.00")}
                    {currency.type === "money" &&
                      formatarNumero(estoque.summary[key])}
                    {currency.type === "percente" &&
                      `${estoque.summary[key].toFixed(2)} %`}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
