import { styled } from "@mui/material";
import DataTable from "react-data-table-component";
import { IoIosRadio } from "react-icons/io";
const Container = styled("div")`
  .rdt_TableHeadRow {
    background-color: #f7f7f7;
  }
  .rdt_TableRow {
    &:nth-child(odd) {
      background-color: #eaeaea;
    }

    &:nth-child(even) {
      background-color: #f7f7f7;
    }

    &:hover {
      background-color: #c4c4c4;
    }
  }
`;
export const ExpandableRowsComponent = ({ data, columns }) => {
  return (
    <>
      <Container>
        <DataTable
          noTableHead
          expandableIcon={<IoIosRadio />}
          columns={columns}
          data={data.expand.map((item) => ({ ...item, disabled: true }))}
          expandableRows
          dense
          expandableRowDisabled={(row) => row.disabled}
          noDataComponent={() => <strong>SEM REGISTROS</strong>}
        />
      </Container>
    </>
  );
};
