import React from "react";
import { ScaleLoader } from "react-spinners";

const Carregando = ({ carregando }) => {
  return (
    <div className="container-loading">
      <ScaleLoader size={150} color={"#f41068"} loading={carregando} />
    </div>
  );
};

export default Carregando;
