import { TextField, styled } from "@mui/material";

const InputNumber = styled(TextField)`
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`;

export const StyledInputNumber = styled(InputNumber)`
input[type="number"] {
  -moz-appearance: textfield; /* Para Firefox */
}
`;