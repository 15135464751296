import React from "react";
import { ToggleButton } from "react-bootstrap";

const ButtonVoltar = ({ fVoltar, nomeBtt }) => {
  return (
    <div className=" container-botao-voltar">
      <ToggleButton
        variant="secondary"
        className="botao-voltar botao-voltar-active"
        onClick={() => fVoltar()}
      >
        {nomeBtt ? nomeBtt : "Voltar"}
      </ToggleButton>
    </div>
  );
};

export default ButtonVoltar;
