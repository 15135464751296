import {
  ContentCopy,
  Delete,
  Edit,
  FileDownload,
  Visibility,
} from "@mui/icons-material";
import {
  Divider,
  ListItemIcon,
  Popper,
  Grow,
  MenuItem,
  Paper,
  MenuList,
  ClickAwayListener,
  CircularProgress,
  Box,
} from "@mui/material";

import { useHistory } from "react-router-dom";
import { api } from "../../../services/api.v2";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { saveAs } from "file-saver";
export default function DenseMenu({
  id,
  edit = false,
  link,
  onDelete,
  onClose,
  ...props
}) {
  const history = useHistory();

  const [isLoadingDownloadCsv, setIsLoadingDownloadCsv] = useState(false);

  const handleEdit = () => {
    history.push(
      `/franquia/lista-interresados/editar/${id}`
    );
  };

  const handleSee = () => {
    history.push(link);
  };

  const handleDelete = () => {
    onDelete(id);
    onClose();
  };
  const handleDownloadCsv = async () => {
    setIsLoadingDownloadCsv(true);

    try {
      const response = await api.get(
        `/franchise/download-xlsx-influencer/${id}`,
        {
          responseType: "blob", // Configura a resposta como um objeto Blob
        }
      );
      // Extrai o conteúdo do objeto Blob
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Define o nome do arquivo
      const fileName = "arquivo.xlsx";

      // Faz o download do arquivo usando a função saveAs do FileSaver
      saveAs(blob, fileName);
    } catch (error) {
      toast.error("Erro ao tentar baixar registros da pagina");
    } finally {
      setIsLoadingDownloadCsv(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Link copiado para a área de transferência.");
      })
      .catch((error) => {
        toast.error("Erro ao copiar link para a área de transferência.");
      });
  };

  return (
    <Popper role={undefined} placement="bottom-start" transition {...props}>
      {({ TransitionProps, placement }) => {
        return (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClose}>
                <MenuList
                  autoFocusItem={props.open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  <MenuItem onClick={handleEdit}>
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    Editar
                  </MenuItem>
                  <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                      <Delete />
                    </ListItemIcon>
                    Deletar
                  </MenuItem>
                  {edit && (
                    <MenuItem onClick={handleSee}>
                      <ListItemIcon>
                        <Visibility />
                      </ListItemIcon>
                      Ver informações
                    </MenuItem>
                  )}
                  <Divider />

                  <MenuItem
                    disabled={isLoadingDownloadCsv}
                    onClick={handleDownloadCsv}
                  >
                    {isLoadingDownloadCsv ? (
                      <Box mr={1.7} sx={{ display: "flex" }}>
                        <CircularProgress color="inherit" size={22} />
                      </Box>
                    ) : (
                      <ListItemIcon>
                        <FileDownload />
                      </ListItemIcon>
                    )}
                    Baixar todos os indicados pelo influencer
                  </MenuItem>

                  <Divider />
                  <MenuItem
                    onClick={() =>
                      copyToClipboard(
                        `https://franquia.aprincipalbb.com.br/franquia/${id}`
                      )
                    }
                  >
                    <ListItemIcon>
                      <ContentCopy />
                    </ListItemIcon>
                    Copiar Link de indicação
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        );
      }}
    </Popper>
  );
}
