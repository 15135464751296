import styled from "styled-components";

export const ContainerGrafico = styled.section`
    padding: 15px 0;
    margin-bottom: 1.25rem;
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    button{
        margin-left: 10px;
    }
`
export const ContainerCenterBotoes = styled.aside`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
    div{
        section{
            display: grid
        }
    }
    p{
        display:flex;
        justify-content: center;
    }
`
export const ContainerTipoGrupo = styled.aside`
    position: absolute;
    margin-left: 0.8rem;
    margin-top: 0.7rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p{
        padding: 0.2rem 1rem;
        background: #cfd0d2;
    color: white;
    border-radius: 0.25rem;
        
    }
`
export const ContainerFilterEstoqueProduto = styled.div`
    max-width: 100%;
    /* width: 700px; */
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    & .Content-buttons-filtrar-hoje{
        width: fit-content;
        margin-left: auto;
    }
`

export const ContainerIniciarBusca = styled.div`
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 100px 20px;
    text-align: center;
    font-style: italic;
    font-weight: bold;
`
