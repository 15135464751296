import { HelpOutlineOutlined } from "@mui/icons-material";
import { InputAdornment, Tooltip } from "@mui/material";
import { StyledInputNumber } from "./StyledInputNumber";

export const InputHelpTooltip = ({ titleTooltip, ...props }) => {
  return (
    <StyledInputNumber
      {...props}
      InputLabelProps={{
        shrink: true,
      }}
      
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={titleTooltip} followCursor>
              <HelpOutlineOutlined />
            </Tooltip>
          </InputAdornment>
        ),
        inputMode: "numeric",
        inputProps: {
          style: {
            MozAppearance: "textfield",
            WebkitAppearance: "textfield",
            appearance: "textfield",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        style: {
          paddingRight: "8px",
        },
      }}
    />
  );
};
