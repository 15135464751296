import { Container } from "./styles";

export const FiltroPeriodo = ({
  periodoInputInicioAux,
  periodoInputFimAux,
  setPeriodoInputInicioAux,
  setPeriodoInputFimAux,
}) => {
  return (
    <Container>
      <span>Período</span>
      <div>
        <p>De: </p>
        <input
          type="date"
          value={periodoInputInicioAux}
          onChange={(e) => {
            setPeriodoInputInicioAux(e.target.value);
          }}
          placeholder="01/01/2020"
        />
      </div>
      <div>
        <p>Até: </p>
        <input
          type="date"
          value={periodoInputFimAux}
          onChange={(e) => {
            console.log(e.target.value);
            setPeriodoInputFimAux(e.target.value);
          }}
          placeholder="31/12/2020"
        />
      </div>
    </Container>
  );
};
