import styled from "styled-components";

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    //background-color: black;
    span{
        justify-content: center;
        display: flex;
        margin-top: 0.5rem;
        font-weight: bold;
        font-size: 1.3rem;
        color: #FC68A2;
    }
    div{
        display:flex;
       padding: 0.5rem;
        justify-content: space-between;
        gap: 0.25rem;
        color: #828282;
         border: 1px solid;
         border-color: #FC68A2;
       border-radius: 8px;
       width: 100%;
        input{
            width: 140px;
            border: none;
            color: #828282;
        }
    }
`
