import toast from "react-hot-toast";

import Title from "../../../components/title";

import React, { useEffect, useState } from "react";
import { PermissionsPage } from "../../../util/permissionsPage";

import {
  Box,
  Container as Cont,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { api } from "../../../services/api.v2";
import { Row } from "../components/Row";
import { StyledTableCell } from "../components/StyledTableCell";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../components/SearchComponent/SearchComponent";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "throttle-debounce";
import { LoadingTable } from "../../../components/Loading/LoadingTable";
import { NoData } from "../../../components/NoData/NoData";
import { FiMoreVertical } from "react-icons/fi";
import { ListMenu } from "./components/ListMenu";
import { isEmptyObject } from "../../../util/isEmptyObject";
import { useUserData } from "../../../hooks/useUserData";

export const ListInterested = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { userData } = useUserData();

  const [total, setTotal] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
  });
  const [data, setData] = useState([]);
  const [optionsProfile, setOptionsProfile] = useState([]);

  const handleChangePage = (event, newPage) => {
    setParams((old) => ({ ...old, page: newPage + 1 }));
  };

  const handleSearch = (ev) => {
    setParams((old) => ({ ...old, search: ev, page: 1, per_page: 10 }));
  };
  const handleChangeRowsPerPage = (event) => {
    setParams((old) => ({ ...old, per_page: +event.target.value, page: 1 }));
  };
  const debounceFunc = debounce(500, handleSearch);

  const getList = async () => {
    setIsLoadingData(true);
    const toastId = toast.loading("Obtendo dados");
    try {
      const response = await api.get("/franchise/list-interested", {
        params: {
          page: params.page,
          per_page: params.per_page,
          search: params.search,
        },
      });
      const { total, data } = response.data;
      setTotal(total);
      setData(data);
      toast.success("Dados recebidos", {
        duration: 5000,
        id: toastId,
      });
    } catch (error) {
      toast.error(error?.message, {
        duration: 5000,
        id: toastId,
        style: {
          borderRadius: "0.25rem",
          background: "#333",
          color: "#fff",
        },
      });
    } finally {
      setIsLoadingData(false);
    }
  };

  const getProfileOptions = async () => {
    try {
      const response = await api.get("/franchise/profile-indicate");
      setOptionsProfile(response.data);
    } catch (error) {
      toast.error(`Error: ${error.message}`, {
        duration: 5000,
        style: {
          borderRadius: "0.25rem",
          background: "#333",
          color: "#fff",
        },
      });
      console.log(error);
    }
  };

  useEffect(() => {
    getProfileOptions();
  }, []);

  useEffect(() => {
    if (!isEmptyObject(userData)) {
      getList();
    }
  }, [userData, params]);

  const handleDelete = async (id) => {
    try {
      await api.delete(`/franchise/delete-influencer/${id}`);
      toast.success("Influencer deletado com sucesso");
      const configParams = { ...params };
      setParams(configParams);
    } catch (error) {
      toast.error("Ocorreu um erro ao deletar");
      console.log(error);
    }
  };
  const cellsHeader = [
    "",
    "Opções",
    "ID",
    "Nome",
    "Perfil do indicador",
    "Telefone",
    "CPF",
    "Email",
    "Interessados",
  ];

  return (
    <>
      <>
        <>
          <Title title="LISTA DE INTERESSADOS" />
          {PermissionsPage("list_interested") && (
            <>
              <Box
                component={Cont}
                marginY={2}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    sx={{
                      borderRadius: "4px",
                      borderColor: "#d6d6d6 !important",
                      border: "1px solid",
                    }}
                    disabled={isLoadingData}
                    placeholder="Pesquisar  "
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => debounceFunc(e.target.value)}
                  />
                </Search>

                <IconButton
                  aria-controls={openMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <FiMoreVertical />
                </IconButton>
              </Box>

              {isLoadingData ? (
                <LoadingTable />
              ) : (
                <>
                  <Cont
                    sx={{
                      paddingBottom: "2rem",
                    }}
                  >
                    {data.length <= 0 ? (
                      <Box
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <NoData />
                      </Box>
                    ) : (
                      <TableContainer component={Paper}>
                        <Table size={"small"} aria-label="collapsible table">
                          <TableHead>
                            <TableRow>
                              {cellsHeader.map((cell) => (
                                <StyledTableCell key={cell}>
                                  {cell}
                                </StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {data.length > 0 &&
                              data.map((row) => (
                                <Row
                                  key={row.id}
                                  row={row}
                                  optionsProfile={optionsProfile}
                                  handleDelete={handleDelete}
                                />
                              ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[
                            10,
                            25,
                            50,
                            100,
                            { label: "Todos", value: total },
                          ]}
                          component="div"
                          count={total}
                          rowsPerPage={params.per_page}
                          page={params.page - 1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage={"Linhas por pagina"}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                        />
                      </TableContainer>
                    )}
                  </Cont>
                </>
              )}
            </>
          )}
        </>
      </>
      <ListMenu onClose={handleClose} open={openMenu} anchorEl={anchorEl} />
    </>
  );
};
