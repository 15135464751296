export const bNivelAcesso = (data) => {
  let auxAcesso = [],
    aux = {};
  data.forEach((ev) => {
    if (ev[ev.field] === "true") {
      aux[ev.field] = true;
    }
  });
  data.forEach((item) => {
    if (item.analiseClientes && item.analiseClientes === "true")
      auxAcesso.analiseClientes = true;
    if (item.cCartaoConciliacao && item.cCartaoConciliacao === "true")
      auxAcesso.cCartaoConciliacao = true;
    if (item.site && item.site === "true") auxAcesso.site = true;
    if (item.site1 && item.site1 === "true") auxAcesso.site1 = true;
    if (item.estoqueMes && item.estoqueMes === "true")
      auxAcesso.estoqueMes = true;
    if (item.estoqueContasPagar && item.estoqueContasPagar === "true")
      auxAcesso.estoqueContasPagar = true;
    if (item.site2 && item.site2 === "true") auxAcesso.site2 = true;
    if (item.design && item.design === "true") auxAcesso.design = true;
    if (item.design_1 && item.design_1 === "true") auxAcesso.design_1 = true;
    if (item.design_2 && item.design_2 === "true") auxAcesso.design_2 = true;
    if (item.design_3 && item.design_3 === "true") auxAcesso.design_3 = true;
    if (item.landing && item.landing === "true") auxAcesso.landing = true;
    if (item.a_envio && item.a_envio === "true") auxAcesso.a_envio = true;
    if (item.landing_1 && item.landing_1 === "true") auxAcesso.landing_1 = true;
    if (item.a_envio1 && item.a_envio1 === "true") auxAcesso.a_envio1 = true;
    if (item.landing_2 && item.landing_2 === "true") auxAcesso.landing_2 = true;
    if (item.colaborador && item.colaborador === "true")
      auxAcesso.colaborador = true;
    if (item.colaborador1 && item.colaborador1 === "true")
      auxAcesso.colaborador1 = true;
    if (item.colaborador2 && item.colaborador2 === "true")
      auxAcesso.colaborador2 = true;
    if (item.forms && item.forms === "true") auxAcesso.forms = true;
    if (item.forms1 && item.forms1 === "true") auxAcesso.forms1 = true;
    if (item.forms2 && item.forms2 === "true") auxAcesso.forms2 = true;
    if (item.forms3 && item.forms3 === "true") auxAcesso.forms3 = true;
    if (item.forms4 && item.forms4 === "true") auxAcesso.forms4 = true;
    if (item.vagas && item.vagas === "true") auxAcesso.vagas = true;
    if (item.avendas && item.avendas === "true") auxAcesso.avendas = true;
    if (item.avendas1 && item.avendas1 === "true") auxAcesso.avendas1 = true;
    if (item.avendas2 && item.avendas2 === "true") auxAcesso.avendas2 = true;
    if (item.avendas3 && item.avendas3 === "true") auxAcesso.avendas3 = true;
    if (item.avendas4 && item.avendas4 === "true") auxAcesso.avendas4 = true;
    if (item.avendas5 && item.avendas5 === "true") auxAcesso.avendas5 = true;
    if (item.avendas6 && item.avendas6 === "true") auxAcesso.avendas6 = true;
    if (item.mvendas && item.mvendas === "true") auxAcesso.mvendas = true;
    if (item.mvendas1 && item.mvendas1 === "true") auxAcesso.mvendas1 = true;
    if (item.mvendas2 && item.mvendas2 === "true") auxAcesso.mvendas2 = true;
    if (item.laboratorio && item.laboratorio === "true")
      auxAcesso.laboratorio = true;
    if (item.vagas1 && item.vagas1 === "true") auxAcesso.vagas1 = true;
    if (item.vagas2 && item.vagas2 === "true") auxAcesso.vagas2 = true;
    if (item.material && item.material === "true") auxAcesso.material = true;
    if (item.material1 && item.material1 === "true") auxAcesso.material1 = true;
    if (item.material2 && item.material2 === "true") auxAcesso.material2 = true;
    if (item.resultados && item.resultados === "true")
      auxAcesso.resultados = true;
    if (item.resultados1 && item.resultados1 === "true")
      auxAcesso.resultados1 = true;
    if (item.resultados2 && item.resultados2 === "true")
      auxAcesso.resultados2 = true;
    if (item.config && item.config === "true") auxAcesso.config = true;
    if (item.config1 && item.config1 === "true") auxAcesso.config1 = true;
    if (item.curso && item.curso === "true") auxAcesso.curso = true;
    if (item.curso1 && item.curso1 === "true") auxAcesso.curso1 = true;
    if (item.financeiro && item.financeiro === "true")
      auxAcesso.financeiro = true;
    if (item.cmetas && item.cmetas === "true") auxAcesso.cmetas = true;
    if (item.cParametrosBonificacao && item.cParametrosBonificacao === "true")
      auxAcesso.cParametrosBonificacao = true;
    if (item.cParametrosBoletim && item.cParametrosBoletim === "true")
      auxAcesso.cParametrosBoletim = true;
    if (item.boletim && item.boletim === "true") auxAcesso.boletim = true;
    if (item.planejamento && item.planejamento === "true")
      auxAcesso.planejamento = true;
    if (item.resultadosMetas && item.resultadosMetas === "true")
      auxAcesso.resultadosMetas = true;
    if (item.parametrosFornecedores && item.parametrosFornecedores === "true")
      auxAcesso.parametrosFornecedores = true;
  });

  return auxAcesso;
};
