import React, { useEffect, useState } from "react";
import { HorizontalBar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import Title from "../../components/title";
import "react-data-table-component-extensions/dist/index.css";
import {
  arrayBases,
  converterData,
  converterDataFormatoPadrao,
  converterLoja,
  converterMes,
  converterNomeLoja,
  fAnosDisponiveis,
  fAnosPorLoja,
} from "../../util/loja";

import Select from "react-select";

import ButtonVoltar from "../../components/ButtonVoltar";
import { vendasGrupoTabelaPorGrupo } from "../../util/tables";
import TabelaNext from "../../components/componentteste/TabelaNext";
import { FiltroPeriodo } from "../../components/FiltroPeriodo/FiltroPeriodo.jsx";
import { TituloTabela } from "../../components/TituloTabela/TituloTabela";
import toast from "react-hot-toast";

import { PermissionsPage } from "../../util/permissionsPage";
import { vendasService } from "../../services/vendas";
import { useUserData } from "../../hooks/useUserData";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { LoadingTable } from "../../components/Loading/LoadingTable";
import { MultiSelectFilterBases } from "../../components/FilterLojaMetas/MultiSelectFilterBases";
import {
  ButtonFilter,
  ButtonToday,
} from "../../components/DatePikerFilter/styles";
const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? " whitesmoke" : "white",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: " #eb0058",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#FFF",
    fontWeight: "bold",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: "#ff0d6c",
      color: "white",
    },
  }),
};

function VendasPorGrupo() {
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useUserData();
  const accessLevel = userData?.nivelAcesso;
  const acessBase = userData?.baseAcesso;

  const [periodoInputInicio, setPeriodoInputInicio] = useState("");
  const [periodoInputFim, setPeriodoInputFim] = useState("");
  const [periodoInputInicioAux, setPeriodoInputInicioAux] = useState("");
  const [periodoInputFimAux, setPeriodoInputFimAux] = useState("");
  const [periodoAno, setPeriodoAno] = useState(false);

  const [anosDisponiveis, setAnosDisponiveis] = useState([]);
  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);
  const [dadosGraficoAgrupado, setDadosGraficoAgrupado] = useState({
    labels: [],
    datasets: [],
  });
  const [dadosGraficoAgrupado1, setDadosGraficoAgrupado1] = useState({
    labels: [],
    datasets: [],
  });
  const [dadosGraficoAgrupado2, setDadosGraficoAgrupado2] = useState({
    labels: [],
    datasets: [],
  });

  const [dadosGerais, setDadosGerais] = useState(0);
  const [dadosGeraisGrupo, setDadosGeraisGrupo] = useState([]);

  const [filtrosAtivosLojasTexto, setFiltrosAtivosLojasTexto] = useState("");
  const [filtrosMesesSelecionados] = useState([]);
  const [filtrosAnosSelecionados, setFiltrosAnosSelecionados] = useState([]);
  const [filtrosLojasSelecionadas, setFiltrosLojasSelecionadas] = useState([]);

  const [lojaTable, setLojaTable] = useState(false);
  const [dadosParaTabela, setDadosParaTabela] = useState([]);
  const [auxDadosParaTabela, setAuxDadosParaTabela] = useState([]);
  const [dadosParaTabelaLoja, setDadosParaTabelaLoja] = useState([]);

  const [dadosUltimoPeriodo, setDadosUltimoPeriodo] = useState([]);
  const [exibeTabela, setExibeTabela] = useState(false);
  const [tipoPeriodo, setTipoPeriodo] = useState("");
  const [auxTipoPeriodo, setAuxTipoPeriodo] = useState("");
  const [filtradoPorLoja, setFiltradoPorLoja] = useState(false);
  const [grupoAtivo, setGrupoAtivo] = useState("");
  const [nivelAtivoDoGrafico, setNivelAtivoDoGrafico] = useState(0);
  const [auxAnoAtual, setAuxAnoAtual] = useState(0);
  const [auxAnoAnterior, setAuxAnoAnterior] = useState(0);

  const [dadosDisponiveis, setDadosDisponiveis] = useState(true);
  const [carregando, setCarregando] = useState(false);
  const [nivelAcesso, setNivelAcesso] = useState([]);
  const [base, setBase] = useState();
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [basesSelecionadas, setBasesSelecionadas] = useState([]);

  useEffect(() => {
    if (nivelAcesso.length === 0) {
      buscarNivelAcesso();
      return;
    }
  }, [userData]);
  useEffect(() => {
    if (periodoAno) {
      preencherDados("ano");
    } else if (
      periodoInputInicio &&
      periodoInputInicio != "" &&
      periodoInputFim &&
      periodoInputFim != ""
    ) {
      preencherDados("input");
    }
  }, [periodoInputInicio, periodoInputFim, periodoAno, userData, base]);
  useEffect(() => {
    preencherInputPeriodo();
  }, []);

  useEffect(() => {
    if (filtrosAnosSelecionados.length > 0) {
      iniciaFiltroPorAno();
    }
  }, [filtrosAnosSelecionados]);

  useEffect(() => {
    if (filtradoPorLoja) {
      iniciaFiltroPorLoja();
    }
  }, [filtrosLojasSelecionadas]);

  useEffect(() => {
    if (grupoAtivo.trim() != "") {
      preencherDadosGrupoEspecifico(tipoPeriodo);
    }
  }, [grupoAtivo]);

  const buscarNivelAcesso = () => {
    if (accessLevel) {
      setNivelAcesso(accessLevel);
      const bases = arrayBases(acessBase);
      setBasesDisponiveis(bases);
      setBase(bases?.map((item) => item.codBase));
    } else {
      setCarregando(false);
      setIsLoading(false);
    }
  };

  function preencherInputPeriodo() {
    let dataHoje = new Date();
    let [inicioAno, hoje] = converterDataFormatoPadrao(dataHoje);
    let { inicioAno: inicioAnoTexto, hoje: hojeTexto } =
      converterData(dataHoje);

    setPeriodoInputInicioAux(inicioAno);
    setPeriodoInputFimAux(hoje);
    setPeriodoInputInicio(inicioAno);
    setPeriodoInputFim(hoje);
  }

  async function preencherDados(tipo) {
    setTipoPeriodo(tipo);
    let auxLojasSelecionadas = [];
    if (tipo != "mes") setAuxTipoPeriodo(tipo);
    setFiltradoPorLoja(false);
    setDadosGeraisGrupo([]);
    setGrupoAtivo("");
    setFiltrosAtivosLojasTexto("");
    setNivelAtivoDoGrafico(0);
    let lojasDisponiveisAux = [];
    lojasDisponiveis.map((item) => {
      lojasDisponiveisAux.push(item);
    });
    setLojasDisponiveis(lojasDisponiveisAux);
    if (tipoPeriodo == "mes") {
      tipo = auxTipoPeriodo;
    }
    if (tipo == "input" || tipo === "loja") {
      setExibeTabela(true);
      var anoAtual = periodoInputInicio.split("-")[0];
      var anoAnterior = parseInt(anoAtual) - 1;
      setAuxAnoAtual(anoAtual);
      setAuxAnoAnterior(anoAnterior);
    } else if (tipo == "ano") {
      var anoAtual = periodoAno.split(",");
      anoAtual = anoAtual.sort((a, b) => a - b);
      var anoAnterior;
      if (anoAtual.length > 1) {
        anoAnterior = anoAtual[anoAtual.length - 2];
      } else {
        anoAnterior = 0;
      }
      anoAtual = anoAtual[anoAtual.length - 1];
      setAuxAnoAtual(anoAtual);
      setAuxAnoAnterior(anoAnterior);
    }
    setCarregando(true);
    setIsLoading(true);
    if (!base) {
      setCarregando(true);
      setLojasDisponiveis([]);
      return;
    }
    window.scrollTo(0, 0);
    const toastId = toast.loading("Esperando dados do RAD");
    try {
      const response = await vendasService.listaVendasGrupo(
        JSON.stringify({
          inicio: periodoInputInicio,
          fim: periodoInputFim,
          ano: periodoAno,
          tipo,
          nivelAcesso: accessLevel,
          base,
        })
      );

      if (!response?.data) {
        toast.error("Não há conexão com o RAD", {
          duration: 7000,
          id: toastId,
          style: {
            borderRadius: "0.25rem",
            background: "#333",
            color: "#fff",
          },
        });
        setCarregando(false);
        setIsLoading(false);
        setDadosDisponiveis(false);
        return;
      }

      if (response?.data?.success && response?.data?.type) {
        toast.success("Dados recebidos", {
          id: toastId,
        });
        setAnosDisponiveis(fAnosDisponiveis(response.data.anos));
        var dataVendas = response.data.data.vendas;
        let dataVendasPeriodoAnterior =
          response.data.data.vendasPeriodoAnterior;
        dataVendas = dataVendas.sort((a, b) => {
          return a.CodLoja - b.CodLoja;
        });
        setDadosGerais(dataVendas);
        dataVendas.map((a) => {
          if (auxLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) == -1) {
            auxLojasSelecionadas.push(converterLoja(a.CodLoja));
          }
        });

        if (filtrosLojasSelecionadas.length > 0) {
          dataVendas = dataVendas.filter((a) => {
            if (
              filtrosLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) >= 0
            ) {
              return true;
            } else {
              return false;
            }
          });
        }
        let grupoDeAnos = agruparPor(dataVendas, "ano");
        let grupoDeAnosArray = Object.entries(grupoDeAnos);
        let separadoPorGrupo = [];
        grupoDeAnosArray.map((item) => {
          let grupoDeLoja = agruparPor(item[1], "CodGrp1");
          separadoPorGrupo.push(grupoDeLoja);
        });
        let resumoSeparadoPorGrupo = [];
        separadoPorGrupo.map((item) => {
          let item2 = Object.entries(item);
          item2.map((item3) => {
            let total = 0;
            let totalPedidos = 0;
            let ano;
            let codGrupo;
            let nomeGrupo;
            let totalCusto = 0;
            let totalLucro = 0;
            let porcentagemLucro = 0;
            item3[1].map((item4, index) => {
              if (index == 0) {
                ano = item4.ano;
                nomeGrupo = item4.NomeGrupo1;
                codGrupo = item4.CodGrp1;
              }
              totalPedidos += isNaN(parseInt(item4.totalPedidos))
                ? 0
                : parseInt(item4.totalPedidos);
              total += parseFloat(item4.VlrTotal);
              totalCusto += parseFloat(item4.VlrCusto);
              totalLucro += parseFloat(item4.VlrLucroBruto);
              porcentagemLucro += parseFloat(item4.VlrLucroBrutoPorcentagem);
            });
            porcentagemLucro = porcentagemLucro / item3[1].length;
            let resumoLojaAno = {
              ano: ano,
              codGrupo,
              nomeGrupo,
              total: total,
              totalPedidos: totalPedidos,
              totalCusto,
              totalLucro,
              porcentagemLucro,
            };
            resumoSeparadoPorGrupo.push(resumoLojaAno);
          });
        });
        var agrupado = agruparPor(resumoSeparadoPorGrupo, "ano");
        grupoDeAnosArray = Object.entries(agrupado);
        setDadosUltimoPeriodo(dataVendasPeriodoAnterior);
        preencherDadosGraficoAgrupado(
          grupoDeAnosArray,
          tipo,
          anoAtual,
          anoAnterior,
          dataVendasPeriodoAnterior
        );
        setLojasDisponiveis(auxLojasSelecionadas);
        setDadosDisponiveis(true);
        setCarregando(false);
        setIsLoading(false);
      } else {
        setCarregando(false);
        setIsLoading(false);
        setDadosDisponiveis(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function preencherDadosGrupoEspecifico(tipo) {
    setTipoPeriodo(tipo);
    let auxLojasSelecionadas = [];
    if (tipo != "mes") setAuxTipoPeriodo(tipo);
    setFiltradoPorLoja(false);
    if (tipoPeriodo == "mes") {
      tipo = auxTipoPeriodo;
    }
    if (tipo == "input") {
      setExibeTabela(true);
      var auxAnoAtual = periodoInputInicio.split("-")[0];
      var auxAnoAnterior = parseInt(auxAnoAtual) - 1;
      setAuxAnoAtual(auxAnoAtual);
      setAuxAnoAnterior(auxAnoAnterior);
    } else if (tipo == "ano") {
      var auxAnoAtual = periodoAno.split(",");
      auxAnoAtual = auxAnoAtual.sort((a, b) => a - b);
      var auxAnoAnterior;
      if (auxAnoAtual.length > 1) {
        auxAnoAnterior = auxAnoAtual[auxAnoAtual.length - 2];
      } else {
        auxAnoAnterior = 0;
      }
      auxAnoAtual = auxAnoAtual[auxAnoAtual.length - 1];
      setAuxAnoAtual(auxAnoAtual);
      setAuxAnoAnterior(auxAnoAnterior);
    }
    setCarregando(true);
    setIsLoading(true);
    window.scrollTo(0, 0);

    try {
      const response = await vendasService.listaVendasGrupo(
        JSON.stringify({
          inicio: periodoInputInicio,
          fim: periodoInputFim,
          ano: periodoAno,
          tipo,
          grupoAtivo,
          nivelAcesso,
          base,
        })
      );

      if (response?.data?.success && response?.data?.type) {
        let dataVendas = response.data.data.vendas;
        let dataVendasPeriodoAnteriror =
          response.data.data.vendasPeriodoAnterior;
        dataVendas = dataVendas.sort((a, b) => {
          return a.CodLoja - b.CodLoja;
        });
        setDadosGeraisGrupo(dataVendas);
        dataVendas.map((a) => {
          if (auxLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) == -1) {
            auxLojasSelecionadas.push(converterLoja(a.CodLoja));
          }
        });

        if (filtrosLojasSelecionadas.length > 0) {
          dataVendas = dataVendas.filter((a) => {
            if (
              filtrosLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) >= 0
            ) {
              return true;
            } else {
              return false;
            }
          });
        }
        let grupoDeAnos = agruparPor(dataVendas, "ano");
        let grupoDeAnosArray = Object.entries(grupoDeAnos);
        let separadoPorLoja = [];
        grupoDeAnosArray.map((item) => {
          let grupoDeLoja = agruparPor(item[1], "CodLoja");
          separadoPorLoja.push(grupoDeLoja);
        });
        let resumoSeparadoPorLoja = [];
        separadoPorLoja.map((item) => {
          let item2 = Object.entries(item);
          item2.map((item3) => {
            let total = 0;
            let totalPedidos = 0;
            let ano;
            let codGrupo;
            let nomeGrupo;
            let totalCusto = 0;
            let totalLucro = 0;
            let porcentagemLucro = 0;
            let codLoja = 0;
            item3[1].map((item4, index) => {
              if (index == 0) {
                ano = item4.ano;
                nomeGrupo = item4.NomeGrupo1;
                codGrupo = item4.CodGrp1;
              }
              totalPedidos += parseInt(item4.totalPedidos);
              total += parseFloat(item4.VlrTotal);
              totalCusto += parseFloat(item4.VlrCusto);
              totalLucro += parseFloat(item4.VlrLucroBruto);
              porcentagemLucro += parseFloat(item4.VlrLucroBrutoPorcentagem);
              codLoja = item4.CodLoja;
            });
            porcentagemLucro = porcentagemLucro / item3[1].length;
            let resumoLojaAno = {
              ano: ano,
              codGrupo,
              nomeGrupo,
              total: total,
              totalPedidos: totalPedidos,
              totalCusto,
              totalLucro,
              porcentagemLucro,
              codLoja,
            };
            resumoSeparadoPorLoja.push(resumoLojaAno);
          });
        });
        var agrupado = agruparPor(resumoSeparadoPorLoja, "ano");
        grupoDeAnosArray = Object.entries(agrupado);
        preencherDadosGraficoAgrupadoGrupo(
          grupoDeAnosArray,
          tipo,
          auxAnoAnterior,
          auxAnoAtual,
          dataVendasPeriodoAnteriror
        );
        setDadosDisponiveis(true);
        setCarregando(false);
        setIsLoading(false);
      } else {
        setCarregando(false);
        setIsLoading(false);
        setDadosDisponiveis(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function agruparPor(objetoArray, propriedade) {
    return objetoArray.reduce(function (acc, obj) {
      let key = obj[propriedade];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const LoadingTemp = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  };

  function iniciaFiltroPorAno() {
    var anosSelecionados = filtrosAnosSelecionados.join(",");
    setPeriodoAno(anosSelecionados);
  }

  function iniciaFiltroPorLoja() {
    preencherDadosVendasPorLoja();
  }

  async function preencherDadosGraficoAgrupado(
    dados,
    tipo,
    anoAtual,
    anoAnterior,
    dadosPeriodoAnterior
  ) {
    dados = dados.reverse();
    var label = [];
    var labelCompleta = [];
    var dataSets = [];
    var dadosTabelaAux = [];

    var dadosGrupoAux = [];
    const promise = await dados.map((item, i) => {
      var dadosGrupo = [];
      item[1] = item[1].sort((a, b) => {
        return b.total - a.total;
      });
      item[1].map((itemGrupo) => {
        var nomeGrupo = itemGrupo.nomeGrupo;
        if (i == 0) {
          label.push(nomeGrupo);
          labelCompleta.push(nomeGrupo);
          dadosGrupo.push(parseFloat(itemGrupo.total));
          dadosGrupoAux.push({ nomeGrupo: itemGrupo.nomeGrupo });
        } else {
          let jaExiste = label.indexOf(nomeGrupo);
          if (jaExiste < 0) {
            label.push(nomeGrupo);
          }
          jaExiste = label.indexOf(nomeGrupo);
          if (jaExiste < 0) {
            labelCompleta.push(nomeGrupo);
            dadosGrupo.push(parseFloat(itemGrupo.total));
            dadosGrupoAux.push({ nomeGrupo: itemGrupo.nomeGrupo });
          } else {
            dadosGrupo = insertAt(
              dadosGrupo,
              jaExiste,
              parseFloat(itemGrupo.total)
            );
            dadosGrupoAux = insertAt(dadosGrupoAux, jaExiste, {
              nomeGrupo: itemGrupo.nomeGrupo,
            });
          }
        }
        dadosTabelaAux.push(itemGrupo);
      });
      var cor =
        i == dados.length - 1
          ? "rgba(255, 194, 218, 1)"
          : i == dados.length - 2
          ? "rgba(91, 139, 252, 1)"
          : i == dados.length - 3
          ? "#5B8BFC"
          : i == dados.length - 4
          ? "#FFC2DA"
          : i == dados.length - 5
          ? ""
          : geraCorAleatoria();
      var datalabels;
      if (i == 1) {
        datalabels = {
          anchor: "end",
          display: true,
          color: cor,
          // rotation: -8,
          align: "end",
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        };
      } else if (i == 0) {
        datalabels = {
          anchor: "end",
          display: true,
          color: cor,
          align: "end",
          // rotation: -8,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        };
      } else {
        datalabels = {
          formatter: function (value, context) {
            return "";
          },
        };
      }

      dataSets.push({
        label: item[0],
        data: dadosGrupo,
        backgroundColor: cor,
        datalabels: datalabels,
      });
    });

    if (dadosPeriodoAnterior && dadosPeriodoAnterior?.length > 0) {
      let periodoAnteriorAux = [];
      if (filtrosLojasSelecionadas.length > 0) {
        await dadosPeriodoAnterior.map((item) => {
          var nomeLoja = converterLoja(item.CodLoja);
          if (filtrosLojasSelecionadas.indexOf(nomeLoja) >= 0) {
            periodoAnteriorAux.push(parseInt(item.VlrTotal));
          }
        });
        dataSets.push({
          label: "Periodo anterior",
          data: periodoAnteriorAux,
          backgroundColor: "#FFDB8A",
          datalabels: {
            anchor: "start",
            display: true,
            align: "start",
          },
        });
      } else {
        const dadosPeriodoAnteriorTemp = [];
        dadosGrupoAux.map((item) => {
          let dadosPeriodoAnteriorAux = dadosPeriodoAnterior.filter(
            (item2) => item2.NomeGrupo1 === item.nomeGrupo
          );
          let dadosPeriodoAnteriorAuxSoma = dadosPeriodoAnteriorAux.reduce(
            (accum, item3) => (accum += Number(item3.VlrTotal)),
            0
          );
          dadosPeriodoAnteriorTemp.push(dadosPeriodoAnteriorAuxSoma);
        });

        dataSets.push({
          label: "Periodo anterior",
          data: dadosPeriodoAnteriorTemp,
          backgroundColor: "#FFDB8A",
          datalabels: {
            formatter: function (value, context) {
              return parseFloat(value).toLocaleString("pt-BR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
            },
          },
        });
      }
    }

    await Promise.all(promise);
    const data = {
      labels: label,
      datasets: dataSets,
    };
    dadosTabelaAux = agruparPor(dadosTabelaAux, "nomeGrupo");
    dadosTabelaAux = Object.entries(dadosTabelaAux);
    //preencherColunasTabela(tipo)
    preencherDadosTabela(
      dadosTabelaAux,
      anoAtual,
      anoAnterior,
      tipo,
      dadosPeriodoAnterior
    );
    setDadosGraficoAgrupado(data);
  }

  function insertAt(array, index, element) {
    if (array.length == index && array.length == 0) {
      array.push(0);
      array.splice(index, 1, element);
      return array;
    }
    if (array.length < index) {
      do {
        array.push(0);
      } while (array.length < index);
      array.splice(index, 1, element);

      return array;
    } else {
      array.splice(index, 1, element);
      return array;
    }
  }

  async function preencherDadosGraficoAgrupadoGrupo(
    dados,
    tipo,
    anoAnterior,
    anoAtual,
    dadosPeriodoAnterior
  ) {
    dados = dados.reverse();
    let label = [];
    let labelCompleta = [];
    let dataSets = [];
    let dadosTabelaAux = [];
    let auxDataSets = [];
    var dadosGrupoAux = [];

    const promise = await dados.map((item, i) => {
      var dadosGrupo = [];
      var maiorDataSet = 0;
      auxDataSets.map((item) => {
        maiorDataSet = maiorDataSet < item.length ? item.length : maiorDataSet;
      });

      for (let i = 0; i < maiorDataSet.length; i++) {
        dadosGrupo.push(0);
      }
      item[1].map((itemGrupo) => {
        var nomeLoja = converterLoja(itemGrupo.codLoja);
        if (i == 0) {
          label.push(nomeLoja);

          labelCompleta.push(nomeLoja);
          dadosGrupo.push(parseFloat(itemGrupo.total));
          dadosGrupoAux.push({ codLoja: itemGrupo.codLoja });
        } else {
          let jaExiste = label.indexOf(nomeLoja);
          if (jaExiste < 0) {
            label.push(nomeLoja);
          }
          jaExiste = label.indexOf(nomeLoja);
          if (jaExiste < 0) {
            labelCompleta.push(nomeLoja);
            dadosGrupo.push(parseFloat(itemGrupo.total));
            dadosGrupoAux.push({ codLoja: itemGrupo.codLoja });
          } else {
            dadosGrupo = insertAt(
              dadosGrupo,
              jaExiste,
              parseFloat(itemGrupo.total)
            );
            dadosGrupoAux = insertAt(dadosGrupoAux, jaExiste, {
              codLoja: itemGrupo.codLoja,
            });
          }
        }

        dadosTabelaAux.push(itemGrupo);
      });
      var cor =
        i == dados.length - 1
          ? "rgba(255, 194, 218, 1)"
          : i == dados.length - 2
          ? "rgba(91, 139, 252, 1)"
          : i == dados.length - 3
          ? "#5B8BFC"
          : i == dados.length - 4
          ? "#FFC2DA"
          : i == dados.length - 5
          ? ""
          : geraCorAleatoria();
      var datalabels;
      if (item[0] == auxAnoAtual) {
        datalabels = {
          anchor: "end",
          display: true,
          color: "#000",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        };
      } else if (item[0] == auxAnoAnterior) {
        datalabels = {
          anchor: "start",
          display: true,
          color: cor,
          align: "start",
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        };
      } else {
        datalabels = {
          formatter: function (value, context) {
            return "";
          },
        };
      }
      auxDataSets.push(dadosGrupo);
      dataSets.push({
        label: item[0],
        data: dadosGrupo,
        backgroundColor: cor,
        datalabels: datalabels,
      });
    });

    if (dadosPeriodoAnterior && dadosPeriodoAnterior?.length > 0) {
      let periodoAnteriorAux = [];
      if (filtrosLojasSelecionadas.length > 0) {
        await dadosPeriodoAnterior.map((item) => {
          var nomeLoja = converterLoja(item.CodLoja);
          if (filtrosLojasSelecionadas.indexOf(nomeLoja) >= 0) {
            periodoAnteriorAux.push(parseInt(item.VlrTotal));
          }
        });
        dataSets.push({
          label: "Periodo anterior",
          data: periodoAnteriorAux,
          backgroundColor: "#FFDB8A",
          datalabels: {
            anchor: "start",
            display: true,
            align: "start",
          },
        });
      } else {
        const dadosPeriodoAnteriorTemp = [];
        dadosGrupoAux.map((item) => {
          let dadosPeriodoAnteriorAux = dadosPeriodoAnterior.filter(
            (item2) => item2.CodLoja === item.codLoja
          );
          let dadosPeriodoAnteriorAuxSoma = dadosPeriodoAnteriorAux.reduce(
            (accum, item3) => (accum += Number(item3.VlrTotal)),
            0
          );
          dadosPeriodoAnteriorTemp.push(dadosPeriodoAnteriorAuxSoma);
        });

        dataSets.push({
          label: "Periodo anterior",
          data: dadosPeriodoAnteriorTemp,
          backgroundColor: "#FFDB8A",
          datalabels: {
            formatter: function (value, context) {
              return parseFloat(value).toLocaleString("pt-BR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
            },
          },
        });
      }
    }

    await Promise.all(promise);
    const data = {
      labels: label,
      datasets: dataSets,
    };
    dadosTabelaAux = agruparPor(dadosTabelaAux, "codLoja");
    dadosTabelaAux = Object.entries(dadosTabelaAux);

    preencherDadosTabelaGrupo(dadosTabelaAux, dadosPeriodoAnterior);

    setDadosGraficoAgrupado1(data);
    setFiltrosAtivosLojasTexto(" Grupo " + grupoAtivo);
    setNivelAtivoDoGrafico(1);
  }
  function geraCorAleatoria() {
    let hexadecimais = "0123456789ABCDEF";
    let cor = "#";

    for (let i = 0; i < 6; i++) {
      cor += hexadecimais[Math.floor(Math.random() * 16)];
    }
    return cor;
  }
  function preencherDadosTabela(
    dados,
    anoAtual,
    anoAnterior,
    tipo,
    dadosPeriodoAnterior
  ) {
    let dadosTabela = [],
      representitivadadeFinal = 0;

    let quantidadeLojasAnoAtual = 0,
      quantidadeLojasAnoAnterior = 0;

    let qtdAnoAtualResumo = 0,
      vlrAnoAtualResumo = 0,
      custoTotalAnoAtualResumo = 0,
      custoTotalAnoAnteriorResumo = 0,
      desempenhoPAResumo = 0,
      qtdAnoAnteriorResumo = 0,
      vlrTotalAnoAnteriorResumo = 0,
      lucroBrutoTotalAnoAtualResumo = 0,
      lucroBrutoTotalAnoAnteriorResumo = 0,
      lucroPorcentagemAnoAtualResumo = 0,
      lucroPorcentagemAnoAnteriorResumo = 0,
      variacaoResumo = 0,
      valorTotalAnoAtualParaRepresentatividade = 0,
      qtdPeriodoAnteriorResumo = 0,
      lucroPeriodoAnteriorResumo = 0,
      porcentagemLucroPeriodoAnteriorResumo = 0,
      vlrTotalPeriodoAnteriorResumo = 0,
      custoPeriodoAnteriorResumo = 0;

    dados.map((item) => {
      item[1].map((item2, index2) => {
        if (item2.ano == anoAtual) {
          valorTotalAnoAtualParaRepresentatividade += parseFloat(item2.total);
        }
      });
    });

    if (dados.length > 0) {
      dados.map((item) => {
        let grupo = item[0];
        let qtdAnoAtual = 0,
          qtdAnoAnterior = 0,
          vlrAnoAtual = 0,
          vlrTotalAnoAnterior = 0,
          custoTotalAnoAtual = 0,
          custoTotalAnoAnterior = 0,
          lucroBrutoTotalAnoAtual = 0,
          desempPeriodoAnterior = 0,
          lucroBrutoTotalAnoAnterior = 0,
          lucroPorcentagemAnoAtual = 0,
          lucroPorcentagemAnoAnterior = 0,
          representatividadeGrupoAnoAtual = 0,
          representatividadeGrupoAnoAnterior = 0,
          variacao;
        const periodoAnteriorItem = dadosPeriodoAnterior?.filter(
          (item) => item?.NomeGrupo1 === grupo
        );

        let vlrPeriodoAnterior = periodoAnteriorItem
          ? periodoAnteriorItem.reduce((acc, item) => {
              return acc + Number(item.VlrTotal);
            }, 0)
          : 0;
        let qtdPeriodoAnterior = periodoAnteriorItem
          ? periodoAnteriorItem.reduce((acc, item) => {
              return acc + Number(item.totalPedidos);
            }, 0)
          : 0;
        let custoPeriodoAnterior = periodoAnteriorItem
          ? periodoAnteriorItem.reduce((acc, item) => {
              return acc + Number(item.VlrCusto);
            }, 0)
          : 0;
        let lucroPeriodoAnterior = periodoAnteriorItem
          ? periodoAnteriorItem.reduce((acc, item) => {
              return acc + Number(item.VlrTotal - item.VlrCusto);
            }, 0)
          : 0;

        let porcentagemLucroPeriodoAnterior = parseFloat(
          parseFloat(
            Number(
              (vlrPeriodoAnterior - custoPeriodoAnterior) / vlrPeriodoAnterior
            ) * 100
          ).toFixed(2)
        );

        qtdPeriodoAnteriorResumo += qtdPeriodoAnterior;
        lucroPeriodoAnteriorResumo += lucroPeriodoAnterior;
        vlrTotalPeriodoAnteriorResumo += vlrPeriodoAnterior;
        custoPeriodoAnteriorResumo += custoPeriodoAnterior;

        var separaDoisUltimosAnos = [];
        if (item[1][0]) {
          separaDoisUltimosAnos.push(item[1][0]);
        }
        if (item[1][1]) {
          separaDoisUltimosAnos.push(item[1][1]);
        } else {
          let ano = (parseInt(item[1][0].ano) - 1).toString();
          let porcentagemLucro = 0;
          let total = 0;
          let totalCusto = 0;
          let totalLucro = 0;
          let totalPedidos = 0;
          separaDoisUltimosAnos.push({
            ano,
            total,
            totalPedidos,
            totalCusto,
            totalLucro,
            porcentagemLucro,
          });
        }
        separaDoisUltimosAnos.map((item2, index2) => {
          if (item2.ano == anoAtual) {
            qtdAnoAtual = parseInt(item2.totalPedidos);
            vlrAnoAtual = parseFloat(parseFloat(item2.total).toFixed(2));
            custoTotalAnoAtual = parseFloat(
              parseFloat(item2.totalCusto).toFixed(2)
            );
            lucroBrutoTotalAnoAtual = parseFloat(
              parseFloat(item2.total - item2.totalCusto).toFixed(2)
            );
            lucroPorcentagemAnoAtual = parseFloat(
              parseFloat(
                Number((item2.total - item2.totalCusto) / item2.total) * 100
              ).toFixed(2)
            );
            qtdAnoAtualResumo += qtdAnoAtual;
            vlrAnoAtualResumo += vlrAnoAtual;
            custoTotalAnoAtualResumo += custoTotalAnoAtual;
            lucroBrutoTotalAnoAtualResumo += lucroBrutoTotalAnoAtual;
            lucroPorcentagemAnoAtualResumo += lucroPorcentagemAnoAtual;
            if (qtdAnoAtual > 0) {
              quantidadeLojasAnoAtual++;
            }
          } else if (item2.ano == anoAnterior) {
            qtdAnoAnterior = parseInt(item2.totalPedidos);
            vlrTotalAnoAnterior = isNaN(item2.total)
              ? 0
              : parseFloat(parseFloat(item2.total).toFixed(2));
            custoTotalAnoAnterior = isNaN(item2.totalCusto)
              ? 0
              : parseFloat(parseFloat(item2.totalCusto).toFixed(2));
            lucroBrutoTotalAnoAnterior = isNaN(item2.totalLucro)
              ? 0
              : parseFloat(
                  parseFloat(item2.total - item2.totalCusto).toFixed(2)
                );
            lucroPorcentagemAnoAnterior = isNaN(item2.porcentagemLucro)
              ? 0
              : parseFloat(
                  parseFloat(
                    Number((item2.total - item2.totalCusto) / item2.total) * 100
                  ).toFixed(2)
                );

            qtdAnoAnteriorResumo += qtdAnoAnterior;
            vlrTotalAnoAnteriorResumo += vlrTotalAnoAnterior;
            custoTotalAnoAnteriorResumo += custoTotalAnoAnterior;
            lucroBrutoTotalAnoAnteriorResumo += lucroBrutoTotalAnoAnterior;
            lucroPorcentagemAnoAnteriorResumo += lucroPorcentagemAnoAnterior;
            if (qtdAnoAnterior > 0) {
              quantidadeLojasAnoAnterior++;
            }
          }
        });

        if (qtdAnoAnterior > 0) {
          variacao = parseFloat(
            ((vlrAnoAtual / vlrTotalAnoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          variacao = 0;
        }

        representatividadeGrupoAnoAtual =
          (vlrAnoAtual / valorTotalAnoAtualParaRepresentatividade) * 100;
        representitivadadeFinal += representatividadeGrupoAnoAtual;

        const ticketMedioAnoAtual = qtdAnoAtual ? vlrAnoAtual / qtdAnoAtual : 0;
        const ticketMedioAnoAnterior = qtdAnoAnterior
          ? vlrTotalAnoAnterior / qtdAnoAnterior
          : 0;
        const ticketMedioPeriodoAnterior = qtdPeriodoAnterior
          ? vlrPeriodoAnterior / qtdPeriodoAnterior
          : 0;

        if (qtdPeriodoAnterior > 0) {
          desempPeriodoAnterior = parseFloat(
            ((vlrAnoAtual / vlrPeriodoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          desempPeriodoAnterior = 0;
        }

        dadosTabela.push({
          grupo: grupo,
          qtdAnoAtual: qtdAnoAtual,
          qtdAnoAnterior: qtdAnoAnterior,
          vlrAnoAtual: vlrAnoAtual,
          vlrTotalAnoAnterior: vlrTotalAnoAnterior,
          custoTotalAtual: custoTotalAnoAtual,
          custoTotalAnterior: custoTotalAnoAnterior,
          lucroBrutoAtual: lucroBrutoTotalAnoAtual,
          lucroBrutoAnterior: lucroBrutoTotalAnoAnterior,
          lucro: lucroPorcentagemAnoAtual,
          lucroAnterior: lucroPorcentagemAnoAnterior,
          variacao: variacao,
          representitivadadeAtual: representatividadeGrupoAnoAtual,
          vlrPeriodoAnterior: vlrPeriodoAnterior,
          qtdPeriodoAnterior: qtdPeriodoAnterior,
          desempPeriodoAnterior: desempPeriodoAnterior,
          lucroPeriodoAnterior: lucroPeriodoAnterior,
          custoPeriodoAnterior: custoPeriodoAnterior,
          porcentagemLucroPeriodoAnterior: porcentagemLucroPeriodoAnterior,
          ticketMedioAnoAtual,
          ticketMedioAnoAnterior,
          ticketMedioPeriodoAnterior,
        });
      });
    }
    lucroPorcentagemAnoAtualResumo = parseFloat(
      (lucroPorcentagemAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );
    lucroPorcentagemAnoAnteriorResumo = parseFloat(
      (lucroPorcentagemAnoAnteriorResumo / quantidadeLojasAnoAnterior).toFixed(
        2
      )
    );
    variacaoResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalAnoAnteriorResumo - 1) * 100).toFixed(2)
    );

    porcentagemLucroPeriodoAnteriorResumo =
      (custoPeriodoAnteriorResumo / vlrTotalPeriodoAnteriorResumo) * 100;

    const ticketMedioAnoAtualResumo = qtdAnoAtualResumo
      ? vlrAnoAtualResumo / qtdAnoAtualResumo
      : 0;
    const ticketMedioAnoAnteriorResumo = qtdAnoAnteriorResumo
      ? vlrTotalAnoAnteriorResumo / qtdAnoAnteriorResumo
      : 0;
    const ticketMedioPeriodoAnteriorResumo = qtdPeriodoAnteriorResumo
      ? vlrTotalPeriodoAnteriorResumo / qtdPeriodoAnteriorResumo
      : 0;

    desempenhoPAResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalPeriodoAnteriorResumo - 1) * 100).toFixed(2)
    );

    lucroBrutoTotalAnoAtualResumo =
      vlrAnoAtualResumo - custoTotalAnoAtualResumo;
    lucroBrutoTotalAnoAnteriorResumo =
      vlrTotalAnoAnteriorResumo - custoTotalAnoAnteriorResumo;
    lucroPeriodoAnteriorResumo =
      vlrTotalPeriodoAnteriorResumo - custoPeriodoAnteriorResumo;

    lucroPorcentagemAnoAtualResumo = vlrAnoAtualResumo
      ? (lucroBrutoTotalAnoAtualResumo / vlrAnoAtualResumo) * 100
      : 0;
    lucroPorcentagemAnoAnteriorResumo = vlrTotalAnoAnteriorResumo
      ? (lucroBrutoTotalAnoAnteriorResumo / vlrTotalAnoAnteriorResumo) * 100
      : 0;
    porcentagemLucroPeriodoAnteriorResumo = vlrTotalPeriodoAnteriorResumo
      ? (lucroPeriodoAnteriorResumo / vlrTotalPeriodoAnteriorResumo) * 100
      : 0;
    dadosTabela.push({
      grupo: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      custoTotalAtual: custoTotalAnoAtualResumo,
      custoTotalAnterior: custoTotalAnoAnteriorResumo,
      lucroBrutoAtual: lucroBrutoTotalAnoAtualResumo,
      lucroBrutoAnterior: lucroBrutoTotalAnoAnteriorResumo,
      lucro: lucroPorcentagemAnoAtualResumo,
      lucroAnterior: lucroPorcentagemAnoAnteriorResumo,
      variacao: variacaoResumo,
      representitivadadeAtual: representitivadadeFinal,
      vlrPeriodoAnterior: vlrTotalPeriodoAnteriorResumo,
      qtdPeriodoAnterior: qtdPeriodoAnteriorResumo,
      lucroPeriodoAnterior: lucroPeriodoAnteriorResumo,
      custoPeriodoAnterior: custoPeriodoAnteriorResumo,
      porcentagemLucroPeriodoAnterior: porcentagemLucroPeriodoAnteriorResumo,
      ticketMedioAnoAtual: ticketMedioAnoAtualResumo,
      desempPeriodoAnterior: desempenhoPAResumo,
      ticketMedioAnoAnterior: ticketMedioAnoAnteriorResumo,
      ticketMedioPeriodoAnterior: ticketMedioPeriodoAnteriorResumo,
    });
    setDadosParaTabela(dadosTabela);

    setAuxDadosParaTabela(dadosTabela);
  }
  function preencherDadosTabelaGrupo(dados, dadosPeriodoAnterior) {
    let dadosTabela = [],
      representitivadadeFinal = 0;

    let quantidadeLojasAnoAtual = 0,
      quantidadeLojasAnoAnterior = 0;
    let qtdAnoAtualResumo = 0,
      vlrAnoAtualResumo = 0,
      desempenhoPAResumo = 0,
      custoTotalAnoAtualResumo = 0,
      custoTotalAnoAnteriorResumo = 0,
      qtdAnoAnteriorResumo = 0,
      vlrTotalAnoAnteriorResumo = 0,
      lucroBrutoTotalAnoAtualResumo = 0,
      lucroBrutoTotalAnoAnteriorResumo = 0,
      lucroPorcentagemAnoAtualResumo = 0,
      lucroPorcentagemAnoAnteriorResumo = 0,
      variacaoResumo = 0,
      qtdPeriodoAnteriorResumo = 0,
      lucroPeriodoAnteriorResumo = 0,
      porcentagemLucroPeriodoAnteriorResumo = 0,
      valorTotalAnoAtualParaRepresentatividade = 0,
      vlrTotalPeriodoAnteriorResumo = 0,
      custoPeriodoAnteriorResumo = 0;

    dados.forEach((item) => {
      item[1].forEach((item2) => {
        if (item2.ano == auxAnoAtual) {
          valorTotalAnoAtualParaRepresentatividade += parseFloat(item2.total);
        }
      });
    });

    if (dados.length > 0) {
      dados.forEach((item) => {
        let loja = item[0];
        let qtdAnoAtual = 0,
          qtdAnoAnterior = 0,
          vlrAnoAtual = 0,
          vlrTotalAnoAnterior = 0,
          desempPeriodoAnterior = 0,
          custoTotalAnoAtual = 0,
          custoTotalAnoAnterior = 0,
          lucroBrutoTotalAnoAtual = 0,
          lucroBrutoTotalAnoAnterior = 0,
          lucroPorcentagemAnoAtual = 0,
          lucroPorcentagemAnoAnterior = 0,
          representatividadeGrupoAnoAtual = 0,
          variacao = 0;

        const periodoAnteriorItem = dadosPeriodoAnterior?.filter(
          (item) => item?.CodLoja === loja
        );

        let vlrPeriodoAnterior = periodoAnteriorItem
          ? periodoAnteriorItem.reduce((acc, item) => {
              return acc + Number(item.VlrTotal);
            }, 0)
          : 0;
        let qtdPeriodoAnterior = periodoAnteriorItem
          ? periodoAnteriorItem.reduce((acc, item) => {
              return acc + Number(item.totalPedidos);
            }, 0)
          : 0;
        let custoPeriodoAnterior = periodoAnteriorItem
          ? periodoAnteriorItem.reduce((acc, item) => {
              return acc + Number(item.VlrCusto);
            }, 0)
          : 0;
        let lucroPeriodoAnterior = periodoAnteriorItem
          ? periodoAnteriorItem.reduce((acc, item) => {
              return acc + Number(item.VlrLucroBruto);
            }, 0)
          : 0;

        qtdPeriodoAnteriorResumo += qtdPeriodoAnterior;
        lucroPeriodoAnteriorResumo += lucroPeriodoAnterior;
        vlrTotalPeriodoAnteriorResumo += vlrPeriodoAnterior;
        custoPeriodoAnteriorResumo += custoPeriodoAnterior;

        var separaDoisUltimosAnos = [];
        if (item[1][0]) {
          separaDoisUltimosAnos.push(item[1][0]);
        }
        if (item[1][1]) {
          separaDoisUltimosAnos.push(item[1][1]);
        } else {
          let ano = (parseInt(item[1][0].ano) - 1).toString();
          let porcentagemLucro = 0;
          let total = 0;
          let totalCusto = 0;
          let totalLucro = 0;
          let totalPedidos = 0;
          separaDoisUltimosAnos.push({
            ano,
            total,
            totalPedidos,
            totalCusto,
            totalLucro,
            porcentagemLucro,
          });
        }
        separaDoisUltimosAnos.forEach((item2, index2) => {
          if (item2.ano == auxAnoAtual) {
            qtdAnoAtual = parseInt(item2.totalPedidos);
            vlrAnoAtual = parseFloat(parseFloat(item2.total).toFixed(2));
            custoTotalAnoAtual = parseFloat(
              parseFloat(item2.totalCusto).toFixed(2)
            );
            lucroBrutoTotalAnoAtual = parseFloat(
              parseFloat(item2.total - item2.totalCusto).toFixed(2)
            );
            lucroPorcentagemAnoAtual = parseFloat(
              parseFloat(
                Number((item2.total - item2.totalCusto) / item2.total) * 100
              ).toFixed(2)
            );

            qtdAnoAtualResumo += qtdAnoAtual;
            vlrAnoAtualResumo += vlrAnoAtual;
            custoTotalAnoAtualResumo += custoTotalAnoAtual;
            lucroBrutoTotalAnoAtualResumo += lucroBrutoTotalAnoAtual;
            lucroPorcentagemAnoAtualResumo += lucroPorcentagemAnoAtual;
            quantidadeLojasAnoAtual++;
          } else if (item2.ano == auxAnoAnterior) {
            qtdAnoAnterior = parseInt(item2.totalPedidos);
            vlrTotalAnoAnterior = isNaN(item2.total)
              ? 0
              : parseFloat(parseFloat(item2.total).toFixed(2));
            custoTotalAnoAnterior = isNaN(item2.totalCusto)
              ? 0
              : parseFloat(parseFloat(item2.totalCusto).toFixed(2));
            lucroBrutoTotalAnoAnterior = isNaN(item2.totalLucro)
              ? 0
              : parseFloat(parseFloat(item2.totalLucro).toFixed(2));
            lucroPorcentagemAnoAnterior = isNaN(item2.porcentagemLucro)
              ? 0
              : parseFloat(
                  parseFloat(
                    Number((item2.total - item2.totalCusto) / item2.total) * 100
                  ).toFixed(2)
                );

            qtdAnoAnteriorResumo += qtdAnoAnterior;
            vlrTotalAnoAnteriorResumo += vlrTotalAnoAnterior;
            custoTotalAnoAnteriorResumo += custoTotalAnoAnterior;
            lucroBrutoTotalAnoAnteriorResumo += lucroBrutoTotalAnoAnterior;
            lucroPorcentagemAnoAnteriorResumo += lucroPorcentagemAnoAnterior;
            quantidadeLojasAnoAnterior++;
          }
        });
        if (qtdAnoAnterior > 0) {
          variacao = parseFloat(
            ((vlrAnoAtual / vlrTotalAnoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          variacao = 0;
        }

        const ticketMedioAnoAtual = qtdAnoAtual ? vlrAnoAtual / qtdAnoAtual : 0;
        const ticketMedioAnoAnterior = qtdAnoAnterior
          ? vlrTotalAnoAnterior / qtdAnoAnterior
          : 0;
        const ticketMedioPeriodoAnterior = qtdPeriodoAnterior
          ? vlrPeriodoAnterior / qtdPeriodoAnterior
          : 0;

        if (qtdPeriodoAnterior > 0) {
          desempPeriodoAnterior = parseFloat(
            ((vlrAnoAtual / vlrPeriodoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          desempPeriodoAnterior = 0;
        }

        representatividadeGrupoAnoAtual =
          (vlrAnoAtual / valorTotalAnoAtualParaRepresentatividade) * 100;
        representitivadadeFinal += representatividadeGrupoAnoAtual;

        dadosTabela.push({
          loja: loja,
          qtdAnoAtual: qtdAnoAtual,
          qtdAnoAnterior: qtdAnoAnterior,
          vlrAnoAtual: vlrAnoAtual,
          vlrTotalAnoAnterior: vlrTotalAnoAnterior,
          custoTotalAtual: custoTotalAnoAtual,
          custoTotalAnterior: custoTotalAnoAnterior,
          lucroBrutoAtual: lucroBrutoTotalAnoAtual,
          lucroBrutoAnterior: lucroBrutoTotalAnoAnterior,
          lucro: lucroPorcentagemAnoAtual,
          representitivadadeAtual: representatividadeGrupoAnoAtual,
          lucroAnterior: lucroPorcentagemAnoAnterior,
          variacao: variacao,
          vlrPeriodoAnterior: vlrTotalPeriodoAnteriorResumo,
          qtdPeriodoAnterior: qtdPeriodoAnteriorResumo,
          lucroPeriodoAnterior: lucroPeriodoAnteriorResumo,
          custoPeriodoAnterior: custoPeriodoAnteriorResumo,
          porcentagemLucroPeriodoAnterior:
            porcentagemLucroPeriodoAnteriorResumo,
          desempPeriodoAnterior: desempPeriodoAnterior,
          ticketMedioAnoAtual,
          ticketMedioAnoAnterior,
          ticketMedioPeriodoAnterior,
        });
      });
    }
    lucroPorcentagemAnoAtualResumo = parseFloat(
      (lucroPorcentagemAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );
    lucroPorcentagemAnoAnteriorResumo = parseFloat(
      (lucroPorcentagemAnoAnteriorResumo / quantidadeLojasAnoAnterior).toFixed(
        2
      )
    );
    variacaoResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalAnoAnteriorResumo - 1) * 100).toFixed(2)
    );

    porcentagemLucroPeriodoAnteriorResumo =
      (custoPeriodoAnteriorResumo / vlrTotalPeriodoAnteriorResumo) * 100;

    const ticketMedioAnoAtualResumo = qtdAnoAtualResumo
      ? vlrAnoAtualResumo / qtdAnoAtualResumo
      : 0;
    const ticketMedioAnoAnteriorResumo = qtdAnoAnteriorResumo
      ? vlrTotalAnoAnteriorResumo / qtdAnoAnteriorResumo
      : 0;
    const ticketMedioPeriodoAnteriorResumo = qtdPeriodoAnteriorResumo
      ? vlrTotalPeriodoAnteriorResumo / qtdPeriodoAnteriorResumo
      : 0;

    desempenhoPAResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalPeriodoAnteriorResumo - 1) * 100).toFixed(2)
    );

    lucroBrutoTotalAnoAtualResumo =
      vlrAnoAtualResumo - custoTotalAnoAtualResumo;
    lucroBrutoTotalAnoAnteriorResumo =
      vlrTotalAnoAnteriorResumo - custoTotalAnoAnteriorResumo;
    lucroPeriodoAnteriorResumo =
      vlrTotalPeriodoAnteriorResumo - custoPeriodoAnteriorResumo;

    lucroPorcentagemAnoAtualResumo = vlrAnoAtualResumo
      ? (lucroBrutoTotalAnoAtualResumo / vlrAnoAtualResumo) * 100
      : 0;
    lucroPorcentagemAnoAnteriorResumo = vlrTotalAnoAnteriorResumo
      ? (lucroBrutoTotalAnoAnteriorResumo / vlrTotalAnoAnteriorResumo) * 100
      : 0;
    porcentagemLucroPeriodoAnteriorResumo = vlrTotalPeriodoAnteriorResumo
      ? (lucroPeriodoAnteriorResumo / vlrTotalPeriodoAnteriorResumo) * 100
      : 0;

    dadosTabela.push({
      loja: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      custoTotalAtual: custoTotalAnoAtualResumo,
      custoTotalAnterior: custoTotalAnoAnteriorResumo,
      lucroBrutoAtual: lucroBrutoTotalAnoAtualResumo,
      lucroBrutoAnterior: lucroBrutoTotalAnoAnteriorResumo,
      lucro: lucroPorcentagemAnoAtualResumo,
      lucroAnterior: lucroPorcentagemAnoAnteriorResumo,
      variacao: variacaoResumo,
      vlrPeriodoAnterior: vlrTotalPeriodoAnteriorResumo,
      qtdPeriodoAnterior: qtdPeriodoAnteriorResumo,
      lucroPeriodoAnterior: lucroPeriodoAnteriorResumo,
      custoPeriodoAnterior: custoPeriodoAnteriorResumo,
      porcentagemLucroPeriodoAnterior: porcentagemLucroPeriodoAnteriorResumo,
      desempPeriodoAnterior: desempenhoPAResumo,
      representitivadadeAtual: representitivadadeFinal,
      ticketMedioAnoAtual: ticketMedioAnoAtualResumo,
      ticketMedioAnoAnterior: ticketMedioAnoAnteriorResumo,
      ticketMedioPeriodoAnterior: ticketMedioPeriodoAnteriorResumo,
    });
    setDadosParaTabela(dadosTabela);
    setDadosParaTabelaLoja(dadosTabela);
  }

  function preencherDadosTabelaMes(dados) {
    let dadosTabela = [],
      representitivadadeFinal = 0;
    let quantidadeLojasAnoAtual = 0,
      quantidadeLojasAnoAnterior = 0;
    let qtdAnoAtualResumo = 0,
      vlrAnoAtualResumo = 0,
      custoTotalAnoAtualResumo = 0,
      custoTotalAnoAnteriorResumo = 0,
      qtdAnoAnteriorResumo = 0,
      vlrTotalAnoAnteriorResumo = 0,
      lucroBrutoTotalAnoAtualResumo = 0,
      lucroBrutoTotalAnoAnteriorResumo = 0,
      lucroPorcentagemAnoAtualResumo = 0,
      valorTotalAnoAtualParaRepresentatividade = 0,
      lucroPorcentagemAnoAnteriorResumo = 0,
      variacaoResumo = 0;
    dados.forEach((item) => {
      item[1].forEach((item2) => {
        if (item2.ano == auxAnoAtual) {
          valorTotalAnoAtualParaRepresentatividade += parseFloat(
            item2.VlrTotal
          );
        }
      });
    });

    if (dados.length > 0) {
      dados.forEach((item) => {
        let mes = parseInt(item[0]);
        let qtdAnoAtual = 0,
          qtdAnoAnterior = 0,
          vlrAnoAtual = 0,
          vlrTotalAnoAnterior = 0,
          custoTotalAnoAtual = 0,
          custoTotalAnoAnterior = 0,
          lucroBrutoTotalAnoAtual = 0,
          lucroBrutoTotalAnoAnterior = 0,
          lucroPorcentagemAnoAtual = 0,
          lucroPorcentagemAnoAnterior = 0,
          representatividadeGrupoAnoAtual = 0,
          variacao = 0;
        item[1].forEach((item2, index2) => {
          if (item2.ano == auxAnoAtual) {
            qtdAnoAtual = parseInt(item2.totalPedidos);
            vlrAnoAtual = isNaN(item2.VlrTotal)
              ? 0
              : parseFloat(parseFloat(item2.VlrTotal).toFixed(2));
            custoTotalAnoAtual = isNaN(item2.VlrCusto)
              ? 0
              : parseFloat(parseFloat(item2.VlrCusto).toFixed(2));
            lucroBrutoTotalAnoAtual = isNaN(item2.VlrLucroBruto)
              ? 0
              : parseFloat(
                  parseFloat(item2.total - item2.totalCusto).toFixed(2)
                );
            lucroPorcentagemAnoAtual = isNaN(item2.VlrLucroBrutoPorcentagem)
              ? 0
              : parseFloat(
                  parseFloat(
                    Number((item2.total - item2.totalCusto) / item2.total) * 100
                  ).toFixed(2)
                );
            qtdAnoAtualResumo += qtdAnoAtual;
            vlrAnoAtualResumo += vlrAnoAtual;
            custoTotalAnoAtualResumo += custoTotalAnoAtual;
            lucroBrutoTotalAnoAtualResumo += lucroBrutoTotalAnoAtual;
            lucroPorcentagemAnoAtualResumo += lucroPorcentagemAnoAtual;
            if (qtdAnoAtual > 0) {
              quantidadeLojasAnoAtual++;
            }
          } else if (item2.ano == auxAnoAnterior) {
            qtdAnoAnterior = isNaN(item2.totalPedidos)
              ? 0
              : parseInt(item2.totalPedidos);
            vlrTotalAnoAnterior = isNaN(item2.VlrTotal)
              ? 0
              : parseFloat(parseFloat(item2.VlrTotal).toFixed(2));
            custoTotalAnoAnterior = isNaN(item2.VlrCusto)
              ? 0
              : parseFloat(parseFloat(item2.VlrCusto).toFixed(2));
            lucroBrutoTotalAnoAnterior = isNaN(item2.VlrLucroBruto)
              ? 0
              : parseFloat(parseFloat(item2.VlrLucroBruto).toFixed(2));
            lucroPorcentagemAnoAnterior = isNaN(item2.VlrLucroBrutoPorcentagem)
              ? 0
              : parseFloat(
                  parseFloat(
                    Number((item2.total - item2.totalCusto) / item2.total) * 100
                  ).toFixed(2)
                );
            qtdAnoAnteriorResumo += qtdAnoAnterior;
            vlrTotalAnoAnteriorResumo += vlrTotalAnoAnterior;
            custoTotalAnoAnteriorResumo += custoTotalAnoAnterior;
            lucroBrutoTotalAnoAnteriorResumo += lucroBrutoTotalAnoAnterior;
            lucroPorcentagemAnoAnteriorResumo += lucroPorcentagemAnoAnterior;
            if (qtdAnoAnterior > 0) {
              quantidadeLojasAnoAnterior++;
            }
          }
        });
        if (vlrTotalAnoAnterior > 0) {
          variacao = parseFloat(
            ((vlrAnoAtual / vlrTotalAnoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          variacao = 0;
        }

        representatividadeGrupoAnoAtual =
          (vlrAnoAtual / valorTotalAnoAtualParaRepresentatividade) * 100;
        representitivadadeFinal += representatividadeGrupoAnoAtual;

        const ticketMedioAnoAtual = qtdAnoAtual ? vlrAnoAtual / qtdAnoAtual : 0;
        const ticketMedioAnoAnterior = qtdAnoAnterior
          ? vlrTotalAnoAnterior / qtdAnoAnterior
          : 0;

        dadosTabela.push({
          mes: mes,
          qtdAnoAtual: qtdAnoAtual,
          qtdAnoAnterior: qtdAnoAnterior,
          vlrAnoAtual: vlrAnoAtual,
          vlrTotalAnoAnterior: vlrTotalAnoAnterior,
          custoTotalAtual: custoTotalAnoAtual,
          representitivadadeAtual: representatividadeGrupoAnoAtual,
          custoTotalAnterior: custoTotalAnoAnterior,
          lucroBrutoAtual: lucroBrutoTotalAnoAtual,
          lucroBrutoAnterior: lucroBrutoTotalAnoAnterior,
          lucro: lucroPorcentagemAnoAtual,
          lucroAnterior: lucroPorcentagemAnoAnterior,
          variacao: variacao,
          ticketMedioAnoAtual,
          ticketMedioAnoAnterior,
        });
      });
    }
    lucroPorcentagemAnoAtualResumo = parseFloat(
      (lucroPorcentagemAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );
    lucroPorcentagemAnoAnteriorResumo = parseFloat(
      (lucroPorcentagemAnoAnteriorResumo / quantidadeLojasAnoAnterior).toFixed(
        2
      )
    );
    variacaoResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalAnoAnteriorResumo - 1) * 100).toFixed(2)
    );

    const ticketMedioAnoAtualResumo = qtdAnoAtualResumo
      ? vlrAnoAtualResumo / qtdAnoAtualResumo
      : 0;
    const ticketMedioAnoAnteriorResumo = qtdAnoAnteriorResumo
      ? vlrTotalAnoAnteriorResumo / qtdAnoAnteriorResumo
      : 0;

    lucroBrutoTotalAnoAtualResumo =
      vlrAnoAtualResumo - custoTotalAnoAtualResumo;
    lucroBrutoTotalAnoAnteriorResumo =
      vlrTotalAnoAnteriorResumo - custoTotalAnoAnteriorResumo;

    lucroPorcentagemAnoAtualResumo = vlrAnoAtualResumo
      ? (lucroBrutoTotalAnoAtualResumo / vlrAnoAtualResumo) * 100
      : 0;
    lucroPorcentagemAnoAnteriorResumo = vlrTotalAnoAnteriorResumo
      ? (lucroBrutoTotalAnoAnteriorResumo / vlrTotalAnoAnteriorResumo) * 100
      : 0;

    dadosTabela.push({
      mes: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      custoTotalAtual: custoTotalAnoAtualResumo,
      custoTotalAnterior: custoTotalAnoAnteriorResumo,
      lucroBrutoAtual: lucroBrutoTotalAnoAtualResumo,
      lucroBrutoAnterior: lucroBrutoTotalAnoAnteriorResumo,
      lucro: lucroPorcentagemAnoAtualResumo,
      lucroAnterior: lucroPorcentagemAnoAnteriorResumo,
      representitivadadeAtual: representitivadadeFinal,
      variacao: variacaoResumo,
      ticketMedioAnoAtual: ticketMedioAnoAtualResumo,
      ticketMedioAnoAnterior: ticketMedioAnoAnteriorResumo,
    });

    setDadosParaTabela(dadosTabela);
  }

  async function preencherDadosGraficoAgrupadoGrupoMes(loja) {
    let codLoja = converterNomeLoja(loja);
    let dados = dadosGeraisGrupo;
    dados = dados.filter((a) => {
      return a.CodLoja == codLoja ? true : false;
    });
    let grupoDeAnos = agruparPor(dados, "ano");
    let grupoDeAnosArray = Object.entries(grupoDeAnos);
    dados = grupoDeAnosArray;
    dados = dados.reverse();
    let label = [];
    let labelCompleta = [];
    let dataSets = [];
    let dadosTabelaAux = [];
    let auxDataSets = [];
    const promise = await dados.map((item, i) => {
      let dadosGrupo = [];
      let maiorDataSet = 0;
      auxDataSets.map((item) => {
        maiorDataSet = maiorDataSet < item.length ? item.length : maiorDataSet;
      });

      for (let i = 0; i < maiorDataSet.length; i++) {
        dadosGrupo.push(0);
      }
      item[1] = item[1].sort((a, b) => {
        return a.mes - b.mes;
      });

      item[1].map((itemGrupo) => {
        let nomeMes = converterMes(itemGrupo.mes);
        if (filtrosMesesSelecionados.length > 0) {
          if (filtrosMesesSelecionados.indexOf(nomeMes) >= 0) {
            if (i == 0) {
              label.push(nomeMes);
              labelCompleta.push(nomeMes);
              dadosGrupo.push(parseFloat(itemGrupo.VlrTotal));
            } else {
              let jaExiste = label.indexOf(nomeMes);
              if (jaExiste < 0) {
                label.push(nomeMes);
                labelCompleta.push(nomeMes);
              }
              jaExiste = label.indexOf(nomeMes);
              if (jaExiste < 0) {
                dadosGrupo.push(parseFloat(itemGrupo.VlrTotal));
              } else {
                dadosGrupo = insertAt(
                  dadosGrupo,
                  jaExiste,
                  parseFloat(itemGrupo.VlrTotal)
                );
              }
            }
            dadosTabelaAux.push(itemGrupo);
          } else {
            let jaExiste = labelCompleta.indexOf(nomeMes);
            if (jaExiste < 0) {
              labelCompleta.push(nomeMes);
            }
          }
        } else {
          if (i == 0) {
            label.push(nomeMes);
            labelCompleta.push(nomeMes);
            dadosGrupo.push(parseFloat(itemGrupo.VlrTotal));
          } else {
            let jaExiste = label.indexOf(nomeMes);
            if (jaExiste < 0) {
              label.push(nomeMes);
              labelCompleta.push(nomeMes);
            }
            jaExiste = label.indexOf(nomeMes);
            if (jaExiste < 0) {
              dadosGrupo.push(parseFloat(itemGrupo.VlrTotal));
            } else {
              dadosGrupo = insertAt(
                dadosGrupo,
                jaExiste,
                parseFloat(itemGrupo.VlrTotal)
              );
            }
          }
          dadosTabelaAux.push(itemGrupo);
        }
      });

      let cor =
        i == dados.length - 1
          ? "rgba(255, 194, 218, 1)"
          : i == dados.length - 2
          ? "rgba(91, 139, 252, 1)"
          : i == dados.length - 3
          ? "#5B8BFC"
          : i == dados.length - 4
          ? "#FFC2DA"
          : i == dados.length - 5
          ? ""
          : geraCorAleatoria();
      let datalabels;
      if (item[0] == auxAnoAtual) {
        datalabels = {
          anchor: "end",
          display: true,
          color: "#000",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        };
      } else if (item[0] == auxAnoAnterior) {
        datalabels = {
          anchor: "start",
          display: true,
          color: cor,
          align: "start",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        };
      } else {
        datalabels = {
          formatter: function (value, context) {
            return "";
          },
        };
      }
      dataSets.push({
        label: item[0],
        data: dadosGrupo,
        backgroundColor: cor,
        datalabels: datalabels,
      });
    });
    await Promise.all(promise);
    const data = {
      labels: label,
      datasets: dataSets,
    };
    dadosTabelaAux = agruparPor(dadosTabelaAux, "mes");
    dadosTabelaAux = Object.entries(dadosTabelaAux);
    preencherDadosTabelaMes(dadosTabelaAux);
    setDadosGraficoAgrupado2(data);
    setFiltrosAtivosLojasTexto(
      filtrosAtivosLojasTexto.split("- Loja")[0] + " - Loja " + loja
    );

    setNivelAtivoDoGrafico(2);
  }

  function preencherDadosVendasPorLoja() {
    preencherDadosVendasPorLojaGrupo();
    const dadosUltimoPeriodoTemp = [...dadosUltimoPeriodo];
    let dataVendas = dadosGerais;
    let auxLojasSelecionadas = [];

    dataVendas.map((a) => {
      if (auxLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) == -1) {
        auxLojasSelecionadas.push(converterLoja(a.CodLoja));
      }
    });
    if (filtrosLojasSelecionadas.length > 0) {
      dataVendas = dataVendas.filter((a) => {
        if (filtrosLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) >= 0) {
          return true;
        } else {
          return false;
        }
      });
    }
    let grupoDeAnos = agruparPor(dataVendas, "ano");
    let grupoDeAnosArray = Object.entries(grupoDeAnos);
    let separadoPorGrupo = [];
    grupoDeAnosArray.map((item) => {
      let grupoDeLoja = agruparPor(item[1], "CodGrp1");
      separadoPorGrupo.push(grupoDeLoja);
    });
    let resumoSeparadoPorGrupo = [];
    separadoPorGrupo.map((item) => {
      let item2 = Object.entries(item);
      item2.map((item3) => {
        let total = 0;
        let totalPedidos = 0;
        let ano;
        let codGrupo;
        let nomeGrupo;
        let totalCusto = 0;
        let totalLucro = 0;
        let porcentagemLucro = 0;
        item3[1].map((item4, index) => {
          if (index == 0) {
            ano = item4.ano;
            nomeGrupo = item4.NomeGrupo1;
            codGrupo = item4.CodGrp1;
          }
          totalPedidos += parseInt(item4.totalPedidos);
          total += parseFloat(item4.VlrTotal);
          totalCusto += parseFloat(item4.VlrCusto);
          totalLucro += parseFloat(item4.VlrLucroBruto);
          porcentagemLucro += parseFloat(item4.VlrLucroBrutoPorcentagem);
        });
        porcentagemLucro = porcentagemLucro / item3[1].length;
        let resumoLojaAno = {
          ano: ano,
          codGrupo,
          nomeGrupo,
          total: total,
          totalPedidos: totalPedidos,
          totalCusto,
          totalLucro,
          porcentagemLucro,
        };
        resumoSeparadoPorGrupo.push(resumoLojaAno);
      });
    });
    var agrupado = agruparPor(resumoSeparadoPorGrupo, "ano");
    grupoDeAnosArray = Object.entries(agrupado);
    preencherDadosGraficoAgrupado(
      grupoDeAnosArray,
      tipoPeriodo,
      auxAnoAtual,
      auxAnoAnterior,
      dadosUltimoPeriodoTemp
    );
  }

  function preencherDadosVendasPorLojaGrupo() {
    let dataVendas = dadosGeraisGrupo;
    let auxLojasSelecionadas = [];
    if (!dataVendas || dataVendas.length == 0) return;
    dataVendas.map((a) => {
      if (auxLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) == -1) {
        auxLojasSelecionadas.push(converterLoja(a.CodLoja));
      }
    });
    if (filtrosLojasSelecionadas.length > 0) {
      dataVendas = dataVendas.filter((a) => {
        if (filtrosLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) >= 0) {
          return true;
        } else {
          return false;
        }
      });
    }
    let grupoDeAnos = agruparPor(dataVendas, "ano");
    let grupoDeAnosArray = Object.entries(grupoDeAnos);
    let separadoPorLoja = [];
    grupoDeAnosArray.map((item) => {
      let grupoDeLoja = agruparPor(item[1], "CodLoja");
      separadoPorLoja.push(grupoDeLoja);
    });
    let resumoSeparadoPorLoja = [];
    separadoPorLoja.map((item) => {
      let item2 = Object.entries(item);
      item2.map((item3) => {
        let total = 0;
        let totalPedidos = 0;
        let ano;
        let codGrupo;
        let nomeGrupo;
        let totalCusto = 0;
        let totalLucro = 0;
        let porcentagemLucro = 0;
        let codLoja = 0;
        item3[1].map((item4, index) => {
          if (index == 0) {
            ano = item4.ano;
            nomeGrupo = item4.NomeGrupo1;
            codGrupo = item4.CodGrp1;
          }
          totalPedidos += parseInt(item4.totalPedidos);
          total += parseFloat(item4.VlrTotal);
          totalCusto += parseFloat(item4.VlrCusto);
          totalLucro += parseFloat(item4.VlrLucroBruto);
          porcentagemLucro += parseFloat(item4.VlrLucroBrutoPorcentagem);
          codLoja = item4.CodLoja;
        });
        porcentagemLucro = porcentagemLucro / item3[1].length;
        let resumoLojaAno = {
          ano: ano,
          codGrupo,
          nomeGrupo,
          total: total,
          totalPedidos: totalPedidos,
          totalCusto,
          totalLucro,
          porcentagemLucro,
          codLoja,
        };
        resumoSeparadoPorLoja.push(resumoLojaAno);
      });
    });
    var agrupado = agruparPor(resumoSeparadoPorLoja, "ano");
    grupoDeAnosArray = Object.entries(agrupado);
    preencherDadosGraficoAgrupadoGrupo(
      grupoDeAnosArray,
      tipoPeriodo,
      auxAnoAnterior,
      auxAnoAtual
    );
  }

  function iniciarFiltroPorPeriodo() {
    setTipoPeriodo("input");
    setAuxTipoPeriodo("input");
    setPeriodoAno(false);
    setFiltrosAnosSelecionados([]);
    setPeriodoInputInicio(periodoInputInicioAux);
    setPeriodoInputFim(periodoInputFimAux);
  }

  const fVoltar = () => {
    if (nivelAtivoDoGrafico === 1) {
      setGrupoAtivo("");
      setFiltrosAtivosLojasTexto("");
    }
    console.log({ nivelAtivoDoGrafico });

    if (nivelAtivoDoGrafico === 2) {
      let novoNivel = nivelAtivoDoGrafico - 1;
      setNivelAtivoDoGrafico(novoNivel);

      setDadosParaTabela(dadosParaTabelaLoja);
      let filtrosAtivos = filtrosAtivosLojasTexto.split("- Loja")[0];
      setFiltrosAtivosLojasTexto(filtrosAtivos);
      setLojaTable(true);
      setTipoPeriodo("input");
    } else if (nivelAtivoDoGrafico === 1) {
      let novoNivel = nivelAtivoDoGrafico - 1;
      setNivelAtivoDoGrafico(novoNivel);

      setLojaTable(false);
      setDadosParaTabela(auxDadosParaTabela);
    }
  };

  function filtrarPorLoja(loja) {
    const lojasSelecionadas = loja.map((item) => item.label);
    LoadingTemp();
    setAuxTipoPeriodo("loja");

    setFiltradoPorLoja(true);
    setFiltrosLojasSelecionadas(lojasSelecionadas);
    var lojasDisponiveisAux = [];
    lojasDisponiveis.map((item) => {
      lojasDisponiveisAux.push(item);
    });
    if (lojasSelecionadas.length === 0) {
      preencherInputPeriodo();
    } else {
      setAnosDisponiveis(fAnosPorLoja(lojasSelecionadas));
      setLojasDisponiveis(lojasDisponiveisAux);
    }
    setLojaTable(false);
    setTipoPeriodo("input");
  }
  const optionsChartBarVendas = {
    title: {
      display: true,
      padding: 20,
      text: "Vendas por Grupo",
    },
    legend: {
      position: "bottom",
      // paddingBottom: 50,
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 25,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
      },
    },
  };

  const optionsChartBarVendasY = {
    title: {
      display: true,
      padding: 20,
      text: "Vendas por Grupo",
      fontColor: "#FC68A2",
      fontSize: 20,
    },
    legend: {
      position: "bottom",
      // paddingBottom: 50,
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 25,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
      },
    },
  };

  function iniciarFiltroHoje() {
    var dataHoje = new Date();
    var [, hoje] = converterDataFormatoPadrao(dataHoje);

    setPeriodoInputInicio(hoje);
    setPeriodoInputFim(hoje);

    setPeriodoInputInicioAux(hoje);
    setPeriodoInputFimAux(hoje);
  }

  const getElementAtEventY = (element) => {
    console.log(element);
    if (!element.length) return;
    const { _index: index, _yScale: eixoY } = element[0];
    if (nivelAtivoDoGrafico == 0) {
      var nomeGrupo = eixoY.ticks[index];
      setGrupoAtivo(nomeGrupo);
      setLojaTable(true);
    } else if (nivelAtivoDoGrafico == 1) {
      var nomeLoja = eixoY.ticks[index];
      preencherDadosGraficoAgrupadoGrupoMes(nomeLoja);
      setLojaTable(false);
      setTipoPeriodo("mes");
    }
  };

  const filtrarBases = (value) => {
    const basesSelecionadasTemp = value;
    if (basesSelecionadasTemp.length === 0) {
      setBasesSelecionadas(basesDisponiveis?.map((item) => item.codBase));
      setBase(basesDisponiveis?.map((item) => item.codBase));
    } else {
      setBasesSelecionadas(basesSelecionadasTemp);
      setBase(basesSelecionadasTemp);
    }
  };
  console.log({ dadosParaTabela });
  return (
    <>
      {PermissionsPage("vendGrupo") && (
        <>
          <Box ml={2}>
            <Title title="Vendas Por Grupo" />
          </Box>

          <Stack gap={2} direction={"row"} mx={2}>
            <Box component={Paper} variant="outlined" px={1} pb={1}>
              <FiltroPeriodo
                periodoInputInicioAux={periodoInputInicioAux}
                periodoInputFimAux={periodoInputFimAux}
                setPeriodoInputInicioAux={setPeriodoInputInicioAux}
                setPeriodoInputFimAux={setPeriodoInputFimAux}
              />
              <Stack gap={1}>
                <ButtonFilter
                  type="button"
                  onClick={() => iniciarFiltroPorPeriodo()}
                  disabled={isLoading}>
                  Filtrar
                </ButtonFilter>
                <ButtonToday
                  type="button"
                  onClick={() => iniciarFiltroHoje()}
                  disabled={isLoading}>
                  Hoje
                </ButtonToday>
              </Stack>
            </Box>

            <Box width={"100%"}>
              <Box pb={1}>
                <MultiSelectFilterBases
                  basesDisponiveis={basesDisponiveis}
                  basesSelecionadas={basesSelecionadas}
                  onChange={filtrarBases}
                  disabled={isLoading}
                />
                <Typography variant="caption" component={"span"}>
                  *Atenção: Quando nenhuma base estiver selecionada, todas as
                  bases serão retornadas.*
                </Typography>
              </Box>
              <Box>
                <Typography
                  textAlign={"initial"}
                  fontWeight={"bold"}
                  component={"span"}>
                  Lojas
                </Typography>
                <Select
                  options={lojasDisponiveis.map((item) => ({
                    label: item,
                    value: item.split(" ")[1],
                  }))}
                  isMulti
                  onChange={filtrarPorLoja}
                  placeholder="Selecione uma loja"
                  styles={colourStyles}
                  isDisabled={isLoading}
                />
                <Typography variant="caption" component={"span"}>
                  *Atenção: Quando nenhuma loja estiver selecionada, todas as
                  lojas serão retornadas.*
                </Typography>
              </Box>
            </Box>
          </Stack>

          {isLoading ? (
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"90vh"}>
              <LoadingTable isLoading={isLoading} />
            </Box>
          ) : (
            <>
              <>
                {dadosDisponiveis && (
                  <Box mx={2} component={Paper} variant="outlined" p={2} m={2}>
                    {nivelAtivoDoGrafico === 0 && (
                      <>
                        <HorizontalBar
                          data={dadosGraficoAgrupado}
                          height={500}
                          options={optionsChartBarVendasY}
                          getElementAtEvent={getElementAtEventY}
                        />
                      </>
                    )}
                    {nivelAtivoDoGrafico === 1 && (
                      <HorizontalBar
                        data={dadosGraficoAgrupado1}
                        height={500}
                        options={optionsChartBarVendas}
                        getElementAtEvent={getElementAtEventY}
                      />
                    )}
                    {nivelAtivoDoGrafico === 2 && (
                      <HorizontalBar
                        data={dadosGraficoAgrupado2}
                        options={optionsChartBarVendas}
                        getElementAtEvent={getElementAtEventY}
                      />
                    )}
                    <ButtonVoltar fVoltar={fVoltar} />
                  </Box>
                )}
              </>

              {dadosDisponiveis && exibeTabela && (
                <Box mx={2}>
                  <TituloTabela>VENDAS POR GRUPO</TituloTabela>

                  <TabelaNext
                    mx={2}
                    dadosParaTabela={dadosParaTabela}
                    colunas={vendasGrupoTabelaPorGrupo(
                      tipoPeriodo,
                      anosDisponiveis,
                      filtrosAnosSelecionados,
                      lojaTable,
                      filtrosLojasSelecionadas,
                      nivelAtivoDoGrafico
                    )}
                  />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default VendasPorGrupo;
