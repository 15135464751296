import { PermissionsPage } from "../../util/permissionsPage";
import Title from "../../components/title";
import {
  Avatar,
  Box,
  Button,
  Container as Cont,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { formatCPF, stringAvatar } from "./utils/formatted";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { useHistory, useParams } from "react-router-dom";

import dayjs, { utc } from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { api } from "../../services/api.v2";
import { TextInfo } from "./components/TextInfo";
import { StyledTableCell } from "./components/StyledTableCell";
import DenseMenu from "./components/DenseMenu";
import { FiMoreVertical } from "react-icons/fi";
import { toast } from "react-hot-toast";
import { useUserData } from "../../hooks/useUserData";
import { ButtonCopyNumber } from "../../components/ButtonCopyNumber/ButtonCopyNumber";
import { LoadingViewInfluencer } from "./components/LoadingViewInfluencer";
import { isEmptyObject } from "../../util/isEmptyObject";
dayjs.extend(advancedFormat);
dayjs.extend(utc);
export const ViewInfluencer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useUserData();
  const { slug } = useParams();
  const history = useHistory();
  const [data, setData] = useState({ user: [] });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const goBackList = () => history.push(`/franquia/lista-interresados`);

  const getInfoInfluencer = async () => {
    setIsLoading(true);
    const response = await api.get(`/franchise/find-info-influencer/${slug}`);
    setIsLoading(false);

    if (response.status === 200) {
      const objData = {
        ...response.data,
        cpf: formatCPF(response.data.cpf),
        date_of_birth: dayjs(response.data.date_of_birth)
          .utc()
          .format("DD/MM/YYYY"),
        created_at: dayjs(response.data.created_at)
          .utc()
          .format("D [de] MMMM, [de] YYYY [às] HH:mm"),
        profile_indicate: response.data.profile_indicate.name,
      };
      delete objData.slug;
      delete objData.uf_id;
      delete objData.city_id;
      setData(objData);
    } else {
      toast.error("Erro ao buscar influencer");
      goBackList();
    }
  };

  useEffect(() => {
    if (!isEmptyObject(userData)) {
      getInfoInfluencer();
    }
  }, [userData]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = async () => {
    try {
      await api.delete(`/franchise/delete-influencer/${slug}`);

      toast.success("Influencer deletado com sucesso");
      goBackList();
    } catch (error) {
      toast.error(error.message || "Ocorreu um erro ao deletar");
    }
  };

  useEffect(() => {}, [userData]);
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const visibleRows = useMemo(
    () => data.user.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, data]
  );

  return (
    <>
      {PermissionsPage("list_interested") && (
        <>
          <Title title="INFORMAÇÕES DO INTERESSADO" />

          <Cont
            sx={{
              paddingBottom: 3,
            }}
          >
            {isLoading ? (
              <LoadingViewInfluencer />
            ) : (
              <>
                <Paper elevation={3} variant="outlined">
                  <Stack
                    gap={4}
                    paddingX={4}
                    paddingY={2}
                    flexDirection={"row"}
                    position={"relative"}
                  >
                    <Avatar {...stringAvatar(data.name)} />
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h4" gutterBottom>
                        {data.name}
                      </Typography>
                      <Box display={"grid"} gridTemplateColumns={"1fr 1fr"}>
                        <TextInfo label={"CPF:"}> {data.cpf}</TextInfo>
                        <TextInfo label={"Data de nascimento:"}>
                          {data.date_of_birth}
                        </TextInfo>
                        <TextInfo label={"Email:"}> {data.email}</TextInfo>
                        <TextInfo label={"Perfil:"}>
                          {data.profile_indicate}
                        </TextInfo>
                        <TextInfo label={"Cidade:"}>{data.city}</TextInfo>
                        <TextInfo label={"Estado:"}>{data.uf}</TextInfo>
                      </Box>
                      <TextInfo label={" Data de cadastro:"}>
                        {data.created_at}
                      </TextInfo>
                    </Box>
                    <IconButton
                      sx={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                      }}
                      aria-controls={openMenu ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <FiMoreVertical />
                    </IconButton>
                    <DenseMenu
                      anchorEl={anchorEl}
                      open={openMenu}
                      id={data.id}
                      onClose={handleClose}
                      onDelete={handleDelete}
                    />
                  </Stack>
                </Paper>
                <Box
                  my={4}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  width={"100%"}
                >
                  <Button variant="outlined" onClick={goBackList} type="button">
                    Voltar
                  </Button>
                </Box>
                {data?.user?.length <= 0 ? (
                  <>
                    <Box py={2} mt={4}>
                      <Typography
                        textAlign={"center"}
                        variant="subtitle1"
                        gutterBottom
                      >
                        Nenhum interresado cadastrado
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <TableContainer
                    component={Paper}
                    sx={{
                      mt: 4,
                    }}
                  >
                    <Table size={"small"} aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Nome</StyledTableCell>
                          <StyledTableCell>Telefone</StyledTableCell>
                          <StyledTableCell>Hora p/ contato</StyledTableCell>
                          <StyledTableCell>Cidade</StyledTableCell>
                          <StyledTableCell>Estado</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {visibleRows.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell>
                              <ButtonCopyNumber phone={row.phone} />
                            </TableCell>
                            <TableCell>
                              {`${row.contact_period}`}{" "}
                              {!!row.start_contact_time &&
                                `- ${row.start_contact_time}:00 as ${row.end_contact_time}:00`}
                            </TableCell>
                            <TableCell>{row.city}</TableCell>
                            <TableCell>{row.uf}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        100,
                        { label: "Todos", value: data?.user.length },
                      ]}
                      labelRowsPerPage={"Linhas por pagina"}
                      component="div"
                      count={data?.user.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                    />
                  </TableContainer>
                )}
              </>
            )}
          </Cont>
        </>
      )}
    </>
  );
};
