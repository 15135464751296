import { useEffect, useState } from "react";
import boletim from "../../../services/boletim";
import toast from "react-hot-toast";

import { columnsTableGroupsInventory } from "../../../column/boletim/GroupsInventory";
import { DataTableBoletim } from "../DataTableBoletim/DataTableBoletim";

export const GroupsInventory = ({ paramsConfig, basesDisponiveis }) => {
  const [dataPageGroupsInventory, setDataPageGroupsInventory] = useState([]);
  const [isLoadingGroupsInventory, setIsLoadingGroupsInventory] =
    useState(false);
  async function getGroupsInventory() {
    try {
      setIsLoadingGroupsInventory(true);
      const response = await boletim.getGroupsInventory(paramsConfig.base, {
        periodo: paramsConfig.periodo,
      });

      const data = response.data;
      const groupsInventoryExpand = data.filter((item) => !item?.isTotal);
      const groupsInventory = [data.find((item) => !!item?.isTotal)];
      groupsInventory[0].expand = groupsInventoryExpand;
      setDataPageGroupsInventory(groupsInventory);
    } catch (error) {
      console.log(error);
      toast.error(error);
    } finally {
      setIsLoadingGroupsInventory(false);
    }
  }
  useEffect(() => {
    if (!paramsConfig.base) return;
    getGroupsInventory();
  }, [basesDisponiveis, paramsConfig]);

  return (
    <DataTableBoletim
      columns={columnsTableGroupsInventory}
      data={dataPageGroupsInventory}
      label="Estoque por grupo"
      isLoading={isLoadingGroupsInventory}
    />
  );
};
