import axios from "axios";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  chekcedCep: async (cep) => {
    return await axios.get("https://viacep.com.br/ws/" + cep + "/json", {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getEstados: async () => {
    return await axios.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados");
  },
};
