import { Delete, Edit } from "@mui/icons-material";
import {
  ClickAwayListener,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";

export const OptionsMenu = ({ onClose, onDelete, onEdit, ...props }) => {
  const handleDelete = () => {
    onDelete();
    onClose();
  };
  const handleEdit = () => {
    onEdit();
  };

  
  return (
    <Popper role={undefined} placement="bottom-start" transition {...props}>
      {({ TransitionProps, placement }) => {
        return (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClose}>
                <MenuList
                  autoFocusItem={props.open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  <MenuItem onClick={handleEdit}>
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    Editar
                  </MenuItem>
                  <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                      <Delete />
                    </ListItemIcon>
                    Deletar
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        );
      }}
    </Popper>
  );
};
