import EstoqueContasPagar from "../pages/Estoque/EstoqueContasPagar";
import EstoqueMes from "../pages/Estoque/EstoqueMes";
import { EstoqueProdutos } from "../pages/Estoque/EstoqueProdutos";
import { EstoqueQualidade } from "../pages/Estoque/EstoqueQualidade";
import { MetaEstoque } from "../pages/Estoque/MetaEstoque";
import Home from "../pages/Home/Home";
import { Unauthorized } from "../pages/Unauthorized";
import { CreateBase } from "../pages/cadaastros/base/Create/CreateBase";
import { BaseList } from "../pages/cadaastros/base/List/BaseList";
import { CreateProfileIndicate } from "../pages/cadaastros/profile-indicate/Create/CreateProfileIndicate";
import { ListProfileIndicate } from "../pages/cadaastros/profile-indicate/List/ListProfileIndicate";
import { CreateStore } from "../pages/cadaastros/stores/Create/CreateStore";
import { StoreList } from "../pages/cadaastros/stores/List/StoreList";
import Boletim from "../pages/financeiro/boletim/boletim";
import { CadastroMetas } from "../pages/financeiro/metas/cadastro-metas";
import { ListarMetas } from "../pages/financeiro/metas/listar-metas";
import Planejamento from "../pages/financeiro/planejamento/planejamento";
import { EditInfluencer } from "../pages/franchise/EditInfluencer";
import { ListInterested } from "../pages/franchise/List/ListInterested";
import { ViewInfluencer } from "../pages/franchise/ViewInfluencer";
import Apresentacao from "../pages/metas/apresentacao";
import Metas from "../pages/metas/metas-gerais";
import { MetasGeraisPorPerido } from "../pages/metas/metas-gerais-periodo";
import MetasVendedor from "../pages/metas/metas-vendedor";
import MetasVendedorPeriodo from "../pages/metas/metas-vendedor-periodo";
import { Candidate } from "../pages/rh/vagas/Candidate/Candidate";
import { CreateVacancies } from "../pages/rh/vagas/Create/CreateVacancies";
import { VacanciesList } from "../pages/rh/vagas/List/VacanciesList";
import { SeeCandidates } from "../pages/rh/vagas/SeeCandidates/SeeCandidates";
import AnaliseClientes from "../pages/vendas/analise-clientes";
import DescontoGlobal from "../pages/vendas/desconto-global";
import { EditarCliente } from "../pages/vendas/editar-clientes-vendas-por-clientes";
import QuantidadePedidos from "../pages/vendas/quantidade-pedidos";
import VendasGlobal from "../pages/vendas/vendas-global";
import { VendasPorClientes } from "../pages/vendas/vendas-por-cliente";
import VendasPorDia from "../pages/vendas/vendas-por-dia";
import VendasPorFornecedor from "../pages/vendas/vendas-por-fornecedor";
import VendasPorGrupo from "../pages/vendas/vendas-por-grupo";
import { VendasPorVendedorGrupo } from "../pages/vendas/vendas-por-vendedor-grupo";

export const route = [
  {
    component: <ListInterested />,
    path: "/franquia/lista-interresados",
    exact: true,
    title: "Lista de interessados franquia",
  },
  {
    component: <ViewInfluencer />,
    path: "/franquia/lista-interresados/:slug",
    exact: true,
    title: "Influencer",
  },
  {
    component: <EditInfluencer />,
    path: "/franquia/lista-interresados/editar/:slug",
    exact: true,
    title: "editar informações",
  },
  {
    component: <Home />,
    path: "/",
    exact: true,
    title: "Inicio",
  },
  {
    component: <AnaliseClientes />,
    path: "/vendas/analise-clientes",
    exact: true,
    title: "Analise de clientes",
  },
  {
    component: <VendasPorClientes />,
    path: "/vendas/vendas-por-cliente",
    exact: true,
    title: "Analise de clientes",
  },
  {
    component: <EditarCliente />,
    path: "/vendas/vendas-por-cliente/:id",
    exact: true,
    title: "Analise de clientes",
  },
  {
    component: <VendasGlobal />,
    path: "/vendas/vendas-global/",
    exact: true,
    title: "vendas global",
  },
  {
    component: <BaseList />,
    path: "/cadastro/base/",
    title: "Lista de bases",
    exact: true,
  },
  {
    component: <CreateBase />,
    path: "/cadastro/base/editar/:id",
    exact: true,
    title: "editar base",
  },
  {
    component: <CreateBase />,
    path: "/cadastro/base/criar",
    exact: true,
    title: "editar base",
  },
  {
    component: <StoreList />,
    path: "/cadastro/lojas/",
    exact: true,
    title: "lista de lojas",
  },
  {
    component: <CreateStore />,
    path: "/cadastro/loja/criar",
    exact: true,
    title: "criar loja",
  },
  {
    component: <CreateStore />,
    path: "/cadastro/loja/editar/:id",
    exact: true,
    title: "editar loja",
  },
  {
    component: <ListProfileIndicate />,
    path: "/cadastro/perfil-indicador/",
    exact: true,
  },
  {
    component: <CreateProfileIndicate />,
    path: "/cadastro/perfil-indicador/criar",
    exact: true,
  },
  {
    component: <CreateProfileIndicate />,
    path: "/cadastro/perfil-indicador/editar/:id",
    exact: true,
  },
  {
    component: <MetasVendedor />,
    path: "/metas/metas-vendedor-geral",
    exact: true,
    title: "Metas por vendedor",
  },
  {
    component: <MetasVendedorPeriodo />,
    path: "/metas/metas-vendedor",
    exact: true,
    title: "Metas Vendedor por Periodo",
  },
  {
    component: <Metas />,
    path: "/metas/metas-gerais",
    exact: true,
    title: "Metas gerais",
  },
  {
    component: <Apresentacao />,
    path: "/metas/apresentacao",
    exact: true,
    title: "metas apresentação",
  },
  {
    component: <QuantidadePedidos />,
    path: "/vendas/quantidade-pedidos/",
    exact: true,
    title: "Quantidade de pedidos",
  },
  {
    component: <VendasPorGrupo />,
    path: "/vendas/vendas-por-grupo/",
    exact: true,
    title: "Vendas por grupo",
  },
  {
    component: <VendasPorFornecedor />,
    path: "/vendas/vendas-por-fornecedor/",
    exact: true,
    title: "Vendas por fornecedor",
  },
  {
    component: <VendasPorDia />,
    path: "/vendas/vendas-por-dia",
    exact: true,
    title: "Vendas por dia",
  },
  {
    component: <DescontoGlobal />,
    path: "/vendas/desconto-global",
    exact: true,
  },
  {
    component: <VendasPorVendedorGrupo />,
    path: "/vendas/vendas-por-vendedor-grupo",
    exact: true,
    title: "Desconto global",
  },
  {
    component: <MetaEstoque />,
    path: "/estoque/estoque-meta-financeira/",
    exact: true,
    title: "META ESTOQUE",
  },
  {
    component: <EstoqueProdutos />,
    path: "/estoque/consulta-produto/",
    exact: true,
    title: "Consulta produto",
  },
  {
    component: <EstoqueQualidade />,
    path: "/estoque/estoque-qualidade/",
    exact: true,
    title: "Qualidade de estoque",
  },
  {
    component: <EstoqueMes />,
    path: "/estoque/estoque-mes/",
    exact: true,
    title: "estoque mês",
  },
  {
    component: <EstoqueContasPagar />,
    path: "/estoque/estoque-contas-pagar/",
    exact: true,
    title: "ESTOQUE POR FORNECEDOR E CONTAS A PAGAR",
  },
  {
    component: <VacanciesList />,
    path: "/rh/vagas/",
    exact: true,
    title: "VAGAS",
  },
  {
    component: <CreateVacancies />,
    path: "/rh/vagas/editar/:id",
    exact: true,
    title: "EDITAR VAGA",
  },
  {
    component: <CreateVacancies />,
    path: "/rh/vagas/criar",
    exact: true,
    title: "CRIAR VAGA",
  },
  {
    component: <SeeCandidates />,
    path: "/rh/vagas/ver-candidatos/:id",
    exact: true,
    title: "VER CANDIDATOS",
  },
  {
    component: <Candidate />,
    path: "/rh/vagas/ver-candidatos/:id/:idCandidate",
    exact: true,
    title: "VER CANDIDATOS",
  },
  {
    component: <Boletim />,
    path: "/financeiro/boletim",
    exact: true,
    title: "BOLETIM",
  },
  {
    component: <Planejamento />,
    path: "/financeiro/planejamento",
    exact: true,
    title: "PLANEJAMENTO",
  },
  {
    component: <Unauthorized />,
    path: "/401",
    exact: true,
    title: "401",
  },
  {
    component: <ListarMetas />,
    path: "/financeiro/metas",
    exact: true,
    title: "METAS FINANCEIRO",
  },
  {
    component: <CadastroMetas />,
    path: "/financeiro/metas/cadastro",
    exact: true,
    title: "CADASTRO METAS",
  },
  {
    component: <CadastroMetas />,
    path: "/financeiro/metas/editar/:id",
    exact: true,
    title: "CADASTRO METAS",
  },
  {
    component: <MetasGeraisPorPerido />,
    path: "/metas/metas-por-periodo",
    exact: true,
    title: "METAS POR PERIODOS",
  },
];
