import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import Title from "../../components/title";

import ReactFullscreen from "react-easyfullscreen";
import "react-data-table-component-extensions/dist/index.css";
import ReactSpeedometer from "react-d3-speedometer";
import { metasService } from "../../services/metasServices";
import { Col, Container, Row } from "react-bootstrap";
import { arrayBases, converterLoja } from "../../util/loja";
import { colunasMetasGerais2 } from "../../util/tables";

import {
  ContainerInputPeriodo,
  ContainerTudo,
  WraperContainer,
} from "../../styles/global";
import TabelaNext from "../../components/componentteste/TabelaNext";

import { LegandaMetas } from "../../components/LegendaMetas/LegandaMetas";
import toast from "react-hot-toast";

import { ModalMetasDirecinarPagVendedor } from "../../components/ModalMetasDirecinarPagVendedor/ModalMetasDirecinarPagVendedor";
import { PermissionsPage } from "../../util/permissionsPage";
import { useLocation } from "react-router";
import { useUserData } from "../../hooks/useUserData";
import { LoadingTable } from "../../components/Loading/LoadingTable";
import {
  Box,
  Button,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MultiSelectFilterLoja } from "../../components/FilterLojaMetas/MultiSelectFilterLoja";
import { MultiSelectFilterBases } from "../../components/FilterLojaMetas/MultiSelectFilterBases";
import { SelectFilterMonth } from "../../components/FilterLojaMetas/SelectMes";
import { Bar1 } from "../../components/GraficosMetasGerais/Bar1";

function Metas() {
  const { search } = useLocation();
  const width = typeof window !== "undefined" ? window?.screen?.width : 0;

  const isApp = new URLSearchParams(search).get("app")
    ? new URLSearchParams(search).get("app")
    : false;

  const [nivelAcesso, setNivelAcesso] = useState([]);
  const { userData } = useUserData();
  const accessLevel = userData?.nivelAcesso;
  const acessBase = userData?.baseAcesso;
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.up("sm"));

  const [, setBaseAcesso] = useState([]);
  const [dataMetasFromApi, setDataMetasFromApi] = useState([]);
  const [dataDescontoParaGrafico, setDataDescontoParaGrafico] = useState([]);
  const [labelsChart, setlabelsChart] = useState([]);
  const [labelsChartCod, setlabelsChartCod] = useState([]);
  const [labelsChartDesconto, setlabelsChartDesconto] = useState([]);
  const [labelsChartDescontoCod, setlabelsChartDescontoCod] = useState([]);
  const [dataDescontoAplicadoFromApi, setDataDescontoAplicadoFromApi] =
    useState([]);
  const [dataChartBarFromApi, setDataChartBarFromApi] = useState([]);
  const [dataMetaOuro, setDataMetaOuro] = useState([]);
  const [dataMetaDiamante, setDataMetaDiamante] = useState([]);
  const [dataChartLineFromApi, setDataChartLineFromApi] = useState([]);
  const [metaTotal, setMetaTotal] = useState(0);
  const [realizadoTotal, setRealizadoTotal] = useState(0);
  const [dataChartProjetadaFromApi, setDataChartProjetadaFromApi] = useState(
    []
  );
  const [dataTableFromApi, setDataTableFromApi] = useState([]);
  const [periodoReferencia, setPeriodoReferencia] = useState("");
  const [dadosDisponiveis, setDadosDisponiveis] = useState(false);
  const [carregando, setCarregando] = useState(true);
  const [periodosDisponiveis, setPeriodosDisponiveis] = useState([]);
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [periodoInputInicio, setPeriodoInputInicio] = useState(false);
  const [periodoInputFim, setPeriodoInputFim] = useState(false);
  const [periodoInputInicioAux, setPeriodoInputInicioAux] = useState("");
  const [periodoInputFimAux, setPeriodoInputFimAux] = useState("");
  const [periodoMinimo, setPeriodoMinimo] = useState("");
  const [periodoMaximo, setPeriodoMaximo] = useState("");
  const [labelsTable, setLabelsTable] = useState([]);
  const [empresasSelecionadas, setEmpresasSelecionadas] = useState([]);
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [basesSelecionadas, setBasesSelecionadas] = useState([]);
  const [dadosCompletos, setDadosCompletos] = useState([]);
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (nivelAcesso.length === 0) {
      buscarNivelAcesso();
    }
  }, [userData]);

  useMemo(() => {
    if (periodoReferencia && periodoReferencia != "") {
      preencherDados();
    } else if (accessLevel?.length) {
      preencherPeriodo();
    }
  }, [periodoReferencia, accessLevel, periodoInputInicio, periodoInputFim]);

  const buscarNivelAcesso = () => {
    if (accessLevel) {
      setCarregando(true);
      setIsLoading(true);
      // setpermissions(permissions)
      setNivelAcesso(accessLevel);
      setBasesDisponiveis(arrayBases(acessBase));
      setBaseAcesso(acessBase);
    } else {
      setCarregando(false);
      setIsLoading(false);
    }
  };

  const handleClose = () => setModal({ ...modal, active: false });

  async function preencherPeriodo() {
    try {
      const result = await metasService.periodosDisponiveis();

      var periodos = [];

      if (
        result?.status === 200 &&
        result?.data?.success &&
        result?.data?.type
      ) {
        var dataAux = result?.data?.data?.periodos;
        var periodoAtual = "";
        let dataAtual = new Date();
        dataAux.forEach((item) => {
          let dataInicioTemp = new Date(item.periodoInicio);
          if (
            periodoAtual === "" &&
            dataAtual.getFullYear() === dataInicioTemp.getFullYear() &&
            dataAtual.getMonth() === dataInicioTemp.getMonth()
          ) {
            periodoAtual = item.id;
          }
          periodos.push({ periodo: item.periodo, id: item.id });
        });
        if (periodoAtual === "") {
          periodoAtual = dataAux[0].id;
        }
        setPeriodoReferencia(periodoAtual);
        setPeriodosDisponiveis(periodos);
      } else {
        preencherPeriodosCasoErro();
      }
    } catch (error) {
      preencherPeriodosCasoErro();
    }
  }

  function direcionarParaMetasPorVendedorasLojaEspecifica(loja) {
    setModal({ ...modal, active: true, loja });
    return;
  }

  function preencherPeriodosCasoErro() {
    var periodos = [];
    var dataAtual = new Date();
    for (let index = 0; index < 12; index++) {
      var mes = dataAtual.getMonth() + 1;
      var ano = dataAtual.getFullYear();
      if (mes < 10) {
        mes = "0" + mes;
      }
      var periodo = ano + "/" + mes;
      periodos.push({ periodo, id: index });
      dataAtual.setMonth(dataAtual.getMonth() - 1);
    }
    setPeriodoReferencia(periodos[0].id);
    setPeriodosDisponiveis(periodos);
  }

  function preencherDiasUteis(
    inicio,
    fim,
    diasRestantes = false,
    diasRemovidos = false
  ) {
    if (diasRestantes && (!periodoInputInicio || !periodoInputFim)) {
      var dataInicio = new Date();
      var dataFim = new Date(fim);
    } else if (diasRestantes && periodoInputInicio && periodoInputFim) {
      var dataInicio = new Date(
        periodoInputFim.replace("-", "/").replace("-", "/").replace("-", "/")
      );
      var dataFim = new Date(fim);
    } else if (diasRestantes) {
      var dataInicio = new Date();
      var dataFim = new Date(fim);
    } else {
      if (periodoInputInicio && periodoInputFim) {
        var dataInicio = new Date(
          periodoInputInicio
            .replace("-", "/")
            .replace("-", "/")
            .replace("-", "/")
        );
        var dataFimAux = new Date(periodoInputFim);
      } else {
        var dataInicio = new Date(inicio);
        var dataFimAux = new Date(fim);
      }
      var dataFim = new Date();
      if (dataFim > dataFimAux) dataFim = dataFimAux;
      if (!periodoInputInicio || !periodoInputFim) {
        dataFim.setDate(dataFim.getDate() - 1);
      }
    }
    dataFim.setHours(23);
    dataFim.setMinutes(59);
    dataFim.setSeconds(59);
    var dataAtual = dataInicio;
    var diasUteis = 0;

    while (dataAtual <= dataFim) {
      // if (dataAtual.getDay() != 0) {
      //     diasUteis++
      // }
      let ano = dataAtual.getFullYear();
      let mes =
        dataAtual.getMonth() + 1 < 10
          ? "0" + (dataAtual.getMonth() + 1)
          : dataAtual.getMonth() + 1;
      let dia =
        dataAtual.getDate() < 10
          ? "0" + dataAtual.getDate()
          : dataAtual.getDate();
      let dataAtualTemp = ano + "" + mes + "" + dia;
      if (diasRemovidos && diasRemovidos.indexOf(dataAtualTemp) < 0) {
        diasUteis++;
      }
      dataAtual.setDate(dataAtual.getDate() + 1);
    }
    return diasUteis;
  }

  async function preencherDados() {
    setCarregando(true);
    setIsLoading(true);
    window.scrollTo(0, 0);
    const toastId = toast.loading("Esperando dados do RAD");

    try {
      const { data } = await metasService.listarMetas({
        periodo: periodoReferencia,
        nivelAcesso: accessLevel,
        baseAcesso: acessBase,
        periodoInputInicio,
        periodoInputFim,
      });

      if (!data?.data) {
        toast.error("Não há conexão com o RAD", {
          id: toastId,
          duration: 7000,
          style: {
            borderRadius: "0.25rem",
            background: "#333",
            color: "#fff",
          },
        });
        setCarregando(false);
        setIsLoading(false);
        setDadosDisponiveis(false);
        return;
      }
      // return;
      if (data?.success && data?.type) {
        let dataTodasLojas = [];
        let dataInicioTemp = "";
        let dataFimTemp = "";

        data.data.forEach((a) => {
          let respostaLojas = a.response;
          if (respostaLojas) {
            let dataRespostaLojas = respostaLojas.data?.realizadaPorLoja;
            dataInicioTemp =
              dataInicioTemp !== "" ? dataInicioTemp : respostaLojas.inicio;
            dataFimTemp = dataFimTemp !== "" ? dataFimTemp : respostaLojas.fim;
            if (dataRespostaLojas?.length > 0) {
              dataRespostaLojas.forEach((b) => {
                dataTodasLojas.push(b);
              });
            }
          }
        });
        setBasesDisponiveis(arrayBases(data.data));

        if (dataInicioTemp && dataFimTemp) {
          let inicioFormatado = dataInicioTemp.split(" ")[0];
          let fimFormatado = dataFimTemp.split(" ")[0];
          if (!periodoInputInicio || !periodoInputFim) {
            setPeriodoInputInicioAux(inicioFormatado);
            setPeriodoInputFimAux(fimFormatado);
          }
          setDataInicio(dataInicioTemp);
          setDataFim(dataFimTemp);

          setPeriodoMinimo(inicioFormatado);
          setPeriodoMaximo(fimFormatado);
        }

        processaDadosLoja(dataTodasLojas, dataInicioTemp, dataFimTemp);
        setDadosCompletos(dataTodasLojas);
        toast.success("Dados recebidos", {
          id: toastId,
        });
      } else {
        setCarregando(false);
        setDadosDisponiveis(false);
        setIsLoading(false);
        toast.error("Não há conexão com o RAD", {
          id: toastId,
          duration: 7000,
          style: {
            borderRadius: "0.25rem",
            background: "#333",
            color: "#fff",
          },
        });
      }
      //})
    } catch (error) {
    } finally {
      setIsLoading(false);
      setCarregando(false);
    }
  }

  function processaDadosLoja(dataTodasLojas, dataInicioTemp, dataFimTemp) {
    let metaSugerida = [];
    let metaOuroSugerida = [];
    let metaDiamanteSugerida = [];
    let metaRealizada = [];
    let labelNomes = [];
    let labelNomesParaFiltro = [];
    let dadosTabela = [];
    let diasUteisGeral = 0;
    let diasRestantesGeral = 0;
    let qtdLojas = 0;
    let projetada = [];
    let desconto = [];
    let labelCod = [];
    let descontoParaGrafico = [];
    let totalMeta = 0;
    let totalRealizada = 0;
    let totalPorcentagem = 0;

    let totalLucroBruto = 0;
    let resumoChart = [];
    let resumoAnaliseChart = [];
    let auxTotalRealizado = 0;
    let descontoTotal = 0;
    var dataMetas = dataTodasLojas;
    let conversao = 0;
    dataMetas = dataMetas.sort((a, b) => a.CodLoja - b.CodLoja);
    //let realizadaPorLoja = data.data.data.realizadaPorLoja;
    dataMetas = dataMetas.sort((a, b) => a.CodLoja - b.CodLoja);
    if (dataMetas.length > 0) {
      dataMetas.map((item) => {
        auxTotalRealizado += parseFloat(item.MetaRealizada);
      });
      auxTotalRealizado = auxTotalRealizado > 0 ? auxTotalRealizado : 1;
      setDataMetasFromApi(dataMetas);
      if (dataMetas && dataMetas.length > 0) {
        dataMetas = dataMetas.sort((a, b) => b.MetaRealizada - a.MetaRealizada);
        dataMetas.map(async (item, index) => {
          labelNomes.push(converterLoja(item.CodLoja));
          labelCod.push(item.CodLoja);
          metaSugerida.push(parseFloat(item.MetaProjetada));
          metaOuroSugerida.push(parseFloat(item.MetaProjetada) * 1.15);
          metaDiamanteSugerida.push(parseFloat(item.MetaProjetada) * 1.3);
          metaRealizada.push(item.MetaRealizada);
          let diasRemovidos = [];
          if (item.diasRemovidos) {
            diasRemovidos = item.diasRemovidos.split(",");
          }

          var metaSugeridaTabela = !isNaN(parseFloat(item.MetaProjetada))
            ? parseFloat(item.MetaProjetada)
            : 0;

          var metaRealizadaTabela = !isNaN(parseFloat(item.MetaRealizada))
            ? parseFloat(item.MetaRealizada)
            : 0;

          var metaProjetadaTabela = !isNaN(parseFloat(item.MetaProjetada))
            ? parseFloat(item.MetaProjetada)
            : 0;

          var porcentagemMeta =
            metaSugeridaTabela > 0
              ? ((metaRealizadaTabela / metaSugeridaTabela) * 100).toFixed(2)
              : "*";

          var orcamentos = !isNaN(item.Orcamentos) ? item.Orcamentos : 0;

          var orcamentosFechados = !isNaN(parseFloat(item.OrcamentoFechado))
            ? parseFloat(item.OrcamentoFechado)
            : 0;

          conversao = ((orcamentosFechados / orcamentos) * 100).toFixed(2);

          desconto.push(
            parseFloat(item.MetaRealizada) + parseFloat(item.DescontoAplicado)
          );
          descontoParaGrafico.push({
            loja: item.nomeLoja,
            valor: parseFloat(item.DescontoAplicado),
            codLoja: item.CodLoja,
          });
          totalMeta += metaSugeridaTabela;
          totalRealizada += metaRealizadaTabela;
          totalPorcentagem +=
            porcentagemMeta != "*" && !isNaN(porcentagemMeta)
              ? parseFloat(porcentagemMeta)
              : 0;

          totalLucroBruto += parseFloat(item.VlrLucroBruto);
          descontoTotal += parseFloat(item.DescontoAplicado);
          //let diasUteis = preencherDiasUteis(dataInicioTemp, dataFimTemp);
          //let diasUteis = item.diasUteis;
          let diasUteis = preencherDiasUteis(
            dataInicioTemp,
            dataFimTemp,
            false,
            diasRemovidos
          );
          diasUteisGeral += parseInt(diasUteis);
          qtdLojas++;
          let diasUteisRestantes = preencherDiasUteis(
            0,
            dataFimTemp,
            true,
            diasRemovidos
          );
          diasRestantesGeral += parseInt(diasUteisRestantes);
          let mediaAtual =
            diasUteis > 0
              ? metaRealizadaTabela / diasUteis
              : metaRealizadaTabela;
          let projecaoFinal =
            mediaAtual && diasUteisRestantes > 0
              ? mediaAtual * diasUteisRestantes + metaRealizadaTabela
              : metaRealizadaTabela;
          let deficitMensal =
            metaSugeridaTabela - metaRealizadaTabela > 0
              ? metaSugeridaTabela - metaRealizadaTabela
              : 0;
          let deficitDiario =
            diasUteisRestantes > 0 ? deficitMensal / diasUteisRestantes : 0;
          let representatividade = parseFloat(
            ((metaRealizadaTabela / auxTotalRealizado) * 100).toFixed(2)
          );
          projetada.push(projecaoFinal);

          let metaAlcancada = (projecaoFinal / metaProjetadaTabela) * 100;
          let customStyles = "#fff";

          if (metaAlcancada < 100) {
            metaAlcancada = 0;
            customStyles = "#c4302b";
          } else if (metaAlcancada >= 100 && metaAlcancada < 115) {
            metaAlcancada = 1;
            customStyles = "#00b14f";
          } else if (metaAlcancada >= 115 && metaAlcancada < 130) {
            metaAlcancada = 2;
            customStyles = "#FFD700";
          } else if (metaAlcancada >= 130) {
            metaAlcancada = 3;
            customStyles = "#6395ec";
          }
          const valueLucro = parseFloat(
            item.VlrLucroBruto / item.MetaRealizada
          );
          let lucroBruto = parseFloat((valueLucro * 100).toFixed(2));
          lucroBruto = !isNaN(lucroBruto)
            ? formatarNumero(lucroBruto, false) + "%"
            : "0%";
          const value = parseFloat(item.DescontoAplicado);
          const totalVenda = parseFloat(item.MetaRealizada);
          let valorDescontoPorcentagem = (value / (totalVenda + value)) * 100;
          valorDescontoPorcentagem =
            !isNaN(valorDescontoPorcentagem) && valorDescontoPorcentagem > 0
              ? formatarNumero(valorDescontoPorcentagem, false) + "%"
              : "0%";
          let valorConversão = !isNaN(conversao) ? conversao : "0";
          dadosTabela.push({
            loja: item.CodLoja,
            rank: index + 1,
            conversao: valorConversão,
            meta: metaSugeridaTabela,
            realizada: metaRealizadaTabela,
            porcentagem: porcentagemMeta,
            diasUteis: diasUteis,
            diasFalta: diasUteisRestantes,
            dMensal: deficitMensal,
            dDiario: deficitDiario,
            projecao: projecaoFinal,
            representatividade: representatividade,
            cor: customStyles,
            descontoAplicado: valorDescontoPorcentagem,
            lucroBruto: lucroBruto,
          });
          labelNomesParaFiltro.push({
            codLoja: item.CodLoja,
            nomeLoja: item.nomeLoja,
            diasUteis: diasUteis,
            diasFalta: diasUteisRestantes,
            quantidadeVendedorasAbaixoMeta: [],
          });
        });

        setMetaTotal(totalMeta);
        setRealizadoTotal(totalRealizada);

        diasUteisGeral = diasUteisGeral / qtdLojas;
        diasRestantesGeral = diasRestantesGeral / qtdLojas;
        //let diasUteisRestantes = preencherDiasUteis(0, data.data.fim, true);
        let mediaAtual =
          diasUteisGeral > 0 ? totalRealizada / diasUteisGeral : totalRealizada;
        let projecaoFinal =
          mediaAtual && diasRestantesGeral > 0
            ? mediaAtual * diasRestantesGeral + totalRealizada
            : totalRealizada;
        let deficitMensal =
          totalMeta - totalRealizada > 0 ? totalMeta - totalRealizada : 0;
        let deficitDiario =
          diasRestantesGeral > 0 ? deficitMensal / diasRestantesGeral : 0;
        totalPorcentagem = (totalRealizada / totalMeta) * 100;
        diasUteisGeral = diasUteisGeral.toFixed(2);

        diasRestantesGeral = diasRestantesGeral.toFixed(2);
        const value = parseFloat(descontoTotal);
        const totalVenda = parseFloat(totalRealizada);
        let valorDescontoPorcentagem = (value / (totalVenda + value)) * 100;
        valorDescontoPorcentagem =
          !isNaN(valorDescontoPorcentagem) && valorDescontoPorcentagem > 0
            ? formatarNumero(valorDescontoPorcentagem, false) + "%"
            : "0%";
        let valorConversão = !isNaN(conversao) ? conversao : "0";
        let resumoLucro =
          formatarNumero((totalLucroBruto / totalVenda) * 100, false) + "%";
        resumoChart.push({
          loja: "Total",
          meta: totalMeta,
          realizada: totalRealizada,
          porcentagem: totalPorcentagem,
          diasUteis: diasUteisGeral,
          diasFalta: diasRestantesGeral,
          dMensal: deficitMensal,
          dDiario: deficitDiario,
          projecao: projecaoFinal,
          representatividade: 100,
        });
        dadosTabela.push({
          loja: "Total",
          rank: "",
          conversao: valorConversão,
          meta: totalMeta,
          realizada: totalRealizada,
          porcentagem: totalPorcentagem,
          diasUteis: diasUteisGeral,
          diasFalta: diasRestantesGeral,
          dMensal: deficitMensal,
          dDiario: deficitDiario,
          projecao: projecaoFinal,
          representatividade: 100,
          coluna2: true,
          descontoAplicado: valorDescontoPorcentagem,
          lucroBruto: resumoLucro,
        });
        let porcentagemAbaixoDaMeta =
          ((totalRealizada - totalMeta) / totalMeta) * 100;
        let porcentagemProjetadoAbaixoDaMeta =
          ((projecaoFinal - totalMeta) / totalMeta) * 100;

        let corAbaixoDaMeta = "#fff";

        if (porcentagemAbaixoDaMeta < 0) {
          corAbaixoDaMeta = "#c4302b";
        } else if (
          porcentagemAbaixoDaMeta >= 0 &&
          porcentagemAbaixoDaMeta < 15
        ) {
          corAbaixoDaMeta = "#00b14f";
        } else if (
          porcentagemAbaixoDaMeta >= 15 &&
          porcentagemAbaixoDaMeta < 30
        ) {
          corAbaixoDaMeta = "#FFD700";
        } else if (porcentagemAbaixoDaMeta >= 30) {
          corAbaixoDaMeta = "#6395ec";
        }
        let corProjetadoAbaixoDaMeta = "#fff";

        if (porcentagemProjetadoAbaixoDaMeta < 0) {
          corProjetadoAbaixoDaMeta = "#c4302b";
        } else if (
          porcentagemProjetadoAbaixoDaMeta >= 0 &&
          porcentagemProjetadoAbaixoDaMeta < 15
        ) {
          corProjetadoAbaixoDaMeta = "#00b14f";
        } else if (
          porcentagemProjetadoAbaixoDaMeta >= 15 &&
          porcentagemProjetadoAbaixoDaMeta < 30
        ) {
          corProjetadoAbaixoDaMeta = "#FFD700";
        } else if (porcentagemProjetadoAbaixoDaMeta >= 30) {
          corProjetadoAbaixoDaMeta = "#6395ec";
        }

        resumoAnaliseChart.push({
          loja: "",
          meta: "",
          cor: corAbaixoDaMeta,
          corProjetado: corProjetadoAbaixoDaMeta,
          realizada: porcentagemAbaixoDaMeta,
          porcentagem: "",
          diasUteis: "",
          diasFalta: "",
          dMensal: "",
          dDiario: "",
          projecao: porcentagemProjetadoAbaixoDaMeta,
          representatividade: "",
        });
        dadosTabela.push({
          loja: "",
          meta: "",
          rank: "",
          conversao: "",
          cor: corAbaixoDaMeta,
          corProjetado: corProjetadoAbaixoDaMeta,
          realizada: porcentagemAbaixoDaMeta,
          porcentagem: "",
          diasUteis: "",
          diasFalta: "",
          dMensal: "",
          dDiario: "",
          projecao: porcentagemProjetadoAbaixoDaMeta,
          representatividade: "",
          coluna3: true,
        });
        labelNomesParaFiltro = labelNomesParaFiltro.sort(
          (a, b) => a.codLoja - b.codLoja
        );
        descontoParaGrafico = descontoParaGrafico.sort((a, b) => {
          return b.valor - a.valor;
        });
        let descontoParaGraficoTemp = [];
        let labelsChartDesconto = [];
        let labelsChartDescontoCod = [];
        descontoParaGrafico.map(async (a) => {
          descontoParaGraficoTemp.push(a.valor);
          labelsChartDesconto.push(converterLoja(a.codLoja));
          labelsChartDescontoCod.push(a.codLoja);
        });

        setDataChartBarFromApi(metaSugerida);
        setDataMetaOuro(metaOuroSugerida);
        setDataMetaDiamante(metaDiamanteSugerida);
        setDataChartLineFromApi(metaRealizada);
        setDataTableFromApi(dadosTabela);
        setlabelsChart(labelNomes);
        setLabelsTable(labelNomesParaFiltro);
        setDataChartProjetadaFromApi(projetada);
        setDataDescontoAplicadoFromApi(desconto);
        setlabelsChartCod(labelCod);
        setDataDescontoParaGrafico(descontoParaGraficoTemp);
        setlabelsChartDesconto(labelsChartDesconto);
        setlabelsChartDescontoCod(labelsChartDescontoCod);
        setDadosDisponiveis(true);
        setCarregando(false);
        setIsLoading(false);
        buscarIndicadoresVendedoras(labelNomesParaFiltro);
      }
    } else {
      setCarregando(false);
      setIsLoading(false);
    }
  }
  async function buscarIndicadoresVendedoras(
    lojasDisponiveisParaAdicionarIndicadores
  ) {
    try {
      setCarregando(true);
      const { data } = await metasService.indicadorVendedorasAbaixoMetaAws({
        baseAcesso: acessBase,
        nivelAcesso: accessLevel,
        periodo: periodoReferencia,
        periodoInputFim,
        periodoInputInicio,
        lojas: lojasDisponiveisParaAdicionarIndicadores,
      });

      if (data.success) {
        let dataTemp = data.data;
        let dataTodasLojasTemp = [];
        dataTemp.map((a) => {
          if (a.response) {
            dataTodasLojasTemp.push(Object.entries(a.response));
          }
        });
        let dataTodasLojas = [];
        dataTodasLojasTemp.map((a) => {
          a.map((b) => {
            dataTodasLojas.push(b);
          });
        });
        if (dataTodasLojas?.length > 0) {
          let vendedoresAbaixoDaMeta = dataTodasLojas; //Object.entries(dataTodasLojas);
          let labelNomesParaFiltroTemp =
            lojasDisponiveisParaAdicionarIndicadores.filter((a) => true);
          vendedoresAbaixoDaMeta.map((a) => {
            let codLoja = a[0];
            let quantidadeVendedorasAbaixoMeta = a[1];
            labelNomesParaFiltroTemp.map((b) => {
              if (b.codLoja == codLoja) {
                for (let i = 0; i < quantidadeVendedorasAbaixoMeta; i++) {
                  b.quantidadeVendedorasAbaixoMeta.push(1);
                }
              }
            });
          });

          setLabelsTable(labelNomesParaFiltroTemp);
        }
      }
    } catch (error) {
    } finally {
      setCarregando(false);
    }
  }

  function formatarNumero(numero, money = true, decimal = 2) {
    if (money) {
      return numero.toLocaleString("pt-BR", {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
        style: "currency",
        currency: "BRL",
      });
    } else {
      return numero.toLocaleString("pt-BR", {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      });
    }
  }
  const handleBase = (value) => {
    const basesSelecionadasTemp = value;
    setBasesSelecionadas(basesSelecionadasTemp);
    let dadosCompletosFiltrados = [];
    if (basesSelecionadasTemp.length > 0) {
      dadosCompletosFiltrados = dadosCompletos.filter(
        (a) => basesSelecionadasTemp.indexOf(a.CodBase) >= 0
      );
    } else {
      dadosCompletosFiltrados = dadosCompletos;
    }
    processaDadosLoja(dadosCompletosFiltrados, dataInicio, dataFim);
  };

  async function filtrarEmpresa(lojasSelecionadas) {
    setCarregando(true);
    setIsLoading(true);
    window.scrollTo(0, 0);
    setDadosDisponiveis(false);

    setEmpresasSelecionadas(lojasSelecionadas);
    let dataMetas = dataMetasFromApi;
    let auxTotalRealizado = 0;
    dataMetas.map((item) => {
      auxTotalRealizado += parseFloat(item.MetaRealizada);
    });
    if (lojasSelecionadas.length > 0) {
      dataMetas = dataMetas.filter((a) => {
        return lojasSelecionadas.indexOf(a.CodLoja) >= 0 ? true : false;
      });
    }
    let labelsTableAux = labelsTable;
    labelsTableAux = labelsTableAux.filter((a) => true);
    setLabelsTable(labelsTableAux);

    let metaSugerida = [];
    let metaOuroSugerida = [];
    let metaDiamanteSugerida = [];
    let metaRealizada = [];
    let labelNomes = [];
    let dadosTabela = [];
    let projetada = [];
    let desconto = [];
    let labelCod = [];
    let descontoParaGrafico = [];
    let totalMeta = 0;
    let totalRealizada = 0;
    let totalPorcentagem = 0;

    let totalProjecao = 0;

    let diasUteisGeral = 0;
    let diasRestantesGeral = 0;
    let qtdLojas = 0;

    if (dataMetas && dataMetas.length > 0) {
      dataMetas = dataMetas.sort((a, b) => b.MetaRealizada - a.MetaRealizada);
      dataMetas.map((item) => {
        //let itemCodLoja= await converterLoja(item.CodLoja, nivelAcesso)

        labelNomes.push(converterLoja(item.CodLoja));
        labelCod.push(item.CodLoja);
        let diasRemovidos = [];
        if (item.diasRemovidos) {
          diasRemovidos = item.diasRemovidos.split(",");
        }
        // labelNomesParaFiltro.push({ codLoja: item.CodLoja, nomeLoja: item.nomeLoja });
        metaSugerida.push(parseFloat(item.MetaProjetada));
        metaOuroSugerida.push(parseFloat(item.MetaProjetada) * 1.15);
        metaDiamanteSugerida.push(parseFloat(item.MetaProjetada) * 1.3);
        metaRealizada.push(item.MetaRealizada);

        var metaSugeridaTabela = !isNaN(parseFloat(item.MetaProjetada))
          ? parseFloat(item.MetaProjetada)
          : 0;

        var metaRealizadaTabela = !isNaN(parseFloat(item.MetaRealizada))
          ? parseFloat(item.MetaRealizada)
          : 0;

        var metaProjetadaTabela = !isNaN(parseFloat(item.MetaProjetada))
          ? parseFloat(item.MetaProjetada)
          : 0;

        var porcentagemMeta =
          metaSugeridaTabela > 0
            ? ((metaRealizadaTabela / metaSugeridaTabela) * 100).toFixed(2)
            : "*";

        desconto.push(
          parseFloat(item.MetaRealizada) + parseFloat(item.DescontoAplicado)
        );
        descontoParaGrafico.push({
          loja: item.nomeLoja,
          valor: parseFloat(item.DescontoAplicado),
          codLoja: item.CodLoja,
        });
        totalMeta += metaSugeridaTabela;
        totalRealizada += metaRealizadaTabela;
        totalPorcentagem +=
          porcentagemMeta != "*" && !isNaN(porcentagemMeta)
            ? parseFloat(porcentagemMeta)
            : 0;

        totalProjecao += metaProjetadaTabela;
        let diasUteis = preencherDiasUteis(
          dataInicio,
          dataFim,
          false,
          diasRemovidos
        );
        diasUteisGeral += parseInt(diasUteis);
        qtdLojas++;
        let diasUteisRestantes = preencherDiasUteis(
          0,
          dataFim,
          true,
          diasRemovidos
        );
        diasRestantesGeral += parseInt(diasUteisRestantes);
        let mediaAtual =
          diasUteis > 0 ? metaRealizadaTabela / diasUteis : metaRealizadaTabela;
        let projecaoFinal =
          mediaAtual && diasUteisRestantes > 0
            ? mediaAtual * diasUteisRestantes + metaRealizadaTabela
            : metaRealizadaTabela;
        let deficitMensal =
          metaSugeridaTabela - metaRealizadaTabela > 0
            ? metaSugeridaTabela - metaRealizadaTabela
            : 0;
        let deficitDiario =
          diasUteisRestantes > 0 ? deficitMensal / diasUteisRestantes : 0;
        let representatividade = parseFloat(
          ((metaRealizadaTabela / auxTotalRealizado) * 100).toFixed(2)
        );
        projetada.push(projecaoFinal);
        let metaAlcancada = (projecaoFinal / metaProjetadaTabela) * 100;
        let customStyles = "#fff";

        if (metaAlcancada < 100) {
          metaAlcancada = 0;
          customStyles = "#c4302b";
        } else if (metaAlcancada >= 100 && metaAlcancada < 115) {
          metaAlcancada = 1;
          customStyles = "#00b14f";
        } else if (metaAlcancada >= 115 && metaAlcancada < 130) {
          metaAlcancada = 2;
          customStyles = "#FFD700";
        } else if (metaAlcancada >= 130) {
          metaAlcancada = 3;
          customStyles = "#6395ec";
        }
        dadosTabela.push({
          loja: item.CodLoja,
          meta: metaSugeridaTabela,
          realizada: metaRealizadaTabela,
          porcentagem: porcentagemMeta,
          diasUteis: diasUteis,
          diasFalta: diasUteisRestantes,
          dMensal: deficitMensal,
          dDiario: deficitDiario,
          projecao: projecaoFinal,
          representatividade: representatividade,
          cor: customStyles,
        });
      });

      setMetaTotal(totalMeta);
      setRealizadoTotal(totalRealizada);

      diasUteisGeral = diasUteisGeral / qtdLojas;
      diasRestantesGeral = diasRestantesGeral / qtdLojas;
      let mediaAtual =
        diasUteisGeral > 0 ? totalRealizada / diasUteisGeral : totalRealizada;
      let projecaoFinal =
        mediaAtual && diasRestantesGeral > 0
          ? mediaAtual * diasRestantesGeral + totalRealizada
          : totalRealizada;
      let deficitMensal =
        totalMeta - totalRealizada > 0 ? totalMeta - totalRealizada : 0;
      let deficitDiario =
        diasRestantesGeral > 0 ? deficitMensal / diasRestantesGeral : 0;
      totalPorcentagem = (totalRealizada / totalMeta) * 100;

      diasUteisGeral = diasUteisGeral.toFixed(2);
      diasRestantesGeral = diasRestantesGeral.toFixed(2);
      dadosTabela.push({
        loja: "Total",
        meta: totalMeta,
        realizada: totalRealizada,
        porcentagem: totalPorcentagem,
        diasUteis: diasUteisGeral,
        diasFalta: diasRestantesGeral,
        dMensal: deficitMensal,
        dDiario: deficitDiario,
        projecao: projecaoFinal,
        representatividade: 100,
        coluna2: true,
      });

      let porcentagemAbaixoDaMeta =
        ((totalRealizada - totalMeta) / totalMeta) * 100;
      let porcentagemProjetadoAbaixoDaMeta =
        ((projecaoFinal - totalMeta) / totalMeta) * 100;
      let corAbaixoDaMeta = "#fff";

      if (porcentagemAbaixoDaMeta < 0) {
        corAbaixoDaMeta = "#c4302b";
      } else if (porcentagemAbaixoDaMeta >= 0 && porcentagemAbaixoDaMeta < 15) {
        corAbaixoDaMeta = "#00b14f";
      } else if (
        porcentagemAbaixoDaMeta >= 15 &&
        porcentagemAbaixoDaMeta < 30
      ) {
        corAbaixoDaMeta = "#FFD700";
      } else if (porcentagemAbaixoDaMeta >= 30) {
        corAbaixoDaMeta = "#6395ec";
      }
      let corProjetadoAbaixoDaMeta = "#fff";

      if (porcentagemProjetadoAbaixoDaMeta < 0) {
        corProjetadoAbaixoDaMeta = "#c4302b";
      } else if (
        porcentagemProjetadoAbaixoDaMeta >= 0 &&
        porcentagemProjetadoAbaixoDaMeta < 15
      ) {
        corProjetadoAbaixoDaMeta = "#00b14f";
      } else if (
        porcentagemProjetadoAbaixoDaMeta >= 15 &&
        porcentagemProjetadoAbaixoDaMeta < 30
      ) {
        corProjetadoAbaixoDaMeta = "#FFD700";
      } else if (porcentagemProjetadoAbaixoDaMeta >= 30) {
        corProjetadoAbaixoDaMeta = "#6395ec";
      }
      dadosTabela.push({
        loja: "",
        meta: "",
        cor: corAbaixoDaMeta,
        corProjetado: corProjetadoAbaixoDaMeta,
        realizada: porcentagemAbaixoDaMeta,
        porcentagem: "",
        diasUteis: "",
        diasFalta: "",
        dMensal: "",
        dDiario: "",
        projecao: porcentagemProjetadoAbaixoDaMeta,
        representatividade: "",
        coluna3: true,
      });
      descontoParaGrafico = descontoParaGrafico.sort((a, b) => {
        return b.valor - a.valor;
      });
      let descontoParaGraficoTemp = [];
      let labelsChartDesconto = [];
      let labelsChartDescontoCod = [];
      descontoParaGrafico.map((a) => {
        descontoParaGraficoTemp.push(a.valor);
        labelsChartDesconto.push(a.loja);
        labelsChartDescontoCod.push(a.codLoja);
      });
      setDataChartBarFromApi(metaSugerida);
      setDataMetaOuro(metaOuroSugerida);
      setDataMetaDiamante(metaDiamanteSugerida);
      setDataChartLineFromApi(metaRealizada);
      setDataTableFromApi(dadosTabela);
      setlabelsChart(labelNomes);
      setDataChartProjetadaFromApi(projetada);
      setDataDescontoAplicadoFromApi(desconto);
      setlabelsChartCod(labelCod);
      setDataDescontoParaGrafico(descontoParaGraficoTemp);
      setlabelsChartDesconto(labelsChartDesconto);
      setlabelsChartDescontoCod(labelsChartDescontoCod);
      // setDataDescontoParaGrafico(descontoParaGrafico);
      setDadosDisponiveis(true);
      setCarregando(false);
      setIsLoading(false);
    }
  }

  function selecionarPeriodo(item) {
    setPeriodoInputInicio(false);
    setPeriodoInputFim(false);
    setPeriodoReferencia(item);
  }

  const dataChart = {
    labels: labelsChart,
    datasets: [
      {
        type: "line",
        label: "Projetada",
        borderColor: "rgb(00, 00, 00)",
        borderWidth: 2,
        fill: false,
        data: dataChartProjetadaFromApi,
        tension: 0,
        borderDash: [10, 5],
        clip: true,
        clamp: true,
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          display: "auto",
          color: "#000",
          align: (context) => {
            if (
              dataChartBarFromApi[context.dataIndex] <
              dataChartProjetadaFromApi[context.dataIndex]
            ) {
              return "-90";
            } else {
              return "90";
            }
          },
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        },
      },
      {
        type: "line",
        label: "Meta",
        borderColor: "#c4302b",
        borderWidth: 2,
        fill: false,
        data: dataChartBarFromApi,
        tension: 0,
        showLine: false,
        clip: true,
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          display: true,
          color: "#c4302b",
          align: (context) => {
            if (
              dataChartBarFromApi[context.dataIndex] <
              dataChartProjetadaFromApi[context.dataIndex]
            ) {
              return "90";
            } else {
              return "-90";
            }
          },
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
        },
      },
      {
        type: "line",
        label: "Diamante",
        borderColor: "#6395ec",
        borderWidth: 5,
        fill: false,
        data: dataMetaDiamante,
        tension: 0,
        showLine: false,
        clip: true,
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          display: false,
          color: "#6395ec",
          align: (context) => {
            if (
              dataChartBarFromApi[context.dataIndex] <
              dataChartProjetadaFromApi[context.dataIndex]
            ) {
              return "90";
            } else {
              return "-90";
            }
          },
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
        },
      },
      {
        type: "line",
        label: "Ouro",
        borderColor: "#FFD700",
        borderWidth: 5,
        fill: false,
        data: dataMetaOuro,
        tension: 0,
        showLine: false,
        clip: true,
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          display: false,
          color: "#FFD700",
          align: (context) => {
            if (
              dataChartBarFromApi[context.dataIndex] <
              dataChartProjetadaFromApi[context.dataIndex]
            ) {
              return "90";
            } else {
              return "-90";
            }
          },
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
        },
      },
      {
        type: "bar",
        label: "Realizada",
        backgroundColor: "rgba(54, 162, 235, 1)",
        data: dataChartLineFromApi,
        borderColor: "transparent",
        borderWidth: 2,
        xAxisID: "B",
        datalabels: {
          anchor: "start",
          display: true,
          color: "rgba(54, 162, 235, 1)",
          align: "start",
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
        },
      },
      {
        type: "bar",
        label: "Desconto",
        backgroundColor: "rgba(255, 102, 0, 0.3)",
        borderColor: "rgba(255, 102, 0, 0.3)",
        borderWidth: 1,
        borderDash: [10, 5],
        data: dataDescontoAplicadoFromApi,
        xAxisID: "B",
        datalabels: {
          anchor: "end",
          display: "auto",
          color: "rgba(255, 255, 255, 1)",
          align: "start",
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 12,
              },
              padding: 0,
            },
          },
          formatter: function () {
            return "";
          },
        },
      },
    ],
  };

  const dataChartDesconto = {
    labels: labelsChartDesconto,
    datasets: [
      {
        type: "bar",
        label: "Desconto",
        backgroundColor: "rgba(255, 102, 0, 0.3)",
        borderColor: "rgba(255, 102, 0, 0.3)",
        borderWidth: 1,
        borderDash: [10, 5],
        data: dataDescontoParaGrafico,
        datalabels: {
          anchor: "start",
          display: "auto",
          color: "rgba(000, 000, 000, 1)",
          align: "start",
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 12,
              },
              padding: 0,
            },
          },
          formatter: function (value, context) {
            // let valorDesconto = dataDescontoAplicadoFromApi[context.dataIndex] - dataChartLineFromApi[context.dataIndex];
            value = parseFloat(value) / 1000;
            return (
              parseFloat(value).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + "K"
            );
          },
        },
      },
      {
        type: "line",
        label: "DescontoPorcentagem",
        backgroundColor: "rgba(255, 102, 0, 0.3)",
        borderColor: "rgba(255, 102, 0, 0.3)",
        borderWidth: 0,
        tension: 0,
        showLine: false,
        borderDash: [0, 0],
        data: dataDescontoParaGrafico,
        fill: false,
        datalabels: {
          anchor: "end",
          display: "auto",
          color: "rgba(000, 000, 000, 1)",
          align: "end",
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 12,
              },
              padding: 0,
            },
          },
          formatter: function (value, context) {
            let lojaAtual = labelsChartDescontoCod[context.dataIndex];
            let indexLojaGraficoPrincipal;
            labelsChartCod.map((a, i) => {
              if (a == lojaAtual) {
                indexLojaGraficoPrincipal = i;
              }
            });

            let totalVenda = parseFloat(
              dataChartLineFromApi[indexLojaGraficoPrincipal]
            );

            // value = dataDescontoParaGrafico[context.dataIndex] - dataChartLineFromApi[context.dataIndex];
            let valorDescontoPorcentagem =
              totalVenda + value > 0 ? (value / (totalVenda + value)) * 100 : 0;
            return formatarNumero(valorDescontoPorcentagem, false) + "%";
          },
        },
      },
    ],
  };

  const optionsChartBarMetas = {
    maintainAspectRatio: !isSm ? false : true,
    responsive: !isSm ? false : true,
    legend: {
      position: "bottom",
      reverse: true,
      labels: {
        filter: function (item, chart) {
          return (
            !item.text.includes("Desconto") &&
            !item.text.includes("Ouro") &&
            !item.text.includes("Diamante")
          );
        },
      },
    },
    layout: {
      padding: {
        top: 30,
        right: 50,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          id: "A",
          ticks: {
            padding: 25,
            display: true,
          },
        },
        {
          id: "B",
          stacked: true,
          ticks: {
            padding: 25,
            display: false,
          },
          offset: true,
          offsetGridLines: false,
        },
      ],
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        title: (tooltipItem, data) => {
          tooltipItem = tooltipItem[0];
          if (tooltipItem.datasetIndex == 0) {
            return "Valor Projetado Para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex == 1) {
            return "Meta Para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex == 2) {
            return "Meta Diamante para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex == 3) {
            return "Meta Ouro para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex == 4) {
            return "Valor Realizado Por " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex == 5) {
            let valorDesconto = formatarNumero(
              dataDescontoAplicadoFromApi[tooltipItem.index]
            );
            return "Total sem desconto " + valorDesconto;
          }
        },
        label: (tooltipItem, data) => {
          if (tooltipItem.datasetIndex != 5) {
            return (
              "Valor Total: " + formatarNumero(parseFloat(tooltipItem.value))
            );
          } else {
            let valorDesconto =
              dataDescontoAplicadoFromApi[tooltipItem.index] -
              dataChartLineFromApi[tooltipItem.index];
            return "Valor do Desconto: " + formatarNumero(valorDesconto);
          }
        },
        footer: (tooltipItem, data) => {
          tooltipItem = tooltipItem[0];
          if (tooltipItem.datasetIndex == 5) {
            let valorDesconto =
              dataDescontoAplicadoFromApi[tooltipItem.index] -
              dataChartLineFromApi[tooltipItem.index];
            let valorDescontoPorcentagem =
              (valorDesconto / dataDescontoAplicadoFromApi[tooltipItem.index]) *
              100;
            return (
              "Porcentagem " +
              formatarNumero(valorDescontoPorcentagem, false) +
              "%"
            );
          } else if (
            tooltipItem.datasetIndex === 3 ||
            tooltipItem.datasetIndex === 2
          ) {
            let totalRealizado = dataChartLineFromApi[tooltipItem.index];
            return (
              "Valor realizado " + formatarNumero(parseFloat(totalRealizado))
            );
          }
        },
      },
    },
    plugins: {
      datalabels: {
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
        formatter: function (value, context) {
          return parseFloat(value).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
    },
  };

  const optionsChartBarDesconto = {
    maintainAspectRatio: !isSm ? false : true,
    responsive: !isSm ? false : true,
    legend: {
      position: "bottom",
      reverse: true,
      labels: {
        filter: function (item, chart) {
          return (
            !item.text.includes("DescontoPorcentagem") &&
            !item.text.includes("Ouro") &&
            !item.text.includes("Diamante")
          );
        },
      },
    },
    layout: {
      padding: {
        top: 30,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            display: false,
          },
        },
      ],
      xAxes: [
        {
          id: "A",
          ticks: {
            padding: 25,
            display: true,
          },
        },
      ],
    },
    tooltips: {
      displayColors: false,
    },
    plugins: {
      datalabels: {
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
        formatter: function (value, context) {
          if (!isNaN(parseFloat(value))) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          } else {
            return parseFloat(0).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          }
        },
      },
    },
  };

  function iniciarFiltroPorPeriodo() {
    setPeriodoInputInicio(periodoInputInicioAux);
    setPeriodoInputFim(periodoInputFimAux);
  }

  const calculateWidth = useCallback(async (dados) => {
    const x = dados.labels;

    let length = x.length;
    if (length) {
      if (length * 110 < width) {
        return width;
      }
      return length * 110;
    }

    return width;
  }, []);

  return (
    <ContainerTudo
      className={`${isApp ? "container-webview-rn container-app" : ""}`}
    >
      <ModalMetasDirecinarPagVendedor show={modal} handleClose={handleClose} />

      {PermissionsPage("metaVendaGeral") && (
        <Container
          className={`${isApp ? "container-metas-app" : "container-metas"}`}
        >
          <div className="container-conteudo">
            {!isApp && (
              <Box mx={[0, 2]}>
                <Title title="Metas Gerais" />
              </Box>
            )}
            {carregando ? (
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"90vh"}
              >
                <LoadingTable isLoading={carregando} />
              </Box>
            ) : (
              <div className="container-metas">
                {!isApp && (
                  <div className="container-lojas-disponiveis">
                    <Box
                      mx={[0, 2]}
                      display={"flex"}
                      flexDirection={["column", "row"]}
                      width={"100%"}
                    >
                      <Box>
                        <Box
                          paddingX={"1rem"}
                          py={".5rem"}
                          component={Paper}
                          elevation={1}
                        >
                          <Box display={"grid"} gap={2}>
                            <ContainerInputPeriodo>
                              <div>
                                <p>De</p>
                                <input
                                  type="date"
                                  value={periodoInputInicioAux}
                                  min={periodoMinimo}
                                  max={periodoMaximo}
                                  onChange={(e) => {
                                    setPeriodoInputInicioAux(e.target.value);
                                  }}
                                  className="filtro-input-periodo"
                                />
                              </div>
                              <div>
                                <p>Até</p>
                                <input
                                  type="date"
                                  value={periodoInputFimAux}
                                  min={periodoMinimo}
                                  max={periodoMaximo}
                                  onChange={(e) => {
                                    setPeriodoInputFimAux(e.target.value);
                                  }}
                                  className="filtro-input-periodo"
                                />
                              </div>
                            </ContainerInputPeriodo>

                            <div className="container-botao-filtrar container-botao-filtrar-metas">
                              <button
                                className="botao-filtrar-input-data botao-filtrar-input-data-metas"
                                onClick={() => iniciarFiltroPorPeriodo()}
                              >
                                Filtrar
                              </button>
                            </div>
                          </Box>
                        </Box>
                        <Box
                          mt={1}
                          display={"flex"}
                          flexDirection={"column"}
                          width={"100%"}
                        >
                          <SelectFilterMonth
                            periodoReferencia={periodoReferencia}
                            periodosDisponiveis={periodosDisponiveis}
                            selecionarPeriodo={selecionarPeriodo}
                          />
                        </Box>
                      </Box>
                      <Box width={"100%"} pl={[0, 2]} pr={[0, 4]}>
                        <MultiSelectFilterBases
                          basesDisponiveis={basesDisponiveis}
                          basesSelecionadas={basesSelecionadas}
                          onChange={handleBase}
                        />
                        <Typography variant="caption" component={"span"}>
                          *Atenção: Quando nenhuma base estiver selecionada,
                          todas as bases serão retornadas.*
                        </Typography>

                        {isSm && (
                          <>
                            <MultiSelectFilterLoja
                              labelsTable={labelsTable}
                              infoSelect={empresasSelecionadas}
                              functionEmpresa={filtrarEmpresa}
                              direcionarParaMetasPorVendedorasLojaEspecifica={
                                direcionarParaMetasPorVendedorasLojaEspecifica
                              }
                            />
                            <Typography variant="caption" component={"span"}>
                              *Atenção: Quando nenhuma loja estiver selecionada,
                              todas as lojas serão retornadas.*
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                  </div>
                )}
                <>
                  <div className="container-metas-interno-superior-metas">
                    {dadosDisponiveis && (
                      <Container>
                        <>
                          <ReactFullscreen>
                            {({ ref, onRequest, onExit }) => (
                              <div
                                ref={ref}
                                style={{
                                  backgroundColor: "#fff",
                                  overflowX: "hidden",
                                }}
                              >
                                <Box
                                  component={Paper}
                                  elevation={1}
                                  display={"flex"}
                                  alignItems={"center"}
                                  flexDirection={"column"}
                                  my={2}
                                >
                                  {isSm && (
                                    <Box
                                      padding={0}
                                      maxWidth={["100vw"]}
                                      minWidth={["1000px"]}
                                      height={["auto"]}
                                    >
                                      <Bar
                                        height={!isSm ? 500 : null}
                                        width={
                                          !isSm
                                            ? calculateWidth(dataChart)
                                            : null
                                        }
                                        data={dataChart}
                                        options={optionsChartBarMetas}
                                      />
                                    </Box>
                                  )}
                                  {!isSm && (
                                    <Bar1
                                      dataChart={dataChart}
                                      dataChartLineFromApi={
                                        dataChartLineFromApi
                                      }
                                      dataDescontoAplicadoFromApi={
                                        dataDescontoAplicadoFromApi
                                      }
                                      lojas={empresasSelecionadas}
                                    />
                                  )}
                                  {isSm && (
                                    <Box
                                      px={2}
                                      justifyContent={"flex-end"}
                                      display={"flex"}
                                      gap={1}
                                      width={"100%"}
                                      pb={2}
                                    >
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => onRequest()}
                                      >
                                        Tela Cheia
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => onExit()}
                                      >
                                        Sair
                                      </Button>
                                    </Box>
                                  )}
                                </Box>
                              </div>
                            )}
                          </ReactFullscreen>

                          <div className="wrapper wpp-marfin">
                            <Row>
                              <Col className="colMetasGerais1">
                                <ReactSpeedometer
                                  width={
                                    document.body.offsetWidth < 500
                                      ? document.body.offsetWidth - 90
                                      : 400
                                  }
                                  height={255}
                                  labelFontSize={isSm ? "14px" : "10px"}
                                  forceRender={true}
                                  value={realizadoTotal}
                                  segments={4}
                                  minValue={0}
                                  maxValue={
                                    metaTotal > 0
                                      ? metaTotal + metaTotal * 0.6
                                      : realizadoTotal + realizadoTotal * 0.5
                                  }
                                  customSegmentStops={[
                                    0,
                                    metaTotal,
                                    metaTotal + metaTotal * 0.15,
                                    metaTotal + metaTotal * 0.3,
                                    metaTotal > 0
                                      ? metaTotal + metaTotal * 0.6
                                      : realizadoTotal + realizadoTotal * 0.5,
                                  ]}
                                  segmentColors={[
                                    "#c4302b",
                                    "#00b14f",
                                    "#FFD700",
                                    "#6395ec",
                                  ]}
                                  currentValueText={realizadoTotal.toLocaleString(
                                    "pt-br",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      style: "currency",
                                      currency: "brl",
                                    }
                                  )}
                                  ringWidth={47}
                                  valueFormat={
                                    metaTotal > 1000000 ? ".3s" : ".5s"
                                  }
                                  needleTransitionDuration={3333}
                                  needleTransition="easeElastic"
                                  needleColor={"gray"}
                                  textColor={"#000"}
                                />
                              </Col>
                              <Col
                                xsOffset={6}
                                xs={12}
                                md={6}
                                className={`colMetasGerais2`}
                              >
                                <Box
                                  padding={0}
                                  maxWidth={"100vw"}
                                  sx={{ overflowX: ["scroll", "auto"] }}
                                  height={["60vh", "auto"]}
                                >
                                  <Bar
                                    height={!isSm ? 500 : null}
                                    width={
                                      !isSm
                                        ? calculateWidth(dataChart)
                                        : undefined
                                    }
                                    data={dataChartDesconto}
                                    options={optionsChartBarDesconto}
                                  />
                                </Box>
                              </Col>
                            </Row>
                          </div>
                        </>
                      </Container>
                    )}
                  </div>
                  <LegandaMetas />

                  {dadosDisponiveis ? (
                    <Container>
                      <WraperContainer>
                        <TabelaNext
                          dadosParaTabela={dataTableFromApi}
                          colunas={colunasMetasGerais2()}
                        />
                      </WraperContainer>
                    </Container>
                  ) : (
                    <></>
                  )}
                </>
              </div>
            )}
          </div>
        </Container>
      )}
    </ContainerTudo>
  );
}

export default Metas;
