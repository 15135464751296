import React, { useEffect, useState } from "react";

import "chartjs-plugin-datalabels";
import "react-data-table-component-extensions/dist/index.css";
import { Box, Divider, Stack, Tab, Tabs } from "@mui/material";
import { PermissionsPage } from "../../../util/permissionsPage";
import { useUserData } from "../../../hooks/useUserData";
import dayjs from "dayjs";
import Title from "../../../components/title";
import ReactDatePicker, { registerLocale } from "react-datepicker";

import pt from "date-fns/locale/pt-BR";
import Select from "react-select";

import planejamento from "../../../services/planejamento";
import { SalesForecast } from "../../../components/componentsPlanejamento/SalesForecast";
import { FinancialPlanningForecast } from "../../../components/componentsPlanejamento/FinancialPlanningForecast/FinancialPlanningForecast";
import ErrorToastHandler from "../../../util/ErrorToastHandler";
import { Realized } from "../../../components/componentsPlanejamento/Realized";

const defaultValuesData = {
  colunas: [],
  dadosPrevisao: [],
  dadosPlanejamento: [],
};
const defaultValuesDataRealized = {
  colunas: [],
  dados: [],
};
registerLocale("es", pt);
function Planejamento() {
  const currencyDay = dayjs().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRealized, setIsLoadingRealized] = useState(true);
  const [dataPage, setDataPage] = useState(defaultValuesData);
  const [dataPageRealized, setDataPageRealized] = useState(
    defaultValuesDataRealized
  );
  const defaultValues = {
    periodo: currencyDay,
  };
  const { userData, basesUserAccess } = useUserData();

  const accessLevel = userData?.nivelAcesso || [];
  const [bases, setBases] = useState({});
  const [paramsConfig, setParamsConfig] = useState({});
  const optionsBase =
    basesUserAccess?.map((item) => ({
      value: item.codBase,
      label: item.base,
    })) || [];

  const onChangeDate = (date) => {
    setStartDate(date);
    setParamsConfig((old) => ({
      ...old,
      periodo: dayjs(date).format("YYYY-MM-DD"),
    }));
  };

  const buscarNivelAcesso = () => {
    if (!!accessLevel && !!basesUserAccess.length > 0) {
      const fristBase = basesUserAccess.find((item) => item.codBase);
      setBases({
        value: fristBase.codBase,
        label: "Base " + fristBase.codBase,
      });
    }
    setParamsConfig(defaultValues);
  };
  useEffect(() => {
    buscarNivelAcesso();
  }, []);
  const getDataPage = async () => {
    setIsLoading(true);
    try {
      const response = await planejamento.get(bases.value, paramsConfig);
      setDataPage(response.data);
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
      setDataPage(defaultValuesData);
    }
    setIsLoading(false);
  };
  const getDataPageRealized = async () => {
    setIsLoadingRealized(true);
    try {
      const response = await planejamento.getRealized(
        bases.value,
        paramsConfig
      );

      setDataPageRealized(response.data);
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
      setDataPageRealized(defaultValuesDataRealized);
    }

    setIsLoadingRealized(false);
  };

  useEffect(() => {
    if (!!bases?.value) {
      getDataPageRealized();
      getDataPage();
    }
  }, [paramsConfig]);

  const onChangeBase = (data) => {
    setBases(data);
    setParamsConfig((old) => ({ ...old, base: data.value }));
  };
  return (
    <>
      {PermissionsPage("planejamento") && (
        <>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pr={2}
          >
            <Title title="Planejamento" />
            <Stack>
              <ReactDatePicker
                value={startDate}
                disabled={isLoading || isLoadingRealized}
                locale={pt}
                onChange={onChangeDate}
                selected={startDate}
                dateFormat="dd/MM/yyyy"
              />
            </Stack>
          </Stack>
          <Box
            sx={{
              mx: 2,
              mb: 2,
            }}
          >
            <Box>
              <Select
                placeholder="Selecione uma base"
                value={bases}
                options={optionsBase}
                onChange={onChangeBase}
                isLoading={isLoading || isLoadingRealized}
                isDisabled={isLoading || isLoadingRealized}
              />
            </Box>
            <Stack gap={2}>
              <SalesForecast dataPage={dataPage} isLoading={isLoading} />
              <FinancialPlanningForecast
                dataPage={dataPage}
                isLoading={isLoading}
              />{" "}
              <Realized
                isLoading={isLoadingRealized}
                dataPage={dataPageRealized}
              />
            </Stack>
          </Box>
        </>
      )}
    </>
  );
}

export default Planejamento;
