import Select from "react-select";
import makeAnimated from "react-select/animated";
import { debounce } from "throttle-debounce";

import "../styles.css";
export const MultiSelectBase = ({
  bases = [],
  isLoading = false,
  paramsConfig,
  onChange,
}) => {
  const animatedComponents = makeAnimated();

  const options = bases.map((item) => ({
    value: item.codBase,
    label: item.base,
  }));

  const defaultValue = options.find(
    (option) => option.value === paramsConfig?.base[0]
  );
  const value =
    paramsConfig?.base?.map((item) => ({
      value: item,
      label: `Base ${item}`,
    })) || [];

  const debounceChange = debounce(500, onChange);
  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: state.isDisabled ? " whitesmoke" : "white",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#ff7aae",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#FFF",
      fontWeight: "bold",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "white",
      ":hover": {
        backgroundColor: "#ff0d6c",
        color: "white",
      },
    }),
  };
  return (
    <div>
      <strong className="font-weight-bold">Base</strong>
      {options.length > 0 && (
        <Select
          components={animatedComponents}
          isMulti
          defaultValue={[defaultValue]}
          name="bases"
          placeholder="Selecione um base"
          isDisabled={isLoading}
          value={value}
          options={options}
          onChange={(e) => debounceChange(e, "base")}
          classNames={{
            control: (state) => state.isFocused && "borderIsFocus",
          }}
          styles={colourStyles}
        />
      )}
    </div>
  );
};
