import { Container } from "react-bootstrap";

import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { useParams, useHistory } from "react-router-dom";

import { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import toast from "react-hot-toast";

import { LoadingTable } from "../../../../components/Loading/LoadingTable";
import { PermissionsPage } from "../../../../util/permissionsPage";
import Title from "../../../../components/title";
import { api } from "../../../../services/api.v2";
import ReactInputMask from "react-input-mask";
import { StyledInputNumber } from "../../components/StyledInputNumber";
import CloseIcon from "@mui/icons-material/Close";
import { InputAutoComplete } from "../../components/InputAutoComplete";
import { InputHelpTooltip } from "../../components/InputHelpTooltip";
import { HelpOutlineOutlined } from "@mui/icons-material";
import { formatterNumberPhoneMask } from "../../utils/formatterNumberPhoneMask";
export const CreateStore = () => {
  const history = useHistory();
  const params = useParams();
  const [values, setValues] = useState([]);
  const {
    formState: { errors, isSubmitting },
    register,
    control,
    reset,
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const [optionsCity, setOptionsCity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getInfoStore = async () => {
    setIsLoading(true);
    if (optionsCity.length === 0) {
      return;
    }
    try {
      const response = await api.get(`/register/stores/info/${params.id}`);
      let numeroWhatsapp = formatterNumberPhoneMask(
        response.data.numeroWhatsapp
      );

      let idLocalidade, cidade, cidadeRead;

      if (isNaN(response.data.idLocalidade)) {
        idLocalidade = "";
        cidade = "other";
        cidadeRead = response.data.cidade;
      } else {
        idLocalidade = response.data.idLocalidade;
        cidadeRead = undefined;
        cidade = optionsCity.find(
          (item) => +item.id === +response.data.idLocalidade
        ).id;
      }
      const whatsGerenteLoja = formatterNumberPhoneMask(
        response.data.whatsGerenteLoja
      );
      const codEstoqueDisponivelParaLoja = !!response.data.config
        .codEstoqueDisponivelParaLoja
        ? response.data.config.codEstoqueDisponivelParaLoja
            .split(",")
            .map(Number)
        : undefined;
      setValues(codEstoqueDisponivelParaLoja);
      reset({
        ...response.data,
        idLocalidade,
        cidade,
        cidadeRead,
        numeroWhatsapp,
        whatsGerenteLoja,
        config: {
          ...response.data.config,
        },
      });
    } catch (error) {
      console.log(error);
      toast.error("Loja não existe");
      goBackList();
    } finally {
      setIsLoading(false);
    }
  };

  const goBackList = () => history.push(`/cadastro/lojas`);

  const onSubmit = async (data) => {
    try {
      const cidade =
        data.cidade === "other"
          ? data.cidadeRead
          : optionsCity.find((item) => +item.id === +data.cidade).name;
      const idLocalidade = data.cidade === "other" ? "" : data.cidade;
      setIsLoading(true);
      const codEstoqueDisponivelParaLoja = values?.join(",") || "";
      const whatsGerenteLoja = !!data.config.whatsGerenteLoja
        ? data.config.whatsGerenteLoja.replace(/[^0-9]/g, "")
        : "";
      const dataSend = {
        ...data,
        numeroWhatsapp: !!data.numeroWhatsapp
          ? data.numeroWhatsapp.replace(/[^0-9]/g, "")
          : "",
        cidade,
        idLocalidade,
        config: {
          ...data.config,
          codEstoqueDisponivelParaLoja,
          whatsGerenteLoja,
        },
      };
      if (params?.id) {
        await api.patch(`/register/stores/update/${params.id}`, dataSend);
      } else {
        await api.post("/register/stores/create", dataSend);
      }

      toast.success("Informações salvas com sucesso");
      goBackList();
    } catch (error) {
      if (
        (error?.response && error?.response?.status === 400) ||
        error?.response?.status === 404
      ) {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      } else {
        toast.error("Erro ao tentar processar seus dados");
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseInputCityRead = () => {
    setValue("cidadeRead", undefined);
    setValue("cidade", "");
  };

  const getOptionsCity = async () => {
    const response = await api.get("/cities");
    if (response.status === 200) {
      setOptionsCity(response.data);
    }
  };
  useEffect(() => {
    getOptionsCity();
  }, []);
  useEffect(() => {
    if (params?.id) {
      getInfoStore();
    } else {
      setIsLoading(false);
    }
  }, [params?.id, optionsCity]);

  return (
    <>
      {isLoading && (
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"90vh"}
        >
          <LoadingTable />
        </Box>
      )}
      {!isLoading && PermissionsPage("list_store") && (
        <>
          <Box ml={2}>
            <Title title="Cadastar Informações da Loja" />
          </Box>

          <Box
            sx={{
              px: 3,
              paddingBottom: 3,
            }}
          >
            <>
              {!isLoading && (
                <Box display={"flex"} gap={4} width={"100%"}>
                  <Box maxWidth={"340px"}>
                    <Typography variant="h6">
                      Por favor, preencha todos os campos do formulário com as
                      informações da loja.
                    </Typography>
                    <Typography pt={2} variant="body1">
                      É imprescindível fornecer todas as informações solicitadas
                      para garantir o correto funcionamento do BI e o sucesso
                      das campanhas.
                    </Typography>
                  </Box>
                  <Paper
                    sx={{
                      p: 2,
                      flex: 1,
                    }}
                    variant="outlined"
                    elevation={3}
                  >
                    <Stack
                      spacing={2}
                      component={"form"}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Typography>Configurações gerais</Typography>
                      <Stack direction="row" spacing={2}>
                        <FormControl sx={{ flex: 1 }}>
                          <StyledInputNumber
                            {...register("base", {
                              required: true,
                              pattern: /^[0-9]*$/,
                            })}
                            id="base"
                            type="number"
                            label="Numero da Base"
                            variant="outlined"
                            size="small"
                            disabled={isSubmitting}
                            error={!!errors.base}
                          />
                        </FormControl>
                        <FormControl sx={{ flex: 1 }}>
                          <StyledInputNumber
                            {...register("numero_loja", {
                              required: true,
                              pattern: /^[0-9]*$/,
                            })}
                            id="numero_loja"
                            type="number"
                            label="Numero da loja"
                            variant="outlined"
                            size="small"
                            disabled={isSubmitting}
                            error={!!errors.numero_loja}
                          />
                        </FormControl>
                        <FormControl sx={{ flex: 1 }}>
                          <TextField
                            {...register("sigla")}
                            id="sigla"
                            label="Sigla da loja"
                            variant="outlined"
                            size="small"
                            disabled={isSubmitting}
                            error={!!errors.sigla}
                            helperText="Ex: MBA"
                          />
                        </FormControl>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <FormControl
                          sx={{
                            flex: 1,
                          }}
                        >
                          <TextField
                            {...register("localidade")}
                            id="localidade"
                            label="Localidade"
                            variant="outlined"
                            size="small"
                            disabled={isSubmitting}
                            error={!!errors.localidade}
                            helperText="Ex: Marabá / Av. Antonio Maia"
                          />
                        </FormControl>

                        <FormControl
                          sx={{
                            maxWidth: "180px",
                            display:
                              watch("cidade") === "other" ? "block" : "none",
                          }}
                        >
                          <TextField
                            {...register("cidadeRead")}
                            id="grouped-native-select"
                            label="Cidade"
                            variant="outlined"
                            size="small"
                            disabled={isSubmitting}
                            error={!!errors.cidadeRead}
                            helperText="Ex: Marabá"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleCloseInputCityRead}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              style: {
                                paddingRight: "0px",
                              },
                            }}
                          />
                        </FormControl>

                        <FormControl
                          sx={{
                            maxWidth: "180px",
                            display:
                              watch("cidade") !== "other" ? "block" : "none",
                          }}
                        >
                          {watch("cidade") !== "other" && (
                            <InputLabel
                              size="small"
                              htmlFor="grouped-native-select"
                            >
                              Cidade
                            </InputLabel>
                          )}

                          <Controller
                            name="cidade"
                            control={control}
                            render={({ field }) => (
                              <>
                                <Select
                                  {...field}
                                  id="grouped-native-select"
                                  label="Cidade"
                                  native
                                  disabled={isSubmitting}
                                  error={!!errors.cidade}
                                  size="small"
                                  autoWidth
                                  sx={{ maxWidth: "180px" }}
                                >
                                  <option aria-label="None" value="" />
                                  {optionsCity.map((item) => (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                  <option value={"other"}>
                                    Outra cidade...
                                  </option>
                                </Select>
                              </>
                            )}
                          />
                        </FormControl>
                      </Stack>
                      <Stack direction="row" spacing={2} pb={2}>
                        <FormControl
                          sx={{
                            maxWidth: "180px",
                          }}
                        >
                          <Controller
                            control={control}
                            name="numeroWhatsapp"
                            rules={{ required: true }}
                            render={({ field }) => (
                              <ReactInputMask
                                mask="(99) 99999-9999"
                                maskChar={null}
                                value={field.value}
                                onChange={field.onChange}
                              >
                                {(inputProps) => (
                                  <TextField
                                    {...inputProps}
                                    id="numeroWhatsapp"
                                    label="Numero WhatsApp"
                                    variant="outlined"
                                    size="small"
                                    InputProps={{
                                      ...inputProps.InputProps,
                                      disabled: false,
                                    }}
                                    error={!!errors.numeroWhatsapp}
                                    helperText="(94) 99919-9191"
                                  />
                                )}
                              </ReactInputMask>
                            )}
                          />
                        </FormControl>
                        <FormControl
                          sx={{
                            maxWidth: "180px",
                          }}
                        >
                          <TextField
                            {...register("linkIntagram")}
                            id="linkIntagram"
                            label="Link do Instagram da loja"
                            variant="outlined"
                            size="small"
                            disabled={isSubmitting}
                            error={!!errors.linkIntagram}
                          />
                        </FormControl>

                        <FormControl
                          sx={{
                            flex: 1,
                          }}
                        >
                          <Controller
                            control={control}
                            name="cnpj"
                            render={({ field }) => (
                              <ReactInputMask
                                mask="99.999.999/9999-99"
                                maskChar={null}
                                value={field.value}
                                onChange={field.onChange}
                              >
                                {(inputProps) => (
                                  <TextField
                                    {...inputProps}
                                    id="cnpj"
                                    label="CNPJ"
                                    variant="outlined"
                                    size="small"
                                    InputProps={{
                                      ...inputProps.InputProps,
                                      disabled: false, // Defina a prop disabled como necessário
                                    }}
                                    error={!!errors.cnpj}
                                    helperText="Ex: 05.686.158/0001-80"
                                  />
                                )}
                              </ReactInputMask>
                            )}
                          />
                        </FormControl>
                      </Stack>
                      <Divider />

                      <Typography>Configurações adicionais</Typography>

                      <Grid container rowSpacing={1}>
                        <Grid item xs={6} pr={2}>
                          <FormControl sx={{ flex: 1, width: "100%" }}>
                            <Controller
                              name="config.codMinCadastroCliente"
                              control={control}
                              rules={{
                                pattern: /^[0-9]*$/,
                              }}
                              render={({ field }) => (
                                <InputHelpTooltip
                                  {...field}
                                  id="config.codMinCadastroCliente"
                                  type="number"
                                  label="Sequencial inicial de clientes"
                                  variant="outlined"
                                  size="small"
                                  disabled={isSubmitting}
                                  titleTooltip="Sequencial inicial de clientes | Código inicial do cadastro de clientes para essa loja"
                                  helperText={"Ex: 1"}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl sx={{ flex: 1, width: "100%" }}>
                            <Controller
                              name="config.codMaxCadastroCliente"
                              control={control}
                              rules={{
                                pattern: /^[0-9]*$/,
                              }}
                              render={({ field }) => (
                                <InputHelpTooltip
                                  {...field}
                                  id="config.codMaxCadastroCliente"
                                  type="number"
                                  label="Sequencial final de clientes"
                                  variant="outlined"
                                  size="small"
                                  disabled={isSubmitting}
                                  titleTooltip="Sequencial final de clientes | Código final do cadastro de clientes para essa loja"
                                  helperText={"Ex: 1000"}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={6} pr={2}>
                          <FormControl sx={{ flex: 1, width: "100%" }}>
                            <Controller
                              control={control}
                              name="config.whatsGerenteLoja"
                              render={({ field }) => (
                                <ReactInputMask
                                  mask="(99) 99999-9999"
                                  maskChar={null}
                                  value={field.value}
                                  onChange={field.onChange}
                                >
                                  {(inputProps) => (
                                    <TextField
                                      {...inputProps}
                                      id="whatsGerenteLoja"
                                      label="WhatsApp da gerente"
                                      variant="outlined"
                                      size="small"
                                      InputProps={{
                                        ...inputProps.InputProps,

                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <Tooltip
                                              title={
                                                "WhatsApp da gerente | Número que receberá notificações relacionadas a loja, como venda entre lojas e transferências"
                                              }
                                              followCursor
                                            >
                                              <HelpOutlineOutlined />
                                            </Tooltip>
                                          </InputAdornment>
                                        ),

                                        style: {
                                          paddingRight: "8px",
                                        },
                                      }}
                                      helperText="(94) 99919-9191"
                                    />
                                  )}
                                </ReactInputMask>
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl sx={{ flex: 1, width: "100%" }}>
                            <Controller
                              name={`config.codEstoqueDisponivelParaLoja`}
                              rules={{
                                pattern: /^[0-9]*$/,
                              }}
                              control={control}
                              render={({ field }) => (
                                <InputAutoComplete
                                  {...field}
                                  name="Códigos de estoque disponíveis"
                                  values={values}
                                  setValues={setValues}
                                  id="config.codEstoqueDisponivelParaLoja"
                                  titleTooltip={
                                    "Códigos de estoque disponíveis | Estoque.CodLocal que devem ser utilizados para buscar o estoque disponível para a loj"
                                  }
                                  example=""
                                  sx={{
                                    flex: 1,
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        gap={2}
                      >
                        <Button
                          variant="outlined"
                          disabled={isSubmitting}
                          onClick={goBackList}
                          type="button"
                        >
                          Voltar
                        </Button>
                        <Button
                          disabled={isSubmitting}
                          variant="contained"
                          type="submit"
                        >
                          Salvar
                        </Button>
                      </Box>
                    </Stack>
                  </Paper>
                </Box>
              )}
            </>
          </Box>
        </>
      )}
    </>
  );
};
