import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { converterLoja } from "../../util/loja";

export const ModalMetasDirecinarPagVendedor = ({ show, handleClose }) => {
  const history = useHistory();
  const irPLoja = () => {
    return history.push(`/metas/metas-vendedor/?loja=${show?.loja}`);
  };
  return (
    <Modal
      show={show?.active}
      size="sm"
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Redirecionamento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Você deseja ir para Metas por Vendedor da loja{" "}
        <span style={{ color: "#FF0000" }}>
          {" "}
          {`${converterLoja(show?.loja)}`}
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="success" onClick={irPLoja}>
          IR - {`${converterLoja(show?.loja)}`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
