import { useUserData } from "../hooks/useUserData";

export const PermissionsPage = (page) => {
  const { userData } = useUserData();
  
  let paginas = [];

  userData?.menu?.forEach((menu) => {
    menu?.submenus?.forEach((submenu) => {
      submenu?.items.forEach((item) => {
        paginas.push(item.field);
      });
    });
  });
  const hasPermission = paginas.filter((u) => u === page);
  return hasPermission.length > 0 ? true : false;
};
