import {
  Box,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import Title from "../../../../components/title";
import { PermissionsPage } from "../../../../util/permissionsPage";

import { useEffect, useState } from "react";
import { LoadingTable } from "../../../../components/Loading/LoadingTable";

import { NoData } from "../../../../components/NoData/NoData";
import { StyledTableCell } from "../../../franchise/components/StyledTableCell";
import { Rows } from "../components/Rows";
import { api } from "../../../../services/api.v2";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { toast } from "react-hot-toast";
import { pink } from "@mui/material/colors";
import { useHistory } from "react-router-dom";

export const ListProfileIndicate = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [total, setTotal] = useState(0);
  const history = useHistory();

  const handleClick = () => {
    history.push("/cadastro/perfil-indicador/criar");
  };

  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
    search: "",
  });
  const [data, setData] = useState([]);
  const handleChangePage = (event, newPage) => {
    setParams((old) => ({ ...old, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    setParams((old) => ({ ...old, per_page: +event.target.value, page: 1 }));
  };
  const handleDelete = async (id) => {
    try {
      await api.delete(`/register/profile-indicate/delete/${id}`);
      toast.success("Perfil deletado com sucesso");
      const configParams = { ...params };
      setParams(configParams);
    } catch (error) {
      toast.error("Ocorreu um erro ao deletar");
      console.log(error);
    }
  };
  const getData = async () => {
    setIsLoadingData(true);
    try {
      const response = await api.get("/register/profile-indicate", {
        params,
      });

      setData(response.data.data);
      setTotal(response.data.total);
    } catch (error) {
      console.log(error);
      if (
        (error?.response && error?.response?.status === 400) ||
        error?.response?.status === 404
      ) {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      } else {
        toast.error("Erro ao tentar processar seus dados");
      }
    } finally {
      setIsLoadingData(false);
    }
  };
  useEffect(() => {
    getData();
  }, [params]);
  const toggleActive = async (idRow) => {
    const toastId = toast.loading("Carregando");
    try {
      const response = await api.get(
        `register/profile-indicate/toggle-active/${idRow}`
      );

      toast.success(response.data, {
        id: toastId,
      });
      setData((old) =>
        old.map((item) => {
          if (item.id === idRow) {
            item.active = !item.active;
          }
          return item;
        })
      );
    } catch (error) {
      console.log(error);
      if (
        (error?.response && error?.response?.status === 400) ||
        error?.response?.status === 404
      ) {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage, {
          id: toastId,
        });
      } else {
        toast.error("Erro ao tentar processar seus dados", {
          id: toastId,
        });
      }
    }
  };

  return (
    <div className="container-app">
      <Container>
        <div className="container-conteudo">
          <Container className="containerFlexBox">
            <Title title="Lista de Perfis de Inficadores de Franquia" />
          </Container>
          {PermissionsPage("list_profile_indicate") && (
            <>
              <Box
                component={Container}
                marginY={2}
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <Tooltip title="Cadstrar nova base">
                  <IconButton
                    size="small"
                    sx={{ color: pink[400] }}
                    onClick={handleClick}
                  >
                    <AddBoxIcon sx={{ width: 50, height: 50 }} />
                  </IconButton>
                </Tooltip>
              </Box>

              {isLoadingData ? (
                <LoadingTable />
              ) : (
                <>
                  <Container
                    sx={{
                      paddingBottom: "2rem",
                    }}
                  >
                    {data.length <= 0 ? (
                      <Box
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <NoData />
                      </Box>
                    ) : (
                      <Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer>
                          <Table
                            stickyHeader
                            size={"small"}
                            aria-label="collapsible table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell />

                                <StyledTableCell>ID</StyledTableCell>
                                <StyledTableCell>Nome</StyledTableCell>
                                <StyledTableCell>Ativo</StyledTableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {data.length > 0 &&
                                data.map((row) => (
                                  <Rows
                                    handleDelete={handleDelete}
                                    row={row}
                                    toggleActive={toggleActive}
                                  />
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[
                            10,
                            25,
                            50,
                            100,
                            { label: "Todos", value: total },
                          ]}
                          component="div"
                          count={total}
                          rowsPerPage={params.per_page}
                          page={params.page - 1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          labelRowsPerPage={"Linhas por pagina"}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                        />
                      </Paper>
                    )}
                  </Container>
                </>
              )}
            </>
          )}
        </div>
      </Container>
    </div>
  );
};
