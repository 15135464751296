import React from "react";
import { Alert } from "react-bootstrap";

const Alerta = () => {
  return (
    <div className="container-aviso-dados-indisponiveis">
      <Alert variant="danger">
        <Alert.Heading>Por favor aguarde, carregando conteúdo. </Alert.Heading>
        <p> Caso nenhuma informação estiver disponível, atualize a pagina. </p>
        <p>
          {" "}
          Se a falta de informações persistir, entre em contato com a Matriz.{" "}
        </p>
      </Alert>
    </div>
  );
};

export default Alerta;
