import styled from "styled-components"
export const ContainerButtonLink = styled.div`
    margin-top: 1rem;
    margin-left: auto;
    button{
        transition: color 2s;
        padding: 0.4rem 1.5rem;
        border: 0;
    &:hover{
        filter: brightness(0.9);
    }
    }
`