import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatarNumero } from "../../util/loja";
import { useState } from "react";

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
export const SalesForecast = ({ dataPage, isLoading }) => {
  const [openCollapseCollumn, setOpenCollapseCollumn] = useState(false);
  return (
    <Box>
      <Box
        sx={{
          mt: 4,
        }}
        component={Paper}
        variant="outlined"
        bgcolor={"#f0dede"}
      >
        <Typography fontWeight={"500"} textAlign={"center"}>
          Previsão de vendas
        </Typography>
      </Box>
      {isLoading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"70px"}
          component={Paper}
          variant="outlined"
        >
          <CircularProgress color="aprincipal" />
        </Box>
      ) : (
        <>
          {dataPage.colunas?.length === 0 &&
          dataPage.dadosPrevisao?.length === 0 ? (
            <Box
              p={3}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              component={Paper}
              variant="outlined"
            >
              <Typography>Nenhum resultado encontrado</Typography>
            </Box>
          ) : (
            <TableContainer component={Paper} variant="outlined">
              <Table size={"small"} aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    {dataPage.colunas.map((item) => (
                      <TableCell
                        sx={{
                          bgcolor: "rgb(0, 176, 240)",
                          cursor: "pointer",
                        }}
                        onClick={() => setOpenCollapseCollumn((old) => !old)}
                        key={item.id}
                      >
                        {item.id === "formaPagamento" ? (
                          <Stack
                            gap={1}
                            direction={"row"}
                            alignItems={"center"}
                          >
                            {openCollapseCollumn ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )}
                            <Typography>{item.text}</Typography>
                          </Stack>
                        ) : (
                          item.text
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataPage.dadosPrevisao.map(
                    ({ formaPagamento, porcentagem, ...item }) => (
                      <TableRow
                        sx={{
                          display:
                            openCollapseCollumn && !item?.isTotal
                              ? "table-row"
                              : "none",
                        }}
                        key={formaPagamento}
                      >
                        <TableCell
                          sx={{
                            bgcolor: "gray",
                            color: "white",
                            fontWeight: "500",
                          }}
                          component="th"
                          scope="row"
                        >
                          {formaPagamento}
                        </TableCell>
                        <TableCell
                          sx={{
                            bgcolor: "gray",
                            color: "white",
                            fontWeight: "500",
                          }}
                          component="th"
                          scope="row"
                        >
                          {porcentagem} %
                        </TableCell>
                        {Object.keys(item).map((key, index) => {
                          if (!dataPage.colunas[index + 2]?.id) {
                            return <></>;
                          }

                          return (
                            <TableCell key={key} component="th" scope="row">
                              {formatarNumero(
                                item[dataPage.colunas[index + 2]?.id]
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    )
                  )}
                  {dataPage.dadosPrevisao.map(
                    ({
                      fontWeight,
                      formaPagamento,
                      porcentagem,
                      bg,
                      ...row
                    }) => {
                      if (!row?.isTotal) return <></>;
                      delete row.isTotal;
                      delete row.noHide;
                      return (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            bgcolor: bg,
                            fontWeight: fontWeight,
                          }}
                        >
                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                              bgcolor: "#535353",
                              fontWeight: fontWeight,
                              color: "white",
                            }}
                            component="th"
                            scope="row"
                          >
                            {formaPagamento}
                          </TableCell>
                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                              fontWeight: fontWeight,
                              color: "white",
                              bgcolor: "#535353",
                            }}
                            component="th"
                            scope="row"
                          >
                            {porcentagem} %
                          </TableCell>
                          {Object.keys(row).map((key, index) => {
                            return (
                              <TableCell
                                sx={{
                                  whiteSpace: "nowrap",
                                  fontWeight: fontWeight,
                                }}
                                key={key}
                                component="th"
                                scope="row"
                              >
                                {formatarNumero(
                                  row[dataPage.colunas[index + 2].id]
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </Box>
  );
};
