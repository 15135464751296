import styled from "styled-components";
export const Container = styled.aside`
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
  > section {
    max-width: 630px;
  }
  button {
    background: #fff;
    padding: 0.24rem 1rem;
    transition: color 0.2s;
    &:hover {
      filter: brightness(0.9);
    }
  }
  .lojaSelecionada {
    background: #808080;
    color: #fff;
  }
`;
