import { Box, Typography } from "@mui/material";
import Select from "react-select";
import "./styles.css";

export const MultiSelectFilterBases = ({
  basesDisponiveis,
  basesSelecionadas,
  onChange,
  disabled = false,
}) => {
  const options = basesDisponiveis.map((item) => ({
    value: item.codBase,
    label: item.base,
  }));

  const optionsValue = basesSelecionadas.map((item) => ({
    value: item,
    label: `Base ${item}`,
  }));
  const onChageSelect = (value) => {
    onChange(value.map((item) => item.value));
  };
  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: state.isDisabled ? " whitesmoke" : "white",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#ff7aae",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#FFF",
      fontWeight: "bold",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "white",
      ":hover": {
        backgroundColor: "#ff0d6c",
        color: "white",
      },
    }),
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      pt={[2, 0]}
      component={"aside"}
    >
      <Typography textAlign={"initial"} fontWeight={"bold"} component={"span"}>
        Bases
      </Typography>
      <Select
        styles={colourStyles}
        isMulti
        name="bases"
        value={optionsValue}
        options={options}
        onChange={onChageSelect}
        isDisabled={disabled}
        placeholder="Selecione"
        classNames={{
          control: (state) => state.isFocused && "borderIsFocus",
        }}
      />
    </Box>
  );
};
