import React from "react";
import { Button } from "react-bootstrap";

const ButtonFiltrar = ({
  type,
  text = "Filtrar",
  disabled,
  title,
  onClick,
}) => {
  return (
    <Button
      type={type}
      disabled={disabled}
      title={title}
      variant="success"
      onClick={onClick}
      className="botao-filtrar-input-data back-color-principal"
    >
      {text}
    </Button>
  );
};

export default ButtonFiltrar;
