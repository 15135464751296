import { api } from "./api.v2";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDataPage: async () => {
    return await api.get(`goals/employeesLastPeriod`);
  },
  getData: async () => {
    return await api.get(`/targets/sales/previous-target`);
  },
  getEditData: async (id) => {
    return await api.get(`/targets/sales/${id}`);
  },
};
