import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { CellYearContainer, ContainerCellAside } from "./styles";

export const CellTableYear = ({ anoAt, anoAn, anoPa, mes = false }) => {
  return (
    <CellYearContainer>
      <div>
        <span>{anoAt}</span>
      </div>
      {
        <div>
          <span>{anoAn}</span>
        </div>
      }
      {!mes && (
        <div>
          <span>{anoPa}</span>
        </div>
      )}
    </CellYearContainer>
  );
};
export const CellAsideYaer = ({ mes }) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="button-tooltip-2">
          {" "}
          <p>AT - Ano Atual</p> <p>AN - Ano Anterior</p>{" "}
          {!mes && <p>PA - Período Anterior</p>}
        </Tooltip>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <ContainerCellAside ref={ref} {...triggerHandler}>
          <strong>AT</strong>
          <strong>AN</strong>
          {!mes && <strong>PA</strong>}
        </ContainerCellAside>
      )}
    </OverlayTrigger>
  );
};

export const CellTableGlobal = ({ valor, ticketMedio, lucro, qtd }) => {
  return (
    <CellYearContainer>
      <div>
        <span>{valor}</span>
      </div>
      <div>
        <span>{qtd}</span>
      </div>
      <div>
        <span>{ticketMedio}</span>
      </div>
      <div>
        <span>{lucro}</span>
      </div>
    </CellYearContainer>
  );
};

export const CellAsideGlobal = () => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="button-tooltip-2">
          {" "}
          <p>VA - Valor Total</p>
          <p>QT - Quantidade de pedidos</p>
          <p>TM - Ticket Médio</p>
          <p>LC - Lucro</p>
        </Tooltip>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <ContainerCellAside ref={ref} {...triggerHandler}>
          <strong>VA</strong>
          <strong>QT</strong>
          <strong>TM</strong>
          <strong>LC</strong>
        </ContainerCellAside>
      )}
    </OverlayTrigger>
  );
};
