import { CellYearContainer } from "./styles";

export const CellPercentege = ({ row, name }) => {
  const lastMonthHidden = !row.lastMonth?.hidden;
  const lastYearHidden = !row.lastYear?.hidden;

  const formatter = (value) => {
    return (
      value.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + "%"
    );
  };
  const Cell = ({ value }) => {
    return (
      <>
        {+value === 0 ? (
          <span style={{ color: "#FECA57" }}>{formatter(value)}</span>
        ) : value < 0 ? (
          <span
            style={{
              color: "#FF6B6B",
              fontWeight: "bold",
              whiteSpace: "nowrap",
            }}
          >
            {formatter(value)}
          </span>
        ) : (
          <span>{value ? formatter(value) : "vazio"}</span>
        )}
      </>
    );
  };
  return (
    <CellYearContainer>
      <div>
        <span>
          <Cell value={row.currency[name]} />
        </span>
      </div>
      {lastYearHidden && (
        <div>
          <span>
            <Cell value={row.lastYear[name]} />
          </span>
        </div>
      )}
      {lastMonthHidden && (
        <div>
          <span>
            <Cell value={row.lastMonth[name]} />
          </span>
        </div>
      )}
    </CellYearContainer>
  );
};
