import { TableCell, TableRow, Tooltip, Typography } from "@mui/material";

import { useHistory, useParams } from "react-router-dom";
import dayjs, { utc } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { ButtonCopyNumber } from "../../../../components/ButtonCopyNumber/ButtonCopyNumber";
import { BsFillEyeFill } from "react-icons/bs";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

export function RowsCandidate(props) {
  const { row } = props;
  const params = useParams();
  const history = useHistory();

  const handleId = (e) => {
    e.preventDefault();
    history.push(`/rh/vagas/ver-candidatos/${params.id}/${row.id}`);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography
            color={"GrayText"}
            onClick={handleId}
            href={`/rh/vagas/ver-candidatos/${params.id}/${row.id}`}
            component={"a"}
          >
            <BsFillEyeFill fontSize={"25px"} />
          </Typography>
        </TableCell>

        <TableCell
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {row.nome}
        </TableCell>

        <TableCell>
          <Tooltip title={row.cidade}>
            <Typography
              maxWidth={"150px"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {row.cidade}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>{row.escolaridade}</TableCell>

        <TableCell>
          <Typography minWidth={"150px"}>
            {dayjs.utc(row.dataCadastro).format("DD/MM/YYYY")}
          </Typography>
        </TableCell>
        <TableCell>
          <ButtonCopyNumber phone={row.telefone} />
        </TableCell>
      </TableRow>
    </>
  );
}
