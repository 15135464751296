import React from "react";
import { Alert, Container, Button } from "react-bootstrap";

const AlertaLogin = ({ isApp }) => {
  return (
    <>
      <div className="alerta-login">
        <Container fluid>
          <Alert variant="warning">
            <Alert.Heading>Ops! Algo deu Errado</Alert.Heading>
            <p>
              {isApp
                ? "Talvez você não tenha acesso a essa tela"
                : "Talvez você não esteja logado ou não tenha acesso a essa tela"}
            </p>
            {!isApp && (
              <div className="d-flex justify-content-end">
                <Button
                  href="https://aprincipalbb.com.br/portal/login"
                  variant="outline-warning"
                >
                  Login
                </Button>
              </div>
            )}
          </Alert>
        </Container>
      </div>
    </>
  );
};

export default AlertaLogin;
