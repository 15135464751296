import React from "react";
import { converterDia, converterLoja } from "../util/loja";

const CellTableQuantidade = ({ tipo, rowInfo }) => {
  return (
    <div>
      {tipo === "loja" || tipo === "input" || tipo === "ano" ? (
        <div data-tag="allowRowEvents">
          <div style={{ whiteSpace: "nowrap" }}>{converterLoja(rowInfo)}</div>
        </div>
      ) : (
        <div data-tag="allowRowEvents">
          <div style={{ whiteSpace: "nowrap" }}>{converterDia(rowInfo)}</div>
        </div>
      )}
    </div>
  );
};

export default CellTableQuantidade;
