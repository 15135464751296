import React, { useEffect, useState } from "react";

import "chartjs-plugin-datalabels";
import "react-data-table-component-extensions/dist/index.css";
import { formatShopAvailable } from "../../../formatdata/formatter-shop-available";
import { Box, Divider, Stack, Tab, Tabs } from "@mui/material";

import { PermissionsPage } from "../../../util/permissionsPage";
import { useUserData } from "../../../hooks/useUserData";
import dayjs from "dayjs";
import Title from "../../../components/title";
import ReactDatePicker, { registerLocale } from "react-datepicker";

import pt from "date-fns/locale/pt-BR";
import Select from "react-select";

import { Puchases } from "../../../components/componentsBoletim/Purchases/Purchases";
import { Sales } from "../../../components/componentsBoletim/Sales/Sales";
import { GroupsInventory } from "../../../components/componentsBoletim/GroupsInventory/GroupsInventory";
import { BanksProvisions } from "../../../components/componentsBoletim/BanksProvisions/BanksProvisions";
import styled from "styled-components";
import { a11yProps } from "../../../util/a11yProps";
import { DataByStore } from "../../../components/componentsBoletim/DataByStore/DataByStore";
import { isEmptyObject } from "../../../util/isEmptyObject";
import { CustomTabPanel } from "../../../components/componentsAnaliseClientes/CustomTabPanel";
const Container = styled("div")`
  .rdt_TableHead {
    background-color: #aeedff;
   
    }
  }
  .rdt_TableHeadRow {
    div:first-child {
      background-color: #aeedff !important;
    }
    background-color: #aeedff !important;
  }
`;
registerLocale("es", pt);
function Boletim() {
  const currencyDay = dayjs().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(new Date());
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const defaultValues = {
    base: null,
    periodo: currencyDay,
  };

  const { userData, basesUserAccess } = useUserData();

  const accessLevel = userData?.nivelAcesso || [];
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [bases, setBases] = useState({});
  const [paramsConfig, setParamsConfig] = useState(defaultValues);
  const optionsBase =
    basesUserAccess?.map((item) => ({
      value: item.codBase,
      label: item.base,
    })) || [];

  const onChangeDate = (date) => {
    setStartDate(date);
    setParamsConfig((old) => ({
      ...old,
      periodo: dayjs(date).format("YYYY-MM-DD"),
    }));
  };

  const buscarNivelAcesso = () => {
    if (!!accessLevel && !!basesUserAccess.length > 0) {
      setBasesDisponiveis(basesUserAccess);
      const fristBase = basesUserAccess.find((item) => item.codBase);
      const selectBaseInitial = [fristBase].map((item) => item.codBase);
      const shopsAvailableForBase = formatShopAvailable(
        selectBaseInitial,
        accessLevel
      );
      setBases({
        value: selectBaseInitial,
        label: "Base " + selectBaseInitial,
      });

      setParamsConfig((old) => ({
        ...old,
        base: selectBaseInitial,
        stores: shopsAvailableForBase.map((item) => item.value),
      }));
    }
  };
  useEffect(() => {
    if (!isEmptyObject(userData)) {
      buscarNivelAcesso();
    }
  }, [userData]);

  const onChangeBase = (data) => {
    setBases(data);
    setParamsConfig((old) => ({ ...old, base: data.value }));
  };
  return (
    <>
      {PermissionsPage("emitirboletim") && (
        <>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            pr={2}
          >
            <Title title="Boletim" />
            <Stack>
              <ReactDatePicker
                value={startDate}
                locale={pt}
                onChange={onChangeDate}
                selected={startDate}
                dateFormat="dd/MM/yyyy"
              />
            </Stack>
          </Stack>
          <Box
            sx={{
              mx: 2,
              mb: 2,
            }}
          >
            <Box>
              <Select
                placeholder="Selecione uma base"
                value={bases}
                options={optionsBase}
                onChange={onChangeBase}
              />
            </Box>

            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                "& .MuiTabs-flexContainer": {
                  justifyContent: "center",
                },
              }}
            >
              <Tab label="Resumo diario" {...a11yProps(0)} />
              <Tab label="Por lojas" {...a11yProps(1)} />
            </Tabs>
            <Divider />
            <CustomTabPanel my={2} value={value} index={0}>
              <Container>
                <Stack gap={2}>
                  <BanksProvisions
                    paramsConfig={paramsConfig}
                    basesDisponiveis={basesDisponiveis}
                  />
                  <GroupsInventory
                    paramsConfig={paramsConfig}
                    basesDisponiveis={basesDisponiveis}
                  />
                  <Sales
                    paramsConfig={paramsConfig}
                    basesDisponiveis={basesDisponiveis}
                  />
                  <Puchases
                    paramsConfig={paramsConfig}
                    basesDisponiveis={basesDisponiveis}
                  />
                </Stack>
              </Container>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Container>
                <DataByStore
                  paramsConfig={paramsConfig}
                  basesDisponiveis={basesDisponiveis}
                />
              </Container>
            </CustomTabPanel>
          </Box>
        </>
      )}
    </>
  );
}

export default Boletim;
