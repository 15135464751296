import React from "react";
import "./Loading.css";
import "./Name.css";
import img from "../../assets/coracao.png";
import { Fade } from "@mui/material";

export const LoadingTable = ({ isLoading = true, ...props }) => {
  return (
    <>
      <Fade in={isLoading} {...props}>
        <div className="">
          <div className="name">
            <img
              className="animate"
              src={img}
              width="168"
              height="98"
              alt="logo aprincipal"
            />
          </div>
        </div>
      </Fade>
    </>
  );
};
