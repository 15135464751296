import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormGroup,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { forwardRef, useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormContext } from "../../pages/financeiro/metas/cadastro-metas";
const NumericFormatCustom = forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      thousandSeparator="."
      decimalSeparator=","
      prefix="R$ "
      placeholder="R$ 0,00"
      allowNegative={false}
    />
  );
});

export const FormEditarMetasMensal = ({ open, data, onClickSave }) => {
  const { control, getValues } = useFormContext();
  const { setDataPage, dataPage, formatterDataPage } = useContext(FormContext);
  const infoLoja = getValues(`${data.CodLoja}`);
  const handleSave = () => {
    const dataAux = dataPage.map((item) => {
      if (item.CodLoja === data.CodLoja) {
        if (+item.metaTM !== +infoLoja.metaTM) {
          infoLoja.metaQtdPedidos = parseInt(
            infoLoja.metaVenda / infoLoja.metaTM
          );
        }
        if (+item.metaVenda !== +infoLoja.metaVenda) {
          infoLoja.metaVenda = +infoLoja.metaVenda;
          infoLoja.metaTM = parseInt(
            infoLoja.metaVenda / infoLoja.metaQtdPedidos
          );
        }
        if (+item.metaQtdPedidos !== +infoLoja.metaQtdPedidos) {
          infoLoja.metaTM = parseInt(
            infoLoja.metaVenda / infoLoja.metaQtdPedidos
          );
        }
        return infoLoja;
      }
      return item;
    });

    setDataPage(dataAux);
    const formatter = Object.values(getValues()).filter((item) => !!item);
    formatterDataPage(
      formatter.map((item) => {
        item.funcionariosDaLoja = Object.values(item.funcionariosDaLoja);
        return item;
      }),
      false
    );
    onClickSave();
  };

  return (
    <Collapse in={open}>
      <Stack
        bgcolor={"#fcfcfc"}
        mb={1}
        component={Paper}
        variant="outlined"
        p={2}
      >
        <FormGroup>
          <Box gap={2} display={"grid"} gridTemplateColumns={"1fr 1fr 1fr"}>
            <FormControl>
              <Controller
                name={`${data.CodLoja}.metaVenda`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{
                      background: "white",
                    }}
                    size="small"
                    label="Valor Vendas"
                    id="formatted-numberformat-input"
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name={`${data.CodLoja}.metaTM`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{
                      background: "white",
                    }}
                    size="small"
                    label="Ticket Medio"
                    id="formatted-numberformat-input"
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name={`${data.CodLoja}.metaItensPorVenda`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    sx={{
                      background: "white",
                    }}
                    label="Item por venda"
                    size="small"
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name={`${data.CodLoja}.metaClientesCadastrados`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{
                      background: "white",
                    }}
                    type="number"
                    label="Clientes cadastrados"
                    size="small"
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name={`${data.CodLoja}.metaQtdPedidos`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{
                      background: "white",
                    }}
                    type="number"
                    label="Qtd Pedidos"
                    size="small"
                  />
                )}
              />
            </FormControl>
            <FormControl disabled>
              <Controller
                name={`${data.CodLoja}.diasUteis`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{
                      background: "white",
                    }}
                    disabled
                    type="number"
                    label="Dias uteis"
                    size="small"
                  />
                )}
              />
            </FormControl>
          </Box>
        </FormGroup>
        <Stack justifyContent={"flex-end"} alignItems={"flex-end"} mt={2}>
          <Button
            onClick={handleSave}
            size="small"
            color="aprincipal"
            variant="contained"
          >
            Inserir Registro
          </Button>
        </Stack>
      </Stack>
    </Collapse>
  );
};
