import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

import { useUserData } from "../../../hooks/useUserData";
import { useAuth } from "../../../hooks/useAuth";

export const MenuProfile = (props) => {
  const { userData } = useUserData();
  const { singOut } = useAuth();
  
  return (
    <Popper
      role={undefined}
      placement="bottom-start"
      sx={{
        minWidth: "130px",
      }}
      transition
      {...props}
    >
      {({ TransitionProps, placement }) => {
        return (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => props.onClose()}>
                <MenuList
                  autoFocusItem={props.open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  <Box px={1} pb={1}>
                    <Typography
                      color={"GrayText"}
                      component={"strong"}
                      fontWeight={"bold"}
                    >
                      {userData.user}
                    </Typography>
                  </Box>

                  <Divider />

                  <MenuItem onClick={() => singOut()}>
                    <FontAwesomeIcon icon={faSignOutAlt} /> Sair
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        );
      }}
    </Popper>
  );
};
