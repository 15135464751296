import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { BsTrash } from "react-icons/bs";
const sexoOpcoes = [
  { nome: "Masculino", id: "M" },
  { nome: "Feminino", id: "F" },
  { nome: "Não sei ainda", id: "N" },
];

const grauParentescoOpcoes = [
  { nome: "Filho", id: "Filho" },
  { nome: "Filha", id: "Filha" },
  { nome: "Irmão", id: "Irmão" },
  { nome: "Irmã", id: "Irmã" },
  { nome: "Pai", id: "Pai" },
  { nome: "Mãe", id: "Mãe" },
  { nome: "Sobrinho", id: "Sobrinho" },
  { nome: "Sobrinha", id: "Sobrinha" },
  { nome: "Primo", id: "Primo" },
  { nome: "Prima", id: "Prima" },
];

export const Dependentes = ({ register, data, reset, getValues, control }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleLoading = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false); // Marca o carregamento como concluído
    }, 700);
  };

  const handleRemove = (index) => {
    const valueFilter = [];
    Object.keys(data).forEach((key, ind) => {
      if (ind !== index) {
        valueFilter.push(data[key]);
      } else {
        data[key].isNew = false;
        data[key].updated = false;
        data[key].deleted = true;
        valueFilter.push(data[key]);
      }
    });
    const dadosDependentes = {};
    valueFilter.forEach((item) => {
      dadosDependentes[item.id] = item;
    });

    reset({ ...getValues(), dadosDependentes: dadosDependentes });
    handleLoading();
  };

  const handleAdd = () => {
    handleLoading();

    reset({
      ...getValues(),
      dadosDependentes: {
        ...data,
        [`name${Math.floor(Math.random() * 1000)}`]: {
          nome: "",
          sexo: "",
          grauParentesco: "",
          nascimento: "",
          semanasgestacao: "",
          datanascimentoprevisto: "",
          isNew: true,
          deleted: false,
          updated: false,
        },
      },
    });
  };
  return (
    <>
      <Stack p={1} component={Paper} elevation={1} mb={2} variant="outlined">
        <Typography>Dependentes</Typography>
        {isLoading ? (
          <Stack justifyContent={"center"} alignItems={"center"}>
            <CircularProgress color="secondary" />
          </Stack>
        ) : (
          <>
            {Object.keys(data).map((key, index) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              if (data[key]?.deleted) {
                return <></>;
              }
              return (
                <>
                  <Box
                    component={Paper}
                    elevation={1}
                    variant="outlined"
                    key={data[key].id}
                    px={1}
                    pb={1}
                  >
                    <Stack direction={"row"} justifyContent={"flex-end"}>
                      <IconButton
                        onClick={() => {
                          handleRemove(index);
                        }}
                      >
                        <BsTrash />
                      </IconButton>
                    </Stack>
                    <Stack direction={"row"} gap={1} mb={1}>
                      <FormControl fullWidth>
                        <TextField
                          {...register(`dadosDependentes.${key}.nome`)}
                          size="small"
                          label="Nome"
                          placeholder=""
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel size="small" id="select-pessoa">
                          Sexo
                        </InputLabel>
                        <Select
                          labelId="sexo-dependentes"
                          id="simple-sexo-dependentes"
                          label="Sexo"
                          {...register(`dadosDependentes.${key}.sexo`)}
                          size="small"
                          native
                          placeholder="Sexo"
                          variant="outlined"
                        >
                          {sexoOpcoes.map((item) => (
                            <option key={item.id} value={item.id.toUpperCase()}>
                              {item.nome}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel size="small" id="select-pessoa">
                          Grau de Parentesco
                        </InputLabel>
                        <Select
                          labelId="grau-parentesco"
                          id="simple-grau-parentesco"
                          label="Grau de Parentesco"
                          {...register(
                            `dadosDependentes.${key}.grauParentesco`
                          )}
                          size="small"
                          native
                          placeholder="Grau de Parentesco"
                          variant="outlined"
                        >
                          {grauParentescoOpcoes.map((item) => (
                            <option key={item.id} value={item.id.toUpperCase()}>
                              {item.nome}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                    <Stack direction={"row"} gap={1} mb={1}>
                      <FormControl fullWidth>
                        <Controller
                          control={control}
                          name={`dadosDependentes.${key}.datanascimentoprevisto`}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              label="Data Nascimento Prevista"
                              variant="outlined"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          {...register(
                            `dadosDependentes.${key}.semanasgestacao`
                          )}
                          size="small"
                          label="Semanas de gestação"
                          type="number"
                          placeholder=""
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <Controller
                          control={control}
                          name={`dadosDependentes.${key}.nascimento`}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              label="Data Nascimento"
                              variant="outlined"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Stack>
                  </Box>
                </>
              );
            })}
          </>
        )}

        <Stack mt={1} alignItems={"flex-end"}>
          <Button variant="contained" color="secondary" onClick={handleAdd}>
            Adicionar dependente
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
