import React, { useEffect, useState } from "react";

import { Col, Container, FormLabel, FormSelect, Row } from "react-bootstrap";

import Title from "../../components/title";

import { baseService } from "../../services/baseServices";
import {
  colunasEstoqueQualidade,
  colunasEstoqueQualidadeCollapse,
  colunasEstoqueQualidadeCollapseInternal,
} from "../../util/tables";
import { agruparPorQualidade } from "../../util/loja";
import { arrayBases } from "../../util/loja";
import toast from "react-hot-toast";

import { PermissionsPage } from "../../util/permissionsPage";
import { CollapseTable } from "../../components/TableCollapse";
import FunnelChart from "../../components/Funil";

import FilterMultBases from "../../components/FilterMultBases";
import FilterLojaSimples from "../../components/FilterLojaSimples";

import { useCallback } from "react";
import { ContainerIniciarBusca } from "./styles";
import "./style.css";
import { useUserData } from "../../hooks/useUserData";
import { Box } from "@mui/material";
import { LoadingTable } from "../../components/Loading/LoadingTable";

export function EstoqueQualidade() {
  const { userData } = useUserData();
  const accessLevel = userData?.nivelAcesso;
  const acessBase = userData?.baseAcesso;
  const [estoque, setEstoque] = useState([]);
  const [estoqueFormatado, setEstoqueFormatado] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [basesDisponiveis, setBasesDisponiveis] = useState([]);

  const [agrupamento, setAgrupamento] = useState("Grupo");
  const [tipoFunil, setTipoFunil] = useState("TotalMonetario");
  const [filtrosLojasSelecionadas, setFiltrosLojasSelecionadas] = useState([]);

  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);
  const [basesSelecionadas, setBasesSelecionadas] = useState([]);

  useEffect(() => {
    if (accessLevel?.length === 0 || basesDisponiveis?.length === 0) {
      buscarNivelAcesso();
      return;
    }
    if (basesSelecionadas?.length > 0) {
      setIsLoading(true);
    }
  }, [basesSelecionadas, accessLevel, acessBase, basesDisponiveis]);

  useEffect(() => {
    if (!basesSelecionadas?.length) return;
    preencherDados();
  }, [basesSelecionadas]);

  function getUniqueCodlojas(products) {
    const codlojas = products.map((product) => product.Codloja);
    return [...new Set(codlojas)];
  }

  const preencherDados = useCallback(async () => {
    //////////////////////////
    setIsLoading(true);
    const toastId = toast.loading(`Buscando dados...`);
    //////////////////////////
    let result = await baseService.getQualidade({
      base: basesSelecionadas,
      nivelAcesso: JSON.stringify(accessLevel),
    });

    if (!result) {
      toast.error(`Erro ao buscar dados`, {
        id: toastId,
      });
      return;
    }
    setEstoque(result);
    let auxCodLojas = getUniqueCodlojas(result);

    setLojasDisponiveis(auxCodLojas);

    let estoqueResult = result;
    if (filtrosLojasSelecionadas.length !== 0) {
      estoqueResult = result.filter(
        (item) => filtrosLojasSelecionadas.indexOf(item.Codloja) >= 0
      );
    }

    result = agruparPorQualidade(estoqueResult, "Grupo", tipoFunil);

    setEstoqueFormatado(result);

    //////////////////////////
    setIsLoading(false);
    toast.success(`Dados recebidos`, {
      id: toastId,
    });
    //////////////////////////
  }, [basesSelecionadas, accessLevel]);

  const filtrarBases = (codBase) => {
    setLojasDisponiveis([]);
    let basesSelecionadasTemp = basesSelecionadas.filter((a) => true);
    if (
      basesSelecionadasTemp.filter((a) => Number(a) === Number(codBase))
        .length > 0
    ) {
      basesSelecionadasTemp = basesSelecionadasTemp.filter(
        (a) => Number(a) !== Number(codBase)
      );
    } else {
      basesSelecionadasTemp.push(codBase);
    }
    setBasesSelecionadas(basesSelecionadasTemp);
  };

  const buscarNivelAcesso = async () => {
    if (accessLevel) {
      setBasesDisponiveis(arrayBases(acessBase));
      if (acessBase.length > 0) {
        setBasesSelecionadas([acessBase[0]]);
      }
    }
  };

  function filtrarPorLoja(loja) {
    const toastId = toast.loading(`Tratando dados...`);

    var lojasSelecionadas = [];
    if (filtrosLojasSelecionadas.length === 0) {
      lojasSelecionadas.push(loja);
    } else {
      if (filtrosLojasSelecionadas.indexOf(loja) >= 0) {
        filtrosLojasSelecionadas.map((item) => {
          if (item !== loja) {
            lojasSelecionadas.push(item);
          }
        });
      } else {
        filtrosLojasSelecionadas.map((item) => {
          lojasSelecionadas.push(item);
        });
        lojasSelecionadas.push(loja);
      }
    }
    setFiltrosLojasSelecionadas(lojasSelecionadas);
    let estoqueResult = estoque;
    if (lojasSelecionadas.length !== 0) {
      estoqueResult = estoque.filter(
        (item) => lojasSelecionadas.indexOf(item.Codloja) >= 0
      );
    }

    let result = agruparPorQualidade(estoqueResult, agrupamento, tipoFunil);
    setEstoqueFormatado(result);
    toast.success(`Dados recebidos`, {
      id: toastId,
    });
  }

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => {
      return (
        <Container>
          <CollapseTable
            rowKey="indicador"
            dadosParaTabela={row?.agrupamento}
            colunas={colunasEstoqueQualidadeCollapse()}
            expandRow={expandRowInternal}
          />
        </Container>
      );
    },
  };

  const expandRowInternal = {
    onlyOneExpanding: true,
    renderer: (row) => {
      return (
        <Container>
          <CollapseTable
            rowKey="CodProd"
            dadosParaTabela={row?.products}
            colunas={colunasEstoqueQualidadeCollapseInternal()}
          />
        </Container>
      );
    },
  };

  function handlerFunil(value) {
    const toastId = toast.loading(`Tratando dados...`);

    setTipoFunil(value);
    let estoqueResult = estoque;
    if (filtrosLojasSelecionadas.length !== 0) {
      estoqueResult = estoque.filter(
        (item) => filtrosLojasSelecionadas.indexOf(item.Codloja) >= 0
      );
    }
    let result = agruparPorQualidade(estoqueResult, agrupamento, value);

    setEstoqueFormatado(result);

    toast.success(`Dados recebidos`, {
      id: toastId,
    });
  }

  function handlerAgrupamento(value) {
    setAgrupamento(value);
    const toastId = toast.loading(`Tratando dados...`);

    let estoqueResult = estoque;
    if (filtrosLojasSelecionadas.length !== 0) {
      estoqueResult = estoque.filter(
        (item) => filtrosLojasSelecionadas.indexOf(item.Codloja) >= 0
      );
    }

    let result = agruparPorQualidade(estoqueResult, value, tipoFunil);
    setEstoqueFormatado(result);

    toast.success(`Dados recebidos`, {
      id: toastId,
    });
  }

  return (
    <>
      <div className="container-app">
        {PermissionsPage("estoqueQualidade") && (
          <Container>
            <div className="container-conteudo" style={{ padding: 20 }}>
              <Container className="containerFlexBox">
                <Title title="QUALIDADE DE ESTOQUE" />
                <FilterMultBases
                  basesDisponiveis={basesDisponiveis}
                  basesSelecionadas={basesSelecionadas}
                  filtrarBases={filtrarBases}
                />
              </Container>
              <Row className="row-filter-produtos" style={{ marginBottom: 50 }}>
                <FilterLojaSimples
                  lojasDisponiveis={lojasDisponiveis}
                  filtrosLojasSelecionadas={filtrosLojasSelecionadas}
                  filtrarPorLoja={filtrarPorLoja}
                />
              </Row>
              <Row className="row-filter-produtos">
                <Col xs={12} md={4}>
                  <FormLabel>Agrupamento Interno</FormLabel>
                  <FormSelect
                    value={agrupamento}
                    onChange={(e) => handlerAgrupamento(e.target.value)}
                  >
                    <option value="Grupo">Grupo</option>
                    <option value="Subgrupo">Subgrupo</option>
                    <option value="Categoria">Categoria</option>
                    <option value="Fornecedor">Fornecedor</option>
                  </FormSelect>
                </Col>
                <Col xs={12} md={4}>
                  <FormLabel>Tipo de Funil</FormLabel>
                  <FormSelect
                    value={tipoFunil}
                    onChange={(e) => handlerFunil(e.target.value)}
                  >
                    <option value="EstoqueAtual">Estoque Atual</option>
                    <option value="PrecoVenda">Preço Venda</option>
                    <option value="TotalMonetario">Total Monetario</option>
                  </FormSelect>
                </Col>
              </Row>
              {isLoading ? (
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={"70vh"}
                >
                  <LoadingTable isLoading={isLoading} />
                </Box>
              ) : (
                <>
                  {estoqueFormatado?.length ? (
                    <>
                      <Container style={{ marginBottom: 50, marginTop: 50 }}>
                        <Row className="row-filter-produtos">
                          <Col xs={12} md={8}>
                            <CollapseTable
                              rowKey="id"
                              dadosParaTabela={estoqueFormatado}
                              colunas={colunasEstoqueQualidade(
                                estoqueFormatado
                              )}
                              expandRow={expandRow}
                            />
                          </Col>
                          <Col xs={12} md={4}>
                            <FunnelChart
                              data={[
                                {
                                  color: "#F5C5D5",
                                  nome: estoqueFormatado[0]?.titulo,
                                  value: estoqueFormatado[0][tipoFunil],
                                },
                                {
                                  color: "#F7A5B5",
                                  nome: estoqueFormatado[1]?.titulo,
                                  value: estoqueFormatado[1][tipoFunil],
                                },
                                {
                                  color: "#F88095",
                                  nome: estoqueFormatado[2]?.titulo,
                                  value: estoqueFormatado[2][tipoFunil],
                                },
                                {
                                  color: "#FA5E7A",
                                  nome: estoqueFormatado[3]?.titulo,
                                  value: estoqueFormatado[3][tipoFunil],
                                },
                                {
                                  color: "#FC3E60",
                                  nome: estoqueFormatado[4]?.titulo,
                                  value: estoqueFormatado[4][tipoFunil],
                                },
                                {
                                  color: "#FF1E47",
                                  nome: estoqueFormatado[5]?.titulo,
                                  value: estoqueFormatado[5][tipoFunil],
                                },
                                {
                                  color: "#FF0030",
                                  nome: estoqueFormatado[6]?.titulo,
                                  value: estoqueFormatado[6][tipoFunil],
                                },
                                {
                                  color: "#E60026",
                                  nome: estoqueFormatado[7]?.titulo,
                                  value: estoqueFormatado[7][tipoFunil],
                                },
                              ]}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </>
                  ) : (
                    <ContainerIniciarBusca>
                      Escolha a(s) base(s) digite o nome ou código do produto e
                      clique em "Buscar"
                    </ContainerIniciarBusca>
                  )}
                </>
              )}
            </div>
          </Container>
        )}
      </div>
    </>
  );
}
