import { FileDownload } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { api } from "../../../../services/api.v2";
import { saveAs } from "file-saver";

export const ListMenu = ({ onClose, ...props }) => {
  const [isLoadingDownloadCsv, setIsLoadingDownloadCsv] = useState(false);

  const handleDownloadCsv = async () => {
    setIsLoadingDownloadCsv(true);
    try {
      const response = await api.get(`franchise/download-xlsx-page/franchise`, {
        responseType: "blob", // Configura a resposta como um objeto Blob
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Define o nome do arquivo
      const fileName = "arquivo.xlsx";
      saveAs(blob, fileName);
    } catch (error) {
      toast.error("Erro ao tentar baixar registros da pagina");
    } finally {
      setIsLoadingDownloadCsv(false);
    }
  };
  return (
    <Popper role={undefined} placement="bottom-start" transition {...props}>
      {({ TransitionProps, placement }) => {
        return (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClose}>
                <MenuList
                  autoFocusItem={props.open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  <MenuItem
                    disabled={isLoadingDownloadCsv}
                    onClick={handleDownloadCsv}
                  >
                    {isLoadingDownloadCsv ? (
                      <Box mr={1.7} sx={{ display: "flex" }}>
                        <CircularProgress color="inherit" size={22} />
                      </Box>
                    ) : (
                      <ListItemIcon>
                        <FileDownload />
                      </ListItemIcon>
                    )}
                    Baixar todos os interessados da pagina
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        );
      }}
    </Popper>
  );
};
