import { formatarNumero } from "../util/loja";

export const options = {
  title: {
    display: true,
    padding: 20,
    text: "Custo Vendas x Custo Estoque",
  },
  legend: {
    position: "top",
    labels: {
      filter: function (item, chart) {
        return !item.text.includes("Media");
      },
    },
  },
  layout: {
    padding: {
      left: 0,
      right: 40,
      top: 0,
      bottom: 0,
    },
  },

  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
        categoryPercentage: 0.8,
        barPercentage: 0.6,
      },
    ],
    xAxes: [
      {
        id: "A",
        ticks: {
          padding: 20,
          beginAtZero: true,
        },
      },
      {
        id: "B",
        ticks: {
          beginAtZero: true,
          display: false,
        },
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
      },
    ],
  },
  tooltips: {
    displayColors: false,
    callbacks: {
      title: (tooltipItem, data) => {
        tooltipItem = tooltipItem[0];
        return tooltipItem.label;
      },
      label: (tooltipItem, data) => {
        const datasets = [
          { title: "Custo Vendas", money: true },
          { title: "Custo Estoque", money: true },
          { title: "Previsão de Compras", money: true },
          { title: "Estoque em Trânsito", money: true },
          { title: "Custo Estoque Total", money: true },
          { title: "Media Mês Qtd", money: false },
          { title: "Media Mês Estoque + Transito Qtd", money: false },
          { title: "Media Mês Estoque + Aguardando Entrega Qtd", money: false },
          { title: "Media Mês Estoque Total Qtd", money: false },
        ];
        return `${datasets[tooltipItem.datasetIndex]?.title}: ${formatarNumero(
          parseFloat(tooltipItem.value),
          datasets[tooltipItem.datasetIndex]?.money
        )}`;
      },
    },
  },
  plugins: {
    datalabels: {
      labels: {
        title: {
          font: {
            weight: "bold",
          },
        },
      },
    },
  },
};

export const optionsQtd = {
  responsive: true,
  layout: {
    padding: {
      left: 0,
      right: 40,
      top: 0,
      bottom: 0,
    },
  },

  title: {
    display: true,
    padding: 20,
    text: "Quantidade Vendas x Quantidade Estoque x Media Mes Qtd",
  },
  legend: {
    position: "bottom",
  },

  scales: {
    // x: {
    //     stacked: true,
    // },
    // y: {
    //     stacked: true,
    // },
    xAxes: [
      {
        id: "A",
        ticks: {
          beginAtZero: true,
        },
      },
      {
        id: "B",
        ticks: {
          display: false,
        },
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          padding: 25,
        },
      },
    ],
  },
  plugins: {
    datalabels: {
      labels: {
        title: {
          font: {
            weight: "bold",
          },
        },
      },
    },
  },
};
export const options3 = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  title: {
    display: true,
    padding: 20,
    text: "TM Estoque x TM Vendas x Media Mes Custo x Custo Vendas",
  },
  legend: {
    position: "bottom",
    reverse: true,
  },
  layout: {
    padding: {
      left: 0,
      right: 40,
      top: 0,
      bottom: 0,
    },
  },
  scales: {
    xAxes: [
      {
        id: "A",
        ticks: {
          beginAtZero: true,
        },
      },
      {
        id: "B",
        ticks: {
          display: false,
        },
        stacked: true,
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
      },
      {
        id: "C",
        ticks: {
          display: false,
        },
        stacked: true,
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          padding: 25,
        },
      },
    ],
  },
  plugins: {
    datalabels: {
      labels: {
        title: {
          font: {
            weight: "bold",
          },
        },
      },
    },
  },
};

export const functionGraficsTables = {
  manipulacaoDadosTabelasPreencher: (response) => {
    console.log("response -> ", response);
    let titles = [],
      estoque = [],
      estoqueTransito = [],
      estoqueAguardandoEntrega = [],
      estoqueTotal = [],
      vendas = [],
      vendasGrafico = [],
      tmVendas = [],
      tmEstoque = [],
      dataSetTM = [],
      dataSet1 = [],
      qtdEstoque = [],
      qtdEstoqueTransito = [],
      qtdEstoqueAguardandoEntrega = [],
      qtdEstoqueTotal = [],
      qtdVendas = [],
      dataSetQ = [],
      vMediaMesQtd = [],
      vMediaMesTransitoQtd = [],
      vMediaMesAguardandoEntregaQtd = [],
      vMediaMesTotalQtd = [],
      vMediaMesCusto = [],
      vMediaMesCustoTransito = [],
      vMediaMesCustoAguardandoEntrega = [],
      vMediaMesCustoTotal = [];
    response?.map((item, index) => {
      titles.push(item.NomeGrupo);
      estoque.push(parseFloat(item.custoEstoque));
      estoqueTransito.push(
        item?.custoEstoqueTransito ? parseFloat(item?.custoEstoqueTransito) : 0
      );
      estoqueAguardandoEntrega.push(
        item?.custoEstoqueAguardandoEntrega
          ? parseFloat(item?.custoEstoqueAguardandoEntrega)
          : 0
      );
      estoqueTotal.push(
        parseFloat(item.custoEstoque) +
          (item?.custoEstoqueTransito
            ? parseFloat(item?.custoEstoqueTransito)
            : 0) +
          (item?.custoEstoqueAguardandoEntrega
            ? parseFloat(item?.custoEstoqueAguardandoEntrega)
            : 0)
      );
      vendas.push(parseFloat(item.CustoVendas));
      vendasGrafico.push(parseFloat(item.CustoVendas).toFixed(2));

      qtdEstoque.push(parseInt(item.qtdEstoque));
      qtdEstoqueTransito.push(
        item?.qtdEstoqueTransito ? parseInt(item.qtdEstoqueTransito) : 0
      );
      qtdEstoqueAguardandoEntrega.push(
        item?.qtdEstoqueAguardandoEntrega
          ? parseInt(item.qtdEstoqueAguardandoEntrega)
          : 0
      );
      qtdEstoqueTotal.push(
        parseFloat(item.qtdEstoque) +
          (item?.qtdEstoqueTransito ? parseInt(item.qtdEstoqueTransito) : 0) +
          (item?.qtdEstoqueAguardandoEntrega
            ? parseFloat(item?.qtdEstoqueAguardandoEntrega)
            : 0)
      );

      qtdVendas.push(parseInt(item.QtdVendas));
      let tmVendasResut = item.CustoVendas / item.QtdVendas;
      let tmEstoqueResut = item.custoEstoque / item.qtdEstoque;
      if (isNaN(tmVendasResut) || tmVendasResut === Infinity) {
        tmVendasResut = 0.0;
      }
      if (isNaN(tmEstoqueResut) || tmEstoqueResut === Infinity) {
        tmEstoqueResut = 0.0;
      }
      tmEstoque.push(parseFloat(tmEstoqueResut.toFixed(2)));
      tmVendas.push(parseFloat(tmVendasResut.toFixed(2)));

      let valueMediaMesQtd = item.qtdEstoque / item.QtdVendas;
      if (isNaN(valueMediaMesQtd) || valueMediaMesQtd === Infinity) {
        valueMediaMesQtd = 0.0;
      }
      let valueMediaMesTransitoQtd =
        (parseFloat(item.qtdEstoque) +
          (item?.qtdEstoqueTransito
            ? parseFloat(item?.qtdEstoqueTransito)
            : 0)) /
        item.QtdVendas;
      if (
        isNaN(valueMediaMesTransitoQtd) ||
        valueMediaMesTransitoQtd === Infinity
      ) {
        valueMediaMesTransitoQtd = 0.0;
      }
      let valueMediaMesAguardandoEntregaQtd =
        (parseFloat(item.qtdEstoque) +
          (item?.qtdEstoqueAguardandoEntrega
            ? parseFloat(item?.qtdEstoqueAguardandoEntrega)
            : 0)) /
        item.QtdVendas;
      if (
        isNaN(valueMediaMesAguardandoEntregaQtd) ||
        valueMediaMesAguardandoEntregaQtd === Infinity
      ) {
        valueMediaMesAguardandoEntregaQtd = 0.0;
      }
      let valueMediaMesTotalQtd =
        (parseFloat(item.qtdEstoque) +
          (item?.qtdEstoqueTransito
            ? parseFloat(item?.qtdEstoqueTransito)
            : 0) +
          (item?.qtdEstoqueAguardandoEntrega
            ? parseFloat(item?.qtdEstoqueAguardandoEntrega)
            : 0)) /
        item.QtdVendas;
      if (isNaN(valueMediaMesTotalQtd) || valueMediaMesTotalQtd === Infinity) {
        valueMediaMesTotalQtd = 0.0;
      }

      let valueMediaMesCusto = item.custoEstoque / item.CustoVendas;
      if (
        isNaN(valueMediaMesCusto) ||
        valueMediaMesCusto === Infinity ||
        valueMediaMesCusto === -Infinity
      ) {
        valueMediaMesCusto = 0.0;
      }
      let valueMediaMesTransitoCusto =
        (parseFloat(item.custoEstoque) +
          (item?.custoEstoqueTransito
            ? parseFloat(item?.custoEstoqueTransito)
            : 0)) /
        item.CustoVendas;
      if (
        isNaN(valueMediaMesTransitoCusto) ||
        valueMediaMesTransitoCusto === Infinity ||
        valueMediaMesTransitoCusto === -Infinity
      ) {
        valueMediaMesTransitoCusto = 0.0;
      }
      let valueMediaMesAguardandoEntregaCusto =
        (parseFloat(item.custoEstoque) +
          (item?.custoEstoqueAguardandoEntrega
            ? parseFloat(item?.custoEstoqueAguardandoEntrega)
            : 0)) /
        item.CustoVendas;
      if (
        isNaN(valueMediaMesAguardandoEntregaCusto) ||
        valueMediaMesAguardandoEntregaCusto === Infinity ||
        valueMediaMesAguardandoEntregaCusto === -Infinity
      ) {
        valueMediaMesAguardandoEntregaCusto = 0.0;
      }
      let valueMediaMesTotalCusto =
        (parseFloat(item.custoEstoque) +
          (item?.custoEstoqueTransito
            ? parseFloat(item?.custoEstoqueTransito)
            : 0) +
          (item?.custoEstoqueAguardandoEntrega
            ? parseFloat(item?.custoEstoqueAguardandoEntrega)
            : 0)) /
        item.CustoVendas;
      if (
        isNaN(valueMediaMesTotalCusto) ||
        valueMediaMesTotalCusto === Infinity ||
        valueMediaMesTotalCusto === -Infinity
      ) {
        valueMediaMesTotalCusto = 0.0;
      }
      vMediaMesQtd.push(parseFloat(valueMediaMesQtd.toFixed(2)));
      vMediaMesTransitoQtd.push(
        parseFloat(valueMediaMesTransitoQtd).toFixed(2)
      );
      vMediaMesAguardandoEntregaQtd.push(
        parseFloat(valueMediaMesAguardandoEntregaQtd).toFixed(2)
      );
      vMediaMesTotalQtd.push(parseFloat(valueMediaMesTotalQtd.toFixed(2)));

      vMediaMesCusto.push(parseFloat(valueMediaMesCusto.toFixed(2)));
      vMediaMesCustoTransito.push(
        parseFloat(valueMediaMesTransitoCusto.toFixed(2))
      );
      vMediaMesCustoAguardandoEntrega.push(
        parseFloat(valueMediaMesAguardandoEntregaCusto.toFixed(2))
      );
      vMediaMesCustoTotal.push(parseFloat(valueMediaMesTotalCusto.toFixed(2)));

      response[index].tmEstoque = tmEstoqueResut;
      response[index].tmVenda = tmVendasResut;
      response[index].mediaMesQtd = valueMediaMesQtd;
      response[index].mediaMesCusto = valueMediaMesCusto;
    });
    dataSet1 = [
      {
        label: "Custo Vendas",
        xAxisID: "A",
        data: vendas,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        datalabels: {
          anchor: "end",
          display: true,
          color: "rgba(255, 99, 132)",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return new Intl.NumberFormat("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              style: "currency",
              currency: "BRL",
            }).format(parseFloat(value));
          },
        },
      },
      {
        label: "Custo Estoque",
        xAxisID: "A",
        data: estoque,
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        datalabels: {
          anchor: "end",
          display: true,
          color: "rgba(53, 162, 235)",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return new Intl.NumberFormat("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              style: "currency",
              currency: "BRL",
            }).format(parseFloat(value));
          },
        },
      },
      {
        label: "Previsão de Compras",
        xAxisID: "A",
        data: estoqueTransito,
        backgroundColor: "rgba(105, 179, 76, 0.5)",
        datalabels: {
          anchor: "end",
          display: true,
          color: "rgba(105, 179, 76)",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return new Intl.NumberFormat("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              style: "currency",
              currency: "BRL",
            }).format(parseFloat(value));
          },
        },
      },
      {
        label: "Estoque em Trânsito",
        xAxisID: "A",
        data: estoqueAguardandoEntrega,
        backgroundColor: "rgba(233, 122, 39, 0.5)",
        datalabels: {
          anchor: "end",
          display: true,
          color: "rgba(233, 122, 39)",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return new Intl.NumberFormat("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              style: "currency",
              currency: "BRL",
            }).format(parseFloat(value));
          },
        },
      },
      {
        label: "Custo Estoque Total",
        xAxisID: "A",
        data: estoqueTotal,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        datalabels: {
          anchor: "end",
          display: true,
          color: "rgba(0, 0, 0)",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return new Intl.NumberFormat("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              style: "currency",
              currency: "BRL",
            }).format(parseFloat(value));
          },
        },
      },
      {
        label: "Media Mês Qtd",
        data: vMediaMesCusto,
        xAxisID: "B",
        backgroundColor: "rgba(255,255,255,0)",
        datalabels: {
          anchor: "start",
          borderColor: "#EB7442",
          opacity: 0.7,
          align: "start",
          rotation: 0,
          color: "rgb(75, 192, 192)",
          formatter: function (value, context) {
            return `${value} /mês`;
          },
        },
      },
      {
        label: "Media Mês Estoque + Transito Qtd",
        data: vMediaMesCustoTransito,
        xAxisID: "B",
        backgroundColor: "rgba(255,255,255,0)",
        datalabels: {
          anchor: "start",
          borderColor: "#EB7442",
          opacity: 0.7,
          align: "start",
          rotation: 0,
          color: "rgb(105, 179, 76)",
          formatter: function (value, context) {
            return `${value} /mês`;
          },
        },
      },
      {
        label: "Media Mês Estoque + Aguardando Entrega Qtd",
        data: vMediaMesCustoAguardandoEntrega,
        xAxisID: "B",
        backgroundColor: "rgba(255,255,255,0)",
        datalabels: {
          anchor: "start",
          borderColor: "#EB7442",
          opacity: 0.7,
          align: "start",
          rotation: 0,
          color: "rgb(233, 122, 39)",
          formatter: function (value, context) {
            return `${value} /mês`;
          },
        },
      },
      {
        label: "Media Mês Estoque Total Qtd",
        data: vMediaMesCustoTotal,
        xAxisID: "B",
        backgroundColor: "rgba(255,255,255,0)",
        datalabels: {
          anchor: "start",
          borderColor: "#EB7442",
          opacity: 0.7,
          align: "start",
          rotation: 0,
          color: "rgb(0, 0, 0, 0.9)",
          formatter: function (value, context) {
            return `${value} /mês`;
          },
        },
      },
    ];

    dataSetQ = [
      {
        // type: 'bar',
        label: "Qtd Vendas",
        xAxisID: "A",
        data: qtdVendas,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        datalabels: {
          anchor: "end",
          display: true,
          color: "rgba(255, 99, 132)",
          align: "end",
          rotation: 0,
        },
      },
      {
        // type: 'bar',
        label: "Qtd Estoque",
        data: qtdEstoque,
        backgroundColor: "rgba(53, 162, 235, 0.4)",
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          display: true,
          color: "rgba(53, 162, 235)",
          align: "end",
          rotation: 0,
        },
      },
      {
        // type: 'bar',
        label: "Qtd Estoque + Em Transito",
        data: qtdEstoqueTransito,
        backgroundColor: "rgba(105, 179, 76, 0.5)",
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          display: true,
          color: "rgb(105, 179, 76)",
          align: "end",
          rotation: 0,
        },
      },
      {
        // type: 'bar',
        label: "Qtd Estoque + Aguardando Entrega",
        data: qtdEstoqueAguardandoEntrega,
        backgroundColor: "rgba(233, 122, 39, 0.5)",
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          display: true,
          color: "rgba(233, 122, 39)",
          align: "end",
          rotation: 0,
        },
      },
      {
        // type: 'bar',
        label: "Qtd Estoque Total",
        data: qtdEstoqueTotal,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          display: true,
          color: "rgba(0, 0, 0)",
          align: "end",
          rotation: 0,
        },
      },
      {
        label: "Media Mês Qtd",
        data: vMediaMesQtd,
        xAxisID: "B",
        backgroundColor: "rgba(255,255,255,0)",
        datalabels: {
          anchor: "start",
          borderRadius: "5",
          display: true,
          opacity: 0.7,
          padding: 3,
          // borderWidth: 2,
          align: "start",
          color: "rgb(75, 192, 192, 0.9)",
          formatter: function (value, context) {
            let teste = `${value} /mês`;
            return teste;
          },
        },
      },
      {
        label: "Media Mês Qtd Estoque + Transito",
        data: vMediaMesTransitoQtd,
        xAxisID: "B",
        backgroundColor: "rgba(255,255,255,0)",
        datalabels: {
          anchor: "start",
          borderRadius: "5",
          display: true,
          opacity: 0.7,
          padding: 3,
          align: "start",
          color: "rgb(105, 179, 76)",
          formatter: function (value, context) {
            let teste = `${value} /mês`;
            return teste;
          },
        },
      },
      {
        label: "Media Mês Qtd Estoque + Aguardando Entrega",
        data: vMediaMesAguardandoEntregaQtd,
        xAxisID: "B",
        backgroundColor: "rgba(255,255,255,0)",
        datalabels: {
          anchor: "start",
          borderRadius: "5",
          display: true,
          opacity: 0.7,
          padding: 3,
          align: "start",
          color: "rgb(233, 122, 39)",
          formatter: function (value, context) {
            let teste = `${value} /mês`;
            return teste;
          },
        },
      },
      {
        label: "Media Mês Qtd Total",
        data: vMediaMesTotalQtd,
        xAxisID: "B",
        backgroundColor: "rgba(255,255,255,0)",
        datalabels: {
          anchor: "start",
          borderRadius: "5",
          display: true,
          opacity: 0.7,
          padding: 3,
          align: "start",
          color: "rgb(0, 0, 0)",
          formatter: function (value, context) {
            let teste = `${value} /mês`;
            return teste;
          },
        },
      },
    ];

    dataSetTM = [
      {
        label: "TM Vendas",
        xAxisID: "A",
        data: tmVendas,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        datalabels: {
          anchor: "end",
          display: true,
          color: "rgba(255, 99, 132, 0.9)",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(parseFloat(value));
          },
        },
      },
      {
        label: "TM Estoque",
        data: tmEstoque,
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          color: "rgba(53, 162, 235, 0.9)",
          align: "end",
          formatter: function (value, context) {
            return new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(parseFloat(value));
          },
        },
      },
      {
        label: "Custo Vendas",
        data: vendasGrafico,
        backgroundColor: "rgba(255,255,255,0)",
        xAxisID: "B",
        datalabels: {
          anchor: "start",
          align: "end",
          color: "#000",
          offset: -2,
          formatter: function (value, context) {
            return new Intl.NumberFormat("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              style: "currency",
              currency: "BRL",
            }).format(parseFloat(value));
          },
        },
      },
      {
        label: "Media Mês Custo",
        data: vMediaMesCusto,
        xAxisID: "C",
        backgroundColor: "rgba(255,255,255,0)",
        datalabels: {
          anchor: "start",
          textAlign: "start",
          align: "start",
          color: "rgb(75, 192, 192, 0.9)",
          formatter: function (value, context) {
            return `${value} /mês`;
          },
        },
      },
    ];
    return {
      dataSetTM,
      response,
      titles,
      dataSet1,
      dataSetQ,
    };
  },
  getElementAtEvent: (element) => {
    if (!element.length) return;
    const {
      _index: index,
      _yScale: eixoY,
      _datasetIndex,
      _chart: canvas,
    } = element[0];
    const idGrafico = canvas.canvas.id;
    const nomeGrupo = eixoY.ticks[index];
    const indexType = _datasetIndex;
    return { nomeGrupo, indexType, idGrafico };
  },
  manipulacaoDadosTabelasPreencherContasPagar: (response) => {
    // console.log("response there -> ", response);
    response.forEach((item, index) => {
      // console.log("item there -> ", item);
      let diferenca = item.totalPagar - item.custo;
      let porcentagem = item.totalPagar ? item.custo / item.totalPagar : 0;
      if (!isNaN(porcentagem)) {
        porcentagem = parseFloat(porcentagem * 100).toFixed(2);
      }

      let valueMediaMesQtd = item.qtdEstoque / item.qtdVendas;
      if (isNaN(valueMediaMesQtd) || valueMediaMesQtd === Infinity) {
        valueMediaMesQtd = 0.0;
      }

      let valueMediaMesCusto = item.custoEstoque / item.CustoVendas;
      if (
        isNaN(valueMediaMesCusto) ||
        valueMediaMesCusto === Infinity ||
        valueMediaMesCusto === -Infinity
      ) {
        valueMediaMesCusto = 0.0;
      }

      response[index].diferenca = diferenca;
      response[index].porcentagem = porcentagem;
      response[index].mediaMesQtd = valueMediaMesQtd;
      response[index].mediaMesCusto = valueMediaMesCusto;
    });
    return { response };
  },
};
