import { createTheme } from "@mui/material";
const baseTheme = createTheme();

export const theme = createTheme({
  breakpoints: {
    ...baseTheme.breakpoints,
    values: {
      ...baseTheme.breakpoints.values,
      lg: 1045,
    },
  },
  palette: {
    background: {
      default: "#F0F0F0", // Substitua pela cor desejada
    },
    aprincipal: {
      main: "#ff7aae", // Substitua pela nova cor desejada

      contrastText: "#fff", // Texto de contraste (geralmente branco)
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          color: "#ff7aae", // Substitua pela cor desejada

          backgroundColor: "#ff7aae",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#ff7aae",
          "&.Mui-selected ": {
            color: "#ff7aae",
          },
        },
      },
    },
  },
});
