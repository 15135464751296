import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';

export const CollapseTable = ({
  rowKey,
  dadosParaTabela,
  expandRow,
  colunas,
  page,
  totalSize,
  handleTableChange,
}) => {

  const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
    <div className="container-table-responsiva">
      <BootstrapTable
        bootstrap4
        remote
        keyField={rowKey}
        data={ data }
        columns={ colunas }
        pagination={ paginationFactory({ page, sizePerPage, totalSize }) }
        onTableChange={ onTableChange }
        expandRow={expandRow}
      />
    </div>
  );

  return (
    <div>
      <RemotePagination
        data={ dadosParaTabela }
        page={ page }
        sizePerPage={ 10 }
        totalSize={ totalSize }
        onTableChange={ handleTableChange }
        handleTableChange={handleTableChange}
      />
    </div>
  );
};
