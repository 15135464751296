export const inputValues = [
    {
      id: "codFormaPagamentoBabyMais",
      type: "number",
      titleTooltip:
        "Código Baby+ | Esse campo salva o código baby+. É utilizado para filtrar os créditos que um cliente possui nas lojas da base",
      name: "Código Baby+",
      example: "Ex: 1",
    },
    {
      id: "codTipoVendaDesafio",
      type: "number",
      titleTooltip:
        "Código Tipo venda desafio | Utilizado nas regras da campanha cliente desafio",
      name: "Código Tipo venda desafio ",
      example: "Ex: 2",
    },
    {
      id: "vendaEntreLojasAtiva",
      type: "number",
      titleTooltip:
        "Venda entre lojas ativa? | Define se devem ser enviadas as mensagens de venda entre loja",
      name: "Venda entre lojas ativa?",
      example: "Ex: 0",
    },
    {
      id: "codCondicaoPgtoCaixaExpresso",
      type: "number",
      titleTooltip:
        "Condição Pgto CaixaExpresso | Código da condição de pagamento CaixaExpresso",
      name: "Condição Pgto Caixa Expresso",
      example: "Ex: 3",
    },

    {
      id: "codFormPgtoDescobriuGanhou",
      type: "number",
      titleTooltip:
        "Forma de pagamento Descobriu Ganhou | Utilizada na página de cadastro descobriu ganhou para adicionar os créditos ao cliente",
      name: "Forma de pagamento Descobriu Ganhou",
      example: "Ex: 4",
    },
    {
      id: "codGrupoMovimentacoesFinanceiras",
      type: "number",
      titleTooltip:
        "Código grupo Movimentações financeiras | Utilizado no planejamento orçamentário",
      name: "Código grupo Movimentações financeiras",
      example: "Ex: 5",
    },
    {
      id: "codTabelaBiEstoque",
      type: "number",
      titleTooltip:
        "Tabela de preços para o bi | Coluna ProdutosTabPreco.CodTabela que deve ser utilizada no bi de estoque",
      name: "Tabela de preços para o bi",
      example: "Ex: 10",
    },
    {
      id: "codClienteVendaDireta",
      type: "autocomplete",
      titleTooltip:
        "Venda direta ao consumidor | Códigos de Clientes.CodCliente que são para vendas direta ao consumidor",
      name: "Venda direta ao consumidor",
      example: "Ex: 1,2,3,4",
    },
    {
      id: "codTabelaEstoque",
      type: "autocomplete",
      titleTooltip:
        "Tabelas de estoque | Colunas LocalEstoque.CodLocal que devem ser utilizadas nos filtros do BI de estoque",
      name: "Tabelas de estoque",
      example: "Ex: 1,2,3,4",
    },
  ];