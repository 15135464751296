import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export function SimpleDialogDelete(props) {
  const { onClose, open, body = false, onDelete } = props;

  const handleClose = () => {
    onClose();
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Atenção!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!!body ? body : " Tem certeza que deseja excluir esse registro?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete}>
          Deletar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
