import React from "react";
import { Form } from "react-bootstrap";

export default function FilterBases({
  basesDisponiveis,
  basesSelecionadas,
  filtrarBases,
}) {
  return (
    <div className="buttonBase">
      <Form.Select
        className="formSelectBase"
        onChange={(e) => {
          filtrarBases(e.target.value);
        }}
      >
        {basesDisponiveis.map((item, index) => {
          return (
            <option
              key={item.codBase}
              value={item.codBase}
              selected={basesSelecionadas.find(
                (itemBase) => itemBase == item.codBase
              )}
            >
              {item.base}
            </option>
          );
        })}
      </Form.Select>
    </div>
  );
}
