import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { formatarNumero } from "../../../util/loja";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { RowsRender } from "./components/RowsRender";

export const FinancialPlanningForecast = ({ dataPage, isLoading }) => {
  const [openCollapseCollumn, setOpenCollapseCollumn] = useState(false);
  const colinasAux = [...dataPage.colunas];
  const colunas = colinasAux
    ?.map((item) => {
      if (item.id === "formaPagamento") {
        item.idC = "contas";
        item.textC = "Contas";
      } else {
        item.idC = item.id;
        item.textC = item.text;
      }
      if (item.id === "porcentagem") return null;
      return item;
    })
    ?.filter((item) => !!item);
  return (
    <Box>
      <Box component={Paper} variant="outlined" bgcolor={"#f0dede"}>
        <Typography fontWeight={"500"} textAlign={"center"}>
          Planejamento Financeiro Previsão
        </Typography>
      </Box>
      {isLoading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"130px"}
          component={Paper}
          variant="outlined"
        >
          <CircularProgress color="aprincipal" />
        </Box>
      ) : (
        <>
          {dataPage.colunas?.length === 0 &&
          dataPage.dadosPlanejamento?.length === 0 ? (
            <Box
              p={3}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              component={Paper}
              variant="outlined"
            >
              <Typography>Nenhum resultado encontrado</Typography>
            </Box>
          ) : (
            <TableContainer component={Paper} variant="outlined">
              <Table size={"small"} aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    {colunas.map((item) => (
                      <TableCell
                        sx={{
                          bgcolor: "rgb(0, 176, 240)",
                          cursor: "pointer",
                        }}
                        onClick={() => setOpenCollapseCollumn((old) => !old)}
                        key={item.idC}
                      >
                        {item.id === "formaPagamento" ? (
                          <Stack
                            gap={1}
                            direction={"row"}
                            alignItems={"center"}
                          >
                            {openCollapseCollumn ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )}
                            <Typography fontWeight={"500"}>
                              {item.text}
                            </Typography>
                          </Stack>
                        ) : (
                          item.textC
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {dataPage.dadosPlanejamento?.map((row) => {
                  return (
                    <RowsRender
                      row={row}
                      openCollapseCollumn={openCollapseCollumn}
                      dataPage={dataPage}
                    />
                  );
                })}
                {dataPage.dadosPlanejamento?.map(
                  ({ fontWeight, bg, contas, formula, order, ...row }) => {
                    if (!row?.noHide) return <></>;
                    delete row.noHide;

                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          bgcolor: bg,
                          fontWeight: fontWeight,
                        }}
                      >
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            fontWeight: fontWeight,
                            bgcolor: "#535353",
                          }}
                          component="th"
                          scope="row"
                        >
                          <Stack
                            gap={1}
                            justifyContent={"space-between"}
                            direction={"row"}
                          >
                            <Typography
                              fontSize={"0.875rem"}
                              fontWeight={fontWeight}
                              color={"white"}
                              component={"p"}
                            >
                              {contas}
                            </Typography>
                            <Typography
                              color={"white"}
                              fontSize={"0.875rem"}
                              component={"span"}
                            >
                              {order}
                            </Typography>
                          </Stack>
                        </TableCell>

                        {Object.keys(row)?.map((key, index) => {
                          const value = row[dataPage.colunas[index + 2]?.idC];
                          if (!value) {
                            return (
                              <TableCell
                                sx={{
                                  whiteSpace: "nowrap",
                                  fontWeight: fontWeight,
                                }}
                                key={key}
                                component="th"
                                scope="row"
                              ></TableCell>
                            );
                          }

                          return (
                            <TableCell
                              sx={{
                                whiteSpace: "nowrap",
                                fontWeight: fontWeight,
                              }}
                              key={key}
                              component="th"
                              scope="row"
                            >
                              <Typography
                                color={parseFloat(value) < 0 && "red"}
                              >
                                {formatarNumero(value)}
                              </Typography>
                              <Typography color={"GrayText"} variant="caption">
                                ({formula})
                              </Typography>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  }
                )}
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </Box>
  );
};
