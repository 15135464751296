import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import ReactInputMask from "react-input-mask";
import editarClientes from "../../services/editarClientes";
import { useEffect, useState } from "react";

export const FormularioPrincipal = ({
  control,
  Controller,
  register,
  setValue,
  setError,
  errors,
  availableStores = [],
}) => {
  const [stateAvailable, setStateAvailable] = useState([]);
  const getState = async () => {
    const response = await editarClientes.getEstados();
    if (response.status === 200) {
      setStateAvailable(
        response.data.map((item) => {
          delete item.regiao;
          return item;
        })
      );
    }
  };

  useEffect(() => {
    getState();
  }, []);
  const validaCep = (cep) => {
    if (cep === "") {
      return false;
    }
    if (cep.length !== 8) {
      return false;
    }
    return true;
  };
  const checkedCEP = async (value) => {
    const cep = value.replace(/[^\d]+/g, "");
    if (!validaCep(cep)) {
      setError("cep");

      return;
    }

    const response = await editarClientes.chekcedCep(cep);
    if (response.status === 200) {
      setValue("uf", response.data.uf);
      setValue("cidade", response.data.localidade);
      setValue("endereco", response.data.logradouro);
      setValue("bairro", response.data.bairro);
    }
  };
  return (
    <>
      <Stack direction={"row"} mb={1}>
        <FormControl fullWidth>
          <Controller
            control={control}
            name="situacao"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={<Switch checked={!!field.value} defaultChecked />}
                label={!!field.value ? "Ativo" : "Inativo"}
              />
            )}
          />
        </FormControl>
        <Stack direction={"row"} gap={1}>
          <FormControl
            sx={{
              minWidth: "170px",
            }}
          >
            <InputLabel size="small" id="select-pessoa">
              Loja
            </InputLabel>
            <Select
              labelId="select-Loja"
              id="simple-select-Loja"
              label="Loja"
              {...register("codLoja")}
              size="small"
              native
              placeholder="Loja"
              variant="outlined"
            >
              <option value={""}>Nenhuma loja</option>
              {availableStores.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              minWidth: "170px",
            }}
          >
            <Controller
              control={control}
              name="ultimaCompra"
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  disabled
                  label="Data da ultima compra"
                  placeholder="Data da ultima compra"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </FormControl>
        </Stack>
      </Stack>

      <Stack mb={1} width={"100%"} direction={"row"} gap={1}>
        <FormControl>
          <Controller
            name="codCliente"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                size="small"
                label="Codigo"
                placeholder="Codigo do cliente"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            {...register("nomeCliente", { require: true })}
            size="small"
            label="Nome"
            placeholder=""
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl
          sx={{
            minWidth: "120px",
          }}
        >
          <InputLabel size="small" id="select-pessoa">
            Pessoa
          </InputLabel>
          <Select
            labelId="select-pessoa"
            id="simple-select-pessoa"
            label="Pessoa"
            {...register("tipoPessoa")}
            size="small"
            native
            placeholder="Pessoa"
            variant="outlined"
          >
            <option>Fisica</option>
            <option>Juridica</option>
          </Select>
        </FormControl>
        <FormControl
          sx={{
            minWidth: "180px",
          }}
        >
          <TextField
            {...register("cpf")}
            size="small"
            label="CPF"
            placeholder=""
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </Stack>
      <Stack mb={1} width={"100%"} direction={"row"} gap={1}>
        <FormControl fullWidth>
          <TextField
            size="small"
            {...register("email")}
            label="Email"
            placeholder="Email"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>

        <FormControl
          sx={{
            minWidth: "120px",
          }}
        >
          <InputLabel size="small" id="select-sexo">
            Sexo
          </InputLabel>
          <Select
            labelId="select-sexo"
            id="simple-select-sexo"
            label="Sexo"
            {...register("sexo")}
            size="small"
            native
            placeholder="Sexo"
            variant="outlined"
          >
            <option value={1}>Masculino</option>
            <option value={0}>Femenino</option>
            <option value={2}>Não sei</option>
          </Select>
        </FormControl>
        <FormControl
          sx={{
            minWidth: "170px",
          }}
        >
          <Controller
            control={control}
            name="dataNascimento"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label="Data Nascimento"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </FormControl>
        <FormControl
          sx={{
            minWidth: "170px",
          }}
        >
          <Controller
            control={control}
            name="dataCadastro"
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                disabled
                label="Data Cadastro"
                placeholder="Data Cadastro"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </FormControl>
      </Stack>
      <Stack mb={1} width={"100%"} direction={"row"} gap={1}>
        <FormControl fullWidth>
          <TextField
            {...register("cep", { required: true })}
            size="small"
            label="CEP"
            placeholder="CEP"
            variant="outlined"
            error={!!errors.cep}
            onBlur={(e) => checkedCEP(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            {...register("cidade")}
            size="small"
            label="Cidade"
            placeholder="Cidade"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            {...register("bairro")}
            size="small"
            label="Bairro"
            placeholder="Bairro"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            {...register("endereco", { required: true })}
            size="small"
            label="Endereço"
            placeholder="Endereço"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </Stack>
      <Stack mb={1} width={"100%"} direction={"row"} gap={1}>
        <FormControl fullWidth>
          <InputLabel size="small" id="select-sexo">
            Estado
          </InputLabel>
          <Select
            labelId="select-uf"
            id="simple-select-uf"
            label="Estado"
            {...register("uf")}
            size="small"
            native
            placeholder="Estado"
            variant="outlined"
          >
            {stateAvailable.map((item) => (
              <option key={item.id} value={item.sigla}>
                {item.sigla} - {item.nome}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            {...register("numeroEndereco", { required: true })}
            size="small"
            label="Numero"
            placeholder="Numero"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            {...register("complemento")}
            size="small"
            label="Complemento"
            placeholder="Complemento"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <Controller
            control={control}
            name="fone1"
            render={({ field }) => (
              <ReactInputMask
                mask="(99) 99999-9999"
                maskChar={null}
                value={field.value}
                onChange={field.onChange}
              >
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    id="numeroWhatsapp"
                    label="Numero WhatsApp"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      ...inputProps.InputProps,
                      disabled: false,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText="(94) 99919-9191"
                  />
                )}
              </ReactInputMask>
            )}
          />
        </FormControl>
      </Stack>
    </>
  );
};
