import { Box, Grid, Paper, Skeleton } from "@mui/material";

export const SkeletonEstoqueContasPagarResumo = () => {
  return (
    <Box component={Paper} elevation={1} p={2} mx={2} mb={2} maxWidth={"906px"}>
      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 16 }}>
        {Array.from(Array(7)).map((_, index) => {
          const isTotal = index === 4;

          return (
            <Grid item xs={2} sm={4} md={isTotal ? 8 : 4} key={index}>
              <Box borderRadius={"4px"} overflow={"hidden"}>
                <Skeleton
                  variant="rectangular"
                  width={isTotal ? "100%" : 205}
                  height={70}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
