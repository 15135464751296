import { Box, Typography } from "@mui/material";

export const TextInfo = ({ label, children }) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Typography variant="subtitle1" component={"span"} fontWeight={"bold"}>
        {label}{" "}
      </Typography>
      <Typography
        marginBottom={0}
        component={"span"}
        variant="subtitle1"
        gutterBottom
      >
        {children   }
      </Typography>
    </Box>
  );
};
