import { api } from "./api.v2";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: async (id, params) => {
    return await api.get(`/jobs/${id}/candidates`, params);
  },
  get: async (id) => {
    return await api.get(`/jobs/candidates/${id}`);
  },
  delete: async (id) => {
    return await api.delete(`/jobs/candidate${id}`);
  },
};
