import {
  Box,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import Title from "../../../../components/title";
import { PermissionsPage } from "../../../../util/permissionsPage";
import { useEffect, useState } from "react";
import { LoadingTable } from "../../../../components/Loading/LoadingTable";
import { NoData } from "../../../../components/NoData/NoData";
import { StyledTableCell } from "../../../franchise/components/StyledTableCell";
import SearchIcon from "@mui/icons-material/Search";

import { api } from "../../../../services/api.v2";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { toast } from "react-hot-toast";
import { pink } from "@mui/material/colors";
import { useHistory } from "react-router-dom";
import { Rows } from "../components/Rows";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../../components/SearchComponent/SearchComponent";
import { debounce } from "throttle-debounce";
import { useUserData } from "../../../../hooks/useUserData";
import { isEmptyObject } from "../../../../util/isEmptyObject";

export const StoreList = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { userData } = useUserData();
  const [total, setTotal] = useState(0);
  const history = useHistory();

  const handleClick = () => {
    history.push("/cadastro/loja/criar");
  };

  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
    search_term: "",
  });
  const [data, setData] = useState([]);
  const handleChangePage = (event, newPage) => {
    setParams((old) => ({ ...old, page: newPage + 1 }));
  };
  const handleSearch = (ev) => {
    setParams((old) => ({ ...old, search_term: ev, page: 1, per_page: 10 }));
  };
  const handleChangeRowsPerPage = (event) => {
    setParams((old) => ({ ...old, per_page: +event.target.value, page: 1 }));
  };
  const handleDelete = async (id) => {
    try {
      await api.delete(`/register/stores/delete/${id}`);
      toast.success("Loja deletada com sucesso");
      const configParams = { ...params };
      setParams(configParams);
    } catch (error) {
      toast.error("Ocorreu um erro ao deletar");
      console.log(error);
    }
  };
  const debounceFunc = debounce(500, handleSearch);

  const getData = async () => {
    setIsLoadingData(true);
    try {
      const response = await api.get("/register/stores", {
        params,
      });
      setData(response.data.data);
      setTotal(response.data.total);
    } catch (error) {
      if (
        (error?.response && error?.response?.status === 400) ||
        error?.response?.status === 404
      ) {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      } else {
        toast.error("Erro ao buscar seus dados");
      }
    } finally {
      setIsLoadingData(false);
    }
  };
  useEffect(() => {
    if (!isEmptyObject(userData)) {
      getData();
    }
  }, [params, userData]);

  return (
    <>
      <>
        <Container className="containerFlexBox">
          <Title title="LISTA DE LOJAS" />
        </Container>
        {PermissionsPage("list_store") && (
          <>
            <Box
              component={Container}
              marginY={2}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  sx={{
                    borderRadius: "4px",
                    borderColor: "#d6d6d6 !important",
                    border: "1px solid",
                  }}
                  disabled={isLoadingData}
                  placeholder="Pesquisar  "
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => debounceFunc(e.target.value)}
                />
              </Search>

              <Tooltip title="Cadstrar nova loja">
                <IconButton
                  size="small"
                  sx={{ color: pink[400] }}
                  onClick={handleClick}
                >
                  <AddBoxIcon sx={{ width: 50, height: 50 }} />
                </IconButton>
              </Tooltip>
            </Box>

            {isLoadingData ? (
              <LoadingTable />
            ) : (
              <>
                <Container
                  sx={{
                    paddingBottom: "2rem",
                  }}
                >
                  {data.length <= 0 ? (
                    <Box
                      width={"100%"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <NoData />
                    </Box>
                  ) : (
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer>
                        <Table
                          stickyHeader
                          size={"small"}
                          aria-label="collapsible table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell />
                              <StyledTableCell>Opções</StyledTableCell>
                              <StyledTableCell>Loja</StyledTableCell>
                              <StyledTableCell>Sigla</StyledTableCell>
                              <StyledTableCell>Cidade</StyledTableCell>
                              <StyledTableCell>Localidade</StyledTableCell>
                              <StyledTableCell>WhatsApp</StyledTableCell>
                              <StyledTableCell>Instagram</StyledTableCell>
                              <StyledTableCell>ID Localidade</StyledTableCell>
                              <StyledTableCell>Base</StyledTableCell>
                              <StyledTableCell>CNPJ</StyledTableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {data.length > 0 &&
                              data.map((row) => (
                                <Rows handleDelete={handleDelete} row={row} />
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[
                          10,
                          25,
                          50,
                          100,
                          { label: "Todos", value: total },
                        ]}
                        component="div"
                        count={total}
                        rowsPerPage={params.per_page}
                        page={params.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={"Linhas por pagina"}
                        SelectProps={{
                          inputProps: {
                            "aria-label": "rows per page",
                          },
                          native: true,
                        }}
                      />
                    </Paper>
                  )}
                </Container>
              </>
            )}
          </>
        )}
      </>
    </>
  );
};
