import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import Title from "../../components/title";

import {
  Box,
  Paper,
  Container as ContainerMui,
  Typography,
  Stack,
} from "@mui/material";
import Select from "react-select";

import { Container, Row, Col } from "react-bootstrap";
import {
  arrayBases,
  converterData,
  converterDataFormatoPadrao,
  converterDia,
  converterLoja,
  converterMes,
  converterNomeLoja,
  fAnosDisponiveis,
  fAnosPorLoja,
} from "../../util/loja";
import FilterLojaSimples from "../../components/FilterLojaSimples";
import FilterBases from "../../components/filterBases";
import FilterAnoMes from "../../components/FilterAnoMes";

import Carregando from "../../components/Carregando";
import AlertaInfoPeriodo from "../../components/AlertaInfoPeriodo";
import ButtonVoltar from "../../components/ButtonVoltar";
import ButtonFiltrar from "../../components/ButtonFiltrar";
import ButtonHoje from "../../components/ButtonHoje";

import Alerta from "../../components/Alerta";
import {
  colunasDias2,
  colunaTabelaGlobalDesempenhoVendasPorDia2,
  colunasTabelasGlobaisVendasPorDia2,
} from "../../util/tables";
import TabelaNext from "../../components/componentteste/TabelaNext";
import { TituloTabela } from "../../components/TituloTabela/TituloTabela";
import {
  ContainerConteudo,
  ContainerDesempenho,
  ContainerTable,
  ContainerTudo,
  WrapperTable,
} from "../../styles/global.js";
import { FiltroPeriodo } from "../../components/FiltroPeriodo/FiltroPeriodo.jsx";
import toast from "react-hot-toast";

import { PermissionsPage } from "../../util/permissionsPage";
import { vendasService } from "../../services/vendas";
import { useUserData } from "../../hooks/useUserData";
import {
  ButtonFilter,
  ButtonToday,
} from "../../components/DatePikerFilter/styles";
import { MultiSelectFilterBases } from "../../components/FilterLojaMetas/MultiSelectFilterBases";
import { LoadingTable } from "../../components/Loading/LoadingTable";
import { SelectBases } from "../../components/SelectBases/SelectBases";
import { formatShopAvailable } from "../../formatdata/formatter-shop-available";

const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? " whitesmoke" : "white",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: " #eb0058",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#FFF",
    fontWeight: "bold",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: "#ff0d6c",
      color: "white",
    },
  }),
};

function VendasPorDia() {
  const { userData } = useUserData();
  const accessLevel = userData?.nivelAcesso;
  const acessBase = userData?.baseAcesso;
  const [availableStore, setAvailableStore] = useState();
  const [storesSelect, setStoreSelect] = useState(null);
  const [paramsConfig, setParamsConfig] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [periodoInputInicio, setPeriodoInputInicio] = useState("");
  const [periodoInputFim, setPeriodoInputFim] = useState("");
  const [periodoInputInicioAux, setPeriodoInputInicioAux] = useState("");
  const [periodoInputFimAux, setPeriodoInputFimAux] = useState("");
  const [periodoAno, setPeriodoAno] = useState(false);

  const [mesesDisponiveis, setMesesDisponiveis] = useState(false);
  const [anosDisponiveis, setAnosDisponiveis] = useState(false);
  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);
  const [dadosGraficoAgrupado, setDadosGraficoAgrupado] = useState({
    labels: [],
    datasets: [],
  });
  const [dadosGraficoDesempenho, setDadosGraficoDesempenho] = useState({
    labels: [],
    datasets: [],
  });
  const [dadosGerais, setDadosGerais] = useState(0);
  const [dadosGeraisDia, setDadosGeraisDia] = useState(0);
  const [dadosGlobaisDesempenho, setDadosGlobaisDesempenho] = useState(0);
  const [anosDisponiveisAuxiliar, setAnosDisponiveisAuxiliar] = useState([]);
  const [filtrosAtivosPeriodoTexto, setFiltrosAtivosPeriodoTexto] =
    useState("");

  const [filtrosMesesSelecionados, setFiltrosMesesSelecionados] = useState([]);
  const [filtrosAnosSelecionados, setFiltrosAnosSelecionados] = useState([]);
  const [filtrosLojasSelecionadas, setFiltrosLojasSelecionadas] = useState([]);
  const [codLojaAtiva, setCodLojaAtiva] = useState(false);

  const [colunasTabelaGlobalAnoAtual, setColunasTabelaGlobalAnoAtual] =
    useState([]);
  const [colunasTabelaGlobalAnoAnterior, setColunasTabelaGlobalAnoAnterior] =
    useState([]);
  const [colunasTabelaGlobalDesempenho, setColunasTabelaGlobalDesempenho] =
    useState([]);
  const [dadosParaTabela, setDadosParaTabela] = useState([]);
  const [dadosParaTabelaGlobalAnoAtual, setDadosParaTabelaGlobalAnoAtual] =
    useState([]);
  const [
    dadosParaTabelaGlobalAnoAnterior,
    setDadosParaTabelaGlobalAnoAnterior,
  ] = useState([]);
  const [dadosParaTabelaGlobalDesempenho, setDadosParaTabelaGlobalDesempenho] =
    useState([]);
  const [dadosUltimoPeriodo, setDadosUltimoPeriodo] = useState([]);
  const [exibeTabela, setExibeTabela] = useState(false);
  const [exibeTabelaGlobal, setExibeTabelaGlobal] = useState(false);
  const [tipoPeriodo, setTipoPeriodo] = useState("");
  const [filtradoPorLoja, setFiltradoPorLoja] = useState(false);
  const [nivelAtivoDoGraficoTotalVendas, setNivelAtivoDoGraficoTotalVendas] =
    useState(0);
  const [nivelAtivoDoGraficoDesempenho, setNivelAtivoDoGraficoDesempenho] =
    useState(0);
  const [numDiaAtivo, setNumDiaAtivo] = useState("");
  const [auxTipoPeriodo, setAuxTipoPeriodo] = useState("");
  const [auxAnoAtual, setAuxAnoAtual] = useState(0);
  const [auxAnoAnterior, setAuxAnoAnterior] = useState(0);
  const [nivelAcesso, setNivelAcesso] = useState([]);
  const [base, setBase] = useState("");
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [basesSelecionadas, setBasesSelecionadas] = useState([]);
  const [dataUltimoPeriodoHr, setDataUltimoPeriodoHr] = useState([]);

  const [horaNext, setHoraNext] = useState(false);
  const [dadosDisponiveis, setDadosDisponiveis] = useState(true);
  const [carregando, setCarregando] = useState(false);

  const [tituloTabelaNext, setTituloTabelaNext] = useState("");
  useEffect(() => {
    buscarNivelAcesso();
    setIsLoading(true);
  }, [userData, base]);

  useEffect(() => {
    // if (nivelAcesso.length == 0 || !permissions) { setIsLoading(true); setCarregando(true); buscarNivelAcesso(); return };

    if (
      periodoAno &&
      accessLevel?.length > 0 &&
      acessBase?.length > 0 &&
      base
    ) {
      preencherDados("ano");
    } else if (
      periodoInputInicio &&
      periodoInputInicio !== "" &&
      periodoInputFim &&
      periodoInputFim !== "" &&
      accessLevel?.length > 0 &&
      acessBase?.length > 0 &&
      base
    ) {
      preencherDados("input");
    }
  }, [periodoInputInicio, periodoInputFim, periodoAno, base, userData]);
  useEffect(() => {
    preencherInputPeriodo();
  }, []);
  useEffect(() => {
    if (codLojaAtiva) {
      preencherDadosVendasPorMes(codLojaAtiva);
    }
  }, [filtrosMesesSelecionados]);

  useEffect(() => {
    if (filtrosAnosSelecionados.length > 0) {
      iniciaFiltroPorAno();
    }
  }, [filtrosAnosSelecionados]);

  useEffect(() => {
    if (filtradoPorLoja) {
      iniciaFiltroPorLoja();
    }
  }, [filtrosLojasSelecionadas]);

  useEffect(() => {
    if (numDiaAtivo.trim() !== "") {
      const bollenHora = numDiaAtivo.trim() !== "" ? true : false;
      preencherDadosDiaEspecifico(tipoPeriodo);
      preencherColunasTabela(tipoPeriodo, bollenHora);
      setTituloTabelaNext("Vendas Por Hora do Dia");
      setHoraNext(bollenHora);
      preenchercolunasTabelasGlobais(bollenHora);
      return;
    } else {
      setHoraNext(false);
      return setTituloTabelaNext("Vendas Por Dia da Semana");
    }
  }, [numDiaAtivo, tipoPeriodo]);

  useEffect(() => {
    if (anosDisponiveis) {
      preencherColunasTabela(
        tipoPeriodo,
        numDiaAtivo.trim() != "" ? true : false
      );
    }
  }, [anosDisponiveis, filtrosAnosSelecionados]);
  const buscarNivelAcesso = async () => {
    if (accessLevel) {
      setNivelAcesso(accessLevel);
      setBasesDisponiveis(arrayBases(acessBase));
      if (acessBase.length > 0) {
        setBase(acessBase[0]);
        setBasesSelecionadas(acessBase[0]);
      }
    } else {
      setCarregando(false);
      setIsLoading(false);
    }
  };
  function preencherInputPeriodo() {
    var dataHoje = new Date();
    var [inicioAno, hoje] = converterDataFormatoPadrao(dataHoje);
    var { inicioAno: inicioAnoTexto, hoje: hojeTexto } =
      converterData(dataHoje);
    setPeriodoInputInicioAux(inicioAno);
    setPeriodoInputFimAux(hoje);
    setPeriodoInputInicio(inicioAno);
    setPeriodoInputFim(hoje);
    setFiltrosAtivosPeriodoTexto(" - " + inicioAnoTexto + " à " + hojeTexto);
  }

  async function preencherDados(tipo) {
    setTipoPeriodo(tipo);
    if (tipo != "mes") setAuxTipoPeriodo(tipo);
    setFiltradoPorLoja(false);
    setDadosDisponiveis(false);
    var lojasDisponiveisAux = [];
    let auxLojasSelecionadas = [];
    lojasDisponiveis.map((item) => {
      lojasDisponiveisAux.push(item);
    });
    setLojasDisponiveis(lojasDisponiveisAux);
    if (tipo == "input") {
      let inicio = periodoInputInicio.split("-");
      inicio = inicio[2] + "/" + inicio[1] + "/" + inicio[0];
      let fim = periodoInputFim.split("-");
      fim = fim[2] + "/" + fim[1] + "/" + fim[0];
      setFiltrosAtivosPeriodoTexto(" - " + inicio + " à " + fim);
      setExibeTabela(true);
      var anoAtual = periodoInputInicio.split("-")[0];
      var anoAnterior = parseInt(anoAtual) - 1;
      setAuxAnoAtual(anoAtual);
      setAuxAnoAnterior(anoAnterior);
    } else if (tipo == "ano") {
      var anoAtual = periodoAno.split(",");
      anoAtual = anoAtual.sort((a, b) => a - b);
      var anoAnterior;
      if (anoAtual.length > 1) {
        anoAnterior = anoAtual[anoAtual.length - 2];
      } else {
        anoAnterior = 0;
      }
      anoAtual = anoAtual[anoAtual.length - 1];
      setAuxAnoAtual(anoAtual);
      setAuxAnoAnterior(anoAnterior);
    }
    setCarregando(true);
    setIsLoading(true);
    const toastId = toast.loading("Esperando dados do RAD");
    window.scrollTo(0, 0);
    console.log({
      inicio: periodoInputInicio,
      fim: periodoInputFim,
      ano: periodoAno,

      tipo,
      nivelAcesso,
      base,
    });
    try {
      const response = await vendasService.listaVendasDiaSemana(
        JSON.stringify({
          inicio: periodoInputInicio,
          fim: periodoInputFim,
          ano: periodoAno,

          tipo,
          nivelAcesso,
          base,
        })
      );

      if (!response?.data) {
        toast.error("Não há conexão com o RAD", {
          id: toastId,
          duration: 7000,
          style: {
            borderRadius: "0.25rem",
            background: "#333",
            color: "#fff",
          },
        });
        setCarregando(false);
        setIsLoading(false);
        setDadosDisponiveis(false);
        return;
      }
      if (response?.data?.success && response?.data?.type) {
        toast.success("Dados recebidos", {
          id: toastId,
        });
        if (anosDisponiveis.length === 0 || !anosDisponiveis) {
          try {
            setAnosDisponiveis(fAnosDisponiveis(response.data.anos));
            setAnosDisponiveisAuxiliar(fAnosDisponiveis(response.data.anos));
          } catch (error) {
            setDadosDisponiveis(false);
            setCarregando(false);
          }
        }
        var dataVendas = response.data.data.vendas;
        let dataVendasPeriodoAnterior =
          response.data.data.vendasPeriodoAnterior;
        setDadosUltimoPeriodo(dataVendasPeriodoAnterior);
        setDadosGerais(dataVendas);
        dataVendas.map((a) => {
          if (auxLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) == -1) {
            auxLojasSelecionadas.push(converterLoja(a.CodLoja));
          }
        });
        if (filtrosLojasSelecionadas.length > 0) {
          dataVendas = dataVendas.filter((a) => {
            if (
              filtrosLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) >= 0
            ) {
              return true;
            } else {
              return false;
            }
          });
        }
        let grupoDeAnos = agruparPor(dataVendas, "ano");
        let grupoDeAnosArray = Object.entries(grupoDeAnos);
        let grupoDeAnoLoja = [];
        grupoDeAnosArray.map((item) => {
          let grupoDeLoja = agruparPor(item[1], "diaSemana");
          grupoDeAnoLoja.push(grupoDeLoja);
        });

        let resumoGrupoDeAnoLoja = [];
        grupoDeAnoLoja.map((item) => {
          let item2 = Object.entries(item);
          item2.map((item3) => {
            let total = 0;
            let totalPedidos = 0;
            let ano;
            let codLoja;
            let diaSemana;
            ano = item3[1][0].ano;
            diaSemana = item3[1][0].diaSemana;

            item3[1].map((item4, index) => {
              codLoja = converterLoja(item4.CodLoja);
              totalPedidos += parseInt(item4.totalPedidos);
              total += parseFloat(item4.VlrTotal);
            });
            let resumoLojaAno = {
              ano: ano,
              total: total,
              totalPedidos: totalPedidos,
              diaSemana: diaSemana,
            };
            resumoGrupoDeAnoLoja.push(resumoLojaAno);
          });
        });
        var agrupado = agruparPor(resumoGrupoDeAnoLoja, "ano");
        grupoDeAnosArray = Object.entries(agrupado);
        preencherDadosGraficoAgrupado(
          grupoDeAnosArray,
          anoAtual,
          anoAnterior,
          tipo,
          dataVendasPeriodoAnterior
        );
        preencherDadosTabelasGlobais(grupoDeAnos);
        setLojasDisponiveis(auxLojasSelecionadas);
        setDadosDisponiveis(true);
        setCarregando(false);
        setIsLoading(false);
      } else {
        setCarregando(false);
        setIsLoading(false);
        setDadosDisponiveis(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function preencherDadosDiaEspecifico(tipo) {
    setTipoPeriodo(tipo);
    let auxLojasSelecionadas = [];
    if (tipo != "mes") setAuxTipoPeriodo(tipo);
    setFiltradoPorLoja(false);
    if (tipoPeriodo == "mes") tipo = auxTipoPeriodo;
    if (tipo == "input") {
      let inicio = periodoInputInicio.split("-");
      inicio = inicio[2] + "/" + inicio[1] + "/" + inicio[0];
      let fim = periodoInputFim.split("-");
      fim = fim[2] + "/" + fim[1] + "/" + fim[0];
      setFiltrosAtivosPeriodoTexto(" - " + inicio + " à " + fim);
      setExibeTabela(true);
      let auxAnoAtual = periodoInputInicio.split("-")[0];
      let auxAnoAnterior = parseInt(auxAnoAtual) - 1;
      setAuxAnoAnterior(auxAnoAnterior);
    } else if (tipo == "ano") {
      let auxAnoAtual = periodoInputInicio.split(",");
      auxAnoAtual = auxAnoAtual.sort((a, b) => a - b);
      let auxAnoAnterior;
      if (auxAnoAtual.length > 1) {
        auxAnoAnterior = auxAnoAtual[auxAnoAtual.length - 2];
      } else {
        auxAnoAnterior = 0;
      }
      auxAnoAtual = auxAnoAtual[auxAnoAtual.length - 1];
      setAuxAnoAnterior(auxAnoAnterior);
    }
    setCarregando(true);
    setIsLoading(true);
    window.scrollTo(0, 0);

    try {
      const response = await vendasService.listaVendasDiaSemana(
        JSON.stringify({
          inicio: periodoInputInicio,
          fim: periodoInputFim,
          ano: periodoAno,

          tipo,
          numDiaAtivo,
          nivelAcesso,
          base,
        })
      );

      if (response?.data?.success && response?.data?.type) {
        var dataVendas = response.data.data.vendas;
        var dataUltimoPeriodo = response.data.data.vendasPeriodoAnterior;
        setDadosGeraisDia(dataVendas);
        dataVendas.map((a) => {
          if (auxLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) == -1) {
            auxLojasSelecionadas.push(converterLoja(a.CodLoja));
          }
        });
        if (filtrosLojasSelecionadas.length > 0) {
          dataVendas = dataVendas.filter((a) => {
            if (
              filtrosLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) >= 0
            ) {
              return true;
            } else {
              return false;
            }
          });
        }
        let grupoDeAnos = agruparPor(dataVendas, "ano"),
          grupoDeAnosArray = Object.entries(grupoDeAnos),
          separadoPorLoja = [];

        grupoDeAnosArray.map((item) => {
          let grupoDeLoja = agruparPor(item[1], "hora");
          separadoPorLoja.push(grupoDeLoja);
        });
        let resumoSeparadoPorLoja = [];
        separadoPorLoja.map((item) => {
          let item2 = Object.entries(item);
          item2.map((item3) => {
            let total = 0,
              totalPedidos = 0,
              ano,
              codGrupo,
              hora,
              totalCusto = 0,
              totalLucro = 0,
              porcentagemLucro = 0,
              codLoja = 0;
            item3[1].map((item4, index) => {
              if (index == 0) {
                ano = item4.ano;
                hora = item4.hora;
                codGrupo = item4.CodGrp3;
              }
              totalPedidos += !isNaN(parseInt(item4.totalPedidos))
                ? parseInt(item4.totalPedidos)
                : 0;
              total += !isNaN(parseFloat(item4.VlrTotal))
                ? parseFloat(item4.VlrTotal)
                : 0;
              totalCusto += !isNaN(parseFloat(item4.VlrCusto))
                ? parseFloat(item4.VlrCusto)
                : 0;
              totalLucro += !isNaN(parseFloat(item4.VlrLucroBruto))
                ? parseFloat(item4.VlrLucroBruto)
                : 0;
              porcentagemLucro += !isNaN(
                parseFloat(item4.VlrLucroBrutoPorcentagem)
              )
                ? parseFloat(item4.VlrLucroBrutoPorcentagem)
                : 0;
              codLoja = item4.CodLoja;
            });
            porcentagemLucro = porcentagemLucro / item3[1].length;
            let resumoLojaAno = {
              ano: ano,
              hora,
              nomeGrupo: hora,
              total: total,
              totalPedidos: totalPedidos,
              totalCusto,
              totalLucro,
              porcentagemLucro,
              codLoja,
            };
            resumoSeparadoPorLoja.push(resumoLojaAno);
          });
        });
        let agrupado = agruparPor(resumoSeparadoPorLoja, "ano");

        grupoDeAnosArray = Object.entries(agrupado);

        let grupoHoraUP = agruparPor(dataUltimoPeriodo, "hora");

        let grupoHorasArrayUP = Object.entries(grupoHoraUP);
        setDataUltimoPeriodoHr(grupoHorasArrayUP);
        grupoHorasArrayUP = grupoHorasArrayUP.map((item) => {
          const total = item[1].reduce((a, b) => {
            return a + Number(b.VlrTotal);
          }, 0);

          return { hora: item[0], total };
        });

        preencherDadosGraficoAgrupadoGrupo(
          grupoDeAnosArray,
          auxAnoAtual,
          auxAnoAnterior,
          grupoHorasArrayUP,
          dataUltimoPeriodo
        );
        preencherDadosTabelasGlobaisHora(grupoDeAnos);
        setDadosDisponiveis(true);
        setCarregando(false);
        setIsLoading(false);
      } else {
        setCarregando(false);
        setIsLoading(false);
        setDadosDisponiveis(false);
      }
    } catch (error) {}
  }

  function agruparPor(objetoArray, propriedade) {
    return objetoArray.reduce(function (acc, obj) {
      let key = obj[propriedade];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  function insertAt(array, index, element) {
    if (array.length == index && array.length == 0) {
      array.push(0);
      array.splice(index, 1, element);
      return array;
    }
    if (array.length < index) {
      do {
        array.push(0);
      } while (array.length < index);
      array.splice(index, 1, element);
      return array;
    } else {
      array.splice(index, 1, element);
      return array;
    }
  }

  function preencherColunasTabela(tipo, hora) {
    // setColunasTabela(colunasDias(tipo, anosDisponiveis, filtrosAnosSelecionados, hora));
    // setColunasTabelaResumo(colunasResumo(anosDisponiveis, filtrosAnosSelecionados))
  }

  function preenchercolunasTabelasGlobais(hora = false) {
    setColunasTabelaGlobalAnoAtual(colunasTabelasGlobaisVendasPorDia2(hora));
    setColunasTabelaGlobalAnoAnterior(colunasTabelasGlobaisVendasPorDia2(hora));
    setColunasTabelaGlobalDesempenho(
      colunaTabelaGlobalDesempenhoVendasPorDia2(hora)
    );
  }

  function iniciaFiltroPorAno() {
    var anosSelecionados = filtrosAnosSelecionados.join(",");
    setPeriodoAno(anosSelecionados);
  }

  function iniciaFiltroPorLoja() {
    preencherDadosVendasPorLoja();
  }

  function iniciarFiltroHoje() {
    var dataHoje = new Date();
    var [, hoje] = converterDataFormatoPadrao(dataHoje);

    setPeriodoInputInicio(hoje);
    setPeriodoInputFim(hoje);

    setPeriodoInputInicioAux(hoje);
    setPeriodoInputFimAux(hoje);
  }
  async function preencherDadosGraficoAgrupado(
    dados,
    anoAtual,
    anoAnterior,
    tipo,
    dadosPeriodoAnterior
  ) {
    dados = dados.reverse();
    var label = [];
    var labelCompleta = [];
    var dataSets = [];
    var dadosTabelaAux = [];

    const promise = await dados.map((item, i) => {
      var dadosGrupo = [];
      let domingo = 0;
      let segunda = 0;
      let terca = 0;
      let quarta = 0;
      let quinta = 0;
      let sexta = 0;
      let sabado = 0;
      item[1].map((itemGrupo) => {
        switch (itemGrupo.diaSemana) {
          case "1":
            domingo += itemGrupo.total;
            break;
          case "2":
            segunda += itemGrupo.total;
            break;
          case "3":
            terca += itemGrupo.total;
            break;
          case "4":
            quarta += itemGrupo.total;
            break;
          case "5":
            quinta += itemGrupo.total;
            break;
          case "6":
            sexta += itemGrupo.total;
            break;
          case "7":
            sabado += itemGrupo.total;
            break;
        }
        var nomeDia = converterDia(itemGrupo.diaSemana);

        let jaExiste = label.indexOf(nomeDia);
        if (jaExiste < 0) {
          label.push(nomeDia);
          labelCompleta.push(nomeDia);
        }
        dadosTabelaAux.push(itemGrupo);
      });

      dadosGrupo = [domingo, segunda, terca, quarta, quinta, sexta, sabado];
      var cor =
        item[0] == anoAnterior
          ? "rgba(255, 194, 218, 1)"
          : item[0] == anoAtual
          ? "rgba(91, 139, 252, 1)"
          : i == dados.length - 3
          ? "#5B8BFC"
          : i == dados.length - 4
          ? "#FFC2DA"
          : i == dados.length - 5
          ? ""
          : geraCorAleatoria();
      var datalabels;
      if (item[0] == anoAtual) {
        datalabels = {
          anchor: "end",
          display: true,
          color: "#000",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        };
      } else if (item[0] == anoAnterior) {
        datalabels = {
          anchor: "start",
          display: true,
          color: cor,
          align: "start",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        };
      } else {
        datalabels = {
          formatter: function (value, context) {
            return "";
          },
        };
      }
      dataSets.push({
        label: item[0],
        data: dadosGrupo,
        backgroundColor: cor,
        datalabels: datalabels,
      });
    });

    if (dadosPeriodoAnterior && dadosPeriodoAnterior?.length > 0) {
      if (filtrosLojasSelecionadas.length > 0) {
        var dadosGrupoPALoja = [];
        let domingo = 0;
        let segunda = 0;
        let terca = 0;
        let quarta = 0;
        let quinta = 0;
        let sexta = 0;
        let sabado = 0;
        await dadosPeriodoAnterior.forEach((item) => {
          var nomeLoja = converterLoja(item.CodLoja);
          if (filtrosLojasSelecionadas.indexOf(nomeLoja) >= 0) {
            switch (item.diaSemana) {
              case "1":
                domingo += parseFloat(item.VlrTotal);
                break;
              case "2":
                segunda += parseFloat(item.VlrTotal);
                break;
              case "3":
                terca += parseFloat(item.VlrTotal);
                break;
              case "4":
                quarta += parseFloat(item.VlrTotal);
                break;
              case "5":
                quinta += parseFloat(item.VlrTotal);
                break;
              case "6":
                sexta += parseFloat(item.VlrTotal);
                break;
              case "7":
                sabado += parseFloat(item.VlrTotal);
                break;
            }
          }
        });
        dadosGrupoPALoja = [
          domingo,
          segunda,
          terca,
          quarta,
          quinta,
          sexta,
          sabado,
        ];
        dataSets.push({
          label: "Periodo anterior",
          data: dadosGrupoPALoja,
          backgroundColor: "#FFDB8A",
          datalabels: {
            formatter: function (value) {
              return parseFloat(value).toLocaleString("pt-BR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
            },
          },
        });
      } else {
        var dadosGrupoPA = [];
        let domingo = 0;
        let segunda = 0;
        let terca = 0;
        let quarta = 0;
        let quinta = 0;
        let sexta = 0;
        let sabado = 0;
        await dadosPeriodoAnterior.forEach((item) => {
          switch (item.diaSemana) {
            case "1":
              domingo += parseFloat(item.VlrTotal);
              break;
            case "2":
              segunda += parseFloat(item.VlrTotal);
              break;
            case "3":
              terca += parseFloat(item.VlrTotal);
              break;
            case "4":
              quarta += parseFloat(item.VlrTotal);
              break;
            case "5":
              quinta += parseFloat(item.VlrTotal);
              break;
            case "6":
              sexta += parseFloat(item.VlrTotal);
              break;
            case "7":
              sabado += parseFloat(item.VlrTotal);
              break;
          }
        });

        dadosGrupoPA = [domingo, segunda, terca, quarta, quinta, sexta, sabado];
        dataSets.push({
          label: "Periodo anterior",
          data: dadosGrupoPA,
          backgroundColor: "#FFDB8A",
          datalabels: {
            formatter: function (value, context) {
              return parseFloat(value).toLocaleString("pt-BR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
            },
          },
        });
      }
    }

    await Promise.all(promise);
    const data = {
      labels: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ],
      datasets: dataSets,
    };
    dadosTabelaAux = agruparPor(dadosTabelaAux, "diaSemana");
    dadosTabelaAux = Object.entries(dadosTabelaAux);
    //preencherColunasTabela(tipo)
    preencherDadosTabela(
      dadosTabelaAux,
      anoAtual,
      anoAnterior,
      dadosPeriodoAnterior
    );
    setDadosGraficoAgrupado(data);
    setNivelAtivoDoGraficoTotalVendas(0);
  }

  async function preencherDadosGraficoAgrupadoGrupo(
    dados,
    anoAtual,
    anoAnterior,
    dataUltimoPeriodo,
    dataUltimoPeriodoAux = false
  ) {
    dados = dados.reverse();

    var label = [];
    var labelCompleta = [];
    var dataSets = [];
    var dadosTabelaAux = [];
    var auxDataSets = [];
    const promise = await dados.map((item, i) => {
      var dadosGrupo = [];
      var maiorDataSet = 0;
      auxDataSets.map((item) => {
        maiorDataSet = maiorDataSet < item.length ? item.length : maiorDataSet;
      });

      for (let i = 0; i < maiorDataSet.length; i++) {
        dadosGrupo.push(0);
      }
      item[1].map((itemGrupo) => {
        var hora = itemGrupo.hora;

        if (i == 0) {
          label.push(hora);

          labelCompleta.push(hora);
          dadosGrupo.push(parseFloat(itemGrupo.total));
        } else {
          let jaExiste = label.indexOf(hora);
          if (jaExiste < 0) {
            label.push(hora);
          }
          jaExiste = label.indexOf(hora);
          if (jaExiste < 0) {
            labelCompleta.push(hora);
            dadosGrupo.push(parseFloat(itemGrupo.total));
          } else {
            dadosGrupo = insertAt(
              dadosGrupo,
              jaExiste,
              parseFloat(itemGrupo.total)
            );
          }
        }

        dadosTabelaAux.push(itemGrupo);
      });
      var cor =
        i == dados.length - 1
          ? "rgba(255, 194, 218, 1)"
          : i == dados.length - 2
          ? "rgba(91, 139, 252, 1)"
          : i == dados.length - 3
          ? "#5B8BFC"
          : i == dados.length - 4
          ? "#FFC2DA"
          : i == dados.length - 5
          ? ""
          : geraCorAleatoria();
      var datalabels;
      if (item[0] == auxAnoAtual) {
        datalabels = {
          anchor: "end",
          display: true,
          color: "#000",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        };
      } else if (item[0] == auxAnoAnterior) {
        datalabels = {
          anchor: "start",
          display: true,
          color: cor,
          align: "start",
          rotation: -15,
          formatter: function (value) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        };
      } else {
        datalabels = {
          formatter: function () {
            return "";
          },
        };
      }
      auxDataSets.push(dadosGrupo);
      dataSets.push({
        label: item[0],
        data: dadosGrupo,
        backgroundColor: cor,
        datalabels: datalabels,
        barThickness: "flex",
      });
    });

    if (dataUltimoPeriodo && dataUltimoPeriodo?.length > 0) {
      if (filtrosLojasSelecionadas.length > 0) {
        var dataUltimoPeriodoAux = [];

        await dataUltimoPeriodo.forEach((item) => {
          item[1].forEach((item2) => {
            var nomeLoja = converterLoja(item2.CodLoja);
            if (filtrosLojasSelecionadas.indexOf(nomeLoja) >= 0) {
              dataUltimoPeriodoAux.push(item2.VlrTotal);
            }
          });
        });

        dataSets.push({
          label: "Periodo anterior",
          data: dataUltimoPeriodoAux,
          backgroundColor: "#FFDB8A",
          datalabels: {
            formatter: function (value, context) {
              return parseFloat(value).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            },
          },
        });
      } else {
        let dataUltimoPeriodoAux = dataUltimoPeriodo.map((item) => {
          return item.total;
        });

        dataSets.push({
          label: "Periodo anterior",
          data: dataUltimoPeriodoAux,
          backgroundColor: "#FFDB8A",
        });
      }
    }

    await Promise.all(promise);
    const data = {
      labels: label,
      datasets: dataSets,
    };
    dadosTabelaAux = agruparPor(dadosTabelaAux, "hora");
    dadosTabelaAux = Object.entries(dadosTabelaAux);
    setDadosGraficoAgrupado(data);
    //preencherColunasTabela(tipoPeriodo)
    preencherDadosTabela(
      dadosTabelaAux,
      anoAtual,
      anoAnterior,
      dataUltimoPeriodoAux ? dataUltimoPeriodoAux : dataUltimoPeriodo,
      !!dataUltimoPeriodoAux
    );

    setNivelAtivoDoGraficoTotalVendas(1);
  }

  function geraCorAleatoria() {
    var hexadecimais = "0123456789ABCDEF";
    var cor = "#";

    for (var i = 0; i < 6; i++) {
      cor += hexadecimais[Math.floor(Math.random() * 16)];
    }
    return cor;
  }

  function preencherDadosTabela(
    dados,
    anoAtual,
    anoAnterior,
    dadosPeriodoAnterior,
    isHora = false
  ) {
    var dadosTabela = [];
    var dadosGraficoDesempenho = [];

    var quantidadeLojasAnoAtual = 0,
      quantidadeLojasAnoAnterior = 0;

    let qtdAnoAtualResumo = 0,
      vlrAnoAtualResumo = 0,
      vlrMedioAnoAtualResumo = 0,
      qtdAnoAnteriorResumo = 0,
      vlrTotalAnoAnteriorResumo = 0,
      vlrMedioAnoAnteriorResumo = 0,
      desempenhoPAResumo = 0,
      desempenhoResumo = 0,
      rprAnoAtual = 0,
      rprAnoAnterior = 0,
      qtdPeriodoAnteriorResumo = 0,
      rprPeriodoAnterior = 0,
      vlrTotalPeriodoAnteriorResumo = 0,
      vlrMedioPeriodoAnteriorResumo = 0;

    var totalPedidosAnoAtual = 0;
    var totalPedidosAnoAnterior = 0;
    var totalPedidosPeriodoAnterior = 0;

    var newDados2 = [];
    if (dados.length > 0) {
      if (dadosPeriodoAnterior?.length) {
        dadosPeriodoAnterior?.map((item) => {
          totalPedidosPeriodoAnterior += Number(item.totalPedidos);
        });
      }

      dados.map((item) => {
        var newDadosAux = [];
        var newDados = [];
        item[1].map((item2, index2) => {
          if (item2.ano == anoAtual) {
            if (newDadosAux.indexOf(item2.ano) == -1) {
              // newDadosAux.push(item.ano);
              newDados.push({
                ano: item2.ano,
                total: item2.total,
                codLoja: item2.codLoja,
                totalPedidos: item2.totalPedidos,
                diaSemana: item2.diaSemana,
              });
              newDadosAux.push(item2.ano);
            } else {
              newDados[newDadosAux.indexOf(item2.ano)].total += item2.total;
              newDados[newDadosAux.indexOf(item2.ano)].totalPedidos +=
                item2.totalPedidos;
            }
            totalPedidosAnoAtual += parseInt(item2.total);
          } else if (item2.ano == anoAnterior) {
            if (newDadosAux.indexOf(item2.ano) == -1) {
              // newDadosAux.push(item.ano);
              newDados.push({
                ano: item2.ano,
                total: item2.total,
                codLoja: item2.codLoja,
                totalPedidos: item2.totalPedidos,
                diaSemana: item2.diaSemana,
              });
              newDadosAux.push(item2.ano);
            } else {
              newDados[newDadosAux.indexOf(item2.ano)].total += item2.total;
              newDados[newDadosAux.indexOf(item2.ano)].totalPedidos +=
                item2.totalPedidos;
            }
            totalPedidosAnoAnterior += parseInt(item2.total);
          }
        });
        newDados2.push([item[0], newDados]);
      });
      newDados2.map((item) => {
        let loja = parseInt(item[0]);
        let qtdAnoAtual = 0,
          vlrAnoAtual = 0,
          vlrMedioAnoAtual = 0,
          qtdAnoAnterior = 0,
          desempPeriodoAnterior = 0,
          vlrTotalAnoAnterior = 0,
          vlrMedioAnoAnterior = 0,
          desempenho = 0,
          representatividadeAtual = 0,
          representatividadeAnterior = 0;

        const periodoAnteriorItem = dadosPeriodoAnterior?.filter((item) => {
          if (isHora) {
            return Number(item?.hora) === loja;
          } else {
            return Number(item?.diaSemana) === loja;
          }
        });
        let vlrPeriodoAnterior = periodoAnteriorItem
          ? periodoAnteriorItem.reduce((acc, item) => {
              return acc + Number(item.VlrTotal);
            }, 0) /*Number(periodoAnteriorItem?.VlrTotal)*/
          : 0;
        let qtdPeriodoAnterior = periodoAnteriorItem
          ? periodoAnteriorItem.reduce((acc, item) => {
              return acc + Number(item.totalPedidos);
            }, 0) /*Number(periodoAnteriorItem?.totalPedidos)*/
          : 0;
        let vlrMedioPeriodoAnterior =
          periodoAnteriorItem && qtdPeriodoAnterior
            ? vlrPeriodoAnterior / qtdPeriodoAnterior
            : 0;
        let representatividadePeriodoAnterior =
          periodoAnteriorItem && totalPedidosPeriodoAnterior
            ? Number(
                (
                  (qtdPeriodoAnterior / totalPedidosPeriodoAnterior) *
                  100
                ).toFixed(2)
              )
            : 0;
        qtdPeriodoAnteriorResumo += qtdPeriodoAnterior;
        vlrTotalPeriodoAnteriorResumo += vlrPeriodoAnterior;

        rprPeriodoAnterior += Number(
          representatividadePeriodoAnterior.toFixed(2)
        );

        let auxValorAnoAnterior = 0,
          auxValorAnoAtual = 0;
        var separaDoisUltimosAnos = [];
        if (item[1][0]) {
          separaDoisUltimosAnos.push(item[1][0]);
        }
        if (item[1][1]) {
          separaDoisUltimosAnos.push(item[1][1]);
        } else {
          let ano = (parseInt(item[1][0].ano) - 1).toString();
          let diaSemana = item[1][0].diaSemana;
          let total = 0;
          let totalPedidos = 0;
          separaDoisUltimosAnos.push({ ano, diaSemana, total, totalPedidos });
        }

        separaDoisUltimosAnos.map((item2, index2) => {
          if (index2 == 0) {
            qtdAnoAtual = parseInt(item2.totalPedidos);
            vlrAnoAtual = parseFloat(parseFloat(item2.total).toFixed(2));
            auxValorAnoAtual += parseFloat(item2.total);
            vlrMedioAnoAtual = parseFloat(
              (vlrAnoAtual / qtdAnoAtual).toFixed(2)
            );
            qtdAnoAtualResumo += qtdAnoAtual;
            vlrAnoAtualResumo += vlrAnoAtual;
            vlrMedioAnoAtualResumo += vlrMedioAnoAtual;
            if (qtdAnoAtual > 0) {
              quantidadeLojasAnoAtual++;
            }
          } else if (index2 == 1) {
            qtdAnoAnterior = parseInt(item2.totalPedidos);
            vlrTotalAnoAnterior = parseFloat(
              parseFloat(item2.total).toFixed(2)
            );
            auxValorAnoAnterior += parseFloat(item2.total);
            vlrMedioAnoAnterior =
              qtdAnoAnterior == 0
                ? 0
                : parseFloat((vlrTotalAnoAnterior / qtdAnoAnterior).toFixed(2));
            qtdAnoAnteriorResumo += qtdAnoAnterior;
            vlrTotalAnoAnteriorResumo += vlrTotalAnoAnterior;
            vlrMedioAnoAnteriorResumo += vlrMedioAnoAnterior;
            if (qtdAnoAnterior != 0) {
              quantidadeLojasAnoAnterior++;
            }
          }
        });
        if (qtdAnoAnterior != 0) {
          desempenho = parseFloat(
            ((auxValorAnoAtual / auxValorAnoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          desempenho = 0;
        }

        if (qtdPeriodoAnterior != 0) {
          desempPeriodoAnterior = parseFloat(
            ((auxValorAnoAtual / vlrPeriodoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          desempPeriodoAnterior = 0;
        }

        dadosGraficoDesempenho.push({ loja, desempenho });
        desempenhoResumo += desempenho;
        representatividadeAtual = parseFloat(
          ((vlrAnoAtual / totalPedidosAnoAtual) * 100).toFixed(2)
        );
        representatividadeAnterior = parseFloat(
          ((vlrTotalAnoAnterior / totalPedidosAnoAnterior) * 100).toFixed(2)
        );
        rprAnoAtual += representatividadeAtual;
        rprAnoAnterior += representatividadeAnterior;
        if (isNaN(loja)) {
          loja = "--//--";
        }
        dadosTabela.push({
          id: loja,
          loja: loja,
          qtdAnoAtual: qtdAnoAtual,
          vlrAnoAtual: vlrAnoAtual,
          vlrMedioAnoAtual: vlrMedioAnoAtual,
          qtdAnoAnterior: qtdAnoAnterior,
          vlrTotalAnoAnterior: vlrTotalAnoAnterior,
          vlrMedioAnoAnterior: vlrMedioAnoAnterior,
          desempPeriodoAnterior: desempPeriodoAnterior,
          desempenho: desempenho,
          representatividadeAtual: representatividadeAtual,
          representatividadeAnterior: representatividadeAnterior,
          vlrPeriodoAnterior: vlrPeriodoAnterior,
          qtdPeriodoAnterior: qtdPeriodoAnterior,
          vlrMedioPeriodoAnterior: vlrMedioPeriodoAnterior,
          representatividadePeriodoAnterior: representatividadePeriodoAnterior,
        });
      });
    }

    vlrMedioAnoAtualResumo = parseFloat(
      (vlrMedioAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );
    vlrMedioAnoAnteriorResumo = parseFloat(
      (vlrMedioAnoAnteriorResumo / quantidadeLojasAnoAnterior).toFixed(2)
    );

    vlrMedioPeriodoAnteriorResumo = parseFloat(
      (vlrTotalPeriodoAnteriorResumo / qtdPeriodoAnteriorResumo).toFixed(2)
    );

    desempenhoResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalAnoAnteriorResumo - 1) * 100).toFixed(2)
    );

    desempenhoPAResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalPeriodoAnteriorResumo - 1) * 100).toFixed(2)
    );

    dadosTabela.push({
      loja: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrMedioAnoAtual: vlrMedioAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      vlrMedioAnoAnterior: vlrMedioAnoAnteriorResumo,
      desempPeriodoAnterior: desempenhoPAResumo,
      desempenho: desempenhoResumo,
      representatividadeAtual: rprAnoAtual,
      representatividadeAnterior: rprAnoAnterior,
      vlrPeriodoAnterior: vlrTotalPeriodoAnteriorResumo,
      qtdPeriodoAnterior: qtdPeriodoAnteriorResumo,
      vlrMedioPeriodoAnterior: vlrMedioPeriodoAnteriorResumo,
      representatividadePeriodoAnterior: rprPeriodoAnterior,
    });
    setDadosParaTabela(dadosTabela);
    preencherDadosGraficoDesempenho(dadosGraficoDesempenho);
  }
  function preencherDadosTabelasGlobais(dados) {
    let grupoDeAnosArray = Object.entries(dados);
    grupoDeAnosArray = grupoDeAnosArray.reverse();
    if (grupoDeAnosArray.length > 1) {
      var separaDoisUltimosAnos = [grupoDeAnosArray[0], grupoDeAnosArray[1]];
      var dadosTabelaGlobalAnoAtual = [];
      var dadosTabelaGlobalAnoAnterior = [];
      var dadosTabelaGlobalDesempenho = [];
      let totalDesempenhoAux = 0;
      let janTotalAnoAtual = 0,
        fevTotalAnoAtual = 0,
        marTotalAnoAtual = 0,
        abrTotalAnoAtual = 0,
        maiTotalAnoAtual = 0,
        junTotalAnoAtual = 0,
        julTotalAnoAtual = 0,
        agoTotalAnoAtual = 0,
        setTotalAnoAtual = 0,
        outTotalAnoAtual = 0,
        novTotalAnoAtual = 0,
        dezTotalAnoAtual = 0,
        totalAnoAtual = 0;
      let janTotalAnoAnterior = 0,
        fevTotalAnoAnterior = 0,
        marTotalAnoAnterior = 0,
        abrTotalAnoAnterior = 0,
        maiTotalAnoAnterior = 0,
        junTotalAnoAnterior = 0,
        julTotalAnoAnterior = 0,
        agoTotalAnoAnterior = 0,
        setTotalAnoAnterior = 0,
        outTotalAnoAnterior = 0,
        novTotalAnoAnterior = 0,
        dezTotalAnoAnterior = 0,
        totalAnoAnterior = 0;
      let janDesempenho = 0,
        fevDesempenho = 0,
        marDesempenho = 0,
        abrDesempenho = 0,
        maiDesempenho = 0,
        junDesempenho = 0,
        julDesempenho = 0,
        agoDesempenho = 0,
        setDesempenho = 0,
        outDesempenho = 0,
        novDesempenho = 0,
        dezDesempenho = 0;
      if (separaDoisUltimosAnos && separaDoisUltimosAnos.length > 0) {
        preenchercolunasTabelasGlobais();
        separaDoisUltimosAnos.map((item, index) => {
          if (item[1].length > 0) {
            let linha = [];
            let grupoDeLojaPorAno = agruparPor(item[1], "diaSemana");
            let grupoDeDiaSemanaPorAnoArray = Object.entries(grupoDeLojaPorAno);
            var dadosDesempenho = [];
            grupoDeDiaSemanaPorAnoArray.map((item2) => {
              if (item2.length == 2 && item2[1].length > 0) {
                var dadosLojaPorMes = item2[1].sort((a, b) => {
                  return parseInt(a.mes) - parseInt(b.mes);
                });
                let jan = 0,
                  fev = 0,
                  mar = 0,
                  abr = 0,
                  mai = 0,
                  jun = 0,
                  jul = 0,
                  ago = 0,
                  set = 0,
                  out = 0,
                  nov = 0,
                  dez = 0,
                  total = 0;
                let diaSemana;
                dadosLojaPorMes.map((item3) => {
                  switch (item3.mes) {
                    case "1":
                      jan += parseFloat(item3.VlrTotal);
                      break;
                    case "2":
                      fev += parseFloat(item3.VlrTotal);
                      break;
                    case "3":
                      mar += parseFloat(item3.VlrTotal);
                      break;
                    case "4":
                      abr += parseFloat(item3.VlrTotal);
                      break;
                    case "5":
                      mai += parseFloat(item3.VlrTotal);
                      break;
                    case "6":
                      jun += parseFloat(item3.VlrTotal);
                      break;
                    case "7":
                      jul += parseFloat(item3.VlrTotal);
                      break;
                    case "8":
                      ago += parseFloat(item3.VlrTotal);
                      break;
                    case "9":
                      set += parseFloat(item3.VlrTotal);
                      break;
                    case "10":
                      out += parseFloat(item3.VlrTotal);
                      break;
                    case "11":
                      nov += parseFloat(item3.VlrTotal);
                      break;
                    case "12":
                      dez += parseFloat(item3.VlrTotal);
                      break;
                  }
                  diaSemana = item3.diaSemana;
                });
                total =
                  jan +
                  fev +
                  mar +
                  abr +
                  mai +
                  jun +
                  jul +
                  ago +
                  set +
                  out +
                  nov +
                  dez;
                if (index == 0) {
                  totalAnoAtual += total;
                  linha = {
                    loja: diaSemana,
                    jan,
                    fev,
                    mar,
                    abr,
                    mai,
                    jun,
                    jul,
                    ago,
                    set,
                    out,
                    nov,
                    dez,
                    total,
                  };
                  janTotalAnoAtual += jan;
                  fevTotalAnoAtual += fev;
                  marTotalAnoAtual += mar;
                  abrTotalAnoAtual += abr;
                  maiTotalAnoAtual += mai;
                  junTotalAnoAtual += jun;
                  julTotalAnoAtual += jul;
                  agoTotalAnoAtual += ago;
                  setTotalAnoAtual += set;
                  outTotalAnoAtual += out;
                  novTotalAnoAtual += nov;
                  dezTotalAnoAtual += dez;
                  dadosDesempenho.push(linha);
                  dadosTabelaGlobalAnoAtual.push(linha);
                } else if (index == 1) {
                  totalAnoAnterior += total;
                  linha = {
                    loja: diaSemana,
                    jan,
                    fev,
                    mar,
                    abr,
                    mai,
                    jun,
                    jul,
                    ago,
                    set,
                    out,
                    nov,
                    dez,
                    total,
                  };
                  janTotalAnoAnterior += jan;
                  fevTotalAnoAnterior += fev;
                  marTotalAnoAnterior += mar;
                  abrTotalAnoAnterior += abr;
                  maiTotalAnoAnterior += mai;
                  junTotalAnoAnterior += jun;
                  julTotalAnoAnterior += jul;
                  agoTotalAnoAnterior += ago;
                  setTotalAnoAnterior += set;
                  outTotalAnoAnterior += out;
                  novTotalAnoAnterior += nov;
                  dezTotalAnoAnterior += dez;
                  dadosDesempenho.push(linha);
                  dadosTabelaGlobalAnoAnterior.push(linha);
                }
              }
            });
            dadosTabelaGlobalDesempenho.push(dadosDesempenho);
          }
        });
        var auxDadosTabelaGlobalDesempenho = [];
        dadosTabelaGlobalDesempenho[0].map((item) => {
          dadosTabelaGlobalDesempenho[1].map((item2) => {
            if (item.loja == item2.loja) {
              let janDes,
                fevDes,
                marDes,
                abrDes,
                maiDes,
                junDes,
                julDes,
                agoDes,
                setDes,
                outDes,
                novDes,
                dezDes,
                totalDes = 0,
                numeroMesesAtivos = 0;
              janDes = item2.jan == 0 ? 0 : (item.jan / item2.jan - 1) * 100;
              fevDes = item2.fev == 0 ? 0 : (item.fev / item2.fev - 1) * 100;
              marDes = item2.mar == 0 ? 0 : (item.mar / item2.mar - 1) * 100;
              abrDes = item2.abr == 0 ? 0 : (item.abr / item2.abr - 1) * 100;
              maiDes = item2.mai == 0 ? 0 : (item.mai / item2.mai - 1) * 100;
              junDes = item2.jun == 0 ? 0 : (item.jun / item2.jun - 1) * 100;
              julDes = item2.jul == 0 ? 0 : (item.jul / item2.jul - 1) * 100;
              agoDes = item2.ago == 0 ? 0 : (item.ago / item2.ago - 1) * 100;
              setDes = item2.set == 0 ? 0 : (item.set / item2.set - 1) * 100;
              outDes = item2.out == 0 ? 0 : (item.out / item2.out - 1) * 100;
              novDes = item2.nov == 0 ? 0 : (item.nov / item2.nov - 1) * 100;
              dezDes = item2.dez == 0 ? 0 : (item.dez / item2.dez - 1) * 100;
              numeroMesesAtivos =
                item2.jan != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.fev != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.mar != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.abr != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.mai != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.jun != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.jul != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.ago != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.set != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.out != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.nov != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.dez != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              totalDes =
                janDes +
                fevDes +
                marDes +
                abrDes +
                maiDes +
                junDes +
                julDes +
                agoDes +
                setDes +
                outDes +
                novDes +
                dezDes;
              totalDesempenhoAux += totalDes;

              auxDadosTabelaGlobalDesempenho.push({
                loja: item.loja,
                jan: parseFloat(janDes.toFixed(2)),
                fev: parseFloat(fevDes.toFixed(2)),
                mar: parseFloat(marDes.toFixed(2)),
                abr: parseFloat(abrDes.toFixed(2)),
                mai: parseFloat(maiDes.toFixed(2)),
                jun: parseFloat(junDes.toFixed(2)),
                jul: parseFloat(julDes.toFixed(2)),
                ago: parseFloat(agoDes.toFixed(2)),
                set: parseFloat(setDes.toFixed(2)),
                out: parseFloat(outDes.toFixed(2)),
                nov: parseFloat(novDes.toFixed(2)),
                dez: parseFloat(dezDes.toFixed(2)),
                total: parseFloat((totalDes / numeroMesesAtivos).toFixed(2)),
              });
              janDesempenho += janDes;
              fevDesempenho += fevDes;
              marDesempenho += marDes;
              abrDesempenho += abrDes;
              maiDesempenho += maiDes;
              junDesempenho += junDes;
              julDesempenho += julDes;
              agoDesempenho += agoDes;
              setDesempenho += setDes;
              outDesempenho += outDes;
              novDesempenho += novDes;
              dezDesempenho += dezDes;
            }
          });
        });
        var totalLojasReferencia =
          dadosTabelaGlobalDesempenho[0].length <
          dadosTabelaGlobalDesempenho[1].length
            ? dadosTabelaGlobalDesempenho[0].length
            : dadosTabelaGlobalDesempenho[1].length;
        auxDadosTabelaGlobalDesempenho.push({
          loja: "Total",
          jan: parseFloat((janDesempenho / totalLojasReferencia).toFixed(2)),
          fev: parseFloat((fevDesempenho / totalLojasReferencia).toFixed(2)),
          mar: parseFloat((marDesempenho / totalLojasReferencia).toFixed(2)),
          abr: parseFloat((abrDesempenho / totalLojasReferencia).toFixed(2)),
          mai: parseFloat((maiDesempenho / totalLojasReferencia).toFixed(2)),
          jun: parseFloat((junDesempenho / totalLojasReferencia).toFixed(2)),
          jul: parseFloat((julDesempenho / totalLojasReferencia).toFixed(2)),
          ago: parseFloat((agoDesempenho / totalLojasReferencia).toFixed(2)),
          set: parseFloat((setDesempenho / totalLojasReferencia).toFixed(2)),
          out: parseFloat((outDesempenho / totalLojasReferencia).toFixed(2)),
          nov: parseFloat((novDesempenho / totalLojasReferencia).toFixed(2)),
          dez: parseFloat((dezDesempenho / totalLojasReferencia).toFixed(2)),
          total: parseFloat(
            (totalDesempenhoAux / totalLojasReferencia).toFixed(2)
          ),
        });
        dadosTabelaGlobalAnoAtual.push({
          loja: "Total",
          jan: janTotalAnoAtual,
          fev: fevTotalAnoAtual,
          mar: marTotalAnoAtual,
          abr: abrTotalAnoAtual,
          mai: maiTotalAnoAtual,
          jun: junTotalAnoAtual,
          jul: julTotalAnoAtual,
          ago: agoTotalAnoAtual,
          set: setTotalAnoAtual,
          out: outTotalAnoAtual,
          nov: novTotalAnoAtual,
          dez: dezTotalAnoAtual,
          total: totalAnoAtual,
        });
        dadosTabelaGlobalAnoAnterior.push({
          loja: "Total",
          jan: janTotalAnoAnterior,
          fev: fevTotalAnoAnterior,
          mar: marTotalAnoAnterior,
          abr: abrTotalAnoAnterior,
          mai: maiTotalAnoAnterior,
          jun: junTotalAnoAnterior,
          jul: julTotalAnoAnterior,
          ago: agoTotalAnoAnterior,
          set: setTotalAnoAnterior,
          out: outTotalAnoAnterior,
          nov: novTotalAnoAnterior,
          dez: dezTotalAnoAnterior,
          total: totalAnoAnterior,
        });

        setDadosParaTabelaGlobalDesempenho(auxDadosTabelaGlobalDesempenho);
        setDadosParaTabelaGlobalAnoAtual(dadosTabelaGlobalAnoAtual);
        setDadosParaTabelaGlobalAnoAnterior(dadosTabelaGlobalAnoAnterior);
        setExibeTabelaGlobal(true);
      } else {
        setExibeTabelaGlobal(false);
      }
    } else {
      setExibeTabelaGlobal(false);
    }
  }

  function preencherDadosTabelasGlobaisHora(dados) {
    let grupoDeAnosArray = Object.entries(dados);
    grupoDeAnosArray = grupoDeAnosArray.reverse();
    if (grupoDeAnosArray.length > 1) {
      var separaDoisUltimosAnos = [grupoDeAnosArray[0], grupoDeAnosArray[1]];
      var dadosTabelaGlobalAnoAtual = [];
      var dadosTabelaGlobalAnoAnterior = [];
      var dadosTabelaGlobalDesempenho = [];
      let janTotalAnoAtual = 0,
        fevTotalAnoAtual = 0,
        marTotalAnoAtual = 0,
        abrTotalAnoAtual = 0,
        maiTotalAnoAtual = 0,
        junTotalAnoAtual = 0,
        julTotalAnoAtual = 0,
        agoTotalAnoAtual = 0,
        setTotalAnoAtual = 0,
        outTotalAnoAtual = 0,
        novTotalAnoAtual = 0,
        dezTotalAnoAtual = 0,
        totalAnoAtual = 0;
      let janTotalAnoAnterior = 0,
        fevTotalAnoAnterior = 0,
        marTotalAnoAnterior = 0,
        abrTotalAnoAnterior = 0,
        maiTotalAnoAnterior = 0,
        junTotalAnoAnterior = 0,
        julTotalAnoAnterior = 0,
        agoTotalAnoAnterior = 0,
        setTotalAnoAnterior = 0,
        outTotalAnoAnterior = 0,
        novTotalAnoAnterior = 0,
        dezTotalAnoAnterior = 0,
        totalAnoAnterior = 0;
      let janDesempenho = 0,
        fevDesempenho = 0,
        marDesempenho = 0,
        abrDesempenho = 0,
        maiDesempenho = 0,
        junDesempenho = 0,
        julDesempenho = 0,
        agoDesempenho = 0,
        setDesempenho = 0,
        outDesempenho = 0,
        novDesempenho = 0,
        dezDesempenho = 0;
      if (separaDoisUltimosAnos && separaDoisUltimosAnos.length > 0) {
        preenchercolunasTabelasGlobais(true);
        separaDoisUltimosAnos.map((item, index) => {
          if (item[1].length > 0) {
            let linha = [];
            let grupoDeLojaPorAno = agruparPor(item[1], "hora");
            let grupoDeDiaSemanaPorAnoArray = Object.entries(grupoDeLojaPorAno);
            var dadosDesempenho = [];
            grupoDeDiaSemanaPorAnoArray.map((item2) => {
              if (item2.length == 2 && item2[1].length > 0) {
                var dadosLojaPorMes = item2[1].sort((a, b) => {
                  return parseInt(a.mes) - parseInt(b.mes);
                });
                let jan = 0,
                  fev = 0,
                  mar = 0,
                  abr = 0,
                  mai = 0,
                  jun = 0,
                  jul = 0,
                  ago = 0,
                  set = 0,
                  out = 0,
                  nov = 0,
                  dez = 0,
                  total = 0;
                let diaSemana;
                dadosLojaPorMes.map((item3) => {
                  switch (item3.mes) {
                    case "1":
                      jan += parseFloat(item3.VlrTotal);
                      break;
                    case "2":
                      fev += parseFloat(item3.VlrTotal);
                      break;
                    case "3":
                      mar += parseFloat(item3.VlrTotal);
                      break;
                    case "4":
                      abr += parseFloat(item3.VlrTotal);
                      break;
                    case "5":
                      mai += parseFloat(item3.VlrTotal);
                      break;
                    case "6":
                      jun += parseFloat(item3.VlrTotal);
                      break;
                    case "7":
                      jul += parseFloat(item3.VlrTotal);
                      break;
                    case "8":
                      ago += parseFloat(item3.VlrTotal);
                      break;
                    case "9":
                      set += parseFloat(item3.VlrTotal);
                      break;
                    case "10":
                      out += parseFloat(item3.VlrTotal);
                      break;
                    case "11":
                      nov += parseFloat(item3.VlrTotal);
                      break;
                    case "12":
                      dez += parseFloat(item3.VlrTotal);
                      break;
                  }
                  diaSemana = item3.hora;
                });
                total =
                  jan +
                  fev +
                  mar +
                  abr +
                  mai +
                  jun +
                  jul +
                  ago +
                  set +
                  out +
                  nov +
                  dez;
                if (index == 0) {
                  totalAnoAtual += total;
                  linha = {
                    loja: diaSemana,
                    jan,
                    fev,
                    mar,
                    abr,
                    mai,
                    jun,
                    jul,
                    ago,
                    set,
                    out,
                    nov,
                    dez,
                    total,
                  };
                  janTotalAnoAtual += jan;
                  fevTotalAnoAtual += fev;
                  marTotalAnoAtual += mar;
                  abrTotalAnoAtual += abr;
                  maiTotalAnoAtual += mai;
                  junTotalAnoAtual += jun;
                  julTotalAnoAtual += jul;
                  agoTotalAnoAtual += ago;
                  setTotalAnoAtual += set;
                  outTotalAnoAtual += out;
                  novTotalAnoAtual += nov;
                  dezTotalAnoAtual += dez;
                  dadosDesempenho.push(linha);
                  dadosTabelaGlobalAnoAtual.push(linha);
                } else if (index == 1) {
                  totalAnoAnterior += total;
                  linha = {
                    loja: diaSemana,
                    jan,
                    fev,
                    mar,
                    abr,
                    mai,
                    jun,
                    jul,
                    ago,
                    set,
                    out,
                    nov,
                    dez,
                    total,
                  };
                  janTotalAnoAnterior += jan;
                  fevTotalAnoAnterior += fev;
                  marTotalAnoAnterior += mar;
                  abrTotalAnoAnterior += abr;
                  maiTotalAnoAnterior += mai;
                  junTotalAnoAnterior += jun;
                  julTotalAnoAnterior += jul;
                  agoTotalAnoAnterior += ago;
                  setTotalAnoAnterior += set;
                  outTotalAnoAnterior += out;
                  novTotalAnoAnterior += nov;
                  dezTotalAnoAnterior += dez;
                  dadosDesempenho.push(linha);
                  dadosTabelaGlobalAnoAnterior.push(linha);
                }
              }
            });
            dadosTabelaGlobalDesempenho.push(dadosDesempenho);
          }
        });
        var auxDadosTabelaGlobalDesempenho = [];
        dadosTabelaGlobalDesempenho[0].map((item) => {
          dadosTabelaGlobalDesempenho[1].map((item2) => {
            if (item.loja == item2.loja) {
              let janDes,
                fevDes,
                marDes,
                abrDes,
                maiDes,
                junDes,
                julDes,
                agoDes,
                setDes,
                outDes,
                novDes,
                dezDes,
                totalDes = 0,
                numeroMesesAtivos = 0;
              janDes = item2.jan == 0 ? 0 : (item.jan / item2.jan - 1) * 100;
              fevDes = item2.fev == 0 ? 0 : (item.fev / item2.fev - 1) * 100;
              marDes = item2.mar == 0 ? 0 : (item.mar / item2.mar - 1) * 100;
              abrDes = item2.abr == 0 ? 0 : (item.abr / item2.abr - 1) * 100;
              maiDes = item2.mai == 0 ? 0 : (item.mai / item2.mai - 1) * 100;
              junDes = item2.jun == 0 ? 0 : (item.jun / item2.jun - 1) * 100;
              julDes = item2.jul == 0 ? 0 : (item.jul / item2.jul - 1) * 100;
              agoDes = item2.ago == 0 ? 0 : (item.ago / item2.ago - 1) * 100;
              setDes = item2.set == 0 ? 0 : (item.set / item2.set - 1) * 100;
              outDes = item2.out == 0 ? 0 : (item.out / item2.out - 1) * 100;
              novDes = item2.nov == 0 ? 0 : (item.nov / item2.nov - 1) * 100;
              dezDes = item2.dez == 0 ? 0 : (item.dez / item2.dez - 1) * 100;
              numeroMesesAtivos =
                item2.jan != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.fev != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.mar != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.abr != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.mai != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.jun != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.jul != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.ago != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.set != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.out != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.nov != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              numeroMesesAtivos =
                item2.dez != 0 ? numeroMesesAtivos + 1 : numeroMesesAtivos;
              totalDes =
                janDes +
                fevDes +
                marDes +
                abrDes +
                maiDes +
                junDes +
                julDes +
                agoDes +
                setDes +
                outDes +
                novDes +
                dezDes;

              auxDadosTabelaGlobalDesempenho.push({
                loja: item.loja,
                jan: parseFloat(janDes.toFixed(2)),
                fev: parseFloat(fevDes.toFixed(2)),
                mar: parseFloat(marDes.toFixed(2)),
                abr: parseFloat(abrDes.toFixed(2)),
                mai: parseFloat(maiDes.toFixed(2)),
                jun: parseFloat(junDes.toFixed(2)),
                jul: parseFloat(julDes.toFixed(2)),
                ago: parseFloat(agoDes.toFixed(2)),
                set: parseFloat(setDes.toFixed(2)),
                out: parseFloat(outDes.toFixed(2)),
                nov: parseFloat(novDes.toFixed(2)),
                dez: parseFloat(dezDes.toFixed(2)),
                total: parseFloat((totalDes / numeroMesesAtivos).toFixed(2)),
              });
              janDesempenho += janDes;
              fevDesempenho += fevDes;
              marDesempenho += marDes;
              abrDesempenho += abrDes;
              maiDesempenho += maiDes;
              junDesempenho += junDes;
              julDesempenho += julDes;
              agoDesempenho += agoDes;
              setDesempenho += setDes;
              outDesempenho += outDes;
              novDesempenho += novDes;
              dezDesempenho += dezDes;
            }
          });
        });
        var totalLojasReferencia =
          dadosTabelaGlobalDesempenho[0].length <
          dadosTabelaGlobalDesempenho[1].length
            ? dadosTabelaGlobalDesempenho[0].length
            : dadosTabelaGlobalDesempenho[1].length;
        var desempenhoTotal = (totalAnoAtual / totalAnoAnterior - 1) * 100;

        auxDadosTabelaGlobalDesempenho.push({
          loja: "Total",
          jan: parseFloat((janDesempenho / totalLojasReferencia).toFixed(2)),
          fev: parseFloat((fevDesempenho / totalLojasReferencia).toFixed(2)),
          mar: parseFloat((marDesempenho / totalLojasReferencia).toFixed(2)),
          abr: parseFloat((abrDesempenho / totalLojasReferencia).toFixed(2)),
          mai: parseFloat((maiDesempenho / totalLojasReferencia).toFixed(2)),
          jun: parseFloat((junDesempenho / totalLojasReferencia).toFixed(2)),
          jul: parseFloat((julDesempenho / totalLojasReferencia).toFixed(2)),
          ago: parseFloat((agoDesempenho / totalLojasReferencia).toFixed(2)),
          set: parseFloat((setDesempenho / totalLojasReferencia).toFixed(2)),
          out: parseFloat((outDesempenho / totalLojasReferencia).toFixed(2)),
          nov: parseFloat((novDesempenho / totalLojasReferencia).toFixed(2)),
          dez: parseFloat((dezDesempenho / totalLojasReferencia).toFixed(2)),
          total: parseFloat(desempenhoTotal.toFixed(2)),
        });
        dadosTabelaGlobalAnoAtual.push({
          loja: "Total",
          jan: janTotalAnoAtual,
          fev: fevTotalAnoAtual,
          mar: marTotalAnoAtual,
          abr: abrTotalAnoAtual,
          mai: maiTotalAnoAtual,
          jun: junTotalAnoAtual,
          jul: julTotalAnoAtual,
          ago: agoTotalAnoAtual,
          set: setTotalAnoAtual,
          out: outTotalAnoAtual,
          nov: novTotalAnoAtual,
          dez: dezTotalAnoAtual,
          total: totalAnoAtual,
        });
        dadosTabelaGlobalAnoAnterior.push({
          loja: "Total",
          jan: janTotalAnoAnterior,
          fev: fevTotalAnoAnterior,
          mar: marTotalAnoAnterior,
          abr: abrTotalAnoAnterior,
          mai: maiTotalAnoAnterior,
          jun: junTotalAnoAnterior,
          jul: julTotalAnoAnterior,
          ago: agoTotalAnoAnterior,
          set: setTotalAnoAnterior,
          out: outTotalAnoAnterior,
          nov: novTotalAnoAnterior,
          dez: dezTotalAnoAnterior,
          total: totalAnoAnterior,
        });

        setDadosParaTabelaGlobalDesempenho(auxDadosTabelaGlobalDesempenho);
        setDadosParaTabelaGlobalAnoAtual(dadosTabelaGlobalAnoAtual);
        setDadosParaTabelaGlobalAnoAnterior(dadosTabelaGlobalAnoAnterior);
        setExibeTabelaGlobal(true);
      } else {
        setExibeTabelaGlobal(false);
      }
    } else {
      setExibeTabelaGlobal(false);
    }
  }

  function preencherDadosTabelaMes(dados) {
    var dadosTabela = [];

    var dadosResumoTabela = [];
    var quantidadeLojasAnoAtual = 0,
      quantidadeLojasAnoAnterior = 0;
    let qtdAnoAtualResumo = 0,
      vlrAnoAtualResumo = 0,
      vlrMedioAnoAtualResumo = 0,
      qtdAnoAnteriorResumo = 0,
      vlrTotalAnoAnteriorResumo = 0,
      vlrMedioAnoAnteriorResumo = 0,
      desempenhoResumo = 0,
      rprAnoAtual = 0,
      rprAnoAnterior = 0;
    var totalPedidosAnoAtual = 0;
    var totalPedidosAnoAnterior = 0;

    if (dados.length > 0) {
      dados.map((item) => {
        item[1].map((item2, index2) => {
          if (index2 == item[1].length - 1) {
            totalPedidosAnoAtual += parseInt(item2.total);
          } else {
            totalPedidosAnoAnterior += parseInt(item2.total);
          }
        });
      });
      dados.map((item) => {
        let mes = parseInt(item[0]);
        let qtdAnoAtual = 0,
          vlrAnoAtual = 0,
          vlrMedioAnoAtual = 0,
          qtdAnoAnterior = 0,
          vlrTotalAnoAnterior = 0,
          vlrMedioAnoAnterior = 0,
          desempenho = 0,
          representatividadeAtual = 0,
          representatividadeAnterior = 0;
        item[1].map((item2, index2) => {
          if (index2 == item[1].length - 1) {
            qtdAnoAtual = parseInt(item2.totalPedidos);
            vlrAnoAtual = parseFloat(parseFloat(item2.VlrTotal).toFixed(2));
            vlrMedioAnoAtual = parseFloat(
              (vlrAnoAtual / qtdAnoAtual).toFixed(2)
            );
            qtdAnoAtualResumo += qtdAnoAtual;
            vlrAnoAtualResumo += vlrAnoAtual;
            vlrMedioAnoAtualResumo += vlrMedioAnoAtual;
            if (qtdAnoAtual > 0) {
              quantidadeLojasAnoAtual++;
            }
          } else {
            qtdAnoAnterior = parseInt(item2.totalPedidos);
            vlrTotalAnoAnterior = parseFloat(
              parseFloat(item2.VlrTotal).toFixed(2)
            );
            vlrMedioAnoAnterior = parseFloat(
              (vlrTotalAnoAnterior / qtdAnoAnterior).toFixed(2)
            );
            qtdAnoAnteriorResumo += qtdAnoAnterior;
            vlrTotalAnoAnteriorResumo += vlrTotalAnoAnterior;
            vlrMedioAnoAnteriorResumo += vlrMedioAnoAnterior;
            if (qtdAnoAnterior > 0) {
              quantidadeLojasAnoAnterior++;
            }
          }
        });
        if (qtdAnoAnterior > 0) {
          desempenho = parseFloat(
            ((qtdAnoAtual / qtdAnoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          desempenho = 0;
        }
        desempenhoResumo += desempenho;
        representatividadeAtual = parseFloat(
          ((vlrAnoAtual / totalPedidosAnoAtual) * 100).toFixed(2)
        );
        representatividadeAnterior = parseFloat(
          ((vlrTotalAnoAnterior / totalPedidosAnoAnterior) * 100).toFixed(2)
        );
        rprAnoAtual += representatividadeAtual;
        rprAnoAnterior += representatividadeAnterior;
        dadosTabela.push({
          mes: mes,
          qtdAnoAtual: qtdAnoAtual,
          vlrAnoAtual: vlrAnoAtual,
          vlrMedioAnoAtual: vlrMedioAnoAtual,
          qtdAnoAnterior: qtdAnoAnterior,
          vlrTotalAnoAnterior: vlrTotalAnoAnterior,
          vlrMedioAnoAnterior: vlrMedioAnoAnterior,
          desempenho: desempenho,
          representatividadeAtual: representatividadeAtual,
          representatividadeAnterior: representatividadeAnterior,
        });
      });
    }

    vlrMedioAnoAtualResumo = parseFloat(
      (vlrMedioAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );
    vlrMedioAnoAnteriorResumo = parseFloat(
      (vlrMedioAnoAnteriorResumo / quantidadeLojasAnoAnterior).toFixed(2)
    );
    desempenhoResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalAnoAnteriorResumo - 1) * 100).toFixed(2)
    );
    dadosResumoTabela.push({
      total: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrMedioAnoAtual: vlrMedioAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      vlrMedioAnoAnterior: vlrMedioAnoAnteriorResumo,
      desempenho: desempenhoResumo,
      representatividadeAtual: rprAnoAtual,
      representatividadeAnterior: rprAnoAnterior,
    });
    dadosTabela.push({
      loja: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrMedioAnoAtual: vlrMedioAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      vlrMedioAnoAnterior: vlrMedioAnoAnteriorResumo,
      desempenho: desempenhoResumo,
      representatividadeAtual: rprAnoAtual,
      representatividadeAnterior: rprAnoAnterior,
    });

    setDadosParaTabela(dadosTabela);
  }

  async function preencherDadosGraficoAgrupadoMes(dados) {
    var label = [];
    var labelCompleta = [];
    var dataSets = [];
    var dadosTabelaAux = [];
    const promise = await dados.map((item, i) => {
      var dadosGrupo = [];
      item[1] = item[1].sort((a, b) => {
        return a.mes - b.mes;
      });

      item[1].map((itemGrupo) => {
        var nomeMes = converterMes(itemGrupo.mes);
        if (filtrosMesesSelecionados.length > 0) {
          if (filtrosMesesSelecionados.indexOf(nomeMes) >= 0) {
            let jaExiste = label.indexOf(nomeMes);
            if (jaExiste < 0) {
              label.push(nomeMes);
              labelCompleta.push(nomeMes);
            }
            dadosGrupo.push(parseFloat(itemGrupo.VlrTotal));
            dadosTabelaAux.push(itemGrupo);
          } else {
            let jaExiste = labelCompleta.indexOf(nomeMes);
            if (jaExiste < 0) {
              labelCompleta.push(nomeMes);
            }
          }
        } else {
          let jaExiste = label.indexOf(nomeMes);
          if (jaExiste < 0) {
            label.push(nomeMes);
            labelCompleta.push(nomeMes);
          }
          dadosGrupo.push(parseFloat(itemGrupo.VlrTotal));
          dadosTabelaAux.push(itemGrupo);
        }
      });
      var cor =
        i == dados.length - 1
          ? "rgba(255, 194, 218, 1)"
          : i == dados.length - 2
          ? "rgba(91, 139, 252, 1)"
          : i == dados.length - 3
          ? "#5B8BFC"
          : i == dados.length - 4
          ? "#FFC2DA"
          : i == dados.length - 5
          ? ""
          : geraCorAleatoria();
      var datalabels;
      if (i == dados.length - 1) {
        datalabels = {
          anchor: "end",
          display: true,
          color: "#000",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        };
      } else if (i == dados.length - 2) {
        datalabels = {
          anchor: "start",
          display: true,
          color: cor,
          align: "start",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        };
      }
      dataSets.push({
        label: item[0],
        data: dadosGrupo,
        backgroundColor: cor,
        datalabels: datalabels,
      });
    });

    await Promise.all(promise);
    const data = {
      labels: label,
      datasets: dataSets,
    };
    dadosTabelaAux = agruparPor(dadosTabelaAux, "mes");
    dadosTabelaAux = Object.entries(dadosTabelaAux);
    preencherDadosTabelaMes(dadosTabelaAux);
    setDadosGraficoAgrupado(data);
    setMesesDisponiveis(labelCompleta);
    setNivelAtivoDoGraficoTotalVendas(1);
  }

  async function preencherDadosGraficoDesempenho(dados) {
    var label = [];
    var dadosDesempenho = [];
    var backgroundColor = [];
    const promise = await dados.map((item, i) => {
      var loja;
      if (dados.length > 7) {
        loja = item.loja;
      } else {
        loja = converterDia(item.loja.toString());
      }
      label.push(loja);
      dadosDesempenho.push(item.desempenho);
      backgroundColor.push("rgba(255, 194, 218, 1)");
    });

    await Promise.all(promise);
    const data = {
      labels: label,
      datasets: [
        {
          label: "Desempenho %",
          data: dadosDesempenho,
          backgroundColor: backgroundColor,
          borderWidth: 1,
        },
      ],
    };
    setDadosGraficoDesempenho(data);
    setDadosGlobaisDesempenho(data);
    setNivelAtivoDoGraficoDesempenho(0);
  }

  function preencherDadosVendasPorMes(codLoja) {
    setCodLojaAtiva(codLoja);

    var dados = dadosGerais;
    dados = dados.filter((a) => {
      return a.CodLoja == codLoja ? true : false;
    });
    let grupoDeAnos = agruparPor(dados, "ano");
    let grupoDeAnosArray = Object.entries(grupoDeAnos);
    preencherDadosGraficoAgrupadoMes(grupoDeAnosArray);
  }

  function preencherDadosVendasPorLoja() {
    var auxLojasSelecionadas = [];
    if (numDiaAtivo != "") {
      var dataVendas = dadosGeraisDia;
      dataVendas.map((a) => {
        if (auxLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) == -1) {
          auxLojasSelecionadas.push(converterLoja(a.CodLoja));
        }
      });
      if (filtrosLojasSelecionadas.length > 0) {
        dataVendas = dataVendas.filter((a) => {
          if (filtrosLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) >= 0) {
            return true;
          } else {
            return false;
          }
        });
      }
      let grupoDeAnos = agruparPor(dataVendas, "ano");
      let grupoDeAnosArray = Object.entries(grupoDeAnos);

      let separadoPorLoja = [];
      grupoDeAnosArray.map((item) => {
        let grupoDeLoja = agruparPor(item[1], "hora");
        separadoPorLoja.push(grupoDeLoja);
      });
      let resumoSeparadoPorLoja = [];
      separadoPorLoja.map((item) => {
        let item2 = Object.entries(item);
        item2.map((item3) => {
          let total = 0;
          let totalPedidos = 0;
          let ano;

          let hora;
          let totalCusto = 0;
          let totalLucro = 0;
          let porcentagemLucro = 0;
          let codLoja = 0;
          item3[1].map((item4, index) => {
            if (index == 0) {
              ano = item4.ano;
              hora = item4.hora;
            }
            totalPedidos += !isNaN(parseInt(item4.totalPedidos))
              ? parseInt(item4.totalPedidos)
              : 0;
            total += !isNaN(parseFloat(item4.VlrTotal))
              ? parseFloat(item4.VlrTotal)
              : 0;
            totalCusto += !isNaN(parseFloat(item4.VlrCusto))
              ? parseFloat(item4.VlrCusto)
              : 0;
            totalLucro += !isNaN(parseFloat(item4.VlrLucroBruto))
              ? parseFloat(item4.VlrLucroBruto)
              : 0;
            porcentagemLucro += !isNaN(
              parseFloat(item4.VlrLucroBrutoPorcentagem)
            )
              ? parseFloat(item4.VlrLucroBrutoPorcentagem)
              : 0;
            codLoja = item4.CodLoja;
          });
          porcentagemLucro = porcentagemLucro / item3[1].length;
          let resumoLojaAno = {
            ano: ano,
            hora,
            nomeGrupo: hora,
            total: total,
            totalPedidos: totalPedidos,
            totalCusto,
            totalLucro,
            porcentagemLucro,
            codLoja,
          };
          resumoSeparadoPorLoja.push(resumoLojaAno);
        });
      });
      var agrupado = agruparPor(resumoSeparadoPorLoja, "ano");
      grupoDeAnosArray = Object.entries(agrupado);
      preencherDadosGraficoAgrupadoGrupo(
        grupoDeAnosArray,
        auxAnoAtual,
        auxAnoAnterior,
        dataUltimoPeriodoHr
      );
      preencherDadosTabelasGlobaisHora(grupoDeAnos);
    } else {
      var dataVendas = dadosGerais;
      var auxLojasSelecionadas = [];

      dataVendas.map((a) => {
        if (auxLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) == -1) {
          auxLojasSelecionadas.push(converterLoja(a.CodLoja));
        }
      });
      if (filtrosLojasSelecionadas.length > 0) {
        dataVendas = dataVendas.filter((a) => {
          if (filtrosLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) >= 0) {
            return true;
          } else {
            return false;
          }
        });
      }
      let grupoDeAnos = agruparPor(dataVendas, "ano");
      let grupoDeAnosArray = Object.entries(grupoDeAnos);
      let grupoDeAnoLoja = [];
      grupoDeAnosArray.map((item) => {
        let grupoDeLoja = agruparPor(item[1], "diaSemana");
        grupoDeAnoLoja.push(grupoDeLoja);
      });

      let resumoGrupoDeAnoLoja = [];
      grupoDeAnoLoja.map((item) => {
        let item2 = Object.entries(item);
        item2.map((item3) => {
          let total = 0;
          let totalPedidos = 0;
          let ano;

          let diaSemana;
          ano = item3[1][0].ano;
          diaSemana = item3[1][0].diaSemana;

          item3[1].map((item4, index) => {
            totalPedidos += parseInt(item4.totalPedidos);
            total += parseFloat(item4.VlrTotal);
          });
          let resumoLojaAno = {
            ano: ano,
            total: total,
            totalPedidos: totalPedidos,
            diaSemana: diaSemana,
          };
          resumoGrupoDeAnoLoja.push(resumoLojaAno);
        });
      });
      var agrupado = agruparPor(resumoGrupoDeAnoLoja, "ano");
      grupoDeAnosArray = Object.entries(agrupado);

      preencherDadosGraficoAgrupado(
        grupoDeAnosArray,
        auxAnoAtual,
        auxAnoAnterior,
        tipoPeriodo,
        dadosUltimoPeriodo
      );
      preencherDadosTabelasGlobais(grupoDeAnos);
      setLojasDisponiveis(auxLojasSelecionadas);
    }
  }
  const LoadingTemp = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  };
  function iniciarFiltroPorPeriodo() {
    setTipoPeriodo("input");
    setAuxTipoPeriodo("input");
    setPeriodoAno(false);
    setFiltrosAnosSelecionados([]);
    setPeriodoInputInicio(periodoInputInicioAux);
    setPeriodoInputFim(periodoInputFimAux);
  }

  function resetarGraficoDesempenho() {
    setDadosGraficoDesempenho(dadosGlobaisDesempenho);
    setNivelAtivoDoGraficoDesempenho(0);
  }

  function converterNomeDia(nomeDia) {
    let semana = [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado",
    ];
    var diaSemana = "";
    semana.map((item, index) => {
      if (nomeDia == item) {
        diaSemana = index + 1;
      }
    });
    return diaSemana.toString();
  }

  function filtrarPorMes(mes) {
    setTipoPeriodo("mes");
    var mesesSelecionados = [];
    if (filtrosMesesSelecionados.length == 0) {
      mesesSelecionados.push(mes);
    } else {
      if (filtrosMesesSelecionados.indexOf(mes) >= 0) {
        filtrosMesesSelecionados.map((item) => {
          if (item != mes) {
            mesesSelecionados.push(item);
          }
        });
      } else {
        filtrosMesesSelecionados.map((item) => {
          mesesSelecionados.push(item);
        });
        mesesSelecionados.push(mes);
      }
    }
    setFiltrosMesesSelecionados(mesesSelecionados);
    var mesesDisponiveisAux = [];
    mesesDisponiveis.map((item) => {
      mesesDisponiveisAux.push(item);
    });
    setMesesDisponiveis(mesesDisponiveisAux);
  }

  function filtrarPorAno(ano) {
    let filtrosLojasSelecionadasAuc = filtrosLojasSelecionadas;
    setTipoPeriodo("ano");
    setAuxTipoPeriodo("ano");
    setMesesDisponiveis(false);
    // setHoraNext(false)
    setNumDiaAtivo("");
    let anosSelecionados = [];
    if (filtrosAnosSelecionados.length == 0) {
      anosSelecionados.push(ano);
    } else {
      if (filtrosAnosSelecionados.indexOf(ano) >= 0) {
        filtrosAnosSelecionados.map((item) => {
          if (item != ano) {
            anosSelecionados.push(item);
          }
        });
      } else {
        filtrosAnosSelecionados.map((item) => {
          anosSelecionados.push(item);
        });
        anosSelecionados.push(ano);
      }
    }
    if (anosSelecionados.length == 0) {
      setPeriodoAno(false);
      preencherInputPeriodo();
      setFiltrosAnosSelecionados([]);
    } else {
      setFiltrosAnosSelecionados(anosSelecionados);
      setFiltrosLojasSelecionadas(filtrosLojasSelecionadasAuc);
    }
  }

  function filtrarPorLoja(loja) {
    const lojasSelecionadas = loja.map((item) => item.label);
    LoadingTemp();
    setAuxTipoPeriodo("loja");

    setFiltradoPorLoja(true);
    setFiltrosLojasSelecionadas(lojasSelecionadas);
    var lojasDisponiveisAux = [];
    lojasDisponiveis.map((item) => {
      lojasDisponiveisAux.push(item);
    });
    if (lojasSelecionadas.length === 0) {
      preencherInputPeriodo();
    } else {
      setAnosDisponiveis(fAnosPorLoja(lojasSelecionadas));
      setLojasDisponiveis(lojasDisponiveisAux);
    }
    setTipoPeriodo("input");
  }

  function preencherGraficoDesempenhoPorMes(nomeLoja) {
    var codLoja = converterNomeLoja(nomeLoja);
    var label = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    var dadosDesempenhoMesEspecifico = [];
    var backgroundColor = [];
    dadosParaTabelaGlobalDesempenho.map((item) => {
      if (item.loja === codLoja) {
        dadosDesempenhoMesEspecifico = [
          item.jan,
          item.fev,
          item.mar,
          item.abr,
          item.mai,
          item.jun,
          item.jul,
          item.ago,
          item.set,
          item.out,
          item.nov,
          item.dez,
        ];
      } else {
        dadosDesempenhoMesEspecifico = [
          item.jan,
          item.fev,
          item.mar,
          item.abr,
          item.mai,
          item.jun,
          item.jul,
          item.ago,
          item.set,
          item.out,
          item.nov,
          item.dez,
        ];
      }
    });

    label.map((item, i) => {
      backgroundColor.push(
        i == 0
          ? "rgba(255, 194, 218, 1)"
          : i == 1
          ? "rgba(91, 139, 252, 1)"
          : i == 2
          ? "#5B8BFC"
          : i == 3
          ? "#FFC2DA"
          : i == 4
          ? ""
          : geraCorAleatoria()
      );
    });

    const data = {
      labels: label,
      datasets: [
        {
          label: "Desempenho %",
          data: dadosDesempenhoMesEspecifico,
          backgroundColor: backgroundColor,
          borderWidth: 1,
        },
      ],
    };
    setDadosGraficoDesempenho(data);
    setNivelAtivoDoGraficoDesempenho(1);
  }
  let delayed;
  const optionsChartBarVendas = {
    title: {
      display: true,
      padding: 20,
      text: "Valor Total de Vendas",
    },
    legend: {
      position: "bottom",
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    animations: {
      onComplete: () => {
        delayed = true;
      },
      delay: (context) => {
        let delay = 0;
        if (context.type === "data" && context.mode === "default" && !delayed) {
          delay = context.dataIndex * 300 + context.datasetIndex * 100;
        }
        return delay;
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 25,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
      },
    },
  };

  const optionsChartBarDesempenho = {
    title: {
      display: true,
      padding: 20,
      text: "Desempenho em Relação ao Ano Anterior",
    },
    legend: {
      position: "bottom",
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        display: true,
        color: "#000",
        align: "end",
      },
    },
  };

  const getElementAtEvent = (element) => {
    if (nivelAtivoDoGraficoTotalVendas > 0) return;
    if (!element.length) return;
    const { _index: index, _xScale: eixoX } = element[0];
    if (nivelAtivoDoGraficoTotalVendas == 0) {
      var numDia = converterNomeDia(eixoX.ticks[index]);
      setNumDiaAtivo(numDia);
      setTituloTabelaNext("Vendas Por Horas do Dia");
    }
  };

  const getElementAtEventGraficoDesempenho = (element) => {
    if (nivelAtivoDoGraficoDesempenho > 0) return;
    if (!element.length) return;
    const { _index: index, _xScale: eixoX } = element[0];
    var nomeLoja = eixoX.ticks[index];
    preencherGraficoDesempenhoPorMes(nomeLoja);
  };

  const filtrarBases = (value) => {
    const basesSelecionadasTemp = value.value;

    setBasesSelecionadas(basesSelecionadasTemp);
    setBase(basesSelecionadasTemp);
  };

  const fVoltar = () => {
    setCodLojaAtiva(false);
    let auxLojasSelecionadas = [];
    if (numDiaAtivo === "") return;
    let dataVendas = dadosGerais;
    dataVendas.map((a) => {
      if (auxLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) === -1) {
        auxLojasSelecionadas.push(converterLoja(a.CodLoja));
      }
    });
    if (filtrosLojasSelecionadas.length > 0) {
      dataVendas = dataVendas.filter((a) => {
        if (filtrosLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) >= 0) {
          return true;
        } else {
          return false;
        }
      });
    }
    let grupoDeAnos = agruparPor(dataVendas, "ano");
    let grupoDeAnosArray = Object.entries(grupoDeAnos);
    let grupoDeAnoLoja = [];
    grupoDeAnosArray.map((item) => {
      let grupoDeLoja = agruparPor(item[1], "diaSemana");
      grupoDeAnoLoja.push(grupoDeLoja);
    });

    let resumoGrupoDeAnoLoja = [];
    grupoDeAnoLoja.map((item) => {
      let item2 = Object.entries(item);
      item2.map((item3) => {
        let total = 0,
          totalPedidos = 0,
          ano,
          diaSemana;
        ano = item3[1][0].ano;
        diaSemana = item3[1][0].diaSemana;
        item3[1].map((item4, index) => {
          totalPedidos += parseInt(item4.totalPedidos);
          total += parseFloat(item4.VlrTotal);
        });
        let resumoLojaAno = {
          ano: ano,
          total: total,
          totalPedidos: totalPedidos,
          diaSemana: diaSemana,
        };
        resumoGrupoDeAnoLoja.push(resumoLojaAno);
      });
    });
    var agrupado = agruparPor(resumoGrupoDeAnoLoja, "ano");
    grupoDeAnosArray = Object.entries(agrupado);
    preencherDadosGraficoAgrupado(
      grupoDeAnosArray,
      auxAnoAtual,
      auxAnoAnterior,
      tipoPeriodo,
      dadosUltimoPeriodo
    );
    preencherDadosTabelasGlobais(grupoDeAnos);
    setLojasDisponiveis(auxLojasSelecionadas);

    setNivelAtivoDoGraficoTotalVendas(0);
    setNumDiaAtivo("");
    setHoraNext(false);
  };
  const loadAvailableStores = (value) => {
    const shopsAvailableForBase = formatShopAvailable(
      [value],
      userData.nivelAcesso
    );
    setAvailableStore(shopsAvailableForBase);
    return shopsAvailableForBase;
  };

  return (
    <>
      {PermissionsPage("vendDia") && (
        <>
          <Box ml={2}>
            <Title title="Vendas Por Dia" />
          </Box>

          <Stack gap={2} direction={"row"} mx={2}>
            <Box component={Paper} variant="outlined" px={1} pb={1}>
              <FiltroPeriodo
                periodoInputInicioAux={periodoInputInicioAux}
                periodoInputFimAux={periodoInputFimAux}
                setPeriodoInputInicioAux={setPeriodoInputInicioAux}
                setPeriodoInputFimAux={setPeriodoInputFimAux}
              />
              <Stack gap={1}>
                <ButtonFilter
                  type="button"
                  onClick={() => iniciarFiltroPorPeriodo()}
                  disabled={isLoading}>
                  Filtrar
                </ButtonFilter>
                <ButtonToday
                  type="button"
                  onClick={() => iniciarFiltroHoje()}
                  disabled={isLoading}>
                  Hoje
                </ButtonToday>
              </Stack>
            </Box>

            <Box width={"100%"}>
              <Box pb={1}>
                <Typography
                  textAlign={"initial"}
                  fontWeight={"bold"}
                  component={"span"}>
                  Bases
                </Typography>
                <Select
                  options={basesDisponiveis.map((item) => ({
                    label: item.base,
                    value: item.codBase,
                  }))}
                  onChange={filtrarBases}
                  placeholder="Selecione uma Base"
                  styles={colourStyles}
                  isDisabled={isLoading}
                />
                <Typography variant="caption" component={"span"}>
                  *Atenção: Quando nenhuma base estiver selecionada, todas as
                  bases serão retornadas.*
                </Typography>
              </Box>
              <Box>
                <Typography
                  textAlign={"initial"}
                  fontWeight={"bold"}
                  component={"span"}>
                  Lojas
                </Typography>
                <Select
                  options={lojasDisponiveis.map((item) => ({
                    label: item,
                    value: item.split(" ")[1],
                  }))}
                  isMulti
                  onChange={filtrarPorLoja}
                  placeholder="Selecione uma loja"
                  styles={colourStyles}
                  isDisabled={isLoading}
                />
                <Typography variant="caption" component={"span"}>
                  *Atenção: Quando nenhuma loja estiver selecionada, todas as
                  lojas serão retornadas.*
                </Typography>
              </Box>
            </Box>
          </Stack>

          {isLoading ? (
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"90vh"}>
              <LoadingTable isLoading={isLoading} />
            </Box>
          ) : (
            <>
              <>
                {dadosDisponiveis && (
                  <>
                    <Box
                      m={2}
                      p={1}
                      component={Paper}
                      variant="outlined"
                      style={{ overflow: "auto" }}>
                      <Bar
                        data={dadosGraficoAgrupado}
                        options={optionsChartBarVendas}
                        getElementAtEvent={getElementAtEvent}
                      />
                      <ButtonVoltar fVoltar={fVoltar} />
                    </Box>
                    <Box
                      m={2}
                      p={1}
                      component={Paper}
                      variant="outlined"
                      style={{ overflow: "auto" }}>
                      <>
                        <Bar
                          data={dadosGraficoDesempenho}
                          options={optionsChartBarDesempenho}
                          getElementAtEvent={getElementAtEventGraficoDesempenho}
                        />
                        <ButtonVoltar fVoltar={resetarGraficoDesempenho} />
                      </>
                    </Box>
                  </>
                )}
              </>

              {dadosDisponiveis && exibeTabela ? (
                <>
                  <TituloTabela>{tituloTabelaNext}</TituloTabela>
                  <Box mx={2} variant="outlined" m={2}>
                    <TabelaNext
                      dadosParaTabela={dadosParaTabela}
                      colunas={colunasDias2(
                        tipoPeriodo,
                        anosDisponiveis,
                        filtrosAnosSelecionados,
                        horaNext
                      )}
                    />
                    {/* {exibeTabelaGlobal && <LegendaTabela />} */}
                  </Box>
                </>
              ) : (
                <></>
              )}

              {dadosDisponiveis && exibeTabelaGlobal ? (
                <>
                  <TituloTabela>VENDAS GLOBAIS ANO ATUAL</TituloTabela>
                  <Box mx={2} variant="outlined" m={2}>
                    <TabelaNext
                      dadosParaTabela={dadosParaTabelaGlobalAnoAtual}
                      colunas={colunasTabelaGlobalAnoAtual}
                    />
                  </Box>
                  {anosDisponiveis?.length > 1 && (
                    <>
                      <TituloTabela>VENDAS GLOBAIS ANO ANTERIOR</TituloTabela>
                      <Box mx={2} variant="outlined" m={2}>
                        <TabelaNext
                          dadosParaTabela={dadosParaTabelaGlobalAnoAnterior}
                          colunas={colunasTabelaGlobalAnoAnterior}
                        />
                      </Box>
                      <TituloTabela>DESEMPENHO POR MÊS</TituloTabela>
                      <Box mx={2} variant="outlined" m={2}>
                        <TabelaNext
                          dadosParaTabela={dadosParaTabelaGlobalDesempenho}
                          colunas={colunasTabelaGlobalDesempenho}
                        />
                      </Box>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default VendasPorDia;
