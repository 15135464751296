import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import "dayjs/locale/pt-br"; // importe o idioma desejado
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import Routes from "./routes/Routes";
import "./css/styles.css";
import dotenv from "dotenv";
dotenv.config();

dayjs.locale("pt-br"); // defina o idioma padrão
function App() {
  return <Routes />;
}

export default App;
