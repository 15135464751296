import React from "react";
import { Button, Row } from "react-bootstrap";

const FilterMes = ({
  periodosDisponiveis,
  selecionarPeriodo,
  periodoReferencia,
}) => {
  return (
    <div className="container-grafico-periodo">
      <h4 className="title-periodo">Período</h4>
      <Row md={4} xs={2}>
        {periodosDisponiveis.map((item, index) => {
          return item.id == periodoReferencia ? (
            <Button
              variant="secondary"
              key={index}
              onClick={() => selecionarPeriodo(item.id)}
            >
              {item.periodo}
            </Button>
          ) : (
            <Button
              variant="outline-secondary"
              key={index}
              onClick={() => selecionarPeriodo(item.id)}
            >
              {item.periodo}
            </Button>
          );
        })}
      </Row>
    </div>
  );
};

export default FilterMes;
