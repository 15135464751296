import DataTable from "react-data-table-component";
import { formatarNumero } from "../../../util/loja";
import {
  Avatar,
  Badge,
  Box,
  Chip,
  Divider,
  Fade,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import { Face } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { TextBody } from "../TextBody/TextBody";
import { TextSubtitle } from "../TextSubtitle/TextSubtitle";
import { LegendGoals } from "../LegendGoals/LegendGoals";
import { IsLoadingList } from "../IsLoadingList/IsLoadingList";
import styled from "styled-components";

const DIAMANTE = `#6395ec`;
const FOGUETE = `#00b14f`;
const OURO = "#FFD700";

const Container = styled("div")`
  .rdt_TableCol {
    background-color: #dcdcdc;
  }
`;
const columns = (colorMeta) => {
  return [
    {
      name: "Status",
      style: {
        backgroundColor: "#f3f3f3",
      },
      selector: "status",
      minWidth: "110px",
    },
    {
      name: "Vendas",
      selector: "vendas",
      minWidth: "140px",
      center: true,
      cell: (row) =>
        row.id === 4 ? (
          <Tooltip title={row.vendas} followCursor>
            <Chip
              size="small"
              label={row.vendas}
              sx={{
                background: colorMeta,
                color: "white",
                fontWeight: "bold",
              }}
            />
          </Tooltip>
        ) : (
          row.vendas
        ),
    },
    { name: "Ticket", selector: "ticket" },
    { name: "Qtd pedidos", selector: "qtd", maxWidth: "90px" },
  ];
};

export const ListSeller = ({ dataPage, isLoading }) => {
  dataPage = !!dataPage ? dataPage : [];
  return (
    <Box pb={2}>
      <LegendGoals />

      {isLoading ? (
        <Stack gap={1}>
          <IsLoadingList />
          <IsLoadingList />
        </Stack>
      ) : (
        <>
          <Stack gap={1.5} mb={"30px !important"}>
            {dataPage?.map((item) => {
              const emoji =
                item?.metaAlcancada === 0
                  ? "🤷🏻‍♀️"
                  : item?.metaAlcancada === 1
                  ? "🚀"
                  : item?.metaAlcancada === 2
                  ? "🥇"
                  : item?.metaAlcancada === 3
                  ? "💎"
                  : "🤗";
              const data = [
                {
                  id: 1,
                  vendas: "",
                  ticket: "",
                  qtd: "",
                  deficit: "",
                  status: "Meta",
                },
                {
                  id: 2,
                  vendas: "",
                  ticket: "",
                  qtd: "",
                  deficit: "",
                  status: "Realizado",
                },
                {
                  id: 3,
                  vendas: "",
                  ticket: "",
                  qtd: "",
                  deficit: "",
                  status: "Défict Diário",
                },
                {
                  id: 4,
                  vendas: "",
                  ticket: "",
                  qtd: "",
                  deficit: "",
                  status: "Previsto",
                },
              ];
              data.forEach((ip) => {
                if (ip.id === 1) {
                  ip.vendas = !!item?.metaProjetada
                    ? formatarNumero(item.metaProjetada)
                    : "";
                  ip.ticket = !!item?.ticketProjetado
                    ? formatarNumero(item.ticketProjetado)
                    : "";
                  ip.qtd = item.quantidadePedidosProjetado;
                }
                if (ip.id === 2) {
                  ip.vendas = !!item?.metaRealizada
                    ? formatarNumero(item.metaRealizada)
                    : "";
                  ip.ticket = !!item?.metaTicketRealizada
                    ? formatarNumero(item.metaTicketRealizada)
                    : "";
                  ip.qtd = item.qtdPedido;
                }
                if (ip.id === 3) {
                  ip.ticket = !!item?.deficitDiarioTicket
                    ? formatarNumero(item.deficitDiarioTicket)
                    : "";
                  ip.vendas = !!item?.deficitDiario
                    ? formatarNumero(item.deficitDiario)
                    : "";
                  ip.qtd = item.deficitDiarioPedidos;
                }
                if (ip.id === 4) {
                  ip.vendas = !!item?.previsaoFinalVendas
                    ? formatarNumero(item.previsaoFinalVendas)
                    : "";
                  ip.qtd =
                    item.previsaoFinalPedidos % 2 === 0
                      ? item.previsaoFinalPedidos
                      : item.previsaoFinalPedidos?.toFixed(2);
                }
              });
              const colorMeta =
                item?.metaAlcancada === 0
                  ? red[500]
                  : item?.metaAlcancada === 1
                  ? FOGUETE
                  : item?.metaAlcancada === 2
                  ? OURO
                  : item?.metaAlcancada === 3
                  ? DIAMANTE
                  : "🤗";
              const colunas = columns(colorMeta);
              return (
                <>
                  <Stack
                    key={item.nomeFuncionario}
                    component={Paper}
                    elevation={1}
                    variant="outlined"
                    gap={1}
                    p={1}
                    direction={["column", "row"]}
                    width={"100%"}
                  >
                    <Stack
                      maxWidth={["100%", "130px"]}
                      width={"100%"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      mr={1}
                    >
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        followCursor
                        title={item.nomeFuncionario}
                      >
                        <Badge
                          badgeContent={emoji}
                          sx={{
                            "& .MuiBadge-badge": {
                              right: 10,
                              top: 12,
                              fontSize: "20px",
                              padding: "0 4px",
                              height: "30px",
                              background: colorMeta,
                            },
                          }}
                        >
                          <Avatar
                            src={item.foto}
                            sx={{
                              width: 100,
                              height: 100,
                              border: `3px solid ${colorMeta}`,
                            }}
                          />
                        </Badge>
                      </Tooltip>

                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        followCursor
                        title={item.nomeFuncionario}
                      >
                        <Chip
                          label={item.nomeFuncionario}
                          icon={<Face color="white" />}
                          sx={{
                            mt: 0.5,
                            color: "white",
                            fontWeight: "bold",
                            background: colorMeta,
                          }}
                        />
                      </Tooltip>
                    </Stack>
                    <Box
                      width={"100%"}
                      component={Paper}
                      elevation={1}
                      variant="outlined"
                    >
                      <Container>
                        <DataTable dense columns={colunas} data={data} />
                      </Container>
                    </Box>
                    <Stack
                      width={"100%"}
                      component={Paper}
                      elevation={1}
                      variant="outlined"
                      direction={"row"}
                    >
                      <Box flex={1}>
                        <TextSubtitle bgcolor={"#f3f3f3"}>
                          Contatos CRM
                        </TextSubtitle>
                        <Divider />
                        <TextBody>
                          Realizados:
                          <strong>{item.crmDone}</strong>
                        </TextBody>
                        <Divider />

                        <TextBody>
                          Disponíveis:
                          <strong>{item.crmAvailable}</strong>
                        </TextBody>
                        <Divider />
                      </Box>
                      <Divider
                        orientation="vertical"
                        sx={{
                          height: "100% !important",
                        }}
                      />
                      <Box flex={1}>
                        <TextSubtitle bgcolor={"#f3f3f3"}>
                          Mais informações
                        </TextSubtitle>
                        <Divider />

                        <TextBody>
                          Conversão:
                          <strong>{item.conversion?.toFixed(2)}</strong>
                        </TextBody>
                        <Divider />

                        <TextBody fontWeight={"Bold"} fontStyle={"italic"}>
                          Pesquisa de satisfação
                        </TextBody>
                        <Divider />

                        <TextBody>
                          Respostas:
                          <strong>{item.ratingQtd}</strong>
                        </TextBody>
                        <Divider />

                        <TextBody>
                          Pontuação:
                          <strong>{item.averageRating?.toFixed(2)}</strong>
                        </TextBody>
                        <Divider />
                      </Box>
                    </Stack>
                  </Stack>
                </>
              );
            })}
          </Stack>
        </>
      )}
    </Box>
  );
};
