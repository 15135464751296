import { api } from "./api.v2";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: async (params) =>
    await api.get("/targets/sales/employees-current-period", {
      params: params,
    }),
  getAvailablePeriods: async () =>
    await api.get(`/targets/sales/available-periods-current-period`),
};
