import { api } from "./api.v2";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDataByStore: async (idBase, params) => {
    return await api.get(`boletim/${idBase}/data-by-store`, {
      params,
    });
  },
  getPurchases: async (idBase, params) => {
    return await api.get(`boletim/${idBase}/purchases`, {
      params,
    });
  },
  getSales: async (idBase, params) => {
    return await api.get(`boletim/${idBase}/sales`, {
      params,
    });
  },
  getGroupsInventory: async (idBase, params) => {
    return await api.get(`boletim/${idBase}/groups-inventory`, {
      params,
    });
  },
  getBanksProvisions: async (idBase, params) => {
    return await api.get(`boletim/${idBase}/banks-provisions`, {
      params,
    });
  },
};
