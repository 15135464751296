import { ContainerTitle } from "./styles.js";
import coracao from "../../assets/coracao.svg";
import { useEffect, useState } from "react";
import { CardHomeGraficoMetas } from "../../components/CardsHome/CardHomeGraficoMetas";
import { CardHomeVendasDias } from "../../components/CardsHome/CardHomeVendasDias";

import toast from "react-hot-toast";
import { PermissionsPage } from "../../util/permissionsPage";
import { useUserData } from "../../hooks/useUserData";
import { Box } from "@mui/material";
import { LoadingTable } from "../../components/Loading/LoadingTable";

const Home = () => {
  const [nameUser, setNameUser] = useState({ nome: "" });
  const { userData, isLoading } = useUserData();

  useEffect(() => {
    if (!!userData) {
      console.log(userData.menu);
      setNameUser({ nome: userData.user });
      toast.success("Acesso valido");
      return;
    }
  }, [userData]);

  return (
    <>
      {isLoading ? (
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"90vh"}
        >
          <LoadingTable />
        </Box>
      ) : (
        <>
          <Box>
            <ContainerTitle>
              <aside>
                <h3>Olá, {nameUser.nome}</h3>
                <span>É bom ter você de volta</span>
              </aside>
              <div>
                <img src={coracao} alt="logo empresa" />
                <h1>Business Intelligence</h1>
              </div>
            </ContainerTitle>
            <Box
              display={"flex"}
              flexDirection={["column-reverse", "row"]}
              gap={[2, 4]}
              width={"100%"}
            >
              {PermissionsPage("vendDia") && <CardHomeVendasDias />}
              {PermissionsPage("metaVendaGeral") && <CardHomeGraficoMetas />}
            </Box>
          </Box>

          <Box>
            <a
              className="linkPesquisa"
              href={`https://aprincipalbb.com.br/portal/home.php?id=${userData?.id}&token=${userData?.token}`}
            >
              <ContainerTitle>
                <h1>Ver pesquisas em aberto</h1>
              </ContainerTitle>
            </a>
          </Box>
        </>
      )}
    </>
  );
};

export default Home;
