import {
  Box,
  Button,
  Divider,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import { useParams, useHistory } from "react-router-dom";

import { useEffect, useState } from "react";

import toast from "react-hot-toast";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { PermissionsPage } from "../../../../util/permissionsPage";
import Title from "../../../../components/title";

import { useUserData } from "../../../../hooks/useUserData";
import { isEmptyObject } from "../../../../util/isEmptyObject";
import ErrorToastHandler from "../../../../util/ErrorToastHandler";
import candidates from "../../../../services/candidates";
import { Flex } from "../components/Flex";
import { Download } from "@mui/icons-material";
import { InfoMain } from "../components/InfoMain";
export const Candidate = () => {
  const history = useHistory();
  const params = useParams();
  const [data, setData] = useState({});
  const { userData } = useUserData();

  const [isLoading, setIsLoading] = useState(true);

  const getInfoVacancies = async () => {
    setIsLoading(true);

    try {
      const response = await candidates.get(params.idCandidate);
      setData(response.data);
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
      console.log(error);
      toast.error("Loja não existe");
    } finally {
      setIsLoading(false);
    }
  };

  const goBackList = () =>
    history.push(`/rh/vagas/ver-candidatos/${params.id}`);

  useEffect(() => {
    if (params?.idCandidate) {
      if (!isEmptyObject(userData)) {
        getInfoVacancies();
      }
    }
  }, [params?.idCandidate, userData]);

  return (
    <>
      {PermissionsPage("list_store") && (
        <>
          <Box pl={2}>
            <Title title={`Informações do candidato`} />
          </Box>

          {isLoading ? (
            <Box width={"100%"} p={2}>
              <Box component={Paper} elevation={1} variant="outlined" p={2}>
                <Flex justifyContent={"space-between"}>
                  <Skeleton
                    variant="rounded"
                    sx={{
                      maxWidth: "300px",
                    }}
                    height={35}
                    width={"100%"}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{
                      maxWidth: "150px",
                    }}
                    height={35}
                    width={"100%"}
                  />
                </Flex>

                <Stack pt={2} mb={2} direction={"row"} gap={2}>
                  <Skeleton variant="rounded" height={55} width={"100%"} />
                  <Skeleton variant="rounded" height={55} width={"100%"} />
                  <Skeleton variant="rounded" height={55} width={"100%"} />
                  <Skeleton variant="rounded" height={55} width={"100%"} />
                </Stack>
                <Divider />
                <Stack pt={2} mb={2} direction={"row"} gap={2}>
                  <Skeleton variant="rounded" height={55} width={"100%"} />
                  <Skeleton variant="rounded" height={55} width={"100%"} />
                  <Skeleton variant="rounded" height={55} width={"100%"} />
                </Stack>
                <Divider />

                <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={70} />

                <Stack
                  spacing={1.5}
                  width={"100%"}
                  justifyContent={"center"}
                  direction={"row"}
                >
                  <Skeleton variant="rounded" height={55} width={150} />
                  <Skeleton variant="rounded" height={55} width={150} />
                  <Skeleton variant="rounded" height={55} width={150} />
                  <Skeleton variant="rounded" height={55} width={150} />
                </Stack>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                px: 2,
                paddingBottom: 3,
              }}
            >
              <>
                {!isLoading && (
                  <Box display={"flex"} gap={4} width={"100%"}>
                    <Paper
                      sx={{
                        p: 2,
                        flex: 1,
                      }}
                      variant="outlined"
                      elevation={3}
                    >
                      <Stack gap={1}>
                        <Flex width={"100%"} justifyContent={"space-between"}>
                          <Typography variant="h5" component={"h5"}>
                            {data.nome}
                          </Typography>
                          <Button
                            color="aprincipal"
                            variant="contained"
                            startIcon={<Download />}
                          >
                            Baixar curriculo
                          </Button>
                        </Flex>
                        <InfoMain data={data} />
                      </Stack>
                      <Stack spacing={2} component={"form"}>
                        <Box
                          display={"flex"}
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                          gap={2}
                        >
                          <Button
                            variant="outlined"
                            onClick={goBackList}
                            color="aprincipal"
                            type="button"
                          >
                            Voltar
                          </Button>
                        </Box>
                      </Stack>
                    </Paper>
                  </Box>
                )}
              </>
            </Box>
          )}
        </>
      )}
    </>
  );
};
