import styled from "styled-components";

export const CellYearContainer = styled.aside`
  display: grid;
  div {
    display: flex;
    justify-content: end;
    &:not(last-child) {
      border-bottom: 1px dashed #aba8a7;
    }
  }
`;
export const ContainerCellAside = styled.aside`
  display: grid;
  strong {
  }
`;

export const TotalSalesGroup = styled.div`
  background-color: #ffff53eb;
  padding: 4px;
  border-radius: 2px;
  color: #222222;
  strong {
    white-space: nowrap;
  }
`;
