import axios from "axios";

const api = axios.create({
  baseURL: "https://api-teste.aprincipalbb.com.br",
});

export const apiProd = axios.create({
  baseURL: "https://api.prod.aprincipalbb.com.br/api",
  //baseURL: "http://localhost:8001",
});

export default api;
