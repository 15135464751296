import { Box, Typography } from "@mui/material";
import { converterLoja, converterNumero } from "../../util/loja";
const DividerCell = () => {
  return <Box border={".7px dashed rgba(0, 0, 0, 0.12)"}></Box>;
};

export const columnsTable = [
  {
    name: <strong style={{ fontSize: "1rem" }}>Loja</strong>,
    selector: (row) => row.loja,
    sortable: true,
    cell: (row) => converterLoja(row.loja),
  },
  {
    name: <strong style={{ fontSize: "1rem" }}>Qtd Clientes</strong>,
    selector: (row) => row.qtdClientes,
    cell: (row) => {
      return (
        <Box width={"100%"} display={"flex"} flexDirection={"column"}>
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>AT: </strong>
            {row.qtdClientes}
          </Typography>
          <DividerCell />
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>AN: </strong>
            {row.qtdClientesAn}
          </Typography>
          <DividerCell />
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>PA: </strong>
            {row.qtdClientesPa}
          </Typography>
        </Box>
      );
    },
  },
  {
    name: <strong style={{ fontSize: "1rem" }}>1ª Compra</strong>,
    selector: (row) => row.qtdClientesPrimeiroPedido,
    cell: (row) => {
      return (
        <Box width={"100%"} display={"flex"} flexDirection={"column"}>
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>AT: </strong>
            {row.qtdClientesPrimeiroPedido}
          </Typography>
          <DividerCell />
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>AN: </strong>
            {row.qtdClientesPrimeiroPedidoAn}
          </Typography>
          <DividerCell />
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>PA: </strong>
            {row.qtdClientesPrimeiroPedidoPa}
          </Typography>
        </Box>
      );
    },
  },
  {
    name: <strong style={{ fontSize: "1rem" }}>Qtd Pedidos</strong>,
    selector: (row) => row.qtdClientes,
    cell: (row) => {
      return (
        <Box width={"100%"} display={"flex"} flexDirection={"column"}>
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>AT: </strong>
            {row.qtdPedidos}
          </Typography>
          <DividerCell />
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>AN: </strong>
            {row.qtdPedidosAn}
          </Typography>
          <DividerCell />
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>PA: </strong>
            {row.qtdPedidosPa}
          </Typography>
        </Box>
      );
    },
  },
  {
    name: <strong style={{ fontSize: "1rem" }}>Pedidos Sem Cadastro</strong>,
    selector: (row) => row.qtdPedidosSemClienteCadastrado,
    cell: (row) => {
      return (
        <Box width={"100%"} display={"flex"} flexDirection={"column"}>
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>AT: </strong>
            {row.qtdPedidosSemClienteCadastrado}
          </Typography>
          <DividerCell />
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>AN: </strong>
            {row.qtdPedidosSemClienteCadastradoAn}
          </Typography>
          <DividerCell />
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>PA: </strong>
            {row.qtdPedidosSemClienteCadastradoPa}
          </Typography>
        </Box>
      );
    },
  },

  {
    name: <strong style={{ fontSize: "1rem" }}>Valor Total </strong>,
    selector: (row) => row.vlr,
    cell: (row) => {
      return (
        <Box width={"100%"} display={"flex"} flexDirection={"column"}>
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>AT: </strong>
            {converterNumero(row.vlr)}
          </Typography>
          <DividerCell />
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>AN: </strong>
            {converterNumero(row.vlrAn)}
          </Typography>
          <DividerCell />
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>PA: </strong>
            {converterNumero(row.vlrPa)}
          </Typography>
        </Box>
      );
    },
  },
  {
    name: <strong style={{ fontSize: "1rem" }}>Ticket Médio </strong>,
    selector: (row) => row.vlrMedio,
    cell: (row) => {
      return (
        <Box width={"100%"} display={"flex"} flexDirection={"column"}>
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>AT: </strong>
            {converterNumero(row.vlrMedio)}
          </Typography>
          <DividerCell />
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>AN: </strong>
            {converterNumero(row.vlrMedioAn)}
          </Typography>
          <DividerCell />
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>PA: </strong>
            {converterNumero(row.vlrMedioPa)}
          </Typography>
        </Box>
      );
    },
  },
  {
    name: <strong style={{ fontSize: "1rem" }}>Repres. (%) </strong>,
    selector: (row) => row.representatividade,
    cell: (row) => {
      return (
        <Box width={"100%"} display={"flex"} flexDirection={"column"}>
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>AT: </strong>
            {row.representatividade} %
          </Typography>
          <DividerCell />
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>AN: </strong>
            {row.representatividadeAn} %
          </Typography>
          <DividerCell />
          <Typography
            whiteSpace={"nowrap"}
            fontSize={"14px"}
            component={"span"}
          >
            <strong>PA: </strong>
            {row.representatividadePa} %
          </Typography>
        </Box>
      );
    },
  },
];
