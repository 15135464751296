import React from 'react'
import './CellTableLoja.css'
const CellTableLoja = ({ rowInfo, rowCor }) => {
    return (
        <div data-tag="allowRowEvents" >
            <div className='cellLoja'
                style={{
                    width: '100%',
                    height: '100%',
                    margin: 0,
                    background: rowCor,
                    padding: 5,
                    whiteSpace: 'nowrap',
                    borderRadius: 4
                }}>
                {rowInfo}
            </div>
        </div>
    )
}

export default CellTableLoja
