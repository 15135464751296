import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";

import { FiMoreVertical } from "react-icons/fi";

import { SimpleDialogDelete } from "../../../../components/SimpleDialogDelete/SimpleDialogDelete";
import { useHistory } from "react-router-dom";
import { OptionsMenu } from "../../components/OptionsMenu";
import { GridItem } from "../../components/GridItem";
import { formatterNumberPhoneMask } from "../../utils/formatterNumberPhoneMask";

export function Rows(props) {
  const { row, handleDelete } = props;
  const [open, setOpen] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    handleDelete(row.id);
  };
  const handleEdit = () => {
    history.push(`/cadastro/loja/editar/${row.id}`);
  };
  const codEstoqueDisponivelParaLoja = !!row.config.codEstoqueDisponivelParaLoja
    ? row.config.codEstoqueDisponivelParaLoja.split(",").map(Number)
    : null;
  const whatsGerenteLoja = formatterNumberPhoneMask(
    row.config.whatsGerenteLoja
  );
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton
            aria-controls={openMenu ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            onClick={handleClick}
          >
            <FiMoreVertical />
          </IconButton>
        </TableCell>

        <TableCell
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {row.loja}
        </TableCell>
        <TableCell>{row.sigla}</TableCell>
        <TableCell>
          <Tooltip title={row.cidade}>
            <Typography
              maxWidth={"150px"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {row.cidade}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>{row.localidade}</TableCell>
        <TableCell>{row.numeroWhatsapp}</TableCell>
        <TableCell>
          <Tooltip title={row.linkIntagram}>
            <Typography
              maxWidth={"150px"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {row.linkIntagram}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>{row.idLocalidade}</TableCell>
        <TableCell>{row.base}</TableCell>
        <TableCell>
          <Typography minWidth={"150px"}>{row.cnpj}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Informações adicionais
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={13}>
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 1, md: 2 }}
                        direction="column"
                      >
                        <GridItem
                          value={row.config.codMinCadastroCliente}
                          label={"Sequencial inicial de clientes "}
                        />
                        <GridItem
                          value={row.config.codMaxCadastroCliente}
                          label={"Sequencial final de clientes "}
                        />

                        <GridItem
                          value={whatsGerenteLoja}
                          label={"WhatsApp da gerente"}
                        />

                        <GridItem
                          value={codEstoqueDisponivelParaLoja}
                          label={"Códigos de estoque disponíveis"}
                        />
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <OptionsMenu
        anchorEl={anchorEl}
        open={openMenu}
        id={row.id}
        onClose={handleClose}
        onDelete={handleClickOpenDialog}
        onEdit={handleEdit}
      />
      <SimpleDialogDelete open={openDialog} onClose={handleCloseDialog} />
    </>
  );
}
