import {
  Box,
  CircularProgress,
  IconButton,
  ListItemIcon,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import { useState } from "react";

import { FiMoreVertical } from "react-icons/fi";

import { SimpleDialogDelete } from "../../../../components/SimpleDialogDelete/SimpleDialogDelete";
import { useHistory } from "react-router-dom";
import dayjs, { utc } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { IOSSwitch } from "../../../cadaastros/profile-indicate/components/IOSSwitch";
import {
  Delete,
  Edit,
  SupervisedUserCircle,
} from "@mui/icons-material";
import { OptionsMenu } from "./OptionsMenu";

dayjs.extend(advancedFormat);
dayjs.extend(utc);

export function Rows(props) {
  const { row, handleDelete, handleTogle } = props;
  const [isLoadingTogle, setIsloadingTogle] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleEdit = () => {
    history.push(`/rh/vagas/editar/${row.id}`);
  };

  const onTogle = async () => {
    setIsloadingTogle(true);
    await handleTogle(row);
    setIsloadingTogle(false);
  };
  const optionsMenu = [
    {
      name: "Editar",
      icon: (
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
      ),
      onClick: () => {
        handleEdit();
      },
    },
    {
      name: "Deletar",
      icon: (
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
      ),
      onClick: () => {
        handleClickOpenDialog();
        handleClose();
      },
    },
    {
      name: "Ver candidatos",
      icon: (
        <ListItemIcon>
          <SupervisedUserCircle />
        </ListItemIcon>
      ),
      onClick: () => {
        history.push(`/rh/vagas/ver-candidatos/${row.id}`);
      },
    },
  ];

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-controls={openMenu ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            onClick={handleClick}
          >
            <FiMoreVertical />
          </IconButton>
        </TableCell>
        <TableCell>
          <Box
            display={"flex"}
            alignItems={"center"}
            width={"100%"}
            justifyContent={"center"}
          >
            {isLoadingTogle ? (
              <CircularProgress
                color="aprincipal"
                sx={{
                  width: "23px !important",
                  height: "23px !important",
                }}
              />
            ) : (
              <IOSSwitch checked={!!+row.status} onClick={onTogle} />
            )}
          </Box>
        </TableCell>

        <TableCell
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {row.nome}
        </TableCell>

        <TableCell>
          <Tooltip title={row.cidade}>
            <Typography
              maxWidth={"150px"}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {row.cidade}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>{row.departamento}</TableCell>

        <TableCell>
          <Typography minWidth={"150px"}>
            {dayjs.utc(row.dataCadastro).format("DD/MM/YYYY")}
          </Typography>
        </TableCell>
      </TableRow>

      <OptionsMenu
        anchorEl={anchorEl}
        open={openMenu}
        id={row.id}
        options={optionsMenu}
        onClose={handleClose}
      />
      <SimpleDialogDelete
        open={openDialog}
        onClose={handleCloseDialog}
        onDelete={() => handleDelete(row.id)}
      />
    </>
  );
}
