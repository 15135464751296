import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useHistory } from 'react-router-dom';
import { ContainerButtonLink } from '../styles';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

export const BuntonLink = ({ ...props }) => {

    const history = useHistory()
    const handleRoutes = () => {
        return history.push(props.path)
    }
    return (
        <ContainerButtonLink>
            <button
                onClick={handleRoutes}
                {...props}
            >
                {props?.children} <FontAwesomeIcon icon={faChevronRight} />
            </button>
        </ContainerButtonLink>
    )
}
