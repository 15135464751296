import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatarNumero } from "../../util/loja";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useState } from "react";

export const Realized = ({ isLoading, dataPage = [] }) => {
  const [openCollapseCollumn, setOpenCollapseCollumn] = useState(false);

  const colunas = dataPage?.colunas;
  return (
    <Box>
      <Box component={Paper} variant="outlined" bgcolor={"#f0dede"}>
        <Typography fontWeight={"500"} textAlign={"center"}>
          Orçado x Realizado
        </Typography>
      </Box>
      {isLoading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"130px"}
          component={Paper}
          variant="outlined"
        >
          <CircularProgress color="aprincipal" />
        </Box>
      ) : (
        <>
          {colunas?.length === 0 && dataPage.dados?.length === 0 ? (
            <Box
              p={4}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              component={Paper}
              variant="outlined"
            >
              <Typography>Nenhum resultado encontrado</Typography>
            </Box>
          ) : (
            <TableContainer component={Paper} variant="outlined">
              <Table size={"small"} aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    {colunas?.map((item) => (
                      <TableCell
                        sx={{
                          bgcolor: "rgb(0, 176, 240)",
                          cursor: "pointer",
                        }}
                        onClick={() => setOpenCollapseCollumn((old) => !old)}
                        key={item.id}
                      >
                        {item.id === "conta" ? (
                          <Stack
                            gap={1}
                            direction={"row"}
                            alignItems={"center"}
                          >
                            {openCollapseCollumn ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )}
                            <Typography>{item.text}</Typography>
                          </Stack>
                        ) : (
                          item.text
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {dataPage.dados?.map(({ contas, ...row }) => {
                  if (row?.noHide) return <></>;
                  delete row.order;

                  delete row.noHide;
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        display:
                          openCollapseCollumn && !row?.isTotal
                            ? "table-row"
                            : "none",
                        fontWeight: "500",
                      }}
                    >
                      <TableCell
                        sx={{
                          whiteSpace: "nowrap",
                          fontWeight: "500",
                          bgcolor: "gray",
                        }}
                        component="th"
                        scope="row"
                      >
                        <Stack
                          gap={1}
                          justifyContent={"space-between"}
                          direction={"row"}
                        >
                          <Typography
                            color={"white"}
                            fontSize={"0.875rem"}
                            fontWeight={500}
                            component={"p"}
                          >
                            {contas}
                          </Typography>
                        </Stack>
                      </TableCell>

                      {Object.keys(row)?.map((key) => {
                        return (
                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                              fontWeight: 500,
                            }}
                            key={key}
                            component="th"
                            scope="row"
                          >
                            <Typography
                              color={parseFloat(row[key]) < 0 && "red"}
                            >
                              {formatarNumero(row[key])}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
                {dataPage.dados?.map(({ contas, ...row }) => {
                  if (!row?.noHide) return <></>;
                  delete row.noHide;
                  delete row.bg;
                  delete row.order;

                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{
                          whiteSpace: "nowrap",
                          fontWeight: "500",
                          bgcolor: "#535353",
                        }}
                        component="th"
                        scope="row"
                      >
                        <Stack
                          gap={1}
                          justifyContent={"space-between"}
                          direction={"row"}
                        >
                          <Typography
                            fontSize={"0.875rem"}
                            fontWeight={"500"}
                            color={"white"}
                            component={"p"}
                          >
                            {contas}
                          </Typography>
                        </Stack>
                      </TableCell>

                      {Object.keys(row)?.map((key) => {
                        return (
                          <TableCell
                            sx={{
                              whiteSpace: "nowrap",
                              fontWeight: "500",
                              bgcolor: "#FFFF99",
                            }}
                            key={key}
                            component="th"
                            scope="row"
                          >
                            <Typography
                              color={parseFloat(+row[key]) < 0 && "red"}
                            >
                              {formatarNumero(row[key])}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </Box>
  );
};
