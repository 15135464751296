import dayjs from "dayjs";
import { formatPercent } from "./Sales";

export const columnsTablePurchases = [
  {
    selector: "venda",
    name: <strong style={{ fontSize: "1rem" }}>Grupo</strong>,
    minWidth: "120px",
    cell: (row) => {
      if (dayjs(row.venda).isValid()) {
        return <strong>{dayjs(row.venda).format("DD/MM/YYYY")}</strong>;
      }
      return <strong>{row.venda}</strong>;
    },
  },
  {
    selector: "bebe",
    name: <strong style={{ fontSize: "1rem" }}>Bebê</strong>,
    cell: (row) => formatPercent(row, "bebe"),

    minWidth: "110px",
    maxWidth: "110px",
  },
  {
    selector: "meias",
    name: <strong style={{ fontSize: "1rem" }}>Meias e Cia</strong>,
    cell: (row) => formatPercent(row, "meias"),
    minWidth: "110px",
    maxWidth: "110px",
  },
  {
    selector: "moveis",
    name: <strong style={{ fontSize: "1rem" }}>Móveis</strong>,
    cell: (row) => formatPercent(row, "moveis"),
    minWidth: "110px",
    maxWidth: "110px",
  },
  {
    selector: "puericulturaL",
    name: <strong style={{ fontSize: "1rem" }}>Pueric. Leve</strong>,
    cell: (row) => formatPercent(row, "puericulturaL"),
    minWidth: "110px",
    maxWidth: "110px",
  },
  {
    selector: "puericulturaP",
    name: <strong style={{ fontSize: "1rem" }}>Pueric. Pesada</strong>,
    cell: (row) => formatPercent(row, "puericulturaP"),
    minWidth: "110px",
    maxWidth: "110px",
  },
  {
    selector: "infanto",
    name: <strong style={{ fontSize: "1rem" }}>Infanto</strong>,
    cell: (row) => formatPercent(row, "infanto"),
    minWidth: "110px",
    maxWidth: "110px",
  },
  {
    selector: "calcados",
    name: <strong style={{ fontSize: "1rem" }}>Calçados</strong>,
    cell: (row) => formatPercent(row, "calcados"),
    minWidth: "110px",
    maxWidth: "110px",
  },
  {
    selector: "outros",
    name: <strong style={{ fontSize: "1rem" }}>Outros</strong>,
    cell: (row) => formatPercent(row, "outros"),
    minWidth: "110px",
    maxWidth: "110px",
  },
  {
    selector: "total",
    name: <strong style={{ fontSize: "1rem" }}>Total</strong>,
    cell: (row) => formatPercent(row, "total"),
    minWidth: "110px",
    maxWidth: "110px",
  },
];
