import React from "react";

const CellTableQuantidade = ({  rowInfo }) => {
  return (
    <div>
      {+rowInfo === 0 ? (
        <div data-tag="allowRowEvents">
          <div style={{ color: "#f39c12", whiteSpace: "nowrap" }}>
            {rowInfo}
          </div>
        </div>
      ) : rowInfo < 0 ? (
        <div data-tag="allowRowEvents">
          <div
            style={{
              color: "#ff0000",
              fontWeight: "bold",
              whiteSpace: "nowrap",
            }}
          >
            {rowInfo}
          </div>
        </div>
      ) : (
        <div data-tag="allowRowEvents">
          <div style={{ whiteSpace: "nowrap" }}>{rowInfo}</div>
        </div>
      )}
    </div>
  );
};

export default CellTableQuantidade;
