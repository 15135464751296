import { Paper, Skeleton, Stack } from "@mui/material";

export const IsLoadingList = () => (
  <Stack
    direction={["column", "row"]}
    component={Paper}
    elevation={1}
    variant="outlined"
    p={1}
    width={"100%"}
    gap={1}
  >
    <Stack
      maxWidth={["100%", "130px"]}
      width={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      mr={1}
    >
      <Skeleton variant="circular" width={100} height={100} />
      <Skeleton
        sx={{
          borderRadius: "2rem",
          mt: 0.5,
        }}
        variant="rounded"
        width={130}
        height={30}
      />
    </Stack>
    <Skeleton
      sx={{
        maxWidth: ["100%", "61%"],
      }}
      variant="rounded"
      width={"100%"}
      height={170}
    />
    <Skeleton
      sx={{
        maxWidth: ["100%", "39%"],
      }}
      variant="rounded"
      width={"100%"}
      height={170}
    />
  </Stack>
);
