import CellTable from "../components/CellTable";
import CellTableQuantidade from "../components/CellTableQuantidade";
import CellTablePorcentagem from "../components/CellTablePorcentagem";
import CellTableTipo from "../components/CellTableTipo";
import {
  converterBase,
  converterDia,
  converterLoja,
  converterMes,
  converterNumero,
  formatarNumero,
} from "./loja";
import CellTableLoja from "../components/CellTableLoja/CellTableLoja";
import {
  CellAsideGlobal,
  CellAsideYaer,
  CellTableGlobal,
  CellTableYear,
} from "../components/CellTableYear/CellTableYear";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useState, MouseEvent } from "react";

export const conditionalRowStyles = [
  {
    when: (row) => row.loja == "Total",
    style: {
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderColor: "#000",
      borderStyle: "solid",
      fontWeight: "bold",
    },
  },
];
export const colunasResumo = (anosDisponiveis, filtrosAnosSelecionados) => {
  let anosListados = [];

  if (filtrosAnosSelecionados.length === 1) {
    anosListados = filtrosAnosSelecionados;
  } else {
    anosListados = anosDisponiveis;
  }
  let colunasResumo = [];
  if (anosListados.length === 1) {
    colunasResumo = [
      {
        name: "Total",
        selector: "total",
        sortable: false,
      },
      {
        name: "Quantidade",
        selector: "qtdAnoAtual",
        sortable: true,
        width: "auto",
      },
      {
        name: "Valor Total",
        selector: "vlrAnoAtual",
        sortable: true,
        format: (row, index) => {
          return converterNumero(row.vlrAnoAtual);
        },
        width: "auto",
      },
      {
        name: "Desconto Médio",
        selector: "vlrMedioAnoAtual",
        sortable: true,
        format: (row, index) => {
          return converterNumero(row.vlrMedioAnoAtual);
        },
        width: "auto",
        allowOverflow: true,
        wrap: false,
      },
      {
        name: "Representatividade (%)",
        selector: "representatividadeAtual",
        sortable: true,
        format: (row, index) => {
          return row.representatividadeAtual.toFixed(2) + "%";
        },
        width: "auto",
      },
    ];
  } else {
    colunasResumo = [
      {
        name: "Total",
        selector: "total",
        sortable: false,
      },
      {
        name: "QTD Ano Atual",
        selector: "qtdAnoAtual",
        sortable: true,
        width: "auto",
      },
      {
        name: "QTD Ano Anterior",
        selector: "qtdAnoAnterior",
        sortable: true,
        width: "auto",
      },
      {
        name: "Valor Total Ano Atual",
        selector: "vlrAnoAtual",
        sortable: true,
        format: (row, index) => {
          return converterNumero(row.vlrAnoAtual);
        },
        width: "auto",
      },
      {
        name: "Valor Total Ano Anterior",
        selector: "vlrTotalAnoAnterior",
        sortable: true,
        format: (row, index) => {
          return converterNumero(row.vlrTotalAnoAnterior);
        },
        width: "auto",
      },
      {
        name: "Desconto Médio Ano Atual",
        selector: "vlrMedioAnoAtual",
        sortable: true,
        format: (row, index) => {
          return converterNumero(row.vlrMedioAnoAtual);
        },
        width: "auto",
        allowOverflow: true,
        wrap: false,
      },
      {
        name: "Desconto Médio Ano Anterior",
        selector: "vlrMedioAnoAnterior",
        sortable: true,
        format: (row, index) => {
          return converterNumero(row.vlrMedioAnoAnterior);
        },
        width: "auto",
      },
      {
        name: "Desempenho (%)",
        selector: "desempenho",
        sortable: true,
        cell: (row) =>
          row.abr < 0 ? (
            <div data-tag="allowRowEvents">
              <div style={{ color: "#ff0000", fontWeight: "bold" }}>
                {row.desempenho.toFixed(2) + "%"}
              </div>
            </div>
          ) : (
            <div data-tag="allowRowEvents">
              <div>{row.desempenho.toFixed(2) + "%"}</div>
            </div>
          ),
        width: "auto",
      },
      {
        name: "Representatividade Ano Atual (%)",
        selector: "representatividadeAtual",
        sortable: true,
        format: (row, index) => {
          return row.representatividadeAtual.toFixed(2) + "%";
        },
        width: "auto",
      },
      {
        name: "Representatividade Ano Anterior (%)",
        selector: "representatividadeAnterior",
        sortable: true,
        format: (row, index) => {
          return row.representatividadeAnterior.toFixed(2) + "%";
        },
        width: "auto",
      },
    ];
  }
  return colunasResumo;
};
export const colunasDias = (
  tipo,
  anosDisponiveis,
  filtrosAnosSelecionados,
  hora = false
) => {
  let anosListados = [],
    columns = [];
  if (filtrosAnosSelecionados.length === 1) {
    anosListados = [];
    anosListados = filtrosAnosSelecionados;
  } else {
    anosListados = [];
    anosListados = anosDisponiveis;
  }
  if (tipo == "input" || tipo == "loja" || tipo == "ano") {
    if (anosListados.length === 1) {
      columns = [
        {
          name: () => {
            return hora ? "Horas" : "Dia";
          },
          selector: "loja",
          format: (row) => {
            return converterDia(row.loja);
          },
          sortable: true,
        },
        {
          name: "Quantidade " + anosListados[0],
          selector: "qtdAnoAtual",
          sortable: true,
          cell: (row) => {
            return <CellTableQuantidade rowInfo={row.qtdAnoAtual} />;
          },
        },
        {
          name: "Valor Total " + anosListados[0],
          selector: "vlrAnoAtual",
          sortable: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrAnoAtual} />;
          },
        },
        {
          name: "Desconto Médio" + anosListados[0],
          selector: "vlrMedioAnoAtual",
          sortable: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrMedioAnoAtual} />;
          },
        },
        {
          name: "Representatividade " + anosListados[0] + " (%)",
          selector: "representatividadeAtual",
          sortable: true,
          cell: (row) => {
            return (
              <CellTablePorcentagem rowInfo={row.representatividadeAtual} />
            );
          },
        },
      ];
    } else {
      columns = [
        {
          name: hora ? "Horas" : "Dia",
          selector: "loja",
          format: (row) => {
            return hora ? row.loja : converterDia(row.loja);
          },
          sortable: true,
        },
        {
          name: "QTD Ano Atual",
          selector: "qtdAnoAtual",
          sortable: true,
          cell: (row) => {
            return <CellTableQuantidade rowInfo={row.qtdAnoAtual} />;
          },
        },
        {
          name: "Valor Total Ano Atual",
          selector: "vlrAnoAtual",
          sortable: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrAnoAtual} />;
          },
        },
        {
          name: "Desconto Médio Ano Atual",
          selector: "vlrMedioAnoAtual",
          sortable: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrMedioAnoAtual} />;
          },
        },
        {
          name: "QTD Ano Anterior",
          selector: "qtdAnoAnterior",
          sortable: true,
          cell: (row) => {
            return <CellTableQuantidade rowInfo={row.qtdAnoAnterior} />;
          },
        },
        {
          name: "Valor Total Ano Anterior",
          selector: "vlrTotalAnoAnterior",
          sortable: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrTotalAnoAnterior} />;
          },
        },
        {
          name: "Desconto Médio Ano Anterior",
          selector: "vlrMedioAnoAnterior",
          sortable: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrMedioAnoAnterior} />;
          },
        },
        {
          name: "Desempenho (%)",
          selector: "desempenho",
          sortable: true,
          cell: (row) => {
            return <CellTablePorcentagem rowInfo={row.desempenho} />;
          },
        },
        {
          name: "Representatividade Ano Atual (%)",
          selector: "representatividadeAtual",
          sortable: true,
          cell: (row) => {
            return (
              <CellTablePorcentagem rowInfo={row.representatividadeAtual} />
            );
          },
        },
        {
          name: "Representatividade Ano Anterior (%)",
          selector: "representatividadeAnterior",
          sortable: true,
          cell: (row) => {
            return (
              <CellTablePorcentagem rowInfo={row.representatividadeAnterior} />
            );
          },
        },
      ];
    }
  } else if (tipo == "dia") {
    if (anosListados.length === 1) {
      columns = [
        {
          name: "Meses de " + anosListados[0],
          selector: "mes",
          sortable: true,
          format: (row, index) => {
            return converterMes(row.mes);
          },
        },
        {
          name: "Quantidade " + anosListados[0],
          selector: "qtdAnoAtual",
          sortable: true,
        },
        {
          name: "Valor Total " + anosListados[0],
          selector: "vlrAnoAtual",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrAnoAtual);
          },
        },
        {
          name: "Desconto Médio " + anosListados[0],
          selector: "vlrMedioAnoAtual",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrMedioAnoAtual);
          },
        },
        {
          name: "Representatividade " + anosListados[0] + " (%)",
          selector: "representatividadeAtual",
          sortable: true,
          format: (row, index) => {
            return row.representatividadeAtual.toFixed(2) + "%";
          },
        },
      ];
    } else {
      columns = [
        {
          name: "Mes",
          selector: "mes",
          sortable: true,
          format: (row, index) => {
            return converterMes(row.mes);
          },
        },
        {
          name: "QTD Ano Atual",
          selector: "qtdAnoAtual",
          sortable: true,
        },
        {
          name: "Valor Total Ano Atual",
          selector: "vlrAnoAtual",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrAnoAtual);
          },
        },
        {
          name: "Desconto Médio Ano Atual",
          selector: "vlrMedioAnoAtual",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrMedioAnoAtual);
          },
        },
        {
          name: "QTD Ano Anterior",
          selector: "qtdAnoAnterior",
          sortable: true,
        },
        {
          name: "Valor Total Ano Anterior",
          selector: "vlrTotalAnoAnterior",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrTotalAnoAnterior);
          },
        },
        {
          name: "Desconto Médio Ano Anterior",
          selector: "vlrMedioAnoAnterior",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrMedioAnoAnterior);
          },
        },
        {
          name: "Desempenho (%)",
          selector: "desempenho",
          sortable: true,
          cell: (row) =>
            row.abr < 0 ? (
              <div data-tag="allowRowEvents">
                <div style={{ color: "#ff0000", fontWeight: "bold" }}>
                  {row.desempenho.toFixed(2) + "%"}
                </div>
              </div>
            ) : (
              <div data-tag="allowRowEvents">
                <div>{row.desempenho.toFixed(2) + "%"}</div>
              </div>
            ),
        },
        {
          name: "Representatividade Ano Atual (%)",
          selector: "representatividadeAtual",
          sortable: true,
          format: (row, index) => {
            return row.representatividadeAtual.toFixed(2) + "%";
          },
        },
        {
          name: "Representatividade Ano Anterior (%)",
          selector: "representatividadeAnterior",
          sortable: true,
          format: (row, index) => {
            return row.representatividadeAnterior.toFixed(2) + "%";
          },
        },
      ];
    }
  }

  return columns;
};
export const colunasInputMesAnos = (
  tipo,
  anosDisponiveis,
  filtrosAnosSelecionados
) => {
  //quantidade de pedidos
  //vendasglobal
  let anosListados = [],
    columns = [];
  if (filtrosAnosSelecionados.length === 1) {
    anosListados = [];
    anosListados = filtrosAnosSelecionados;
  } else {
    anosListados = [];
    anosListados = anosDisponiveis;
  }
  if (tipo == "input" || tipo == "loja" || tipo == "ano") {
    if (anosListados.length === 1) {
      columns = [
        {
          name: "Loja",
          id: "lojas",
          selector: (row) => row?.loja,
          cell: (row) => {
            return <CellTableTipo rowInfo={row.loja} tipo={tipo} />;
          },
          width: "85px",
        },
        {
          name: "Quantidade " + anosListados[0],
          selector: (row) => row?.qtdAnoAtual,
          right: true,
          cell: (row) => {
            return <CellTableQuantidade rowInfo={row.qtdAnoAtual} />;
          },
        },
        {
          name: "Valor Total " + anosListados[0],
          selector: (row) => row?.vlrAnoAtual,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrAnoAtual} />;
          },
        },
        {
          name: "Desconto Médio " + anosListados[0],
          selector: (row) => row?.vlrMedioAnoAtual,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrMedioAnoAtual} />;
          },
        },
        {
          name: "Representatividade " + anosListados[0] + " (%)",
          selector: (row) => row?.representatividadeAtual,
          right: true,
          cell: (row) => {
            return (
              <CellTablePorcentagem rowInfo={row.representatividadeAtual} />
            );
          },
        },
      ];
    } else {
      columns = [
        {
          id: "lojas",
          name: "Loja",
          selector: (row) => row?.loja,
          cell: (row) => {
            return <CellTableTipo rowInfo={row.loja} tipo={tipo} />;
          },
          width: "85px",
        },
        {
          name: "Ano Atual",
          selector: (row) => row?.qtdAnoAtual,
          cell: (row) => {
            return <CellTableQuantidade rowInfo={row.qtdAnoAtual} />;
          },
          right: true,
        },
        {
          name: "Ano Anterior",
          selector: (row) => row?.qtdAnoAnterior,
          right: true,
          cell: (row) => {
            return <CellTableQuantidade rowInfo={row.qtdAnoAnterior} />;
          },
        },
        {
          name: "Ano Atual",
          selector: (row) => row?.vlrAnoAtual,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrAnoAtual} />;
          },
        },
        {
          name: "Ano Anterior",
          selector: (row) => row?.vlrTotalAnoAnterior,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrTotalAnoAnterior} />;
          },
        },
        {
          name: "Ano Atual",
          selector: (row) => row?.vlrMedioAnoAtual,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrMedioAnoAtual} />;
          },
        },
        {
          name: "Ano Anterior",
          selector: (row) => row?.vlrMedioAnoAnterior,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrMedioAnoAnterior} />;
          },
        },
        {
          name: "Ano Atual",
          selector: (row) => row?.representatividadeAtual,
          right: true,
          cell: (row) => {
            return (
              <CellTablePorcentagem rowInfo={row.representatividadeAtual} />
            );
          },
        },
        {
          name: "Ano Anterior",
          selector: (row) => row?.representatividadeAnterior,
          right: true,
          cell: (row) => {
            return (
              <CellTablePorcentagem rowInfo={row.representatividadeAnterior} />
            );
          },
        },
        {
          name: "Desem(%)",
          width: "85px",
          selector: (row) => row?.desempenho,
          right: true,
          cell: (row) => {
            return <CellTablePorcentagem rowInfo={row.desempenho} />;
          },
        },
      ];
    }
  } else if (tipo === "mes") {
    if (anosListados.length === 1) {
      columns = [
        {
          name: "Meses de " + anosListados[0],
          selector: "mes",
          sortable: true,
          format: (row, index) => {
            return converterMes(row.mes);
          },
        },
        {
          name: "Quantidade " + anosListados[0],
          selector: "qtdAnoAtual",
          sortable: true,
        },
        {
          name: "Valor Total " + anosListados[0],
          selector: "vlrAnoAtual",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrAnoAtual);
          },
        },
        {
          name: "Desconto Médio " + anosListados[0],
          selector: "vlrMedioAnoAtual",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrMedioAnoAtual);
          },
        },
        {
          name: "Representatividade " + anosListados[0] + " (%)",
          selector: "representatividadeAtual",
          sortable: true,
          format: (row, index) => {
            return row.representatividadeAtual.toFixed(2) + "%";
          },
        },
      ];
    } else {
      columns = [
        {
          name: "Mes",
          selector: "mes",
          sortable: true,
          format: (row, index) => {
            return converterMes(row.mes);
          },
        },
        {
          name: "QTD Ano Atual",
          selector: "qtdAnoAtual",
          sortable: true,
        },
        {
          name: "Valor Total Ano Atual",
          selector: "vlrAnoAtual",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrAnoAtual);
          },
        },
        {
          name: "Desconto Médio Ano Atual",
          selector: "vlrMedioAnoAtual",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrMedioAnoAtual);
          },
        },
        {
          name: "QTD Ano Anterior",
          selector: "qtdAnoAnterior",
          sortable: true,
        },
        {
          name: "Valor Total Ano Anterior",
          selector: "vlrTotalAnoAnterior",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrTotalAnoAnterior);
          },
        },
        {
          name: "Desconto Médio Ano Anterior",
          selector: "vlrMedioAnoAnterior",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrMedioAnoAnterior);
          },
        },
        {
          name: "Desempenho (%)",
          selector: "desempenho",
          sortable: true,
          cell: (row) =>
            row.abr < 0 ? (
              <div data-tag="allowRowEvents">
                <div style={{ color: "#ff0000", fontWeight: "bold" }}>
                  {row.desempenho.toFixed(2) + "%"}
                </div>
              </div>
            ) : (
              <div data-tag="allowRowEvents">
                <div>{row.desempenho.toFixed(2) + "%"}</div>
              </div>
            ),
        },
        {
          name: "Representatividade Ano Atual (%)",
          selector: "representatividadeAtual",
          sortable: true,
          format: (row, index) => {
            return row.representatividadeAtual.toFixed(2) + "%";
          },
        },
        {
          name: "Representatividade Ano Anterior (%)",
          selector: "representatividadeAnterior",
          sortable: true,
          format: (row, index) => {
            return row.representatividadeAnterior.toFixed(2) + "%";
          },
        },
      ];
    }
  }

  return columns;
};
export const colunasTabelasGlobais = () => {
  let mesesPrev = [
    "Loja",
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
    "Total",
  ];
  let columns = [];

  mesesPrev.map((item) => {
    let aux = item.toLowerCase();
    if (aux === "loja") {
      columns.push({
        name: item,
        selector: aux,
        sortable: true,
        cell: (row) => (
          <div data-tag="allowRowEvents">
            <div style={{ whiteSpace: "nowrap" }}>
              {converterLoja(row[aux])}
            </div>
          </div>
        ),
      });
    } else if (aux === "total") {
      columns.push({
        name: item,
        selector: aux,
        sortable: true,
        cell: (row) => <CellTable rowInfo={row.total} />,
        allowOverflow: true,
      });
    } else {
      columns.push({
        name: item,
        selector: aux,
        sortable: true,
        cell: (row) => <CellTable rowInfo={row[aux]} />,
        allowOverflow: true,
      });
    }
  });
  return columns;
};
export const colunaTabelaGlobalDesempenho = () => {
  let mesesPrev = [
    "Loja",
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
    "Média",
  ];
  let columns = [];
  mesesPrev.map((item) => {
    let aux = item.toLowerCase();
    if (aux === "loja") {
      columns.push({
        name: "Loja",
        selector: "loja",
        cell: (row) => (
          <div data-tag="allowRowEvents">
            <div style={{ whiteSpace: "nowrap" }}>
              {converterLoja(row.loja)}
            </div>
          </div>
        ),
        sortable: true,
      });
    } else if (aux === "média") {
      columns.push({
        name: "Média",
        selector: "total",
        sortable: true,
        cell: (row) => <CellTablePorcentagem rowInfo={row.total} />,
        width: "auto",
        allowOverflow: true,
      });
    } else {
      columns.push({
        name: item,
        selector: aux,
        sortable: true,
        cell: (row) => <CellTablePorcentagem rowInfo={row[aux]} />,
        width: "auto",
        allowOverflow: true,
      });
    }
  });
  return columns;
};
export const colunasTabelasGlobaisVendasPorDia = (hora = false) => {
  let mesesPrev = [
    "Dia",
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
    "Total",
  ];
  let columns = [];

  mesesPrev.map((item) => {
    let aux = item.toLowerCase();
    if (aux === "dia") {
      columns.push({
        name: hora ? "Horas" : "Dia",
        selector: "loja",
        cell: (row) => (
          <div data-tag="allowRowEvents">
            <div style={{ whiteSpace: "nowrap" }}>
              {row.loja == "Total" ? row.loja : converterDia(row.loja)}
            </div>
          </div>
        ),
        sortable: true,
      });
    } else if (aux === "total") {
      columns.push({
        name: item,
        selector: aux,
        sortable: true,
        cell: (row) => <CellTable rowInfo={row.total} />,
        allowOverflow: true,
      });
    } else {
      columns.push({
        name: item,
        selector: aux,
        sortable: true,
        cell: (row) => <CellTable rowInfo={row[aux]} />,
        allowOverflow: true,
      });
    }
  });

  return columns;
};
export const colunaTabelaGlobalDesempenhoVendasPorDia = (hora = false) => {
  let mesesPrev = [
    "Dia",
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
    "Média",
  ];
  let columns = [];
  mesesPrev.map((item) => {
    let aux = item.toLowerCase();
    if (aux === "dia") {
      columns.push({
        name: hora ? "Horas" : "Dia",
        selector: "loja",
        cell: (row) => (
          <div data-tag="allowRowEvents">
            <div style={{ whiteSpace: "nowrap" }}>
              {row.loja == "Total" ? row.loja : converterDia(row.loja)}
            </div>
          </div>
        ),
        sortable: true,
      });
    } else if (aux === "média") {
      columns.push({
        name: "Média",
        selector: "total",
        sortable: true,
        cell: (row) => <CellTablePorcentagem rowInfo={row.total} />,
        width: "auto",
        allowOverflow: true,
      });
    } else {
      columns.push({
        name: item,
        selector: aux,
        sortable: true,
        cell: (row) => <CellTablePorcentagem rowInfo={row[aux]} />,
        width: "auto",
        allowOverflow: true,
      });
    }
  });
  return columns;
};
export const vendasGrupo = (anosDisponiveis, filtrosAnosSelecionados) => {
  let colunasResumo = [],
    anosListados = [];

  if (filtrosAnosSelecionados.length === 1) {
    anosListados = filtrosAnosSelecionados;
  } else {
    anosListados = anosDisponiveis;
  }
  if (anosListados.length === 1) {
    colunasResumo = [
      {
        name: "Média",
        selector: "total",
        sortable: false,
      },
      {
        name: "QTD Ano Atual",
        selector: "qtdAnoAtual",
        sortable: true,
        right: true,
        cell: (row) => {
          return <CellTableQuantidade rowInfo={row.qtdAnoAtual} />;
        },
      },
      {
        name: "Valor Total Ano Atual",
        selector: "vlrAnoAtual",
        sortable: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.vlrAnoAtual} />;
        },
      },
      {
        name: "Ticket Médio Ano Atual",
        selector: "vlrMedioAnoAtual",
        sortable: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.vlrMedioAnoAtual} />;
        },
      },
    ];
  } else {
    colunasResumo = [
      {
        name: "Média",
        selector: "total",
        sortable: false,
      },
      {
        name: "QTD Ano Atual",
        selector: "qtdAnoAtual",
        sortable: true,
        right: true,
        cell: (row) => {
          return <CellTableQuantidade rowInfo={row.qtdAnoAtual} />;
        },
      },
      {
        name: "Valor Total Ano Atual",
        selector: "vlrAnoAtual",
        sortable: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.vlrAnoAtual} />;
        },
      },
      {
        name: "Ticket Médio Ano Atual",
        selector: "vlrMedioAnoAtual",
        sortable: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.vlrMedioAnoAtual} />;
        },
      },
      {
        name: "QTD Ano Anterior",
        selector: "qtdAnoAnterior",
        sortable: true,
        right: true,
        cell: (row) => {
          return <CellTableQuantidade rowInfo={row.qtdAnoAnterior} />;
        },
      },
      {
        name: "Valor Total Ano Anterior",
        selector: "vlrTotalAnoAnterior",
        sortable: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.vlrTotalAnoAnterior} />;
        },
      },
      {
        name: "Ticket Médio Ano Anterior",
        selector: "vlrMedioAnoAnterior",
        sortable: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.vlrMedioAnoAnterior} />;
        },
      },
      {
        name: "Desempenho (%)",
        selector: "desempenho",
        sortable: true,
        right: true,
        cell: (row) => {
          return <CellTablePorcentagem rowInfo={row.desempenho} />;
        },
      },
    ];
  }
  return colunasResumo;
};
export const vendasGrupoTabelaPrincipal = (
  tipo,
  anosDisponiveis,
  filtrosAnosSelecionados
) => {
  let columns = [],
    anosListados = [];
  if (filtrosAnosSelecionados.length === 1) {
    anosListados = filtrosAnosSelecionados;
  } else {
    anosListados = anosDisponiveis;
  }
  if (tipo == "input" || tipo == "loja" || tipo == "ano") {
    if (anosListados.length === 1) {
      columns = [
        {
          name: "Grupo",
          selector: "grupo",
          sortable: true,
          allowOverflow: true,
        },
        {
          name: "Quantidade " + anosListados[0],
          selector: "qtdAnoAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.qtdAnoAtual} />;
          },
        },
        {
          name: "Valor Total " + anosListados[0],
          selector: "vlrAnoAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrAnoAtual} />;
          },
        },
        {
          name: "Custo Total " + anosListados[0],
          selector: "custoTotalAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.custoTotalAtual} />;
          },
        },
        {
          name: "Lucro Bruto " + anosListados[0],
          selector: "lucroBrutoAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.lucroBrutoAtual} />;
          },
        },
        {
          name: "Lucro (%)" + anosListados[0],
          selector: "lucro",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTablePorcentagem rowInfo={row.lucro} />;
          },
        },
        {
          name: "Representatividade (%) " + anosListados[0],
          selector: "representitivadadeAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return (
              <CellTablePorcentagem rowInfo={row.representitivadadeAtual} />
            );
          },
        },
      ];
    } else {
      columns = [
        {
          name: "Grupo",
          selector: "grupo",
          sortable: true,
          allowOverflow: true,
        },
        {
          name: "QTD Ano Atual",
          selector: "qtdAnoAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.qtdAnoAtual} />;
          },
        },
        {
          name: "QTD Ano Anterior",
          selector: "qtdAnoAnterior",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrAnoAtual} />;
          },
        },
        {
          name: "Valor Total Ano Atual",
          selector: "vlrAnoAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrAnoAtual} />;
          },
        },
        {
          name: "Valor Total Ano Anterior",
          selector: "vlrTotalAnoAnterior",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrTotalAnoAnterior} />;
          },
        },
        {
          name: "Custo Total Ano Atual",
          selector: "custoTotalAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.custoTotalAtual} />;
          },
        },
        {
          name: "Custo Total Ano Anterior",
          selector: "custoTotalAnterior",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.custoTotalAnterior} />;
          },
        },
        {
          name: "Lucro Bruto Ano Atual",
          selector: "lucroBrutoAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.lucroBrutoAtual} />;
          },
        },
        {
          name: "Lucro Bruto Ano Anterior",
          selector: "lucroBrutoAnterior",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.lucroBrutoAnterior} />;
          },
        },
        {
          name: "Lucro (%)",
          selector: "lucro",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTablePorcentagem rowInfo={row.lucro} />;
          },
        },
        {
          name: "Lucro Ano Anterior (%)",
          selector: "lucroAnterior",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTablePorcentagem rowInfo={row.lucroAnterior} />;
          },
        },
        {
          name: "Representatividade Atual (%)",
          selector: "representitivadadeAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return (
              <CellTablePorcentagem rowInfo={row.representitivadadeAtual} />
            );
          },
        },
        {
          name: "Variação",
          selector: "variacao",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTablePorcentagem rowInfo={row.variacao} />;
          },
        },
      ];
    }
  } else if (tipo == "mes") {
    if (anosListados.length === 1) {
      columns = [
        {
          name: "Mês",
          selector: "mes",
          sortable: true,
          allowOverflow: true,
          format: (row, index) => {
            return row.mes != "Total" ? converterMes(row.mes) : row.mes;
          },
        },
        {
          name: "QTD Ano Atual",
          selector: "qtdAnoAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTableQuantidade rowInfo={row.qtdAnoAtual} />;
          },
        },
        {
          name: "Valor Total Ano Atual",
          selector: "vlrAnoAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrAnoAtual} />;
          },
        },
        {
          name: "Valor Total Ano Atual",
          selector: "vlrAnoAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrAnoAtual} />;
          },
        },
        {
          name: "Custo Total Ano Atual",
          selector: "custoTotalAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.custoTotalAtual} />;
          },
        },
        {
          name: "Lucro Bruto Ano Atual",
          selector: "lucroBrutoAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.lucroBrutoAtual} />;
          },
        },
        {
          name: "Lucro (%)",
          selector: "lucro",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTablePorcentagem rowInfo={row.lucro} />;
          },
        },
      ];
    } else {
      columns = [
        {
          name: "Mês",
          selector: "mes",
          sortable: true,
          allowOverflow: true,
          format: (row, index) => {
            return row.mes != "Total" ? converterMes(row.mes) : row.mes;
          },
        },
        {
          name: "QTD Ano Atual",
          selector: "qtdAnoAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTableQuantidade rowInfo={row.qtdAnoAtual} />;
          },
        },
        {
          name: "QTD Ano Anterior",
          selector: "qtdAnoAnterior",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.qtdAnoAnterior} />;
          },
        },
        {
          name: "Valor Total Ano Atual",
          selector: "vlrAnoAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrAnoAtual} />;
          },
        },
        {
          name: "Valor Total Ano Anterior",
          selector: "vlrTotalAnoAnterior",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.vlrTotalAnoAnterior} />;
          },
        },
        {
          name: "Custo Total Ano Atual",
          selector: "custoTotalAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.custoTotalAtual} />;
          },
        },
        {
          name: "Custo Total Ano Anterior",
          selector: "custoTotalAnterior",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.custoTotalAnterior} />;
          },
        },
        {
          name: "Lucro Bruto Ano Atual",
          selector: "lucroBrutoAtual",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.lucroBrutoAtual} />;
          },
        },
        {
          name: "Lucro Bruto Ano Anterior",
          selector: "lucroBrutoAnterior",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTable rowInfo={row.lucroBrutoAnterior} />;
          },
        },
        {
          name: "Lucro (%)",
          selector: "lucro",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTablePorcentagem rowInfo={row.lucro} />;
          },
        },
        {
          name: "Lucro Ano Anterior (%)",
          selector: "lucroAnterior",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTablePorcentagem rowInfo={row.lucroAnterior} />;
          },
        },
        {
          name: "Variação",
          selector: "variacao",
          sortable: true,
          allowOverflow: true,
          right: true,
          cell: (row) => {
            return <CellTablePorcentagem rowInfo={row.variacao} />;
          },
        },
      ];
    }
  }
  return columns;
};
export const colunasTabelaGrupo = (
  anosDisponiveis,
  filtrosAnosSelecionados
) => {
  let columns = [],
    anosListados = [];

  if (filtrosAnosSelecionados.length === 1) {
    anosListados = filtrosAnosSelecionados;
  } else {
    anosListados = anosDisponiveis;
  }
  if (anosListados.length === 1) {
    columns = [
      {
        name: "Loja",
        selector: "loja",
        sortable: true,
        allowOverflow: true,
        format: (row, index) => {
          return converterLoja(row.loja);
        },
      },
      {
        name: "QTD Ano Atual",
        selector: "qtdAnoAtual",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTableQuantidade rowInfo={row.qtdAnoAtual} />;
        },
      },
      {
        name: "Valor Total Ano Atual",
        selector: "vlrAnoAtual",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.vlrAnoAtual} />;
        },
      },
      {
        name: "Custo Total Ano Atual",
        selector: "custoTotalAtual",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.custoTotalAtual} />;
        },
      },
      {
        name: "Lucro Bruto Ano Atual",
        selector: "lucroBrutoAtual",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.lucroBrutoAtual} />;
        },
      },
      {
        name: "Lucro (%)",
        selector: "lucro",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTablePorcentagem rowInfo={row.lucro} />;
        },
      },
    ];
  } else {
    columns = [
      {
        name: "Loja",
        selector: "loja",
        sortable: true,
        allowOverflow: true,
        format: (row, index) => {
          return converterLoja(row.loja);
        },
      },
      {
        name: "QTD Ano Atual",
        selector: "qtdAnoAtual",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTableQuantidade rowInfo={row.qtdAnoAtual} />;
        },
      },
      {
        name: "QTD Ano Anterior",
        selector: "qtdAnoAnterior",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTableQuantidade rowInfo={row.vlrAnoAtual} />;
        },
      },
      {
        name: "Valor Total Ano Atual",
        selector: "vlrAnoAtual",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.vlrAnoAtual} />;
        },
      },
      {
        name: "Valor Total Ano Anterior",
        selector: "vlrTotalAnoAnterior",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.vlrTotalAnoAnterior} />;
        },
      },
      {
        name: "Custo Total Ano Atual",
        selector: "custoTotalAtual",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.custoTotalAtual} />;
        },
      },
      {
        name: "Custo Total Ano Anterior",
        selector: "custoTotalAnterior",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.custoTotalAnterior} />;
        },
      },
      {
        name: "Lucro Bruto Ano Atual",
        selector: "lucroBrutoAtual",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.lucroBrutoAtual} />;
        },
      },
      {
        name: "Lucro Bruto Ano Anterior",
        selector: "lucroBrutoAnterior",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.lucroBrutoAnterior} />;
        },
      },
      {
        name: "Lucro (%)",
        selector: "lucro",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTablePorcentagem rowInfo={row.lucro} />;
        },
      },
      {
        name: "Lucro Ano Anterior (%)",
        selector: "lucroAnterior",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTablePorcentagem rowInfo={row.lucroAnterior} />;
        },
      },
      {
        name: "Variação",
        selector: "variacao",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTablePorcentagem rowInfo={row.variacao} />;
        },
      },
    ];
  }

  return columns;
};
export const colunasTabelaGrupoFornecedores = (
  anosDisponiveis,
  filtrosAnosSelecionados
) => {
  let columns = [],
    anosListados = [];
  if (filtrosAnosSelecionados.length === 1) {
    anosListados = filtrosAnosSelecionados;
  } else {
    anosListados = anosDisponiveis;
  }
  if (anosListados.length === 1) {
    columns = [
      {
        name: "Grupo",
        selector: "grupo",
        sortable: true,
        allowOverflow: true,
      },
      {
        name: "Quantidade " + anosListados[0],
        selector: "qtdAnoAtual",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTableQuantidade rowInfo={row.qtdAnoAtual} />;
        },
      },
      {
        name: "Valor Total " + anosListados[0],
        selector: "vlrAnoAtual",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.vlrAnoAtual} />;
        },
      },
      {
        name: "Custo Total " + anosListados[0],
        selector: "custoTotalAtual",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.custoTotalAtual} />;
        },
      },
      {
        name: "Lucro Bruto " + anosListados[0],
        selector: "lucroBrutoAtual",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.lucroBrutoAtual} />;
        },
      },
      {
        name: "Lucro (%)" + anosListados[0],
        selector: "lucro",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTablePorcentagem rowInfo={row.lucro} />;
        },
      },
    ];
  } else {
    columns = [
      {
        name: "Grupo",
        selector: "grupo",
        sortable: true,
        allowOverflow: true,
      },
      {
        name: "QTD Ano Atual",
        selector: "qtdAnoAtual",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTableQuantidade rowInfo={row.qtdAnoAtual} />;
        },
      },
      {
        name: "QTD Ano Anterior",
        selector: "qtdAnoAnterior",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTableQuantidade rowInfo={row.vlrAnoAtual} />;
        },
      },
      {
        name: "Valor Total Ano Atual",
        selector: "vlrAnoAtual",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.vlrAnoAtual} />;
        },
      },
      {
        name: "Valor Total Ano Anterior",
        selector: "vlrTotalAnoAnterior",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.vlrTotalAnoAnterior} />;
        },
      },
      {
        name: "Custo Total Ano Atual",
        selector: "custoTotalAtual",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.custoTotalAtual} />;
        },
      },
      {
        name: "Custo Total Ano Anterior",
        selector: "custoTotalAnterior",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.custoTotalAnterior} />;
        },
      },
      {
        name: "Lucro Bruto Ano Atual",
        selector: "lucroBrutoAtual",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.lucroBrutoAtual} />;
        },
      },
      {
        name: "Lucro Bruto Ano Anterior",
        selector: "lucroBrutoAnterior",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTable rowInfo={row.lucroBrutoAnterior} />;
        },
      },
      {
        name: "Lucro (%)",
        selector: "lucro",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTablePorcentagem rowInfo={row.lucro} />;
        },
      },
      {
        name: "Lucro Ano Anterior (%)",
        selector: "lucroAnterior",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTablePorcentagem rowInfo={row.lucroAnterior} />;
        },
      },
      {
        name: "Variação",
        selector: "variacao",
        sortable: true,
        allowOverflow: true,
        right: true,
        cell: (row) => {
          return <CellTablePorcentagem rowInfo={row.variacao} />;
        },
      },
    ];
  }
  return columns;
};
export const colunasMetasGerais = () => {
  let columns = [
    {
      name: "Loja",
      selector: "loja",
      sortable: true,
      // format: (row, index) => { return converterLoja(row.loja) },
      style: { fontSize: 15 },
      grow: 2,

      width: "85px",
      cell: (row) => {
        return (
          <CellTableLoja rowInfo={converterLoja(row.loja)} rowCor={row.cor} />
        );
      },
      conditionalCellStyles: [
        {
          when: (row) => true,
          style: (row) => ({
            backgroundColor: row.cor,
            "&:hover": {
              cursor: "pointer",
            },
          }),
        },
      ],
    },
    {
      name: "%",
      selector: "porcentagem",
      sortable: true,
      right: true,
      format: (row, index) => {
        return formatarNumero(row.porcentagem, false) + "%";
      },
      style: { fontSize: 15 },
      compact: true,
      grow: 1,
    },
    {
      name: "Meta",
      selector: "meta",
      sortable: true,
      right: true,
      format: (row, index) => {
        return formatarNumero(row.meta);
      },
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div style={{ whiteSpace: "nowrap" }}>
              {formatarNumero(row.meta)}
            </div>
          </div>
        );
      },
      style: { fontSize: 15 },
      grow: 2,
      compact: true,
    },
    {
      name: "Realizada",
      selector: "realizada",
      sortable: true,
      right: true,
      // format: (row, index) => { return formatarNumero(row.realizada) },
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div style={{ whiteSpace: "nowrap" }}>
              {formatarNumero(row.realizada)}
            </div>
          </div>
        );
      },
      style: { fontSize: 15 },
      grow: 2,
      compact: true,
    },
    {
      name: "D/ Úteis",
      selector: "diasUteis",
      sortable: true,
      right: true,
      style: { fontSize: 15 },
      compact: true,
      grow: 1,
      width: "90px",
    },
    {
      name: "D/ Falta",
      selector: "diasFalta",
      sortable: true,
      right: true,
      style: { fontSize: 15 },
      compact: true,
      grow: 1,
      width: "90px",
    },
    {
      name: "Défict Mensal",
      selector: "dMensal",
      sortable: true,
      right: true,
      format: (row, index) => {
        return formatarNumero(row.dMensal);
      },
      style: { fontSize: 15 },
      compact: true,
      grow: 2,
    },
    {
      name: "Défict Diário",
      selector: "dDiario",
      sortable: true,
      right: true,
      format: (row, index) => {
        return formatarNumero(row.dDiario);
      },
      style: { fontSize: 15 },
      compact: true,
      grow: 2,
    },
    {
      name: "Represent.",
      selector: "representatividade",
      sortable: true,
      right: true,
      format: (row, index) => {
        return formatarNumero(row.representatividade, false) + "%";
      },
      style: { fontSize: 15 },

      grow: 2,
    },
    {
      name: "Projeção",
      selector: "projecao",
      sortable: true,
      cell: (row) => {
        return (
          <CellTableLoja
            rowInfo={formatarNumero(row.projecao)}
            rowCor={row.cor}
          />
        );
      },
      right: true,
      style: { fontSize: 15 },
      grow: 2,
      conditionalCellStyles: [
        {
          when: (row) => true,
          style: (row) => ({
            backgroundColor: row.cor,
            "&:hover": {
              cursor: "pointer",
            },
          }),
        },
      ],
    },
    {
      name: "Txa. Conversão",
      selector: "conversao",
      sortable: true,
      right: true,
      format: (row) => {
        return formatarNumero(row.conversao) + "%";
      },
      style: { fontSize: 15 },
      compact: true,
      grow: 1,
    },
    {
      name: "cor",
      selector: "cor",
      sortable: false,
      omit: true,
    },
  ];
  return columns;
};

export const colunasMetasGerais2 = () => {
  let columns = [
    {
      text: "Rank",
      dataField: "rank",
      align: () => {
        return "left";
      },
      formatter: (row) => {
        if (row === "") {
          return row;
        }
        return row + "º";
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
      },
    },
    {
      text: "Loja",
      dataField: "loja",
      formatter: (row) => {
        return <CellTableLoja rowInfo={converterLoja(row)} rowCor={row} />;
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
        return {
          backgroundColor: row?.cor,
        };
      },
    },
    {
      text: "%",
      dataField: "porcentagem",
      align: () => {
        return "right";
      },
      formatter: (row) => {
        if (row === "") {
          return row;
        }
        return formatarNumero(row, false) + "%";
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
      },
    },
    {
      text: "Realizada",
      dataField: "realizada",
      align: () => {
        return "right";
      },
      formatter: (row, obj) => {
        if (obj.coluna3 === true) {
          return formatarNumero(row, false) + "%";
        }
        return formatarNumero(row);
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
        return {
          backgroundColor: row?.cor,
        };
      },
    },
    {
      text: "Meta",
      dataField: "meta",
      align: () => {
        return "right";
      },
      formatter: (row, obj) => {
        if (obj.coluna3 === true) {
          if (row === "") {
            return row;
          }
          return formatarNumero(row, false) + "%";
        }
        return formatarNumero(row);
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
      },
    },
    {
      text: "D/ Úteis",
      dataField: "diasUteis",
      align: () => {
        return "right";
      },
      formatter: (row, obj) => {
        if (obj.coluna3 === true) {
          if (row === "") {
            return row;
          }
          return formatarNumero(row, false) + "%";
        }
        return row;
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
      },
    },
    {
      text: "D/ Falta",
      dataField: "diasFalta",
      align: () => {
        return "right";
      },
      formatter: (row, obj) => {
        if (obj.coluna3 === true) {
          if (row === "") {
            return row;
          }
          return formatarNumero(row, false) + "%";
        }
        return row;
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
      },
    },
    {
      text: "Défict Mensal",
      dataField: "dMensal",
      align: () => {
        return "right";
      },
      formatter: (row, obj) => {
        if (obj.coluna3 === true) {
          if (row === "") {
            return row;
          }
          return formatarNumero(row, false) + "%";
        }
        return formatarNumero(row);
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
      },
    },
    {
      text: "Défict Diário",
      dataField: "dDiario",
      align: () => {
        return "right";
      },
      formatter: (row, obj) => {
        if (obj.coluna3 === true) {
          if (row === "") {
            return row;
          }
          return formatarNumero(row, false) + "%";
        }
        return formatarNumero(row);
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
      },
    },
    {
      text: "Represent.",
      dataField: "representatividade",
      align: () => {
        return "right";
      },
      formatter: (row, obj) => {
        if (obj.coluna3 === true) {
          if (row === "") {
            return row;
          }
          return formatarNumero(row, false) + "%";
        }
        return formatarNumero(row, false) + "%";
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
      },
    },
    {
      text: "Projeção",
      dataField: "projecao",
      align: () => {
        return "right";
      },
      formatter: (row, obj) => {
        if (obj.coluna3 === true) {
          if (row === "") {
            return row;
          }
          return formatarNumero(row, false) + "%";
        }
        return formatarNumero(row);
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
        return {
          backgroundColor: row?.cor,
        };
      },
    },
    {
      text: "Txa. Conversão",
      dataField: "conversao",
      align: () => {
        return "right";
      },
      formatter: (row) => {
        if (!row || row === "") {
          return "";
        }
        return formatarNumero(row) + "%";
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
      },
    },
    {
      text: "Desconto",
      dataField: "descontoAplicado",
      align: () => {
        return "right";
      },

      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
        return {
          backgroundColor: row?.cor,
        };
      },
    },
    {
      text: "Lucro Bruto",
      dataField: "lucroBruto",
      align: () => {
        return "right";
      },
      // formatter: (row) => {
      //   console.log(row);
      //   if (!row || row === "") {
      //     return "";
      //   }
      //   return formatarNumero(row) + "%";
      // },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
      },
    },

    // {
    //     text: 'cor',
    //     dataField: 'cor',
    //     align: () => { return 'right' },

    //     omit: true,
    // },
  ];
  return columns;
};
export const colunasApresentacao = () => {
  let columns = [
    {
      text: "Rank",
      dataField: "rank",
      align: () => {
        return "left";
      },
      formatter: (row) => {
        if (row === "") {
          return row;
        }
        return row + "º";
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
      },
    },
    {
      text: "Loja",
      dataField: "loja",
      formatter: (row) => {
        return <CellTableLoja rowInfo={converterLoja(row)} rowCor={row} />;
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
        return {
          backgroundColor: row?.cor,
        };
      },
    },
    {
      text: "%",
      dataField: "porcentagem",
      align: () => {
        return "right";
      },
      formatter: (row) => {
        if (row === "") {
          return row;
        }
        return formatarNumero(row, false) + "%";
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
      },
    },
    // {
    //   text: "Realizada",
    //   dataField: "realizada",
    //   align: () => {
    //     return "right";
    //   },
    //   formatter: (row, obj) => {
    //     if (obj.coluna3 === true) {
    //       return formatarNumero(row, false) + "%";
    //     }
    //     return formatarNumero(row);
    //   },
    //   style: (cell, row) => {
    //     if (row.coluna2 === true) {
    //       return {
    //         backgroundColor: "#333",
    //         color: "#FFF",
    //       };
    //     }
    //     return {
    //       backgroundColor: row?.cor,
    //     };
    //   },
    // },
    // {
    //   text: "Meta",
    //   dataField: "meta",
    //   align: () => {
    //     return "right";
    //   },
    //   formatter: (row, obj) => {
    //     if (obj.coluna3 === true) {
    //       if (row === "") {
    //         return row;
    //       }
    //       return formatarNumero(row, false) + "%";
    //     }
    //     return formatarNumero(row);
    //   },
    //   style: (cell, row) => {
    //     if (row.coluna2 === true) {
    //       return {
    //         backgroundColor: "#333",
    //         color: "#FFF",
    //       };
    //     }
    //   },
    // },
    {
      text: "D/ Úteis",
      dataField: "diasUteis",
      align: () => {
        return "right";
      },
      formatter: (row, obj) => {
        if (obj.coluna3 === true) {
          if (row === "") {
            return row;
          }
          return formatarNumero(row, false) + "%";
        }
        return row;
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
      },
    },
    {
      text: "D/ Falta",
      dataField: "diasFalta",
      align: () => {
        return "right";
      },
      formatter: (row, obj) => {
        if (obj.coluna3 === true) {
          if (row === "") {
            return row;
          }
          return formatarNumero(row, false) + "%";
        }
        return row;
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
      },
    },
    // {
    //   text: "Défict Mensal",
    //   dataField: "dMensal",
    //   align: () => {
    //     return "right";
    //   },
    //   formatter: (row, obj) => {
    //     if (obj.coluna3 === true) {
    //       if (row === "") {
    //         return row;
    //       }
    //       return formatarNumero(row, false) + "%";
    //     }
    //     return formatarNumero(row);
    //   },
    //   style: (cell, row) => {
    //     if (row.coluna2 === true) {
    //       return {
    //         backgroundColor: "#333",
    //         color: "#FFF",
    //       };
    //     }
    //   },
    // },
    // {
    //   text: "Défict Diário",
    //   dataField: "dDiario",
    //   align: () => {
    //     return "right";
    //   },
    //   formatter: (row, obj) => {
    //     if (obj.coluna3 === true) {
    //       if (row === "") {
    //         return row;
    //       }
    //       return formatarNumero(row, false) + "%";
    //     }
    //     return formatarNumero(row);
    //   },
    //   style: (cell, row) => {
    //     if (row.coluna2 === true) {
    //       return {
    //         backgroundColor: "#333",
    //         color: "#FFF",
    //       };
    //     }
    //   },
    // },
    {
      text: "Represent.",
      dataField: "representatividade",
      align: () => {
        return "right";
      },
      formatter: (row, obj) => {
        if (obj.coluna3 === true) {
          if (row === "") {
            return row;
          }
          return formatarNumero(row, false) + "%";
        }
        return formatarNumero(row, false) + "%";
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
      },
    },
    {
      text: "Projeção",
      dataField: "projecao",
      align: () => {
        return "right";
      },
      formatter: (row, obj) => {
        if (row === "") {
          return row;
        }
        return formatarNumero(row, false) + "%";
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
        return {
          backgroundColor: row?.cor,
        };
      },
    },
    {
      text: "Txa. Conversão",
      dataField: "conversao",
      align: () => {
        return "right";
      },
      formatter: (row) => {
        if (!row || row === "") {
          return "";
        }
        return formatarNumero(row) + "%";
      },
      style: (cell, row) => {
        if (row.coluna2 === true) {
          return {
            backgroundColor: "#333",
            color: "#FFF",
          };
        }
      },
    },
  ];
  return columns;
};
export const conunasTabelaDois = () => {
  let columns = [
    {
      dataField: "id",
      text: "",
      hidden: true,
    },
    {
      text: "Meses",
      dataField: "meses",
      formatter: (cell) => {
        return converterLoja(cell);
      },
    },
    {
      dataField: "quantidadeClientes",
      text: "Quantidade Clientes",
      align: () => {
        return "right";
      },
    },
    {
      dataField: "quantidadePedidos",
      text: "Quantidade de Pedidos",
      align: () => {
        return "right";
      },
    },
    {
      dataField: "valorTotal",
      text: "Valor Total",
      align: () => {
        return "right";
      },
      formatter: (cell) => <CellTable rowInfo={cell} />,
    },
    {
      dataField: "ticketMedio",
      text: "Ticket Médio ",
      align: () => {
        return "right";
      },
      formatter: (cell) => <CellTable rowInfo={cell} />,
    },
  ];
  return columns;
};
export const colunasAnaliseMetasGerais = () => {
  let columnsAnalise = [
    {
      name: "Loja",
      selector: "loja",
      grow: 2,
    },
    {
      name: "Meta",
      selector: "meta",
      sortable: true,
      right: true,
      grow: 2,
    },
    {
      name: "Realizada",
      selector: "realizada",
      sortable: true,
      right: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div
              style={{
                backgroundColor: row.cor,
                fontWeight: "bold",
                padding: 5,
                whiteSpace: "nowrap",
                borderRadius: 10,
              }}>
              {formatarNumero(row.realizada, false) + "%"}
            </div>
          </div>
        );
      },
      style: { fontSize: 15 },
      grow: 2,
    },
    {
      name: "%",
      selector: "porcentagem",
      grow: 1,
    },
    {
      name: "Dias - Úteis",
      selector: "diasUteis",
      grow: 1,
    },
    {
      name: "Dias - Falta",
      selector: "diasFalta",
      sortable: true,
      right: true,
      style: { fontSize: 15 },
      grow: 1,
      compact: true,
    },
    {
      name: "Défict Mensal",
      selector: "dMensal",
      grow: 2,
    },
    {
      name: "Défict Diário",
      selector: "dDiario",
      grow: 2,
    },
    {
      name: "Projeção",
      selector: "projecao",
      sortable: true,
      right: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div
              style={{
                backgroundColor: row.corProjetado,
                fontWeight: "bold",
                padding: 5,
                whiteSpace: "nowrap",
                borderRadius: 10,
              }}>
              {formatarNumero(row.projecao, false) + "%"}
            </div>
          </div>
        );
      },
      style: { fontSize: 15 },
      grow: 2,
    },
    {
      name: "Representatividade",
      selector: "representatividade",
      grow: 2,
    },
    {
      name: "cor",
      selector: "cor",
      sortable: false,
      omit: true,
    },
    {
      name: "corProjetado",
      selector: "corProjetado",
      sortable: false,
      omit: true,
    },
  ];
  return columnsAnalise;
};

export const customStylesTable = {
  headRow: {
    style: {
      fontSize: "22px",
      minWidth: 0,
    },
  },
};
export const colunasInputMesAnos2 = (
  tipo,
  anosDisponiveis,
  filtrosAnosSelecionados
) => {
  //quantidade de pedidos
  //vendas global
  let anosListados = [],
    columns = [];
  if (filtrosAnosSelecionados.length === 1) {
    anosListados = [];
    anosListados = filtrosAnosSelecionados;
  } else {
    anosListados = [];
    anosListados = anosDisponiveis;
  }
  if (tipo === "input" || tipo === "loja" || tipo === "ano") {
    columns = [
      {
        dataField: "id",
        text: "",
        hidden: true,
      },
      {
        dataField: "loja",
        text: "Loja",
        align: () => {
          return "left";
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
        formatter: (cell) => <CellTableTipo rowInfo={cell} tipo={tipo} />,
      },
      {
        text: "",
        dataField: "",
        align: () => {
          return "left";
        },
        formatter: (cell) => {
          return <CellAsideYaer />;
        },
        csvFormatter: (cell, row, rowIndex) => "AT \nAN",
      },
      {
        dataField: "qtdAnoAtual",
        text: "Quantidade",
        align: () => {
          return "right";
        },
        style: () => {
          return { verticalAlign: "middle" };
        },
        headerAlign: () => "right",
        formatter: (cell, index) => {
          return (
            <CellTableYear
              anoAn={index.qtdAnoAnterior}
              anoAt={index.qtdAnoAtual}
              anoPa={index.qtdPeriodoAnterior}
            />
          );
        },
        csvFormatter: (cell, row, rowIndex) =>
          `${row.qtdAnoAnterior} \n${row.qtdAnoAtual}`,
      },
      {
        dataField: "qtdAnoAnterior",
        text: "QTD - AN",
        hidden: true,
        align: () => {
          return "right";
        },
        formatter: (cell) => <CellTableQuantidade rowInfo={cell} />,
      },
      {
        dataField: "vlrAnoAtual",
        text: "Valor Total",
        headerAlign: () => "right",
        style: () => {
          return { verticalAlign: "middle" };
        },
        align: () => {
          return "right";
        },
        formatter: (cell, index) => {
          return (
            <CellTableYear
              anoAn={<CellTable rowInfo={index.vlrTotalAnoAnterior} />}
              anoAt={<CellTable rowInfo={index.vlrAnoAtual} />}
              anoPa={<CellTable rowInfo={index.vlrPeriodoAnterior} />}
            />
          );
        },
        csvFormatter: (cell, row, rowIndex) =>
          `${converterNumero(row.vlrTotalAnoAnterior)} \n${converterNumero(
            row.vlrAnoAtual
          )}`,
      },
      {
        dataField: "vlrTotalAnoAnterior",
        hidden: true,
        text: "VT - AN",
        align: () => {
          return "right";
        },
        formatter: (cell) => <CellTable rowInfo={cell} />,
      },
      {
        dataField: "vlrMedioAnoAtual",
        text: "Ticket Médio",
        style: () => {
          return { verticalAlign: "middle" };
        },
        headerAlign: () => "right",
        align: () => {
          return "right";
        },
        formatter: (cell, index) => {
          return (
            <CellTableYear
              anoAn={<CellTable rowInfo={index.vlrMedioAnoAnterior} />}
              anoAt={<CellTable rowInfo={index.vlrMedioAnoAtual} />}
              anoPa={<CellTable rowInfo={index.vlrMedioPeriodoAnterior} />}
            />
          );
        },
      },

      {
        text: "Custo Total",
        dataField: "custoTotalAtual",
        formatter: (cell, index) => (
          <CellTableYear
            anoAn={<CellTable rowInfo={index.custoTotalAnterior} />}
            anoAt={<CellTable rowInfo={index.custoTotalAtual} />}
            anoPa={<CellTable rowInfo={index.custoPeriodoAnterior} />}
          />
        ),
        align: () => {
          return "right";
        },
      },
      {
        text: "Lucro Bruto",
        dataField: "lucroBrutoAtual",
        formatter: (cell, index) => (
          <CellTableYear
            anoAn={<CellTable rowInfo={index.lucroBrutoAnterior} />}
            anoAt={<CellTable rowInfo={index.lucroBrutoAtual} />}
            anoPa={<CellTable rowInfo={index.lucroPeriodoAnterior} />}
          />
        ),
        align: () => {
          return "right";
        },
      },
      {
        text: "Lucro (%)",
        dataField: "lucro",
        formatter: (cell, index) => (
          <CellTableYear
            anoAt={<CellTablePorcentagem rowInfo={index.lucro} />}
            anoAn={<CellTablePorcentagem rowInfo={index.lucroAnterior} />}
            anoPa={
              <CellTablePorcentagem
                rowInfo={index.porcentagemLucroPeriodoAnterior}
              />
            }
          />
        ),
        align: () => {
          return "right";
        },
      },

      {
        dataField: "vlrMedioAnoAnterior",
        text: "VM - AN",
        hidden: true,
        align: () => {
          return "right";
        },
        formatter: (cell) => <CellTable rowInfo={cell} />,
      },
      {
        dataField: "representatividadeAtual",
        text: "Representatividade",
        style: () => {
          return { verticalAlign: "middle" };
        },
        align: () => {
          return "right";
        },
        headerAlign: () => "right",
        formatter: (cell, index) => {
          return (
            <CellTableYear
              anoAt={
                <CellTablePorcentagem rowInfo={index.representatividadeAtual} />
              }
              anoAn={
                <CellTablePorcentagem
                  rowInfo={index.representatividadeAnterior}
                />
              }
              anoPa={
                <CellTablePorcentagem
                  rowInfo={index.representatividadePeriodoAnterior}
                />
              }
            />
          );
        },
      },
      {
        dataField: "representatividadeAnterior",
        text: "Repre. AN",
        align: () => {
          return "right";
        },
        hidden: true,
        formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
      },
      {
        text: "Desemp. (%)",
        dataField: "desempenho",
        style: () => {
          return { verticalAlign: "middle" };
        },
        headerAlign: () => "right",
        align: () => {
          return "right";
        },
        formatter: (cell, index) => (
          <CellTablePorcentagem
            rowInfo={cell}
            desemp={true}
            desempPeriodoAnterior={index.desempPeriodoAnterior}
            desempAnterior={true}
          />
        ),
      },
    ];
  } else if (tipo === "mes") {
    let meses = `Meses de ${anosListados[0]}`;
    let quantidade = `Quantidade de ${anosListados[0]}`;
    let valorTotal = `Valor Total de ${anosListados[0]}`;
    let descMedio = `Desconto Médio de ${anosListados[0]}`;
    let repres = `Representatividade. de ${anosListados[0]}`;
    if (anosListados.length === 1) {
      columns = [
        {
          dataField: "mes",
          text: meses,
          align: () => {
            return "left";
          },
          style: () => {
            return { verticalAlign: "middle" };
          },
          formatter: (cell) => {
            if (cell === "Total") {
              return cell;
            } else {
              return converterMes(cell);
            }
          },
        },
        {
          text: quantidade,
          dataField: "qtdAnoAtual",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTableQuantidade rowInfo={cell} />,
        },
        {
          text: valorTotal,
          align: () => {
            return "right";
          },
          dataField: "vlrAnoAtual",
          formatter: (cell) => <CellTable rowInfo={cell} />,
        },
        {
          text: descMedio,
          align: () => {
            return "right";
          },
          dataField: "vlrMedioAnoAtual",
          formatter: (cell) => <CellTable rowInfo={cell} />,
        },

        {
          text: "Custo Total",
          dataField: "custoTotalAtual",
          formatter: (cell) => <CellTable rowInfo={cell} />,
          align: () => {
            return "right";
          },
        },
        {
          text: "Lucro Bruto",
          dataField: "lucroBrutoAtual",
          formatter: (cell) => <CellTable rowInfo={cell} />,
          align: () => {
            return "right";
          },
        },
        {
          text: "Lucro (%)",
          dataField: "lucro",
          formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
          align: () => {
            return "right";
          },
        },
        {
          text: repres,
          align: () => {
            return "right";
          },
          dataField: "representatividadeAtual",
          formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
        },
      ];
    } else {
      columns = [
        {
          dataField: "mes",
          text: "Meses",
          formatter: (cell) => {
            if (cell === "Total") {
              return cell;
            } else {
              return converterMes(cell);
            }
          },
        },
        {
          text: "",
          dataField: "",
          align: () => {
            return "left";
          },
          formatter: (cell) => {
            return <CellAsideYaer mes />;
          },
          csvFormatter: (cell, row, rowIndex) => "AT \nAN",
        },
        {
          dataField: "qtdAnoAtual",
          text: "Quantidade",
          align: () => {
            return "right";
          },
          style: () => {
            return { verticalAlign: "middle" };
          },
          headerAlign: () => "right",
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={index.qtdAnoAnterior}
                anoAt={index.qtdAnoAtual}
                anoPa={index.qtdPeriodoAnterior}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${row.qtdAnoAnterior} \n${row.qtdAnoAtual}`,
        },
        {
          dataField: "qtdAnoAnterior",
          text: "QTD - AN",
          hidden: true,
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTableQuantidade rowInfo={cell} />,
        },
        {
          dataField: "vlrAnoAtual",
          text: "Valor Total",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.vlrTotalAnoAnterior} />}
                anoAt={
                  <CellTable
                    rowInfo={index.vlrAnoAtual}
                    anoPa={<CellTable rowInfo={index.vlrPeriodoAnterior} />}
                  />
                }
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.vlrTotalAnoAnterior)} \n${converterNumero(
              row.vlrAnoAtual
            )}`,
        },
        {
          dataField: "vlrTotalAnoAnterior",
          hidden: true,
          text: "VT - AN",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTable rowInfo={cell} />,
        },
        {
          dataField: "vlrMedioAnoAtual",
          text: "Ticket Médio",
          style: () => {
            return { verticalAlign: "middle" };
          },
          headerAlign: () => "right",
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.vlrMedioAnoAnterior} />}
                anoAt={<CellTable rowInfo={index.vlrMedioAnoAtual} />}
                anoPa={<CellTable rowInfo={index.vlrMedioPeriodoAnterior} />}
              />
            );
          },
        },

        {
          text: "Custo Total",
          dataField: "custoTotalAtual",
          formatter: (cell, index) => (
            <CellTableYear
              anoAn={<CellTable rowInfo={index.custoTotalAnterior} />}
              anoAt={<CellTable rowInfo={index.custoTotalAtual} />}
              anoPa={<CellTable rowInfo={index.custoPeriodoAnterior} />}
            />
          ),
          align: () => {
            return "right";
          },
        },
        {
          text: "Lucro Bruto",
          dataField: "lucroBrutoAtual",
          formatter: (cell, index) => (
            <CellTableYear
              anoAn={<CellTable rowInfo={index.lucroBrutoAnterior} />}
              anoAt={<CellTable rowInfo={index.lucroBrutoAtual} />}
              anoPa={<CellTable rowInfo={index.lucroPeriodoAnterior} />}
            />
          ),
          align: () => {
            return "right";
          },
        },
        {
          text: "Lucro (%)",
          dataField: "lucro",
          formatter: (cell, index) => (
            <CellTableYear
              anoAt={<CellTablePorcentagem rowInfo={index.lucro} />}
              anoAn={<CellTablePorcentagem rowInfo={index.lucroAnterior} />}
              anoPa={
                <CellTablePorcentagem
                  rowInfo={index.porcentagemLucroPeriodoAnterior}
                />
              }
            />
          ),
          align: () => {
            return "right";
          },
        },
        {
          dataField: "vlrMedioAnoAnterior",
          text: "VM - AN",
          hidden: true,
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTable rowInfo={cell} />,
        },
        {
          dataField: "representatividadeAtual",
          text: "Representatividade",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          headerAlign: () => "right",
          formatter: (cell, index) => {
            return (
              <CellTableYear
                mes={true}
                anoAt={
                  <CellTablePorcentagem
                    rowInfo={index.representatividadeAtual}
                  />
                }
                anoAn={
                  <CellTablePorcentagem
                    rowInfo={index.representatividadeAnterior}
                  />
                }
                anoPa={
                  <CellTablePorcentagem
                    rowInfo={index.representatividadePeriodoAnterior}
                  />
                }
              />
            );
          },
        },
        {
          dataField: "representatividadeAnterior",
          text: "Repre. AN",
          align: () => {
            return "right";
          },
          hidden: true,
          formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
        },
        {
          text: "Desemp. (%)",
          dataField: "desempenho",
          style: () => {
            return { verticalAlign: "middle" };
          },
          headerAlign: () => "right",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
        },
      ];
    }
  }

  return columns;
};
const verificaDia = (dia, hora) => {
  if (hora) {
    return `${!isNaN(dia) ? dia + " H" : dia}`;
  }
  if (dia === "Total") {
    return dia;
  }
  if (dia && !hora) {
    return converterDia(dia);
  }
  return dia;
};
export const colunasDias2 = (
  tipo,
  anosDisponiveis,
  filtrosAnosSelecionados,
  hora = false
) => {
  let anosListados = [],
    columns = [];
  if (filtrosAnosSelecionados.length === 1) {
    anosListados = [];
    anosListados = filtrosAnosSelecionados;
  } else {
    anosListados = [];
    anosListados = anosDisponiveis;
  }

  if (tipo === "input" || tipo === "loja" || tipo === "ano") {
    let txt = hora ? "Horas" : "Dia";
    if (anosListados.length === 1) {
      columns = [
        {
          dataField: "id",
          text: "",
          hidden: true,
        },
        {
          text: txt,
          dataField: "loja",
          formatter: (cell) => {
            return verificaDia(cell, hora);
          },
        },
        {
          dataField: "qtdAnoAtual",
          text: "Quantidade",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTableQuantidade rowInfo={cell} />,
        },
        {
          dataField: "vlrAnoAtual",
          text: "Valor Total",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTable rowInfo={cell} />,
        },
        {
          dataField: "vlrMedioAnoAtual",
          text: "Ticket Médio ",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTable rowInfo={cell} />,
        },
        {
          dataField: "representatividadeAtual",
          text: "Represen.",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
        },
      ];
    } else {
      columns = [
        {
          text: txt,
          dataField: "loja",
          formatter: (cell) => {
            return verificaDia(cell, hora);
          },
          align: () => {
            return "left";
          },
          style: () => {
            return { verticalAlign: "middle" };
          },
          csvFormatter: (cell, row, rowIndex) => verificaDia(cell, hora),
        },
        {
          text: "",
          dataField: "",
          align: () => {
            return "left";
          },
          formatter: (cell) => {
            return <CellAsideYaer />;
          },
          csvFormatter: (cell, row, rowIndex) => "AT \nAN",
        },
        {
          dataField: "qtdAnoAtual",
          text: "Quantidade",
          align: () => {
            return "right";
          },
          style: () => {
            return { verticalAlign: "middle" };
          },
          headerAlign: () => "right",
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={index.qtdAnoAnterior}
                anoAt={index.qtdAnoAtual}
                anoPa={index.qtdPeriodoAnterior}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${row.qtdAnoAnterior} \n${row.qtdAnoAtual}`,
        },
        {
          dataField: "vlrAnoAtual",
          text: "Valor Total",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.vlrTotalAnoAnterior} />}
                anoAt={<CellTable rowInfo={index.vlrAnoAtual} />}
                anoPa={<CellTable rowInfo={index.vlrPeriodoAnterior} />}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.vlrTotalAnoAnterior)} \n${converterNumero(
              row.vlrAnoAtual
            )}`,
        },
        {
          dataField: "vlrMedioAnoAtual",
          text: "Ticket Médio",
          style: () => {
            return { verticalAlign: "middle" };
          },
          headerAlign: () => "right",
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.vlrMedioAnoAnterior} />}
                anoAt={<CellTable rowInfo={index.vlrMedioAnoAtual} />}
                anoPa={<CellTable rowInfo={index.vlrMedioPeriodoAnterior} />}
              />
            );
          },
        },
        {
          dataField: "representatividadeAtual",
          text: "Representatividade",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          headerAlign: () => "right",
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAt={
                  <CellTablePorcentagem
                    rowInfo={index.representatividadeAtual}
                  />
                }
                anoAn={
                  <CellTablePorcentagem
                    rowInfo={index.representatividadeAnterior}
                  />
                }
                anoPa={
                  <CellTablePorcentagem
                    rowInfo={index.representatividadePeriodoAnterior}
                  />
                }
              />
            );
          },
        },
        {
          dataField: "representatividadeAnterior",
          text: "Repre. AN",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
          hidden: true,
        },
        {
          text: "Desemp. (%)",
          dataField: "desempenho",
          style: () => {
            return { verticalAlign: "middle" };
          },
          headerAlign: () => "right",
          align: () => {
            return "right";
          },
          formatter: (cell, index) => (
            <CellTablePorcentagem
              rowInfo={cell}
              desemp={true}
              desempPeriodoAnterior={index.desempPeriodoAnterior}
              desempAnterior={true}
            />
          ),
        },
      ];
    }
  } else if (tipo == "dia") {
    if (anosListados.length === 1) {
      columns = [
        {
          name: "Meses de " + anosListados[0],
          selector: "mes",
          sortable: true,
          format: (row, index) => {
            return converterMes(row.mes);
          },
        },
        {
          name: "Quantidade " + anosListados[0],
          selector: "qtdAnoAtual",
          sortable: true,
        },
        {
          name: "Valor Total " + anosListados[0],
          selector: "vlrAnoAtual",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrAnoAtual);
          },
        },
        {
          name: "Desconto Médio " + anosListados[0],
          selector: "vlrMedioAnoAtual",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrMedioAnoAtual);
          },
        },
        {
          name: "Representatividade " + anosListados[0] + " (%)",
          selector: "representatividadeAtual",
          sortable: true,
          format: (row, index) => {
            return row.representatividadeAtual.toFixed(2) + "%";
          },
        },
      ];
    } else {
      columns = [
        {
          name: "Mes",
          selector: "mes",
          sortable: true,
          format: (row, index) => {
            return converterMes(row.mes);
          },
        },
        {
          name: "QTD Ano Atual",
          selector: "qtdAnoAtual",
          sortable: true,
        },
        {
          name: "Valor Total Ano Atual",
          selector: "vlrAnoAtual",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrAnoAtual);
          },
        },
        {
          name: "Desconto Médio Ano Atual",
          selector: "vlrMedioAnoAtual",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrMedioAnoAtual);
          },
        },
        {
          name: "QTD Ano Anterior",
          selector: "qtdAnoAnterior",
          sortable: true,
        },
        {
          name: "Valor Total Ano Anterior",
          selector: "vlrTotalAnoAnterior",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrTotalAnoAnterior);
          },
        },
        {
          name: "Desconto Médio Ano Anterior",
          selector: "vlrMedioAnoAnterior",
          sortable: true,
          format: (row, index) => {
            return converterNumero(row.vlrMedioAnoAnterior);
          },
        },
        {
          name: "Desempenho (%)",
          selector: "desempenho",
          sortable: true,
          cell: (row) =>
            row.abr < 0 ? (
              <div data-tag="allowRowEvents">
                <div style={{ color: "#ff0000", fontWeight: "bold" }}>
                  {row.desempenho.toFixed(2) + "%"}
                </div>
              </div>
            ) : (
              <div data-tag="allowRowEvents">
                <div>{row.desempenho.toFixed(2) + "%"}</div>
              </div>
            ),
        },
        {
          name: "Representatividade Ano Atual (%)",
          selector: "representatividadeAtual",
          sortable: true,
          format: (row, index) => {
            return row.representatividadeAtual.toFixed(2) + "%";
          },
        },
        {
          name: "Representatividade Ano Anterior (%)",
          selector: "representatividadeAnterior",
          sortable: true,
          format: (row, index) => {
            return row.representatividadeAnterior.toFixed(2) + "%";
          },
        },
      ];
    }
  }

  return columns;
};
export const colunasTabelasGlobaisVendasPorDia2 = (hora) => {
  let mesesPrev = [
    "Dia",
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
    "Total",
  ];
  let columns = [];
  let txt = hora ? "Horas" : "Dia";
  mesesPrev.map((item) => {
    let aux = item.toLowerCase();
    if (aux === "dia") {
      columns.push({
        text: txt,
        dataField: "loja",
        formatter: (cell) => {
          return verificaDia(cell, hora);
        },
      });
    } else if (aux === "total") {
      columns.push({
        align: () => {
          return "right";
        },
        text: item,
        dataField: aux,
        formatter: (cell) => <CellTable rowInfo={cell} />,
      });
    } else {
      columns.push({
        text: item,
        align: () => {
          return "right";
        },
        dataField: aux,
        formatter: (cell) => <CellTable rowInfo={cell} />,
      });
    }
  });

  return columns;
};
export const colunaTabelaGlobalDesempenhoVendasPorDia2 = (hora) => {
  let mesesPrev = [
    "Dia",
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
    "Média",
  ];
  let columns = [];
  let txt = hora ? "Horas" : "Dia";

  mesesPrev.map((item) => {
    let aux = item.toLowerCase();
    if (aux === "dia") {
      columns.push({
        text: txt,
        dataField: "loja",
        formatter: (cell) => {
          return verificaDia(cell, hora);
        },
      });
    } else if (aux === "média") {
      columns.push({
        align: () => {
          return "right";
        },
        text: "Média",
        dataField: "total",
        formatter: (cell) => (
          <CellTablePorcentagem rowInfo={cell} desemp={true} />
        ),
      });
    } else {
      columns.push({
        text: item,
        align: () => {
          return "right";
        },
        dataField: aux,
        formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
      });
    }
  });

  return columns;
};
export const tabelaAnalisePedidoNext = () => {
  let columns = [
    {
      dataField: "id",
      text: "",
      hidden: true,
    },
    {
      text: "Loja",
      dataField: "loja",
      formatter: (cell) => {
        return converterLoja(cell);
      },
    },
    {
      dataField: "qtdClientes",
      text: "Quantidade Clientes",
      align: () => {
        return "right";
      },
    },
    {
      dataField: "vlr",
      text: "Valor Total",
      align: () => {
        return "right";
      },
      formatter: (cell) => <CellTable rowInfo={cell} />,
    },
    {
      dataField: "vlrMedio",
      text: "Ticket Médio ",
      align: () => {
        return "right";
      },
      formatter: (cell) => <CellTable rowInfo={cell} />,
    },
    {
      dataField: "representatividade",
      text: "Represen.",
      align: () => {
        return "right";
      },
      formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
    },
  ];
  return columns;
};
export const colunasTabelasGlobaisNext = (desempenho = false) => {
  //vendas global
  let mesesPrev = [
    "Loja",
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
    "Total",
  ];
  let columns = [];
  mesesPrev.map((item) => {
    let aux = item.toLowerCase();
    if (aux === "loja") {
      columns.push({
        text: item,
        dataField: aux,
        formatter: (cell) => {
          return converterLoja(cell);
        },
      });
    } else if (aux === "total") {
      columns.push({
        text: item,
        dataField: aux,
        align: () => {
          return "right";
        },
        formatter: (cell) => {
          return desempenho ? (
            <CellTablePorcentagem rowInfo={cell} desemp={true} />
          ) : (
            <CellTable rowInfo={cell} />
          );
        },
      });
    } else {
      columns.push({
        text: item,
        dataField: aux,
        align: () => {
          return "right";
        },
        formatter: (cell) => {
          return desempenho ? (
            <CellTablePorcentagem rowInfo={cell} desemp={true} />
          ) : (
            <CellTable rowInfo={cell} />
          );
        },
      });
    }
  });
  return columns;
};

export const vendasGrupoTabelaPorGrupo = (
  tipo,
  lojas,
  dataF,
  text,
  filtrosLojasSelecionadas,
  nivelAtivoDoGrafico
) => {
  let columns = [];
  const nameColumn = nivelAtivoDoGrafico === 1 ? "loja" : "grupo";
  console.log("nivelAtivoDoGrafico", nivelAtivoDoGrafico, { nameColumn });
  if (tipo === "input" || tipo === "loja" || tipo === "ano") {
    if (false) {
      columns = [
        {
          dataField: "loja",
          text: "Lojas",
          formatter: (cell) => (lojas ? converterLoja(cell) : cell),
        },
        {
          dataField: "qtdAnoAtual",
          text: "",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTableQuantidade rowInfo={cell} />,
        },
        {
          dataField: "vlrAnoAtual",
          text: "Valor Total",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTable rowInfo={cell} />,
        },
        {
          text: "Ticket Médio",
          dataField: "ticketMedio",
          formatter: (cell) => <CellTable rowInfo={cell} />,
          align: () => {
            return "right";
          },
        },
        {
          text: "Custo Total",
          dataField: "custoTotalAtual",
          formatter: (cell) => <CellTable rowInfo={cell} />,
          align: () => {
            return "right";
          },
        },
        {
          text: "Lucro Bruto ",
          dataField: "lucroBrutoAtual",
          formatter: (cell) => <CellTable rowInfo={cell} />,
          align: () => {
            return "right";
          },
        },
        {
          text: "Lucro (%)",
          dataField: "lucro",
          formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
          align: () => {
            return "right";
          },
        },
        {
          text: "Representatividade (%) ",
          dataField: "representitivadadeAtual",
          formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
          align: () => {
            return "right";
          },
        },
      ];
    } else {
      columns = [
        {
          dataField: nameColumn,
          text: "Grupos",
          style: () => {
            return { verticalAlign: "middle" };
          },
          formatter: (cell) => {
            console.log("cell", cell);
            return lojas ? converterLoja(cell) : cell;
          },
        },
        {
          text: "",
          dataField: "",
          align: () => {
            return "left";
          },
          formatter: (cell) => {
            return <CellAsideYaer />;
          },
          csvFormatter: (cell, row, rowIndex) => "AT \nAN",
        },
        {
          dataField: "qtdAnoAtual",
          text: "Quantidade",
          align: () => {
            return "right";
          },
          style: () => {
            return { verticalAlign: "middle" };
          },
          headerAlign: () => "right",
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={index.qtdAnoAnterior}
                anoAt={index.qtdAnoAtual}
                anoPa={index.qtdPeriodoAnterior}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${row.qtdAnoAnterior} \n${row.qtdAnoAtual}`,
        },
        {
          dataField: "vlrAnoAtual",
          text: "Valor Total",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.vlrTotalAnoAnterior} />}
                anoAt={<CellTable rowInfo={index.vlrAnoAtual} />}
                anoPa={<CellTable rowInfo={index.vlrPeriodoAnterior} />}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.vlrTotalAnoAnterior)} \n${converterNumero(
              row.vlrAnoAtual
            )}`,
        },
        {
          dataField: "ticketMedio",
          text: "Ticket Médio",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.ticketMedioAnoAnterior} />}
                anoAt={<CellTable rowInfo={index.ticketMedioAnoAtual} />}
                anoPa={<CellTable rowInfo={index.ticketMedioPeriodoAnterior} />}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.vlrTotalAnoAnterior)} \n${converterNumero(
              row.vlrAnoAtual
            )}`,
        },
        {
          dataField: "custoTotalAtual",
          text: "Custo Total",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.custoTotalAnterior} />}
                anoAt={<CellTable rowInfo={index.custoTotalAtual} />}
                anoPa={<CellTable rowInfo={index.custoPeriodoAnterior} />}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.custoTotalAnterior)} \n${converterNumero(
              row.custoTotalAtual
            )}`,
        },
        {
          dataField: "lucroBrutoAtual",
          text: "Lucro Bruto",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.lucroBrutoAnterior} />}
                anoAt={<CellTable rowInfo={index.lucroBrutoAtual} />}
                anoPa={<CellTable rowInfo={index.lucroPeriodoAnterior} />}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.lucroBrutoAnterior)} \n${converterNumero(
              row.lucroBrutoAtual
            )}`,
        },
        {
          dataField: "lucro",
          text: "Lucro",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTablePorcentagem rowInfo={index.lucroAnterior} />}
                anoAt={<CellTablePorcentagem rowInfo={index.lucro} />}
                anoPa={
                  <CellTablePorcentagem
                    rowInfo={index.porcentagemLucroPeriodoAnterior}
                  />
                }
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.lucroAnterior)} \n${converterNumero(
              row.lucro
            )}`,
        },
        {
          text: "Repre. ",
          dataField: "representitivadadeAtual",
          align: () => {
            return "right";
          },
          style: () => {
            return { verticalAlign: "middle" };
          },
          formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
        },
        {
          text: "Variação",
          dataField: "variacao",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTablePorcentagem
                rowInfo={cell}
                desemp={true}
                desempPeriodoAnterior={index.desempPeriodoAnterior}
                desempAnterior={true}
              />
            );
          },
        },
      ];
    }
  }
  return columns;
};

export const vendasGrupoTabelaPorFornecedor = (
  tipo,
  anosDisponiveis,
  filtrosAnosSelecionados,
  lojas = false,
  filtrosLojasSelecionadas
) => {
  let columns = [],
    anosListados = [];
  if (filtrosAnosSelecionados.length === 1) {
    anosListados = filtrosAnosSelecionados;
  } else {
    anosListados = anosDisponiveis;
  }
  if (tipo == "input" || tipo == "loja" || tipo == "ano") {
    const dataF = lojas ? "loja" : "grupo";
    const text = lojas ? "Lojas" : "Grupos";
    if (
      (anosListados.length === 1 && filtrosLojasSelecionadas?.length === 1) ||
      (filtrosLojasSelecionadas?.length === 0 && anosListados.length === 1)
    ) {
      columns = [
        {
          dataField: dataF,
          text: text,
          formatter: (cell) => (lojas ? converterLoja(cell) : cell),
        },
        {
          dataField: "qtdAnoAtual",
          text: "Quantidade",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTableQuantidade rowInfo={cell} />,
        },
        {
          dataField: "vlrAnoAtual",
          text: "Valor Total",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTable rowInfo={cell} />,
        },
        {
          dataField: "ticketMedio",
          text: "Ticket Médio",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTable rowInfo={cell} />,
        },
        {
          text: "Custo Total",
          dataField: "custoTotalAtual",
          formatter: (cell) => <CellTable rowInfo={cell} />,
          align: () => {
            return "right";
          },
        },
        {
          text: "Lucro Bruto ",
          dataField: "lucroBrutoAtual",
          formatter: (cell) => <CellTable rowInfo={cell} />,
          align: () => {
            return "right";
          },
        },
        {
          text: "Lucro (%)",
          dataField: "lucro",
          formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
          align: () => {
            return "right";
          },
        },
        {
          text: "Representatividade (%) ",
          dataField: "representitivadadeAtual",
          formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
          align: () => {
            return "right";
          },
        },
      ];
    } else {
      columns = [
        {
          dataField: dataF,
          text: text,
          style: () => {
            return { verticalAlign: "middle" };
          },
          formatter: (cell) => (lojas ? converterLoja(cell) : cell),
        },
        {
          text: "",
          dataField: "",
          align: () => {
            return "left";
          },
          formatter: (cell) => {
            return <CellAsideYaer />;
          },
          csvFormatter: (cell, row, rowIndex) => "AT \nAN",
        },
        {
          dataField: "qtdAnoAtual",
          text: "Quantidade",
          align: () => {
            return "right";
          },
          style: () => {
            return { verticalAlign: "middle" };
          },
          headerAlign: () => "right",
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={index.qtdAnoAnterior}
                anoAt={index.qtdAnoAtual}
                anoPa={index.qtdPeriodoAnterior}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${row.qtdAnoAnterior} \n${row.qtdAnoAtual}`,
        },
        {
          dataField: "vlrAnoAtual",
          text: "Valor Total",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.vlrTotalAnoAnterior} />}
                anoAt={<CellTable rowInfo={index.vlrAnoAtual} />}
                anoPa={<CellTable rowInfo={index.vlrPeriodoAnterior} />}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.vlrTotalAnoAnterior)} \n${converterNumero(
              row.vlrAnoAtual
            )}`,
        },
        {
          dataField: "ticketMedio",
          text: "Ticket Médio",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.ticketMedioAnoAnterior} />}
                anoAt={<CellTable rowInfo={index.ticketMedioAnoAtual} />}
                anoPa={<CellTable rowInfo={index.ticketMedioPeriodoAnterior} />}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.vlrTotalAnoAnterior)} \n${converterNumero(
              row.vlrAnoAtual
            )}`,
        },
        {
          dataField: "custoTotalAtual",
          text: "Custo Total",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.custoTotalAnterior} />}
                anoAt={<CellTable rowInfo={index.custoTotalAtual} />}
                anoPa={<CellTable rowInfo={index.custoPeriodoAnterior} />}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.custoTotalAnterior)} \n${converterNumero(
              row.custoTotalAtual
            )}`,
        },
        {
          dataField: "lucroBrutoAtual",
          text: "Lucro Bruto",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.lucroBrutoAnterior} />}
                anoAt={<CellTable rowInfo={index.lucroBrutoAtual} />}
                anoPa={<CellTable rowInfo={index.lucroPeriodoAnterior} />}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.lucroBrutoAnterior)} \n${converterNumero(
              row.lucroBrutoAtual
            )}`,
        },
        {
          dataField: "lucro",
          text: "Lucro",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTablePorcentagem rowInfo={index.lucroAnterior} />}
                anoAt={<CellTablePorcentagem rowInfo={index.lucro} />}
                anoPa={
                  <CellTablePorcentagem
                    rowInfo={index.porcentagemLucroPeriodoAnterior}
                  />
                }
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.lucroAnterior)} \n${converterNumero(
              row.lucro
            )}`,
        },
        {
          text: "Variação",
          dataField: "variacao",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTablePorcentagem
                rowInfo={cell}
                desemp={true}
                desempPeriodoAnterior={index.desempPeriodoAnterior}
                desempAnterior={true}
              />
            );
          },
        },
      ];
    }
  } else if (tipo == "mes") {
    if (anosListados.length === 1) {
      columns = [
        {
          dataField: "mes",
          text: "Meses",
          formatter: (cell) => {
            if (cell === "Total") {
              return cell;
            } else {
              return converterMes(cell);
            }
          },
        },
        {
          dataField: "qtdAnoAtual",
          text: "Quantidade",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTableQuantidade rowInfo={cell} />,
        },
        {
          dataField: "vlrAnoAtual",
          text: "Valor Total",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTable rowInfo={cell} />,
        },
        {
          dataField: "ticketMedio",
          text: "Ticket Médio",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTable rowInfo={cell} />,
        },
        {
          text: "Custo Total",
          dataField: "custoTotalAtual",
          formatter: (cell) => <CellTable rowInfo={cell} />,
          align: () => {
            return "right";
          },
        },
        {
          text: "Lucro Bruto ",
          dataField: "lucroBrutoAtual",
          formatter: (cell) => <CellTable rowInfo={cell} />,
          align: () => {
            return "right";
          },
        },
        {
          text: "Lucro (%)",
          dataField: "lucro",
          formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
          align: () => {
            return "right";
          },
        },
        {
          text: "Representatividade (%) ",
          dataField: "representitivadadeAtual",
          formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
          align: () => {
            return "right";
          },
        },
      ];
    } else {
      columns = [
        {
          dataField: "mes",
          text: "Meses",
          formatter: (cell) => {
            if (cell === "Total") {
              return cell;
            } else {
              return converterMes(cell);
            }
          },
        },
        {
          text: "",
          dataField: "",
          align: () => {
            return "left";
          },
          formatter: () => {
            return <CellAsideYaer mes />;
          },
          csvFormatter: () => "AT \nAN",
        },
        {
          dataField: "qtdAnoAtual",
          text: "Quantidade",
          align: () => {
            return "right";
          },
          style: () => {
            return { verticalAlign: "middle" };
          },
          headerAlign: () => "right",
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={index.qtdAnoAnterior}
                anoAt={index.qtdAnoAtual}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${row.qtdAnoAnterior} \n${row.qtdAnoAtual}`,
        },
        {
          dataField: "vlrAnoAtual",
          text: "Valor Total",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.vlrTotalAnoAnterior} />}
                anoAt={<CellTable rowInfo={index.vlrAnoAtual} />}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.vlrTotalAnoAnterior)} \n${converterNumero(
              row.vlrAnoAtual
            )}`,
        },
        {
          dataField: "ticketMedio",
          text: "Ticket Médio",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.ticketMedioAnoAnterior} />}
                anoAt={<CellTable rowInfo={index.ticketMedioAnoAtual} />}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.vlrTotalAnoAnterior)} \n${converterNumero(
              row.vlrAnoAtual
            )}`,
        },
        {
          dataField: "custoTotalAtual",
          text: "Custo Total",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.custoTotalAnterior} />}
                anoAt={<CellTable rowInfo={index.custoTotalAtual} />}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.custoTotalAnterior)} \n${converterNumero(
              row.custoTotalAtual
            )}`,
        },
        {
          dataField: "lucroBrutoAtual",
          text: "Lucro Bruto",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.lucroBrutoAnterior} />}
                anoAt={<CellTable rowInfo={index.lucroBrutoAtual} />}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.lucroBrutoAnterior)} \n${converterNumero(
              row.lucroBrutoAtual
            )}`,
        },
        {
          dataField: "lucro",
          text: "Lucro",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.lucroAnterior} />}
                anoAt={<CellTable rowInfo={index.lucro} />}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.lucroAnterior)} \n${converterNumero(
              row.lucro
            )}`,
        },
        {
          text: "Variação",
          dataField: "variacao",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell) => {
            return <CellTablePorcentagem rowInfo={cell} />;
          },
        },
      ];
    }
  }
  return columns;
};

export const colunasDescontoGlobal = (
  tipo,
  anosDisponiveis,
  filtrosAnosSelecionados,
  lojas = false
) => {
  let anosListados = [],
    columns = [];
  if (filtrosAnosSelecionados.length === 1) {
    anosListados = [];
    anosListados = filtrosAnosSelecionados;
  } else {
    anosListados = [];
    anosListados = anosDisponiveis;
  }

  if (tipo === "input" || tipo === "loja" || tipo === "ano") {
    if (anosListados.length === 1) {
      columns = [
        {
          dataField: "id",
          text: "",
          hidden: true,
        },
        {
          dataField: "loja",
          text: "Loja",
          formatter: (cell) => <CellTableTipo rowInfo={cell} tipo={tipo} />,
        },
        {
          dataField: "qtdAnoAtual",
          text: "Quantidade",
          align: () => {
            return "right";
          },

          formatter: (cell) => <CellTableQuantidade rowInfo={cell} />,
        },
        {
          dataField: "vlrAnoAtual",
          text: "Valor Total",
          align: () => {
            return "right";
          },

          formatter: (cell) => <CellTable rowInfo={cell} />,
        },
        {
          dataField: "vlrMedioAnoAtual",
          text: "Ticket Médio ",
          align: () => {
            return "right";
          },

          formatter: (cell) => <CellTable rowInfo={cell} />,
        },
        {
          dataField: "representatividadeAtual",
          text: "Represen.",
          align: () => {
            return "right";
          },

          formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
        },
      ];
    } else {
      columns = [
        {
          dataField: "id",
          text: "",
          hidden: true,
        },
        {
          dataField: "loja",
          text: "Loja",
          formatter: (cell) => <CellTableTipo rowInfo={cell} tipo={tipo} />,
        },
        {
          text: "",
          dataField: "",
          align: () => {
            return "left";
          },
          formatter: (cell) => {
            return <CellAsideYaer />;
          },
          csvFormatter: (cell, row, rowIndex) => "AT \nAN",
        },
        {
          dataField: "qtdAnoAtual",
          text: "Quantidade",
          align: () => {
            return "right";
          },
          style: () => {
            return { verticalAlign: "middle" };
          },
          headerAlign: () => "right",
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={index.qtdAnoAnterior}
                anoAt={index.qtdAnoAtual}
                anoPa={index.qtdPeriodoAnterior}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${row.qtdAnoAnterior} \n${row.qtdAnoAtual}`,
        },
        {
          dataField: "vlrAnoAtual",
          text: "Valor Total",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.vlrTotalAnoAnterior} />}
                anoAt={<CellTable rowInfo={index.vlrAnoAtual} />}
                anoPa={<CellTable rowInfo={index.vlrPeriodoAnterior} />}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.vlrTotalAnoAnterior)} \n${converterNumero(
              row.vlrAnoAtual
            )}`,
        },
        {
          dataField: "vlrMedioAnoAtual",
          text: "Ticket Médio",
          style: () => {
            return { verticalAlign: "middle" };
          },
          headerAlign: () => "right",
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.vlrMedioAnoAnterior} />}
                anoAt={<CellTable rowInfo={index.vlrMedioAnoAtual} />}
                anoPa={<CellTable rowInfo={index.vlrMedioPeriodoAnterior} />}
              />
            );
          },
        },
        {
          dataField: "representatividadeAtual",
          text: "Representatividade",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          headerAlign: () => "right",
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAt={
                  <CellTablePorcentagem
                    rowInfo={index.representatividadeAtual}
                  />
                }
                anoAn={
                  <CellTablePorcentagem
                    rowInfo={index.representatividadeAnterior}
                  />
                }
                anoPa={
                  <CellTablePorcentagem
                    rowInfo={index.representatividadePeriodoAnterior}
                  />
                }
              />
            );
          },
        },
        {
          text: "Desemp. (%)",
          dataField: "desempenho",
          style: () => {
            return { verticalAlign: "middle" };
          },
          headerAlign: () => "right",
          align: () => {
            return "right";
          },
          formatter: (cell, index) => (
            <CellTablePorcentagem
              rowInfo={cell}
              desemp={true}
              desempPeriodoAnterior={index.desempPeriodoAnterior}
              desempAnterior={true}
            />
          ),
        },
      ];
    }
  } else if (tipo == "mes") {
    let meses = `Meses de ${anosListados[0]}`;
    let quantidade = `Quantidade de ${anosListados[0]}`;
    let valorTotal = `Valor Total de ${anosListados[0]}`;
    let descMedio = `Desconto Médio de ${anosListados[0]}`;
    let repres = `Representatividade. de ${anosListados[0]}`;
    if (anosListados.length === 1) {
      columns = [
        {
          dataField: "mes",
          text: meses,
          formatter: (cell) => {
            if (cell === "Total") {
              return cell;
            } else {
              return converterMes(cell);
            }
          },
        },
        {
          text: quantidade,
          dataField: "qtdAnoAtual",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTableQuantidade rowInfo={cell} />,
        },
        {
          text: valorTotal,
          align: () => {
            return "right";
          },
          dataField: "vlrAnoAtual",
          formatter: (cell) => <CellTable rowInfo={cell} />,
        },
        {
          text: descMedio,
          align: () => {
            return "right";
          },
          dataField: "vlrMedioAnoAtual",
          formatter: (cell) => <CellTable rowInfo={cell} />,
        },
        {
          text: repres,
          align: () => {
            return "right";
          },
          dataField: "representatividadeAtual",
          formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
        },
      ];
    } else {
      columns = [
        {
          dataField: "mes",
          text: "Meses",
          formatter: (cell) => {
            if (cell === "Total") {
              return cell;
            } else {
              return converterMes(cell);
            }
          },
        },
        {
          text: "",
          dataField: "",
          align: () => {
            return "left";
          },
          formatter: (cell) => {
            return <CellAsideYaer mes />;
          },
          csvFormatter: (cell, row, rowIndex) => "AT \nAN",
        },
        {
          dataField: "qtdAnoAtual",
          text: "Quantidade",
          align: () => {
            return "right";
          },
          style: () => {
            return { verticalAlign: "middle" };
          },
          headerAlign: () => "right",
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={index.qtdAnoAnterior}
                anoAt={index.qtdAnoAtual}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${row.qtdAnoAnterior} \n${row.qtdAnoAtual}`,
        },
        {
          dataField: "vlrAnoAtual",
          text: "Valor Total",
          headerAlign: () => "right",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.vlrTotalAnoAnterior} />}
                anoAt={<CellTable rowInfo={index.vlrAnoAtual} />}
              />
            );
          },
          csvFormatter: (cell, row, rowIndex) =>
            `${converterNumero(row.vlrTotalAnoAnterior)} \n${converterNumero(
              row.vlrAnoAtual
            )}`,
        },
        {
          dataField: "vlrMedioAnoAtual",
          text: "Ticket Médio",
          style: () => {
            return { verticalAlign: "middle" };
          },
          headerAlign: () => "right",
          align: () => {
            return "right";
          },
          formatter: (cell, index) => {
            return (
              <CellTableYear
                anoAn={<CellTable rowInfo={index.vlrMedioAnoAnterior} />}
                anoAt={<CellTable rowInfo={index.vlrMedioAnoAtual} />}
              />
            );
          },
        },
        {
          dataField: "representatividadeAtual",
          text: "Representatividade",
          style: () => {
            return { verticalAlign: "middle" };
          },
          align: () => {
            return "right";
          },
          headerAlign: () => "right",
          formatter: (cell, index) => {
            return (
              <CellTableYear
                mes={true}
                anoAt={
                  <CellTablePorcentagem
                    rowInfo={index.representatividadeAtual}
                  />
                }
                anoAn={
                  <CellTablePorcentagem
                    rowInfo={index.representatividadeAnterior}
                  />
                }
              />
            );
          },
        },
        {
          text: "Desemp. (%)",
          dataField: "desempenho",
          style: () => {
            return { verticalAlign: "middle" };
          },
          headerAlign: () => "right",
          align: () => {
            return "right";
          },
          formatter: (cell) => <CellTablePorcentagem rowInfo={cell} />,
        },
      ];
    }
  }

  return columns;
};

export const colunasEstoqueMes = (dados) => {
  let colunasEstoque = [
    {
      dataField: "CodGrp1",
      text: "id",
      hidden: true,
    },
    {
      dataField: "NomeGrupo",
      text: "Estoque/Grupo",
      footer: "TOTAL GERAL",
    },
    {
      dataField: "CustoVendas",
      text: "Custo Vendas(R$)",
      formatter: (cell) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(cell),
      footer: (ind, col) => {
        if (ind.length === 0) {
          return "";
        }
        let arrNumber = ind.map((item) => parseFloat(item));
        let soma = arrNumber.reduce((soma, i) => soma + i);
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(soma);
      },
    },
    {
      dataField: "QtdVendas",
      text: "Qtd Venda",
      formatter: (cell) => parseInt(cell),
      footer: (ind, col) => {
        if (ind.length === 0) {
          return "";
        }
        let arrNumber = ind.map((item) => parseFloat(item));
        let soma = arrNumber.reduce((soma, i) => soma + i);
        return soma?.toFixed(2);
      },
    },
    {
      dataField: "tmVenda",
      text: "TM Venda",
      formatter: (cell) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(cell),
      footer: (ind, col) => {
        if (ind.length === 0) {
          return "";
        }
        let arrNumber = ind.map((item) => parseFloat(item));
        let soma = arrNumber.reduce((soma, i, index, arr) => soma + i);
        let media = soma / ind.length;
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(media);
      },
    },
    {
      dataField: "custoEstoque",
      text: "Custo Estoque",
      formatter: (cell) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(cell),
      footer: (ind, col) => {
        if (ind.length === 0) {
          return "";
        }
        let arrNumber = ind.map((item) => parseFloat(item));
        let soma = arrNumber.reduce((soma, i) => soma + i);
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(soma);
      },
    },
    {
      dataField: "tmEstoque",
      text: "TM Estoque",
      formatter: (cell) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(cell),
      footer: (ind, col, b, c) => {
        if (ind.length === 0) {
          return "";
        }
        let arrNumber = ind.map((item) => parseFloat(item));
        let soma = arrNumber.reduce((soma, i, index, arr) => soma + i);
        let media = soma / ind.length;
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(media);
      },
    },
    {
      dataField: "qtdEstoque",
      text: "Qtd Estoque",
      formatter: (cell) => parseInt(cell),
      footer: (ind, col) => {
        if (ind.length === 0) {
          return "";
        }
        let arrNumber = ind.map((item) => parseFloat(item));
        return arrNumber.reduce((soma, i) => soma + i);
      },
    },
    {
      dataField: "mediaMesQtd",
      text: "Media Mês Qtd",
      formatter: (cell) => parseFloat(cell.toFixed(2)),
      footer: (ind, col, b) => {
        if (ind.length === 0) {
          return "";
        }
        const qtdEstoqueAux = dados?.reduce((acc, item) => {
          return acc + item?.qtdEstoque;
        }, 0);

        const qtdVendasAux = dados?.reduce((acc, item) => {
          return acc + item?.QtdVendas;
        }, 0);

        return qtdVendasAux ? (qtdEstoqueAux / qtdVendasAux).toFixed(2) : 0;
      },
    },
    {
      dataField: "mediaMesCusto",
      text: "Media Mês Custo",
      formatter: (cell) => parseFloat(cell.toFixed(2)),
      footer: (ind, col) => {
        if (ind.length === 0) {
          return "";
        }

        const custoEstoqueAux = dados?.reduce((acc, item) => {
          return acc + item?.custoEstoque;
        }, 0);

        const custoVendasAux = dados?.reduce((acc, item) => {
          return acc + item?.CustoVendas;
        }, 0);

        return custoVendasAux
          ? (custoEstoqueAux / custoVendasAux).toFixed(2)
          : 0;
      },
    },
  ];
  return colunasEstoque;
};

export const colunasEstoqueContasPagar = (dados) => {
  let colunasEstoque = [
    {
      dataField: "CodFor",
      text: "id",
      hidden: true,
    },
    {
      dataField: "NomeFor",
      text: "Fornecedor",

      footer: "TOTAL GERAL",
    },

    {
      dataField: "mediaMesQtd",
      text: "Media Mês Qtd",
      formatter: (cell) => parseFloat(cell.toFixed(2)),
      footer: (ind, col, b) => {
        if (ind.length === 0) {
          return "";
        }
        const qtdEstoqueAux = dados?.reduce((acc, item) => {
          return acc + item?.qtdEstoque;
        }, 0);

        const qtdVendasAux = dados?.reduce((acc, item) => {
          return acc + item?.qtdVendas;
        }, 0);

        return qtdVendasAux ? (qtdEstoqueAux / qtdVendasAux).toFixed(2) : 0;
      },
    },
    {
      dataField: "mediaMesCusto",
      text: "Media Mês Custo",
      formatter: (cell) => parseFloat(cell.toFixed(2)),
      footer: (ind, col) => {
        if (ind.length === 0) {
          return "";
        }

        const custoEstoqueAux = dados?.reduce((acc, item) => {
          return acc + item?.custoEstoque;
        }, 0);

        const custoVendasAux = dados?.reduce((acc, item) => {
          return acc + item?.custoEstoque;
        }, 0);

        return custoVendasAux
          ? (custoEstoqueAux / custoVendasAux).toFixed(2)
          : 0;
      },
    },
    {
      dataField: "CustoVendas",
      text: "Custo Vendas(R$)",
      formatter: (cell) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(cell),
      footer: (ind, col) => {
        if (ind.length === 0) {
          return "";
        }
        let arrNumber = ind.map((item) => parseFloat(item));
        let soma = arrNumber.reduce((soma, i) => soma + i);
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(soma);
      },
    },
    {
      dataField: "custo",
      text: "Custo Estoque(R$)",
      formatter: (cell) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(cell),
      footer: (ind, col) => {
        if (ind.length === 0) {
          return "";
        }
        let arrNumber = ind.map((item) => parseFloat(item));
        let soma = arrNumber.reduce((soma, i) => soma + i);
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(soma);
      },
    },
    {
      dataField: "totalPagar",
      text: "Total a Pagar",
      formatter: (cell) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(cell),
      footer: (ind, col) => {
        if (ind.length === 0) {
          return "";
        }
        let arrNumber = ind.map((item) => parseFloat(item));
        let soma = arrNumber.reduce((soma, i) => soma + i);
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(soma);
      },
    },
    {
      dataField: "diferenca",
      text: "Diferença",
      formatter: (cell, index) => {
        return <CellTable rowInfo={index.diferenca} />;
      },

      footer: (ind, col) => {
        if (ind.length === 0) {
          return "";
        }
        let arrNumber = ind.map((item) => parseFloat(item));
        let soma = arrNumber.reduce((soma, i) => soma + i);
        return <CellTable rowInfo={soma} />;
      },
    },
    {
      dataField: "porcentagem",
      text: "%",
      formatter: (cell) => `${cell} %`,
    },
  ];
  return colunasEstoque;
};

export const colunasEstoqueQualidade = () => {
  let colunasEstoque = [
    {
      dataField: "abrir",
      text: "",
      isDummyField: true,
      editable: false,
      formatter: () => (
        <div
          style={{
            boxShadow: "0px 0px 2px 0px rgb(0 0 0)",
            textAlign: "center",
          }}>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      ),
      headerStyle: { minWidth: "60px" },
      footer: (ind, col) => {
        return "";
      },
    },
    {
      dataField: "titulo",
      text: "Nivel",
      headerStyle: { minWidth: "60px" },
      footer: (ind, col) => {
        return "";
      },
    },
    {
      dataField: "percentage",
      text: "%",
      headerStyle: { minWidth: "30px" },
      footer: (ind, col, b) => {
        if (ind.length === 0) {
          return "";
        }

        let arrNumber = ind.map((item) => parseFloat(item));
        let soma = arrNumber.reduce((soma, i) => soma + i);
        return soma.toFixed(0);
      },
    },
    {
      dataField: "products",
      text: "Quant. Produtos",
      headerStyle: { minWidth: "80px" },
      formatter: (cell, row) => {
        return Number(row?.products?.length);
      },
      footer: (ind, col, b) => {
        if (ind.length === 0) {
          return "";
        }

        let arrNumber = ind.map((item) => parseFloat(item.length));
        let soma = arrNumber.reduce((soma, i) => soma + i);
        return soma.toFixed(0);
      },
    },
    {
      dataField: "EstoqueAtual",
      text: "Estoque Atual",
      headerStyle: { minWidth: "80px" },
      footer: (ind, col, b) => {
        if (ind.length === 0) {
          return "";
        }

        let arrNumber = ind.map((item) => parseFloat(item));
        let soma = arrNumber.reduce((soma, i) => soma + i);
        return soma.toFixed(0);
      },
    },
    {
      dataField: "PrecoVenda",
      text: "Preço Venda",
      formatter: (cell) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(cell),
      headerStyle: { minWidth: "120px" },
      footer: (ind, col, b) => {
        if (ind.length === 0) {
          return "";
        }

        let arrNumber = ind.map((item) => parseFloat(item));
        let soma = arrNumber.reduce((soma, i) => soma + i);
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(soma);
      },
    },
    {
      dataField: "TotalMonetario",
      text: "Total Monetario",
      formatter: (cell) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(cell),
      headerStyle: { minWidth: "120px" },
      footer: (ind, col, b) => {
        if (ind.length === 0) {
          return "";
        }

        let arrNumber = ind.map((item) => parseFloat(item));
        let soma = arrNumber.reduce((soma, i) => soma + i);
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(soma);
      },
    },
  ];
  return colunasEstoque;
};

export const colunasEstoqueQualidadeCollapse = () => {
  let colunasEstoque = [
    {
      dataField: "abrir",
      text: "",
      isDummyField: true,
      editable: false,
      formatter: () => (
        <div
          style={{
            boxShadow: "0px 0px 2px 0px rgb(0 0 0)",
            textAlign: "center",
          }}>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      ),
      headerStyle: { minWidth: "60px" },
    },
    {
      dataField: "indicador",
      text: "Agrupamento",
      headerStyle: { minWidth: "60px" },
    },
    {
      dataField: "percentage",
      text: "%",
      headerStyle: { minWidth: "30px" },
    },
    {
      dataField: "products",
      text: "Quant. Produtos",
      headerStyle: { minWidth: "120px" },
      formatter: (cell, row) => {
        return Number(row?.products?.length);
      },
    },
    {
      dataField: "EstoqueAtual",
      text: "Estoque Atual",
      headerStyle: { minWidth: "120px" },
    },
    {
      dataField: "PrecoVenda",
      text: "Preço Venda",
      formatter: (cell) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(cell),
      headerStyle: { minWidth: "120px" },
    },
    {
      dataField: "TotalMonetario",
      text: "Total Monetario",
      formatter: (cell) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(cell),
      headerStyle: { minWidth: "120px" },
    },
  ];
  return colunasEstoque;
};

export const colunasEstoqueQualidadeCollapseInternal = () => {
  let colunasEstoque = [
    {
      dataField: "CodProd",
      text: "Cod. Produto",
      headerStyle: { minWidth: "60px" },
    },
    {
      dataField: "Descricao",
      text: "Descricao",
      headerStyle: { minWidth: "120px" },
    },
    {
      dataField: "DiasUltEntrada",
      text: "Dias Ult. Entrada",
      headerStyle: { minWidth: "120px" },
    },
    {
      dataField: "EstoqueAtual",
      text: "Estoque Atual",
      headerStyle: { minWidth: "120px" },
    },
    {
      dataField: "PrecoVenda",
      text: "Preço Venda",
      formatter: (cell) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(cell),
      headerStyle: { minWidth: "120px" },
    },
    {
      dataField: "TotalMonetario",
      text: "Total Monetario",
      formatter: (cell) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(cell),
      headerStyle: { minWidth: "120px" },
    },
  ];
  return colunasEstoque;
};

export const colunasEstoqueProdutos = () => {
  let colunasEstoque = [
    {
      dataField: "abrir",
      text: "",
      isDummyField: true,
      editable: false,
      formatter: () => (
        <div
          style={{
            textAlign: "center",
          }}>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      ),
      headerStyle: { minWidth: "60px" },
    },
    {
      dataField: "codProd",
      text: "Cód. Prod.",
      // sort: true,
      headerStyle: { minWidth: "120px" },
      // sortCaret: (order, column) => customCaret(order, column),
    },
    {
      dataField: "descricao",
      text: "Descrição",

      // sort: true,
      // sortCaret: (order, column) => customCaret(order, column),
      headerStyle: { minWidth: "250px" },
      // footer: "TOTAL GERAL",
    },
    {
      dataField: "precoVenda",
      text: "Vlr. Tab.",
      formatter: (cell) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(cell),
      // sort: true,
      // sortCaret: (order, column) => customCaret(order, column),
      headerStyle: { minWidth: "120px" },
    },
    {
      dataField: "estoqueAtual",
      text: "Estoque",
      // sort: true,
      // sortCaret: (order, column) => customCaret(order, column),
      headerStyle: { minWidth: "120px" },
    },
    {
      dataField: "descontoPromocao",
      text: "Desc. Prom. Esp.",
      // sort: true,
      // sortCaret: (order, column) => customCaret(order, column),
      headerStyle: { minWidth: "180px" },
      formatter: (cell, row) => {
        if (Number(row.promocao)) {
          if (row.tipoDesconto === "R") {
            return new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(cell);
          } else if (row.tipoDesconto === "P") {
            return new Intl.NumberFormat("pt-BR", {}).format(cell) + "%";
          } else {
            return null;
          }
        } else {
          return "--//--";
        }
      },
    },
    {
      dataField: "precoPromocao",
      text: "Vlr. Promo.",
      // sort: true,
      // sortCaret: (order, column) => customCaret(order, column),
      headerStyle: { minWidth: "140px" },
      formatter: (cell, row) => {
        if (Number(row.promocao) && !isNaN(cell)) {
          return new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(cell);
        } else {
          return "--//--";
        }
      },
    },
    {
      dataField: "dataFimPromocao",
      text: "Fim Promo.",
      headerStyle: { minWidth: "120px" },
      formatter: (cell, row) => {
        if (Number(row.promocao) && !isNaN(new Date(cell).getTime())) {
          return "--//--";
        } else if (!isNaN(new Date(cell).getTime())) {
          return Intl.DateTimeFormat("pt-BR").format(new Date(cell));
        }
      },
    },
    {
      dataField: "grupo",
      text: "Grupo",
      // sort: true,
      // sortCaret: (order, column) => customCaret(order, column),
      headerStyle: { minWidth: "120px" },
    },
    {
      dataField: "subGrupo",
      text: "Subgrupo",
      // sort: true,
      // sortCaret: (order, column) => customCaret(order, column),
      headerStyle: { minWidth: "130px" },
    },
    {
      dataField: "estoqueTransito",
      text: "Est. Transito",
      // sort: true,
      // sortCaret: (order, column) => customCaret(order, column),
      headerStyle: { minWidth: "160px" },
    },
  ];
  return colunasEstoque;
};

export const colunasEstoquePorBase = () => {
  let colunasEstoque = [
    {
      dataField: "Base",
      text: "Base",
      footer: "TOTAL",
    },
    {
      dataField: "EstoqueAtual",
      text: "Estoque",
      formatter: (cell) => (cell ? Number(cell).toFixed(0) : 0),
      footer: (ind, col) => {
        if (ind.length === 0) {
          return "";
        }
        const soma = ind.reduce((previousValue, currentValue) => {
          if (Number(currentValue)) {
            return previousValue + Number(currentValue);
          } else {
            return previousValue;
          }
        }, 0);
        return Number(soma).toFixed(0);
      },
    },
    {
      dataField: "EstoqueTransito",
      text: "Em Trânsito",
      formatter: (cell) => (cell ? Number(cell).toFixed(0) : 0),
      footer: (ind, col) => {
        if (ind.length === 0) {
          return "";
        }
        const soma = ind.reduce((previousValue, currentValue) => {
          if (Number(currentValue)) {
            return previousValue + Number(currentValue);
          } else {
            return previousValue;
          }
        }, 0);
        return Number(soma).toFixed(0);
      },
    },
  ];
  return colunasEstoque;
};

export const colunasEstoquePorLocal = () => {
  let colunasEstoque = [
    {
      dataField: "Base",
      text: "Base",
      footer: "TOTAL",
    },
    {
      dataField: "CodLocal",
      text: "Cód. Local",
      footer: "",
    },
    {
      dataField: "NomeLocal",
      text: "Local",
      footer: "",
    },
    {
      dataField: "EstoqueAtual",
      text: "Estoque",
      formatter: (cell) => (cell ? Number(cell).toFixed(0) : 0),
      footer: (ind, col) => {
        if (ind.length === 0) {
          return "";
        }
        const soma = ind.reduce((previousValue, currentValue) => {
          if (Number(currentValue)) {
            return previousValue + Number(currentValue);
          } else {
            return previousValue;
          }
        }, 0);
        return Number(soma).toFixed(0);
      },
    },
    {
      dataField: "EstoqueTransito",
      text: "Em Trânsito",
      formatter: (cell) => (cell ? Number(cell).toFixed(0) : 0),
      footer: (ind, col) => {
        if (ind.length === 0) {
          return "";
        }
        const soma = ind.reduce((previousValue, currentValue) => {
          if (Number(currentValue)) {
            return previousValue + Number(currentValue);
          } else {
            return previousValue;
          }
        }, 0);
        return Number(soma).toFixed(0);
      },
    },
  ];
  return colunasEstoque;
};

export const colunasTabelasGlobaisV2 = (desempenho = false) => {
  //vendas global
  let mesesPrev = [
    { desc: "Loja" },
    {
      desc: "Jan",
      valor: "jan",
      lucro: "janLucro",
      ticketMedio: "janTicket",
      qtd: "janQtd",
    },
    {
      desc: "Fev",
      valor: "fev",
      lucro: "fevLucro",
      ticketMedio: "fevTicket",
      qtd: "fevQtd",
    },
    {
      desc: "Mar",
      valor: "mar",
      lucro: "marLucro",
      ticketMedio: "marTicket",
      qtd: "marQtd",
    },
    {
      desc: "Abr",
      valor: "abr",
      lucro: "abrLucro",
      ticketMedio: "abrTicket",
      qtd: "abrQtd",
    },
    {
      desc: "Mai",
      valor: "mai",
      lucro: "maiLucro",
      ticketMedio: "maiTicket",
      qtd: "maiQtd",
    },
    {
      desc: "Jun",
      valor: "jun",
      lucro: "junLucro",
      ticketMedio: "junTicket",
      qtd: "junQtd",
    },
    {
      desc: "Jul",
      valor: "jul",
      lucro: "julLucro",
      ticketMedio: "julTicket",
      qtd: "julQtd",
    },
    {
      desc: "Ago",
      valor: "ago",
      lucro: "agoLucro",
      ticketMedio: "agoTicket",
      qtd: "agoQtd",
    },
    {
      desc: "Set",
      valor: "set",
      lucro: "setLucro",
      ticketMedio: "setTicket",
      qtd: "setQtd",
    },
    {
      desc: "Out",
      valor: "out",
      lucro: "outLucro",
      ticketMedio: "outTicket",
      qtd: "outQtd",
    },
    {
      desc: "Nov",
      valor: "nov",
      lucro: "novLucro",
      ticketMedio: "novTicket",
      qtd: "novQtd",
    },
    {
      desc: "Dez",
      valor: "dez",
      lucro: "dezLucro",
      ticketMedio: "dezTicket",
      qtd: "dezQtd",
    },
    {
      desc: "Total",
      valor: "total",
      lucro: "totalLucro",
      ticketMedio: "totalTicket",
      qtd: "totalQtd",
    },
  ];
  let columns = [];
  mesesPrev.forEach((item) => {
    let aux = item.desc.toLowerCase();
    if (aux === "loja") {
      columns.push({
        text: item.desc,
        dataField: aux,
        style: () => {
          return { verticalAlign: "middle" };
        },
        formatter: (cell) => {
          return converterLoja(cell);
        },
      });
      columns.push({
        text: "",
        dataField: "",
        align: () => {
          return "left";
        },
        formatter: (cell) => {
          return <CellAsideGlobal />;
        },
      });
    } else if (aux === "total") {
      columns.push({
        text: item.desc,
        dataField: aux,
        align: () => {
          return "right";
        },

        formatter: (cell, row, a, b, c) => {
          return (
            <CellTableGlobal
              lucro={formatarNumero(parseFloat(row.totalLucro), false) + "%"}
              qtd={parseInt(row.totalQtd)}
              ticketMedio={formatarNumero(row.totalTicket, true, 0)}
              valor={formatarNumero(row.total, true, 0)}
            />
          );
        },
      });
    } else {
      columns.push({
        text: item.desc,
        dataField: aux,
        align: () => {
          return "right";
        },
        formatter: (cell, row, a, b, c) => {
          return (
            <CellTableGlobal
              lucro={formatarNumero(row[item.lucro], false) + "%"}
              qtd={parseInt(row[item.qtd])}
              ticketMedio={formatarNumero(row[item.ticketMedio], true, 0)}
              valor={formatarNumero(row[item.valor], true, 0)}
            />
          );
        },
      });
    }
  });
  return columns;
};
