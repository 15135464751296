import axios from "axios";

const api = axios.create({
  //baseURL: "http://localhost:10101/v2",
  //baseURL: "https://api.prod.aprincipalbb.com.br/qa",
  baseURL: process.env.REACT_APP_API_URL,
});

const registerInterceptTokenManager = (singOut) => {
  const interceptTokenManager = api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (requestError) => {
      if (requestError.response?.status === 401) {
        singOut();
      }

      return Promise.reject(requestError);
    }
  );

  return () => {
    api.interceptors.response.eject(interceptTokenManager);
  };
};

export { api, registerInterceptTokenManager };
