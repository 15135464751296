import { ContentCopy } from "@mui/icons-material";
import { IconButton, Link, Stack, Tooltip, Typography } from "@mui/material";
import { copyToClipboard } from "../../util/copyToClipboard";

export const ButtonCopyNumber = ({ phone }) => {
  return (
    <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
      <Tooltip title="Copiar numero">
        <IconButton aria-label="copy" onClick={() => copyToClipboard(phone)}>
          <ContentCopy />
        </IconButton>
      </Tooltip>

      <Link
        href={`https://wa.me/55${phone?.replace(/[^0-9]/g, "")}`}
        component={"a"}
        target="blank"
        color={"#000"}
        sx={{
          ":hover": {
            color: "blue",
          },
        }}
      >
        <Typography
          fontSize={"13px"}
          whiteSpace={"nowrap"}
          textOverflow={"ellipsis"}
          overflow={"hidden"}
        >
          {phone}
        </Typography>
      </Link>
    </Stack>
  );
};
