import { CM_STORAGE } from "./storageConfig";

export const storageDataCadastroMetasSave = (data) => {
  localStorage.setItem(CM_STORAGE, JSON.stringify(data));
};

export const storageDataCadastroMetasGet = () => {
  const data = localStorage.getItem(CM_STORAGE);
  if (data) {
    return JSON.parse(data);
  }
  return [];
};
