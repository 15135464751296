import './LegendaTabela.css'
export const LegendaTabela = ({ vendasPor = false}) => {
    return (
        <>
            {!vendasPor ? <div id='containerlegenda'>
                <span><strong >QTD</strong> - Quantidade</span>
                <span><strong >VT</strong> - Valor Total</span>
                <span><strong >VM</strong> - Valor Medio</span>
                <span><strong >AN</strong> - Ano Anterior</span>
                <span><strong >AT</strong> - Ano Atual</span>
                <span><strong >REPRE</strong> - Representatividade</span>
                <span><strong >DESEMP</strong> - Desempenho</span>
            </div> :
                <div id='containerlegenda'>
                    <span><strong >QTD</strong> - Quantidade</span>
                    <span><strong >VT</strong> - Valor Total</span>
                    <span><strong >VM</strong> - Valor Medio</span>
                    <span><strong >CT</strong> - Custo Total</span>
                    <span><strong >LB</strong> - Lucro Bruto</span>
                    <span><strong >AN</strong> - Ano Anterior</span>
                    <span><strong >AT</strong> - Ano Atual</span>
                </div>}
        </>

    )
}