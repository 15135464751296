import { api } from "./api.v2";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getListGoals: async (params) => {
    return await api.get("/targets/sales/list-goals", {
      params,
    });
  },
  getListPeriods: async () => {
    return await api.get("/targets/sales/list-of-available-periods");
  },
};
