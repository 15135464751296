import { Chip, Stack } from "@mui/material";
import { red } from "@mui/material/colors";
const CustomChip = ({ bgcolor, ...props }) => (
  <Chip
    sx={{
      fontWeight: "bold",
      color: "white",
      bgcolor,
    }}
    {...props}
  />
);
export const LegendGoals = () => (
  <Stack
    direction={"row"}
    gap={1}
    my={1}
    pb={[0.3, 0]}
    overflow={"auto"}
    justifyContent={"center"}
  >
    <CustomChip bgcolor="#6395ec" label="Diamante 💎" />
    <CustomChip bgcolor="#ffd700" label="Ouro 🥇" />
    <CustomChip bgcolor="#00b14f" label="Atingiu a Meta 🚀" />
    <CustomChip bgcolor={red[500]} label="Não atingiu 🤷🏻‍♀️" />
  </Stack>
);
