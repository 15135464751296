import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { converterNumero } from "../../util/loja";

export const TableMain = ({ data }) => {
  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{
        mt: 0.5,
        mb: 1,
      }}
    >
      <Table size="small" aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Valor Vendas</TableCell>
            <TableCell>Ticket Medio</TableCell>
            <TableCell>Item por venda</TableCell>
            <TableCell>Clientes cadastrados</TableCell>
            <TableCell>Qtd Pedidos</TableCell>
            <TableCell>Dias uteis</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{converterNumero(+data.metaVenda)}</TableCell>
            <TableCell>{converterNumero(data.metaTM)}</TableCell>
            <TableCell>{data.metaQtdPedidos}</TableCell>
            <TableCell>{data.metaItensPorVenda}</TableCell>
            <TableCell>{data.metaClientesCadastrados}</TableCell>

            <TableCell>{data.diasUteis}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
