import React from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { converterLoja, converterNomeLoja } from "../util/loja";

const FilterLojaSimples = ({
  lojasDisponiveis,
  filtrosLojasSelecionadas,
  filtrarPorLoja,
  disabled,
}) => {
  return (
    <div className="containerTableCell">
      <div className="containerButtoonLoja">
        <ButtonGroup>
          {lojasDisponiveis.length > 1
            ? lojasDisponiveis.map((item, index) => {
                return filtrosLojasSelecionadas.indexOf(item) >= 0 ? (
                  <ToggleButton
                    variant="secondary"
                    type="radio"
                    key={converterNomeLoja(item)}
                    className="radius01 btt-active"
                    onClick={() => filtrarPorLoja(item)}
                    disabled={disabled}
                  >
                    {converterLoja(item)}
                  </ToggleButton>
                ) : (
                  <ToggleButton
                    className="radius01"
                    type="radio"
                    variant="outline-secondary"
                    key={converterNomeLoja(item)}
                    onClick={() => filtrarPorLoja(item)}
                    disabled={disabled}
                  >
                    {converterLoja(item)}
                  </ToggleButton>
                );
              })
            : ""}
        </ButtonGroup>
      </div>
    </div>
  );
};

export default FilterLojaSimples;
