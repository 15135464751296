import { Box, Tab, Tabs, Typography } from "@mui/material";
import { FormExpandableRows } from "./FormExpandableRows";
import { CustomTabPanel } from "../componentsAnaliseClientes/CustomTabPanel";
import { useContext, useEffect, useState } from "react";
import { a11yProps } from "../../util/a11yProps";
import dayjs from "dayjs";
import { FormContext } from "../../pages/financeiro/metas/cadastro-metas";
import { useFormContext } from "react-hook-form";
import {
  storageDataCadastroMetasGet,
  storageDataCadastroMetasSave,
} from "../../storage/storageCadastroMetas";

export const FormRowFuncionario = ({ data, expanded, handleExpandClick }) => {
  const [valueTab, setValueTab] = useState(null);
  const { periodsSelects, setDataPage } = useContext(FormContext);
  const { getValues, setValue } = useFormContext();
  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };
  useEffect(() => {
    if (!!expanded) {
      handleChange(null, 0);
    }
  }, [expanded]);

  const calcularMetaEspecial = (key) => {
    const valuePeridos = {
      ...getValues(
        `${data.CodLoja}.funcionariosDaLoja.${data.CodFunc}.periodos.${key}`
      ),
    };
    if (valuePeridos.metaEspecial) {
      let somaMetaEspecial = 0;
      let somaMetaVenda = 0;
      Object.values(
        getValues(`${data.CodLoja}.funcionariosDaLoja.${data.CodFunc}.periodos`)
      ).forEach((item) => {
        if (!!item?.metaEspecial) {
          somaMetaEspecial = somaMetaEspecial + 1;
          somaMetaVenda = somaMetaVenda + +item.metaVenda;
        }
      });
      const qtdNaoEspecial =
        Object.values(periodsSelects).length - somaMetaEspecial;

      const vendaRestanteParaDistribuir =
        getValues(
          `${data.CodLoja}.funcionariosDaLoja.${data.CodFunc}.metaVenda`
        ) - somaMetaVenda;

      Object.keys(
        getValues(`${data.CodLoja}.funcionariosDaLoja.${data.CodFunc}.periodos`)
      ).forEach((keyPeriod) => {
        if (
          keyPeriod !== key &&
          !getValues(
            `${data.CodLoja}.funcionariosDaLoja.${data.CodFunc}.periodos.${keyPeriod}.metaEspecial`
          )
        ) {
          setValue(
            `${data.CodLoja}.funcionariosDaLoja.${data.CodFunc}.periodos.${keyPeriod}.metaVenda`,
            Math.ceil(vendaRestanteParaDistribuir / qtdNaoEspecial)
          );
        }
      });
    }
  };

  const handleSave = (key) => {
    calcularMetaEspecial(key);
    const valuePeridos = {
      ...getValues(
        `${data.CodLoja}.funcionariosDaLoja.${data.CodFunc}.periodos.${key}`
      ),
    };
    const dataPageStorage = storageDataCadastroMetasGet();
    const formatterData = dataPageStorage.map((item) => {
      if (item.CodLoja === data.CodLoja) {
        if (
          +item.funcionariosDaLoja[data.CodFunc].periodos[key].metaTM !==
          +valuePeridos.metaTM
        ) {
          const qtdAjustado = Math.ceil(
            valuePeridos.metaVenda / valuePeridos.metaTM
          );
          setValue(
            `${data.CodLoja}.funcionariosDaLoja.${data.CodFunc}.periodos.${key}.metaQtdPedidos`,
            qtdAjustado
          );
          item.funcionariosDaLoja[data.CodFunc].periodos[key].metaQtdPedidos =
            qtdAjustado;
        }

        if (
          +item.funcionariosDaLoja[data.CodFunc].periodos[key].metaVenda !==
          +valuePeridos.metaVenda
        ) {
          const metaTMAjustada = Math.ceil(
            valuePeridos.metaVenda / valuePeridos.metaQtdPedidos
          );
          setValue(
            `${data.CodLoja}.funcionariosDaLoja.${data.CodFunc}.periodos.${key}.metaTM`,
            metaTMAjustada
          );
          item.funcionariosDaLoja[data.CodFunc].periodos[key].metaTM =
            metaTMAjustada;
        }
        if (
          +item.funcionariosDaLoja[data.CodFunc].periodos[key]
            .metaQtdPedidos !== +valuePeridos.metaQtdPedidos
        ) {
          const metaTMAjustada = Math.ceil(
            valuePeridos.metaVenda / valuePeridos.metaQtdPedidos
          );
          setValue(
            `${data.CodLoja}.funcionariosDaLoja.${data.CodFunc}.periodos.${key}.metaTM`,
            metaTMAjustada
          );

          item.funcionariosDaLoja[data.CodFunc].periodos[key].metaTM =
            metaTMAjustada;
        }
      }
      return item;
    });
    setDataPage(formatterData);
    storageDataCadastroMetasSave(formatterData);

    if (valueTab + 1 === Object.values(periodsSelects).length) {
      setValueTab(0);
      handleExpandClick();
    } else {
      setValueTab((old) => old + 1);
    }
  };
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          py: 0.5,
        }}
      >
        <Tabs
          value={valueTab}
          onChange={handleChange}
          aria-label="tabs periods"
          orientation="vertical"
          variant="scrollable"
        >
          {Object.values(periodsSelects).map((item, index) => (
            <Tab
              sx={{
                borderRight: 1,
                borderColor: "divider",
              }}
              key={index}
              label={`${dayjs(item.startDate).format("DD/MM")} - ${dayjs(
                item.endDate
              ).format("DD/MM")}`}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        <Box flex={1} px={2}>
          {Object.keys(periodsSelects).map((key, index) => (
            <CustomTabPanel
              key={periodsSelects[key]?.startDate}
              value={valueTab}
              index={index}
            >
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                fontWeight={"500"}
                component={"span"}
                mb={0}
                gutterBottom
              >
                Semana {valueTab + 1} -
                {`${dayjs(periodsSelects[key]?.startDate).format(
                  "DD/MM/YYYY"
                )} - ${dayjs(periodsSelects[key]?.endDate).format(
                  "DD/MM/YYYY"
                )}`}
              </Typography>
              <FormExpandableRows
                data={data}
                chave={key}
                valueTab={valueTab}
                handleSave={handleSave}
              />
            </CustomTabPanel>
          ))}
        </Box>
      </Box>
    </>
  );
};
