import { createContext, useContext, useEffect, useState } from "react";
import api from "../services/api";
import apiProd from "../services/apiProd";
import { bNivelAcesso } from "../util/login";

const NivelAcessoContext = createContext({})

export const NivelAcessoProvider = ({ match, children }) => {
    const [levelAcess, setLevelAcess] = useState({})
    useEffect(() => {
        const id = match.params.id;
        const token = match.params.token;
        api.get('/powerbi/request.php', {
            params: { entity: 'login', entityData: JSON.stringify({ id, token }), entityFunction: 'getNivelAcesso' }
        }).then(response => {
            const { baseAcesso, nivelAcesso, nome, root, setor, siglasLojasCadastradas } = response?.data[0]
            const data = {
                user: [
                    nome,
                    setor
                ],
                permissions: root ? bNivelAcesso(JSON.parse(root) || []) : false,
                accessLevel: nivelAcesso ? nivelAcesso : false,
                acessBase: baseAcesso ? JSON.parse(baseAcesso) : false,
                id,
                token,
                menu: {}
            }
            setLevelAcess(data)
            localStorage.setItem('siglasLojasCadastradas', JSON.stringify(siglasLojasCadastradas))
        })
        apiProd.get('api/collaborator/get-page-permissions.php', {
            params: { userId: id },
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            const permissionsMenu = response?.data?.data
            setLevelAcess(prev => ({
                ...prev,
                menu: permissionsMenu
            }))
            console.log(permissionsMenu);
        })
    }, [match])
    return (
        <NivelAcessoContext.Provider value={levelAcess}>
            {children}
        </NivelAcessoContext.Provider>
    )
}
export const useLevelAcess = () => {
    const context = useContext(NivelAcessoContext)
    return context
}