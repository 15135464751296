import { Accordion } from "react-bootstrap";
import { CellNameYaer } from "./cells/CellNameYaer";
import { CellAmount } from "./cells/CellAmount";
import { CellMoney } from "./cells/CellMoney";
import { CellPercentege } from "./cells/CellPercentage";
import { CellPerformace } from "./cells/CellPerformace";
import TabelaNext from "../../components/componentteste/TabelaNext";
import { converterLoja } from "../../util/loja";
import { CellTotalSalesGroup } from "./cells/CellTotalSalesGroup";
const caseInsensitiveSort = (rowA, rowB) => {
  const a = Number(rowA.codloja);
  const b = Number(rowB.codloja);

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const columnsTable = [
  {
    name: <strong style={{ fontSize: "1rem" }}>Vendedor</strong>,
    selector: (row) => row.NomeFuncionario,
    sortable: true,
  },
  {
    name: <strong style={{ fontSize: "1rem" }}>Loja</strong>,
    selector: (row) => row.codloja,
    sortable: true,
    sortFunction: caseInsensitiveSort,
    cell: (row) => {
      return (
        <div>
          <span
            style={{
              backgroundColor: "hsl(0, 0%, 90%)",
              padding: "4px",
              borderRadius: "2px",
              marginLeft: 4,
            }}
          >
            {converterLoja(row.codloja)}
          </span>
        </div>
      );
    },
  },

  {
    name: <strong style={{ fontSize: "1rem" }}>Valor Total</strong>,
    selector: (row) => row.Venda_Total_Grupo.currency,
    cell: (row) => {
      return (
        <div style={{ display: "flex", gap: "0.8rem" }}>
          <CellTotalSalesGroup row={row} name={"currency"} type={"AT"} />
          <CellTotalSalesGroup row={row} name={"lastYear"} type={"AN"} />
          <CellTotalSalesGroup row={row} name={"lastMonth"} type={"PA"} />
        </div>
      );
    },
  },
];

const columns = [
  {
    dataField: "NomeGrupo1",
    text: "Grupo",
  },
  {
    text: "",
    dataField: "",
    align: () => {
      return "center";
    },
    style: () => {
      return { verticalAlign: "middle" };
    },
    formatter: (_, row) => {
      return <CellNameYaer row={row} />;
    },
    csvFormatter: () => "AT \nAN",
  },
  {
    dataField: "T_Pedidos",
    text: "Quantidade",
    align: () => {
      return "right";
    },
    style: () => {
      return { verticalAlign: "middle" };
    },
    headerAlign: () => "right",
    formatter: (_, row) => {
      return <CellAmount row={row} name={"T_Pedidos"} />;
    },
  },
  {
    dataField: "Venda_Total",
    text: "Valor Total",
    headerAlign: () => "right",
    style: () => {
      return { verticalAlign: "middle" };
    },
    align: () => {
      return "right";
    },
    formatter: (_, row) => {
      return <CellMoney row={row} name={"Venda_Total"} />;
    },
  },
  {
    dataField: "ticket_medio",
    text: "Ticket Medio",
    headerAlign: () => "right",
    style: () => {
      return { verticalAlign: "middle" };
    },
    align: () => {
      return "right";
    },
    formatter: (_, row) => {
      return <CellMoney row={row} name={"ticket_medio"} />;
    },
  },
  {
    dataField: "T_Custo",
    text: "Custo Total",
    headerAlign: () => "right",
    style: () => {
      return { verticalAlign: "middle" };
    },
    align: () => {
      return "right";
    },
    formatter: (_, row) => {
      return <CellMoney row={row} name={"T_Custo"} />;
    },
  },
  {
    dataField: "T_Itens",
    text: "Total Itens",
    headerAlign: () => "right",
    style: () => {
      return { verticalAlign: "middle" };
    },
    align: () => {
      return "right";
    },
    formatter: (_, row) => {
      return <CellMoney row={row} name={"T_Itens"} />;
    },
  },

  {
    dataField: "T_LucroBruto",
    text: "Lucro Bruto",
    headerAlign: () => "right",
    style: () => {
      return { verticalAlign: "middle" };
    },
    align: () => {
      return "right";
    },
    formatter: (_, row) => {
      return <CellMoney row={row} name={"T_LucroBruto"} />;
    },
  },
  {
    dataField: "LucroBruto_perc",
    text: "Lucro (%)",
    headerAlign: () => "right",
    style: () => {
      return { verticalAlign: "middle" };
    },
    align: () => {
      return "right";
    },
    formatter: (_, row) => {
      return <CellPercentege row={row} name={"LucroBruto_perc"} />;
    },
  },

  {
    dataField: "Repres_Perc",
    text: "Repres.",
    headerAlign: () => "right",
    style: () => {
      return { verticalAlign: "middle" };
    },
    align: () => {
      return "right";
    },
    formatter: (_, row) => {
      return <CellPercentege row={row} name={"Repres_Perc"} />;
    },
  },
  {
    dataField: "performace",
    text: "Desemp. (%)",
    headerAlign: () => "right",
    style: () => {
      return { verticalAlign: "middle" };
    },
    align: () => {
      return "right";
    },
    formatter: (_, row) => {
      return <CellPerformace row={row} />;
    },
  },
];

export const ExpandableRowsComponent = ({ data }) => {
  return (
    <>
      <div style={{ overflowX: "auto", padding: "1rem" }}>
        <Accordion alwaysOpen>
          <TabelaNext dadosParaTabela={data.grupos} colunas={columns} />
        </Accordion>
      </div>
    </>
  );
};
