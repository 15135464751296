import React from 'react'
import Spin from './Spin'
const Loading = ({estado}) => {
    return (<>
        {estado && <>
            <div className={estado ? "showBackgroundLoading" : "hideBackgroundLoading"}>
                <><Spin /></>
            </div>
        </>}
    </>)
}

export default Loading
