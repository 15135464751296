import { api } from "./api.v2";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDepartamentos: async () => {
    return await api.get("/base/departments/select");
  },
  getBases: async () => {
    return await api.get("/base/select");
  },
  getLojas: async (base) => {
    return await api.get(`/base/${base}/stores/select`);
  },
};
