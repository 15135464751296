import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Paper,
  Stack,
  TextField,
  Typography,
  Select as SelectMUI,
  MenuItem,
  OutlinedInput,
} from "@mui/material";

import { useParams, useHistory } from "react-router-dom";

import { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import toast from "react-hot-toast";

import { PermissionsPage } from "../../../../util/permissionsPage";
import Title from "../../../../components/title";

import vacancies from "../../../../services/vacancies";
import { useUserData } from "../../../../hooks/useUserData";
import { isEmptyObject } from "../../../../util/isEmptyObject";
import select from "../../../../services/select";
import ErrorToastHandler from "../../../../util/ErrorToastHandler";
import { IOSSwitch } from "../../../cadaastros/profile-indicate/components/IOSSwitch";
import { IsloadingFormCreate } from "../components/IsLoadingFormCreate";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const CreateVacancies = () => {
  const history = useHistory();
  const params = useParams();
  const [optionsDepartamentos, setOptionsDepartamentos] = useState([]);

  const { userData } = useUserData();
  const {
    formState: { errors, isSubmitting },
    register,
    control,
    reset,
    handleSubmit,
  } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [storeAvailable, setStoreAvailable] = useState([]);
  const [baseAvailable, setBaseAvailable] = useState([]);
  const [isLoadingStore, setIsLoadingStore] = useState(false);
  const getInfoVacancies = async () => {
    setIsLoading(true);

    try {
      const response = await vacancies.get(params.id);

      reset({
        ...response.data,
        base: response.data.codBase,
      });
    } catch (error) {
      console.log(error);
      toast.error("Loja não existe");
    } finally {
      setIsLoading(false);
    }
  };
  const getDepartamentos = async () => {
    const response = await select.getDepartamentos();
    if (response.status === 200) {
      setOptionsDepartamentos(
        response.data.map((item) => ({ value: item.id, label: item.name }))
      );
    }
  };
  const getBases = async () => {
    const response = await select.getBases();
    if (response.status === 200) {
      await getLojas(response.data[0].id);

      setBaseAvailable(
        response.data.map((item) => ({ value: item.id, label: item.name }))
      );
    }
  };
  const getLojas = async (base) => {
    setIsLoadingStore(true);
    const response = await select.getLojas(base);
    if (response.status === 200) {
      setStoreAvailable(
        response.data.map((item) => ({ value: item.id, label: item.name }))
      );
    }
    setIsLoadingStore(false);
  };

  const goBackList = () => history.push(`/rh/vagas`);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const dataSend = {
        ...data,
        status: !!data.status ? 1 : 0,
      };
      if (params?.id) {
        await vacancies.update(params?.id, dataSend);
      } else {
        await vacancies.create(dataSend);
      }
      toast.success("Informações salvas com sucesso");
      goBackList();
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params?.id) {
      if (!isEmptyObject(userData) && optionsDepartamentos.length > 0) {
        getInfoVacancies();
      }
    } else {
      setIsLoading(false);
    }
  }, [params?.id, userData, optionsDepartamentos]);
  useEffect(() => {
    if (!isEmptyObject(userData)) {
      const subscribe = async () => {
        await getBases();
        await getDepartamentos();
      };
      subscribe();
    }
  }, [userData]);

  const handleChangeBase = async (id) => {
    await getLojas(id);
  };

  return (
    <>
      {PermissionsPage("list_store") && (
        <>
          <Title
            title={`${
              !!params?.id ? "Editar" : "Cadastar"
            } Informações da vaga`}
          />

          {isLoading ? (
            <IsloadingFormCreate />
          ) : (
            <Box
              sx={{
                px: 2,
                paddingBottom: 3,
              }}
            >
              <>
                {!isLoading && (
                  <Box display={"flex"} gap={4} width={"100%"}>
                    <Paper
                      sx={{
                        p: 2,
                        flex: 1,
                      }}
                      variant="outlined"
                      elevation={3}
                    >
                      <Stack
                        spacing={2}
                        component={"form"}
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <FormControl
                          sx={{
                            pl: 2,
                          }}
                        >
                          <Controller
                            name="status"
                            control={control}
                            defaultValue={true}
                            render={({ field }) => (
                              <Stack direction={"row"}>
                                <FormControlLabel
                                  control={<IOSSwitch {...field} />}
                                  checked={field.value}
                                />
                                <Typography component={"span"}>
                                  {field.value ? "Ativo" : "Desativo"}
                                </Typography>
                              </Stack>
                            )}
                          />
                        </FormControl>
                        <Typography>Informações gerais</Typography>
                        <Stack direction="row" spacing={2}>
                          <FormControl
                            sx={{
                              flex: 1,
                              zIndex: 1000,
                            }}
                          >
                            <InputLabel size="small" htmlFor="base">
                              Base
                            </InputLabel>
                            <Controller
                              name="base"
                              control={control}
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => (
                                <SelectMUI
                                  {...field}
                                  error={!!errors.base}
                                  id="base"
                                  size="small"
                                  input={<OutlinedInput label="Base" />}
                                  MenuProps={MenuProps}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    handleChangeBase(e.target.value);
                                  }}
                                >
                                  {baseAvailable.map((name) => (
                                    <MenuItem
                                      key={name.value}
                                      value={name.value}
                                    >
                                      {name.label}
                                    </MenuItem>
                                  ))}
                                </SelectMUI>
                              )}
                            />
                          </FormControl>
                          <FormControl
                            sx={{
                              flex: 1,
                              zIndex: 1000,
                            }}
                            disabled={isLoadingStore}
                          >
                            <InputLabel size="small" htmlFor="idLoja">
                              Loja
                            </InputLabel>
                            <Controller
                              name="idLoja"
                              control={control}
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => (
                                <SelectMUI
                                  {...field}
                                  id="idLoja"
                                  size="small"
                                  error={!!errors.idLoja}
                                  input={<OutlinedInput label="Loja" />}
                                  MenuProps={MenuProps}
                                >
                                  {storeAvailable.map((name) => (
                                    <MenuItem
                                      key={name.value}
                                      value={name.value}
                                    >
                                      {name.label}
                                    </MenuItem>
                                  ))}
                                </SelectMUI>
                              )}
                            />
                          </FormControl>
                        </Stack>
                        <FormControl
                          sx={{
                            flex: 1,
                            zIndex: 1000,
                          }}
                        >
                          <InputLabel size="small" htmlFor="idDepartamento">
                            Departamento
                          </InputLabel>
                          <Controller
                            name="idDepartamento"
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <SelectMUI
                                {...field}
                                id="idDepartamento"
                                size="small"
                                error={!!errors.idDepartamento}
                                input={<OutlinedInput label="Departamento" />}
                                MenuProps={MenuProps}
                              >
                                {optionsDepartamentos.map((name) => (
                                  <MenuItem key={name.value} value={name.value}>
                                    {name.label}
                                  </MenuItem>
                                ))}
                              </SelectMUI>
                            )}
                          />
                        </FormControl>

                        <Stack direction="row" spacing={2}>
                          <FormControl sx={{ flex: 1 }}>
                            <TextField
                              {...register("nome", { required: true })}
                              id="nome"
                              label="Nome da vaga"
                              variant="outlined"
                              size="small"
                              disabled={isSubmitting}
                              error={!!errors.nome}
                            />
                          </FormControl>
                        </Stack>
                        <Stack direction="row" spacing={2}></Stack>
                        <Stack direction={["column", "row"]} gap={2} pb={1}>
                          <FormControl fullWidth>
                            <TextField
                              {...register("funcoes")}
                              id="funcoes"
                              label="Funções"
                              variant="outlined"
                              multiline
                              rows={4}
                              size="small"
                              disabled={isSubmitting}
                              error={!!errors.funcoes}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              {...register("requisitos")}
                              multiline
                              rows={4}
                              id="requisitos"
                              label="Requisitos"
                              variant="outlined"
                              size="small"
                              disabled={isSubmitting}
                              error={!!errors.requisitos}
                            />
                          </FormControl>
                        </Stack>
                        <Stack direction={["column", "row"]} gap={2} pb={1}>
                          <FormControl fullWidth>
                            <TextField
                              {...register("beneficios")}
                              id="beneficios"
                              label="Beneficios"
                              variant="outlined"
                              multiline
                              rows={4}
                              size="small"
                              disabled={isSubmitting}
                              error={!!errors.beneficios}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <TextField
                              {...register("carga")}
                              multiline
                              rows={4}
                              id="carga"
                              label="Carga Horaria Semanal"
                              variant="outlined"
                              size="small"
                              disabled={isSubmitting}
                              error={!!errors.carga}
                            />
                          </FormControl>
                        </Stack>

                        <Box
                          display={"flex"}
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                          gap={2}
                        >
                          <Button
                            variant="outlined"
                            disabled={isSubmitting}
                            onClick={goBackList}
                            color="aprincipal"
                            type="button"
                          >
                            Voltar
                          </Button>
                          <Button
                            disabled={isSubmitting}
                            color="aprincipal"
                            variant="contained"
                            type="submit"
                          >
                            Salvar
                          </Button>
                        </Box>
                      </Stack>
                    </Paper>
                  </Box>
                )}
              </>
            </Box>
          )}
        </>
      )}
    </>
  );
};
