import React from "react";
import { Container } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";

export default function FilterBasesApp({
  basesDisponiveis,
  basesSelecionadas,
  filtrarBases,
  required,
}) {
  const animatedComponents = makeAnimated();

  const options = basesDisponiveis.map((item) => ({
    value: item.codBase,
    label: item.base,
  }));

  const defaultValue = options.filter((item) => {
    if (basesSelecionadas?.length > 0) {
      if (basesSelecionadas?.indexOf(item.value) >= 0) {
        return true;
      }
      return false;
    }
    return false;
  });

  const defaultValueVendedor = options.filter((item) => {
    if (item.value === basesSelecionadas[0]) {
      return item;
    } else {
      return false;
    }
  });

  const onChange = (value) => {
    if (required) {
      return filtrarBases(value.value);
    } else {
      if (value.length === 0) {
        const selecteds = options.map((item) => item.value);
        return filtrarBases(selecteds);
      }
      const selecteds = value.map((item) => item.value);
      filtrarBases(selecteds);
    }
  };

  return (
    <Container>
      {required ? (
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          value={defaultValueVendedor}
          options={options}
          placeholder="Selecione as base"
          onChange={onChange}
        />
      ) : (
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          value={defaultValue}
          isMulti
          options={options}
          placeholder="Selecione as base"
          onChange={onChange}
        />
      )}
    </Container>
  );
}
