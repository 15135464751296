import { Edit } from "@mui/icons-material";
import {
  ClickAwayListener,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";

export const OptionsTable = ({ onClose, onDelete, onEdit, base, ...props }) => {
  const handleEdit = (e) => {
    e.preventDefault();
    onEdit();
  };
  return (
    <Popper role={undefined} placement="bottom-start" transition {...props}>
      {({ TransitionProps, placement }) => {
        return (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClose}>
                <MenuList
                  autoFocusItem={props.open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  <MenuItem
                    sx={{
                      ":hover": {
                        color: "inherit",
                      },
                    }}
                    href={`vendas/vendas-por-cliente/${props.id}?base=${base}`}
                    onClick={handleEdit}
                    component="a"
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    Editar
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        );
      }}
    </Popper>
  );
};
