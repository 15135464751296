import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Title from "../../../../components/title";
import { PermissionsPage } from "../../../../util/permissionsPage";
import { useEffect, useRef, useState } from "react";
import { LoadingTable } from "../../../../components/Loading/LoadingTable";
import { NoData } from "../../../../components/NoData/NoData";
import { StyledTableCell } from "../../../franchise/components/StyledTableCell";
import SearchIcon from "@mui/icons-material/Search";

import { toast } from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../../components/SearchComponent/SearchComponent";
import { debounce } from "throttle-debounce";
import { useUserData } from "../../../../hooks/useUserData";
import { isEmptyObject } from "../../../../util/isEmptyObject";
import vacancies from "../../../../services/vacancies";

import ErrorToastHandler from "../../../../util/ErrorToastHandler";
import candidates from "../../../../services/candidates";
import { RowsCandidate } from "../components/RowsCandidate";

export const SeeCandidates = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { userData } = useUserData();
  const params = useParams();
  const [total, setTotal] = useState(0);
  const history = useHistory();
  const defaultValues = {
    page: 1,
    perPage: 10,
    searchTerm: "",
    status: 1,
    orderBy: "id",
    orderByType: "desc",
    idVaga: params.id,
  };

  const handleBack = () => {
    history.push("/rh/vagas/");
  };
  const [value, setValue] = useState(1);
  const [configParams, setConfigParams] = useState(defaultValues);
  const [data, setData] = useState([]);
  const inputRef = useRef(null);

  const handleChangePage = (event, newPage) => {
    inputRef.current.value = "";

    setConfigParams((old) => ({ ...old, page: newPage + 1 }));
  };
  const handleSearch = (ev) => {
    setConfigParams((old) => ({
      ...old,
      searchTerm: ev,
      page: 1,
      per_page: 10,
    }));
  };
  const handleChangeRowsPerPage = (event) => {
    setConfigParams((old) => ({
      ...old,
      perPage: +event.target.value,
      page: 1,
    }));
  };
  const handleDelete = async (id) => {
    try {
      await vacancies.delete(id);
      toast.success("Deletada com sucesso");
      const config = { ...configParams };
      setConfigParams(config);
    } catch (error) {
      toast.error("Ocorreu um erro ao deletar");
      console.log(error);
    }
  };
  const debounceFunc = debounce(500, handleSearch);
  console.log(params?.id);

  const getData = async () => {
    setIsLoadingData(true);
    try {
      const response = await candidates.list(params?.id, {
        params: configParams,
      });

      setData(response.data.data);
      setTotal(response.data.total);
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
    } finally {
      setIsLoadingData(false);
    }
  };
  useEffect(() => {
    if (!isEmptyObject(userData) && !!params?.id) {
      getData();
    }
  }, [configParams, userData, params]);

  const handleTogle = async (row) => {
    try {
      const status = !!+row.state ? 0 : 1;
      const response = await vacancies.togle({ id: row.id, status });
      setConfigParams((old) => ({ ...old }));
      toast.success(response.data.msg);
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
    } finally {
    }
  };

  const columnsHeading = [
    "Visualizar",
    "Nome",
    "Cidade",
    "Escolaridade",
    "Data Cadastro",
    "Telefone",
  ];
  return (
    <>
      <>
        <Title title="LISTA DE CANDIDATOS" />
        {PermissionsPage("list_store") && (
          <>
            <Box
              marginY={2}
              mx={2}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  sx={{
                    borderRadius: "4px",
                    borderColor: "#d6d6d6 !important",
                    border: "1px solid",
                  }}
                  disabled={isLoadingData}
                  placeholder="Pesquisar  "
                  inputProps={{
                    ref: inputRef,
                    "aria-label": "search",
                  }}
                  onChange={(e) => debounceFunc(e.target.value)}
                />
              </Search>
              <Button
                variant="outlined"
                onClick={handleBack}
                color="aprincipal"
                type="button"
              >
                Voltar
              </Button>
            </Box>

            {isLoadingData ? (
              <LoadingTable />
            ) : (
              <>
                <Box
                  mx={2}
                  sx={{
                    paddingBottom: "2rem",
                  }}
                >
                  {data.length <= 0 ? (
                    <Box
                      width={"100%"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <NoData />
                    </Box>
                  ) : (
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer>
                        <Table
                          stickyHeader
                          size={"small"}
                          aria-label="collapsible table"
                        >
                          <TableHead>
                            <TableRow>
                              {columnsHeading.map((item) => (
                                <StyledTableCell key={item}>
                                  {item}
                                </StyledTableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {data.length > 0 &&
                              data.map((row) => (
                                <RowsCandidate
                                  handleDelete={handleDelete}
                                  row={row}
                                  handleTogle={handleTogle}
                                />
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[
                          10,
                          25,
                          50,
                          100,
                          { label: "Todos", value: total },
                        ]}
                        component="div"
                        count={total}
                        rowsPerPage={configParams.perPage}
                        page={configParams.page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={"Linhas por pagina"}
                        SelectProps={{
                          inputProps: {
                            "aria-label": "rows per page",
                          },
                          native: true,
                        }}
                      />
                    </Paper>
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </>
    </>
  );
};
