import { Container } from "react-bootstrap";
import { PermissionsPage } from "../../util/permissionsPage";
import Title from "../../components/title";
import {
  Box,
  Button,
  Container as Cont,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";

import advancedFormat from "dayjs/plugin/advancedFormat";
import { useParams, useHistory } from "react-router-dom";

import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { api } from "../../services/api.v2";

import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";
import utc from "dayjs/plugin/utc";
import { useUserData } from "../../hooks/useUserData";
import { LoadingTable } from "../../components/Loading/LoadingTable";

dayjs.extend(advancedFormat);
dayjs.extend(utc);
export const EditInfluencer = () => {
  const { userData } = useUserData();

  const history = useHistory();
  const { slug } = useParams();
  const {
    control,
    formState: { errors, isSubmitting },
    register,
    getValues,
    reset,
    handleSubmit,
  } = useForm();
  const [isLoadingOptions, setIsLoadingOptions] = useState({
    state: false,
    city: false,
    profile: false,
  });
  const [optionsStates, setOptionsState] = useState([]);
  const [optionsCity, setOptionsCity] = useState([]);
  const [optionsProfile, setOptionsProfile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const selectedCityDisabled = !!getValues("uf_id");
  const getInfoInfluencer = async () => {
    try {
      const response = await api.get(`/franchise/find-info-influencer/${slug}`);

      reset({
        ...response.data,
        date_of_birth: dayjs(response.data.date_of_birth)
          .utc()
          .format("YYYY-MM-DD"),
        profile_indicate: response.data.profile_indicate.id,
      });
      getCitys(response.data.uf_id);
    } catch (error) {
      console.log(error);
      toast.error("Usuario não existe");
    }
  };
  const getState = async () => {
    setIsLoadingOptions((old) => ({ ...old, state: true }));

    try {
      const response = await axios.get(
        "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
      );
      const data = response.data;
      const formattedData = data.map((item) => ({
        id: item.id,
        name: `${item.sigla} - ${item.nome} `,
      }));
      setOptionsState(formattedData);
    } catch (error) {
      toast.error(`Error: ${error}`);
      console.log(error);
    } finally {
      setIsLoadingOptions((old) => ({ ...old, state: false }));
    }
  };
  const getProfileOptions = async () => {
    try {
      setIsLoadingOptions((old) => ({ ...old, profile: true }));

      const response = await api.get("/franchise/profile-indicate");
      setOptionsProfile(response.data);
    } catch (error) {
      toast.error(`Error: ${error}`);
      console.log(error);
    } finally {
      setIsLoadingOptions((old) => ({ ...old, profile: false }));
    }
  };

  const getCitys = async (id) => {
    try {
      setIsLoadingOptions((old) => ({ ...old, city: true }));
      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id}/municipios`
      );
      const dataFormatted = response.data.map((item) => ({
        id: item.id,
        name: item.nome,
      }));
      setOptionsCity(dataFormatted);
    } catch (error) {
      toast.error(`Error: ${error}`);
      console.log(error);
    } finally {
      setIsLoadingOptions((old) => ({ ...old, city: false }));
    }
  };
  const goBackList = () => history.push(`/franquia/lista-interresados/`);
  const handleSelectState = (id) => {
    getCitys(id);
  };
  useEffect(() => {
    if (userData?.nivelAcesso?.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [userData?.nivelAcesso]);
  const onSubmit = async (data) => {
    try {
      const objData = {
        ...data,
        uf: optionsStates.find((item) => item.id === data.uf_id).name,
        city: optionsCity.find((item) => item.id === data.city_id).name,
      };
      delete objData.user;
      delete objData.slug;
      await api.post(`/franchise/update-indicate/${slug}`, objData);
      toast.success("Influencer atualizado com sucesso.");
      goBackList();
    } catch (error) {
      toast.error(
        error.message || "Ocorreu um erro ao tentar atualizar as informações"
      );
    }
  };
  useEffect(() => {
    getProfileOptions();
    getState();
  }, []);

  useEffect(() => {
    getInfoInfluencer();
  }, []);

  return (
    <div className="container-app">
      {isLoading && (
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"90vh"}
        >
          <LoadingTable />
        </Box>
      )}
      {userData?.nivelAcesso?.length > 0 &&
        PermissionsPage("list_interested") && (
          <Container>
            <div className="container-conteudo">
              <Container className="containerFlexBox">
                <Title title="EDITAR INFORMAÇÕES DO INFLUENCIADOR" />
              </Container>

              <Cont
                sx={{
                  paddingBottom: 3,
                }}
              >
                <>
                  {!isLoading && (
                    <Stack
                      spacing={2}
                      component={"form"}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <FormControl>
                        <TextField
                          {...register("name", { required: true })}
                          id="first-name"
                          label="Nome completo"
                          variant="outlined"
                          size="small"
                          disabled={isSubmitting}
                          error={!!errors.name}
                        />
                      </FormControl>
                      <Stack direction="row" spacing={2}>
                        <FormControl size="small">
                          <TextField
                            {...register("cpf", { required: true })}
                            id="cpf"
                            label="CPF"
                            variant="outlined"
                            size="small"
                            disabled={isSubmitting}
                            error={!!errors.cpf}
                          />
                        </FormControl>
                        <FormControl
                          sx={{
                            minWidth: "180px",
                          }}
                        >
                          <TextField
                            id="date_of_birth"
                            type="date"
                            defaultValue={"'1997-06-01'"}
                            size="small"
                            disabled={isSubmitting}
                            error={!!errors.date_of_birth}
                            {...register("date_of_birth", { required: true })}
                            label="Data de nascimento"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                        <FormControl fullWidth size="small">
                          <InputLabel id="uf_id">Perfil</InputLabel>
                          <Controller
                            name="profile_indicate"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                error={!!errors.profile_indicate}
                                label="Perfil"
                                disabled={isSubmitting}
                                {...field}
                              >
                                {optionsProfile.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Stack>
                      <FormControl size="small">
                        <TextField
                          {...register("email", { required: true })}
                          id="email"
                          type="email"
                          variant="outlined"
                          label="Email"
                          disabled={isSubmitting}
                          size="small"
                          error={!!errors.email}
                        />
                      </FormControl>

                      <Stack direction="row" spacing={2}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="uf_id">Estado</InputLabel>

                          <Controller
                            name="uf_id"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                error={!!errors.uf_id}
                                disabled={
                                  isLoadingOptions.state || isSubmitting
                                }
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  handleSelectState(e.target.value);
                                }}
                                label="Estado"
                              >
                                {optionsStates.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Cidade
                          </InputLabel>

                          <Controller
                            name="city_id"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                error={!!errors.city_id}
                                disabled={
                                  isLoadingOptions.city ||
                                  !selectedCityDisabled ||
                                  isSubmitting
                                }
                                label="Cidade"
                                {...field}
                              >
                                {optionsCity.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Stack>
                      <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        gap={2}
                      >
                        <Button
                          variant="outlined"
                          disabled={isSubmitting}
                          onClick={goBackList}
                          type="button"
                        >
                          Voltar
                        </Button>
                        <Button
                          disabled={isSubmitting}
                          variant="contained"
                          type="submit"
                        >
                          Salvar
                        </Button>
                      </Box>
                    </Stack>
                  )}
                </>
              </Cont>
            </div>
          </Container>
        )}
    </div>
  );
};
