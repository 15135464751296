export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
export const formatCPF = (value) => {
  // Remove todos os caracteres não numéricos da string
  const numericString = value.replace(/\D/g, "");

  // Aplica a formatação com os pontos e hífen
  const formattedString = numericString.replace(
    /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
    "$1.$2.$3-$4"
  );

  return formattedString;
};

export function stringAvatar(name) {
  if (!name) return <></>;
  const nameParts = name.split(" ");

  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 120,
      height: 120,
    },
    children: `${nameParts[0][0]}${
      nameParts.length > 1 ? nameParts[1][0] : ""
    }`,
  };
}
