import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { converterNumero } from "../../util/loja";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { FormContext } from "../../pages/financeiro/metas/cadastro-metas";
import dayjs from "dayjs";
import { FaCircle } from "react-icons/fa";

export const TableSemanal = ({ open, data }) => {
  const { getValues } = useFormContext();

  const { periodsSelects } = useContext(FormContext);
  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{
        mt: 0.5,
        mb: 1,
        display: open ? "none" : "table",
      }}
    >
      <Table size="small" aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>Periodo</TableCell>
            <TableCell>Valor Vendas</TableCell>
            <TableCell>Ticket Medio</TableCell>
            <TableCell>Item por venda</TableCell>
            <TableCell>Clientes cadastrados</TableCell>
            <TableCell>Qtd Pedidos</TableCell>
            <TableCell>Dias uteis</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(periodsSelects).map((key, index) => {
            return (
              <TableRow>
                <TableCell>
                  {`${dayjs(periodsSelects[key]?.startDate).format(
                    "DD/MM"
                  )} - ${dayjs(periodsSelects[key]?.endDate).format("DD/MM")}`}
                  <Box component={"span"} ml={1}>
                    <FaCircle
                      fontSize={"10px"}
                      color={
                        !!getValues(
                          `${data.CodLoja}.periodos.${key}.metaEspecial`
                        )
                          ? "green"
                          : "red"
                      }
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  {converterNumero(
                    +getValues(`${data.CodLoja}.periodos.${key}.metaVenda`)
                  )}
                </TableCell>
                <TableCell>
                  {converterNumero(
                    +getValues(`${data.CodLoja}.periodos.${key}.metaTM`)
                  )}
                </TableCell>
                <TableCell>
                  {getValues(`${data.CodLoja}.periodos.${key}.metaQtdPedidos`)}
                </TableCell>
                <TableCell>
                  {getValues(
                    `${data.CodLoja}.periodos.${key}.metaItensPorVenda`
                  )}
                </TableCell>
                <TableCell>
                  {getValues(
                    `${data.CodLoja}.periodos.${key}.metaClientesCadastrados`
                  )}
                </TableCell>

                <TableCell>
                  {getValues(`${data.CodLoja}.periodos.${key}.diasUteis`)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
