import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";

export const OptionsMenu = ({ onClose, options = [], ...props }) => {
  
  return (
    <Popper role={undefined} placement="bottom-start" transition {...props}>
      {({ TransitionProps, placement }) => {
        return (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClose}>
                <MenuList
                  autoFocusItem={props.open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {options.map((item) => (
                    <MenuItem onClick={() => item.onClick()} key={item.id}>
                      {item.icon}
                      {item.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        );
      }}
    </Popper>
  );
};
