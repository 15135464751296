import React, { useEffect } from "react";
import NavBarAll from "../NavBarAll/NavBarAll";
import { Toaster } from "react-hot-toast";
import { Box } from "@mui/material";
export const ProviderRoutes = ({ children, title = "" }) => {
  useEffect(() => {
    if (!!title) {
      document.title = `BI | ${title.toUpperCase()}`;
    }
  }, [title]);
  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            background: "#333",
            color: "#fff",
          },
          success: {
            style: {},
          },
          error: {
            style: {
              background: "#333",
              color: "#fff",
            },
          },
        }}
        position="bottom-left"
        reverseOrder={false}
      />

      <NavBarAll children={children} />
    </>
  );
};
