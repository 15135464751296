import { toast } from "react-hot-toast";

class ErrorToastHandler {
  constructor(error, toastId = null) {
    this.error = error;
    this.toastId = toastId;
  }

  showErrorToast() {
    const id = !!this.toastId ? this.toastId : null;
    if (
      (this.error?.response && this.error?.response?.status === 400) ||
      this.error?.response?.status === 404
    ) {
      const errorMessage = this.error.response.data.message;
      toast.error(errorMessage, {
        id,
      });
    } else {
      toast.error("Ocorreu um erro ao carregar, por favor tente novamente", {
        id,
      });
    }
  }
}

export default ErrorToastHandler;
