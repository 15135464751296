import { ReactComponent as Logo } from "../../assets/nodata.svg";
import { Container } from "./styles";

export const NoData = ({ noLogo = true }) => {
  return (
    <Container>
      {noLogo && <Logo />}
      <p>Nenhum registro encontrado</p>
    </Container>
  );
};
