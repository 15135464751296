import React from "react";

const CellTablePorcentagem = ({
  rowInfo,
  desemp,
  desempPeriodoAnterior,
  desempAnterior = false,
}) => {
  return (
    <>
      {+rowInfo === 0 ? (
        <div style={{ color: "#FECA57" }}>
          {rowInfo.toLocaleString("pt-br", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + "%"}
        </div>
      ) : rowInfo < 0 ? (
        <div
          style={{ color: "#FF6B6B", fontWeight: "bold", whiteSpace: "nowrap" }}
        >
          {rowInfo.toLocaleString("pt-br", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + "%  "}
          &#9662;
        </div>
      ) : desemp ? (
        <div
          style={{ color: "#1DD1A1", fontWeight: "bold", whiteSpace: "nowrap" }}
        >
          {rowInfo
            ? rowInfo.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + "% "
            : "vazio"}
          &#9652;
        </div>
      ) : (
        <div>
          {rowInfo
            ? rowInfo.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + "%"
            : "vazio"}
        </div>
      )}
      {desempAnterior ? (
        <div
          style={{
            borderTop: "1px dashed #ABA8A7",
            marginTop: 5,
            paddingTop: 5,
          }}
        >
          {Number(desempPeriodoAnterior) === 0 ? (
            <div style={{ color: "#FECA57" }}>
              {desempPeriodoAnterior.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + "%"}
            </div>
          ) : desempPeriodoAnterior < 0 ? (
            <div
              style={{
                color: "#FF6B6B",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              {desempPeriodoAnterior.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + "%  "}
              &#9662;
            </div>
          ) : desemp ? (
            <div
              style={{
                color: "#1DD1A1",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              {desempPeriodoAnterior
                ? desempPeriodoAnterior.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + "% "
                : "vazio"}
              &#9652;
            </div>
          ) : (
            <div>
              {desempPeriodoAnterior
                ? desempPeriodoAnterior.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + "%"
                : "vazio"}
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default CellTablePorcentagem;
