import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "chartjs-plugin-datalabels";
import Title from "../../components/title";

import "react-data-table-component-extensions/dist/index.css";
import { arrayBases } from "../../util/loja";

import { Col, Row, Stack } from "react-bootstrap";
import toast from "react-hot-toast";
import { debounce as throttleDebounce } from "throttle-debounce";

import { PermissionsPage } from "../../util/permissionsPage";
import dayjs from "dayjs";
import DataTable from "react-data-table-component";
import { LoadingTable } from "../../components/Loading/LoadingTable";
import { DatePikerFilter } from "../../components/DatePikerFilter/DatePikerFilter";
import SearchIcon from "@mui/icons-material/Search";

import { NoData } from "../../components/NoData/NoData";
import { useUserData } from "../../hooks/useUserData";
import { api } from "../../services/api.v2";
import { SelectBases } from "../../components/SelectBases/SelectBases";
import {
  ExpandableRowsComponent,
  columnsTable1,
} from "../../column/vendas-por-clientes/vendas-por-clientes";
import { Box, Paper, Container as ContainerMui } from "@mui/material";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../components/SearchComponent/SearchComponent";

import { ButtonFilter } from "../../components/DatePikerFilter/styles";
import { formatShopAvailable } from "../../formatdata/formatter-shop-available";
import { MultiSelectLojas } from "../../components/MultiSelect/MultiSelectLojas/MultiSelectLojas";
import { useHistory } from "react-router-dom";
const customPaginationText = {
  rowsPerPageText: "Linhas por página:",
  rangeSeparatorText: "de",
  noRowsPerPageText: "Sem linhas por página",
  selectAllRowsItemText: "todas",
};
const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

export const VendasPorClientes = () => {
  const { userData } = useUserData();
  const accessLevel = userData?.nivelAcesso || [];
  const acessBase = userData?.baseAcesso || [];
  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);
  const [filterShop, setFilterShop] = useState([]);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [inputSeachValue, setInputSearchValue] = useState("");
  const [dataTable, setDataTable] = useState([]);
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const firstDayOfMonth = dayjs().startOf("month").format("YYYY-MM-DD");
  const currencyDay = dayjs().format("YYYY-MM-DD");
  const [totalRow, setTotalRow] = useState(0);
  const inputRef = useRef(null);
  const history = useHistory();
  const handleEdit = (row) => {
    history.push(
      `/vendas/vendas-por-cliente/${row.codCliente}?base=${paramsConfig.base}`
    );
  };
  const defaultParamsConfig = {
    base: "",
    perPage: 10,
    page: 1,
    searchTerm: "",
    first_period: firstDayOfMonth,
    final_period: currencyDay,
    order: "asc",
    orderId: "codCliente",
  };
  const [paramsConfig, setParamsConfig] = useState(defaultParamsConfig);
  const columns = columnsTable1(handleEdit, paramsConfig.base);
  useEffect(() => {
    if (isEmptyObject(userData)) return;
    buscarNivelAcesso();
  }, [userData]);

  const buscarNivelAcesso = () => {
    if (accessLevel.length > 0) {
      const bases = arrayBases(acessBase);
      setBasesDisponiveis(bases);
      const fristBase = bases.find((item) => item.codBase);
      const selectBaseInitial = [fristBase].map((item) => item.codBase);

      const shopsAvailableForBase = formatShopAvailable(
        selectBaseInitial,
        accessLevel
      );
      setLojasDisponiveis(accessLevel);
      setFilterShop(shopsAvailableForBase);
      setParamsConfig((old) => ({
        ...old,
        base: fristBase.codBase,
        stores: shopsAvailableForBase.map((item) => item.value),
      }));
    }
  };
  const handleCleanFilter = () => {
    inputRef.current.value = "";
    const shopsAvailableForBase = formatShopAvailable(
      [paramsConfig.base],
      accessLevel
    );
    setLojasDisponiveis(shopsAvailableForBase);
    setFilterShop(shopsAvailableForBase);
    setParamsConfig((old) => ({
      ...defaultParamsConfig,
      base: old.base,
      stores: shopsAvailableForBase.map((item) => item.value),
    }));
  };

  async function preencherDados() {
    window.scrollTo(0, 0);
    const toastId = toast.loading("Esperando dados do RAD");

    setIsLoadingData(true);
    try {
      const response = await api.get("/customer/rfv", {
        params: {
          codBase: paramsConfig.base,
          perPage: paramsConfig.perPage,
          page: paramsConfig.page,
          searchTerm: paramsConfig.searchTerm,
          first_period: paramsConfig.first_period,
          final_period: paramsConfig.final_period,
          order: paramsConfig.order,
          orderId: paramsConfig.orderId,
          stores: paramsConfig.stores,
        },
      });
      if (response.status === 200) {
        const data = response.data.data;
        setTotalRow(response.data.total);
        setDataTable(data);
        toast.success("Dados recebidos", {
          duration: 5000,
          id: toastId,
        });
      }
    } catch (error) {
      if (
        (error?.response && error?.response?.status === 400) ||
        error?.response?.status === 404
      ) {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage, {
          id: toastId,
        });
      } else {
        toast.error("Ocorreu um erro ao carregar, por favor tente novamente", {
          id: toastId,
        });
      }
    } finally {
      setIsLoadingData(false);
    }
  }

  useEffect(() => {
    if (!paramsConfig?.base) return;
    preencherDados();
  }, [paramsConfig]);

  const onChangeDatePicker = (value) => {
    setParamsConfig((old) => ({ ...old, ...value }));
  };
  const onChangeParamsConfig = (value, name) => {
    setParamsConfig((old) => ({ ...old, [name]: value }));
  };

  const onChangeParamsConfigBase = (value, name) => {
    const shopsAvailableForBase = formatShopAvailable([value], accessLevel);
    setLojasDisponiveis(shopsAvailableForBase);
    setFilterShop(shopsAvailableForBase);
    setParamsConfig((old) => ({
      ...old,
      [name]: value,
      stores: shopsAvailableForBase.map((item) => item.value),
    }));
  };
  function debounce(func, wait = 500) {
    let timeout;
    function debounced(...args) {
      const later = () => {
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    }

    debounced.clear = () => {
      clearTimeout(timeout);
    };

    return debounced;
  }

  const onAccept = useCallback((newValue) => {
    onChangeParamsConfig(newValue, "stores");
  }, []);
  const deboucedOnAccept = useMemo(() => debounce(onAccept, 1500), [onAccept]);
  const handleMultiSelectFilterShop = (value) => {
    const storesFilter = value.map((item) => item.value);
    setFilterShop(value);
    deboucedOnAccept(storesFilter);
  };

  const handleSearch = (ev) => {
    setParamsConfig((old) => ({
      ...old,
      searchTerm: ev,
      page: 1,
      perPage: 10,
    }));
  };
  const debounceFunc = throttleDebounce(500, (value) => {
    handleSearch(value);
  });

  const handleSort = async (column, sortDirection) => {
    setParamsConfig((old) => ({
      ...old,
      order: sortDirection,
      orderId: column.selector,
    }));
  };

  return (
    <>
      {PermissionsPage("vendGrupo") && (
        <>
        <Box ml={2}>

          <Title title="Analise Por Cliente" />
        </Box>

          <Row
            className="justify-content-md-center"
            style={{ padding: "0 1rem" }}
          >
            <DatePikerFilter
              paramsConfig={paramsConfig}
              onChange={onChangeDatePicker}
              isLoading={isLoadingData}
            />
            <Col>
              <Stack gap={2}>
                <SelectBases
                  basesDisponiveis={basesDisponiveis}
                  onChange={onChangeParamsConfigBase}
                  paramsConfig={paramsConfig}
                  isLoading={isLoadingData}
                />
                <MultiSelectLojas
                  lojas={lojasDisponiveis}
                  onChange={handleMultiSelectFilterShop}
                  paramsConfig={paramsConfig}
                  isLoading={isLoadingData}
                  shops={filterShop}
                  baseUnique
                />
                <Box
                  display={"flex"}
                  width={"100%"}
                  justifyContent={"space-between"}
                >
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      sx={{
                        borderRadius: "4px",
                        borderColor: "hsl(0,0%,76%) !important",
                        border: "1px solid",
                        marginLeft: "0px",
                      }}
                      disabled={isLoadingData}
                      placeholder="Pesquisar  "
                      inputProps={{
                        ref: inputRef,
                        "aria-label": "search",
                      }}
                      onChange={(e) => {
                        debounceFunc(e.target.value);
                      }}
                    />
                  </Search>
                  <ButtonFilter
                    style={{
                      width: "fit-content",
                      fontSize: ".8rem",
                      fontWeight: "500",
                    }}
                    onClick={handleCleanFilter}
                    disabled={isLoadingData}
                  >
                    LIMPAR FILTROS
                  </ButtonFilter>
                </Box>
              </Stack>
            </Col>
          </Row>
          <Box display={"flex"} ml={2}></Box>
          <Box
            component={Paper}
            elevation={dataTable?.length === 0 || isLoadingData ? 0 : 1}
            m={2}
            pb={isLoadingData ? 8 : 0}
          >
            <DataTable
              columns={columns}
              data={dataTable}
              expandableRows
              progressPending={isLoadingData}
              expandableRowsComponent={ExpandableRowsComponent}
              progressComponent={<LoadingTable />}
              pagination
              paginationServer
              paginationTotalRows={totalRow}
              onChangeRowsPerPage={(e) => onChangeParamsConfig(e, "perPage")}
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
              paginationComponentOptions={{
                rowsPerPageText: customPaginationText.rowsPerPageText,
                rangeSeparatorText: customPaginationText.rangeSeparatorText,
                noRowsPerPageText: customPaginationText.noRowsPerPageText,
                selectAllRowsItemText:
                  customPaginationText.selectAllRowsItemText,
              }}
              onSort={handleSort}
              onChangePage={(e) => onChangeParamsConfig(e, "page")}
              noDataComponent={<NoData />}
            />
          </Box>
        </>
      )}
    </>
  );
};
