import dayjs from "dayjs";
import { converterNumero } from "../../util/loja";

export const columnsTableGroupsInventory = [
  {
    selector: "grupo",
    name: <strong style={{ fontSize: "1rem" }}>Estoque / Grupo</strong>,
    cell: (row) => {
      if (dayjs(row.grupo).isValid()) {
        return <strong>{dayjs(row.grupo).format("DD/MM/YYYY")}</strong>;
      }
      return <strong>{row.grupo}</strong>;
    },
  },
  {
    selector: "venda",
    name: <strong style={{ fontSize: "1rem" }}>Venda</strong>,
    cell: (row) => converterNumero(row.venda),
  },
  {
    selector: "custo",
    name: <strong style={{ fontSize: "1rem" }}>Custo</strong>,
    cell: (row) => converterNumero(row.custo),
  },
  {
    selector: "porcentagem",
    name: <strong style={{ fontSize: "1rem" }}>%</strong>,
    cell: (row) => `${row.porcentagem.toFixed(2)} %`,
  },
  {
    selector: "media",
    name: <strong style={{ fontSize: "1rem" }}>Media</strong>,
    cell: (row) => `${row.media.toFixed(2)}`,
  },
];
