import { Container } from "react-bootstrap";

import {
  Box,
  Button,
  Container as Cont,
  FormControl,
  FormControlLabel,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { useParams, useHistory } from "react-router-dom";

import { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import toast from "react-hot-toast";

import { LoadingTable } from "../../../../components/Loading/LoadingTable";
import { PermissionsPage } from "../../../../util/permissionsPage";
import Title from "../../../../components/title";
import { api } from "../../../../services/api.v2";
import { IOSSwitch } from "../components/IOSSwitch";

export const CreateProfileIndicate = () => {
  const history = useHistory();
  const params = useParams();
  const {
    formState: { errors, isSubmitting },
    register,
    control,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: {
      active: true,
    },
  });

  const [isLoading, setIsLoading] = useState(true);

  const getInfoBase = async () => {
    setIsLoading(true);

    try {
      const response = await api.get(
        `/register/profile-indicate/info/${params.id}`
      );

      reset({
        ...response.data,
      });
    } catch (error) {
      console.log(error);
      toast.error("Perfil não existe");
      goBackList();
    } finally {
      setIsLoading(false);
    }
  };

  const goBackList = () => history.push(`/cadastro/perfil-indicador/`);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const dataSend = { ...data, active: data.active ? 1 : 0 };

      if (params?.id) {
        await api.patch(
          `/register/profile-indicate/update/${params.id}`,
          dataSend
        );
      } else {
        await api.post("/register/profile-indicate/create", dataSend);
      }

      toast.success("Informações salvas com sucesso");
      goBackList();
    } catch (error) {
      if (
        (error?.response && error?.response?.status === 400) ||
        error?.response?.status === 404
      ) {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      } else {
        toast.error("Erro ao tentar processar seus dados");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getInfoBase();
    } else {
      setIsLoading(false);
    }
  }, [params?.id]);

  return (
    <div className="container-app">
      {isLoading && (
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"90vh"}
        >
          <LoadingTable />
        </Box>
      )}
      {!isLoading && PermissionsPage("list_profile_indicate") && (
        <Container>
          <div className="container-conteudo">
            <Container className="containerFlexBox">
              <Title title="Cadastar Informações do Perfil do Indicador" />
            </Container>

            <Box
              sx={{
                px: 3,
                paddingBottom: 3,
              }}
            >
              <>
                {!isLoading && (
                  <Box display={"flex"} gap={4} sx={{}}>
                    <Box maxWidth={"50%"}>
                      <Typography variant="h6">
                        Por favor, preencha todos os campos do formulário com as
                        informações sobre o Perfil de indicação da pagina de
                        franquia.
                      </Typography>
                      <Typography pt={2} variant="body1">
                        É imprescindível fornecer todas as informações
                        solicitadas para garantir o correto funcionamento das
                        indicações da pagina de franquia.
                      </Typography>
                    </Box>
                    <Paper
                      sx={{
                        p: 2,
                        width: "100%",
                      }}
                      variant="outlined"
                      elevation={3}
                    >
                      <Stack
                        spacing={2}
                        component={"form"}
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <Stack spacing={2}>
                          <FormControl
                            sx={{
                              paddingLeft: 2,
                            }}
                          >
                            <Controller
                              name="active"
                              control={control}
                              defaultValue={true}
                              render={({ field }) => (
                                <Stack direction={"row"}>
                                  <FormControlLabel
                                    control={<IOSSwitch {...field} />}
                                    checked={field.value}
                                  />
                                  <Typography component={"span"}>
                                    {field.value ? "Ativo" : "Desativo"}
                                  </Typography>
                                </Stack>
                              )}
                            />
                          </FormControl>
                          <FormControl
                            sx={{
                              flex: 1,
                            }}
                          >
                            <TextField
                              {...register("name", {
                                required: true,
                              })}
                              id="name"
                              label="Nome do perfil de indicação"
                              variant="outlined"
                              size="small"
                              disabled={isSubmitting}
                              error={!!errors.name}
                            />
                          </FormControl>
                        </Stack>

                        <Box
                          display={"flex"}
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                          gap={2}
                          bottom={2}
                        >
                          <Button
                            variant="outlined"
                            disabled={isSubmitting}
                            onClick={goBackList}
                            type="button"
                          >
                            Voltar
                          </Button>
                          <Button
                            disabled={isSubmitting}
                            variant="contained"
                            type="submit"
                          >
                            Salvar
                          </Button>
                        </Box>
                      </Stack>
                    </Paper>
                  </Box>
                )}
              </>
            </Box>
          </div>
        </Container>
      )}
    </div>
  );
};
