import Select from "react-select";
export const SelectBases = ({
  basesDisponiveis,
  onChange,
  isLoading,
  paramsConfig,
}) => {
  const options = basesDisponiveis.map((item) => ({
    value: item.codBase,
    label: item.base,
  }));

  const filterBase = basesDisponiveis.find(
    (item) => +item?.codBase === +paramsConfig?.base
  );

  const value = {
    value: filterBase?.codBase,
    label: filterBase?.base,
  };

  const handleSelect = (data) => {
    onChange(data.value, 'base');
  };
  return (
    <>
      <strong className="font-weight-bold">Base</strong>
      <Select
        onChange={handleSelect}
        value={value}
        options={options}
        isDisabled={isLoading}
      />
    </>
  );
};
