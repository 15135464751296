import { Box, Grid, Paper, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { LiaBoxesSolid } from "react-icons/lia";

export const CardIconHome = ({ value, label, icon =  <LiaBoxesSolid fontSize="2rem" color={grey[700]} /> }) => {
  return (
    <>
      <Grid item xs={[6]}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          p={1}
          alignItems={"flex-end"}
          justifyContent={"space-between"}
          component={Paper}
          elevation={1}
        >
          <Typography variant="h6" textTransform={'capitalize'} component={"span"} fontWeight={"normal"}>
            {label}
          </Typography>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            {icon}
            <Typography component={"h5"} variant="h6">
              {value}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
