import React from "react";
import { BrowserRouter, Switch, Route, withRouter } from "react-router-dom";
import { route } from "./route";
import MetasApp from "../pages/metas/metas-gerais-app";
import MetasVendedorApp from "../pages/metas/metas-vendedor-app";
import { AuthProvider } from "../hooks/useAuth";
import { UserDataProvider } from "../hooks/useUserData";
import { ProviderRoutes } from "../components/ProviderRoutes/ProviderRoutes";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "../styles/themes";
import { Unauthorized } from "../pages/Unauthorized";

function Routes() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <BrowserRouter basename="/">
          <Switch>
            <Route
              path="/metas/metas-gerais-app/:id?/:token?"
              exact
              component={withRouter(MetasApp)}
            />

            <Route
              path="/metas/metas-vendedor-app/:id?/:token?"
              exact
              component={withRouter(MetasVendedorApp)}
            />

            <Route path="/401" exact component={Unauthorized} />
            <UserDataProvider>
              {route.map((item, index) => {
                return (
                  <Route
                    key={index}
                    exact={item.exact}
                    path={item.path}
                    render={() => (
                      <ProviderRoutes title={item?.title}>
                        {item.component}
                      </ProviderRoutes>
                    )}
                  />
                );
              })}
            </UserDataProvider>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default Routes;
