import { api } from "./api.v2";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: async (params) => {
    return await api.get("/jobs", params);
  },
  get: async (id) => {
    return await api.get(`/jobs/${id}`);
  },
  create: async (data) => {
    return await api.post("/jobs", data);
  },
  update: async (id, data) => {
    return await api.put(`/jobs/${id}`, data);
  },
  togle: async (id) => {
    return await api.put(`/jobs/${id}/status`);
  },
  delete: async (id) => {
    return await api.delete(`/jobs/${id}`);
  },
};
