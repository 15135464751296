import { TableCell, TableRow, Typography } from "@mui/material";
import { formatarNumero } from "../../../../util/loja";
import { useState } from "react";
import { Stack } from "react-bootstrap";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

export const RowsRender = ({
  openCollapseCollumn,
  dataPage,
  row: { collapse, contas, order, ...row },
}) => {
  const [open, setOpen] = useState(false);
  if (row?.noHide) return <></>;

  let formula = false;
  let bgcolor = false;

  if (row?.formula) {
    formula = row?.formula;
    bgcolor = row?.bg;
    delete row?.formula;
    delete row?.bg;
    delete row?.fontWeight;
  }
  delete row.noHide;
  return (
    <>
      <TableRow
        key={row.id}
        onClick={!!collapse ? () => setOpen((old) => !old) : null}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          display: openCollapseCollumn && !row?.isTotal ? "table-row" : "none",
          fontWeight: "500",
          cursor: !!collapse && "pointer",
        }}
      >
        <TableCell
          sx={{
            whiteSpace: "nowrap",
            fontWeight: "500",
            bgcolor: "gray",
          }}
          component="th"
          scope="row"
        >
          <Stack gap={1} justifyContent={"space-between"} direction={"row"}>
            <Typography
              color={"white"}
              fontSize={"0.875rem"}
              fontWeight={500}
              component={"p"}
            >
              {collapse ? (
                <Stack gap={1} direction={"row"} alignItems={"center"}>
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  <Typography fontSize={"0.875rem"} fontWeight={"500"}>
                    {contas}
                  </Typography>
                </Stack>
              ) : (
                contas
              )}
            </Typography>
            <Typography
              color={"white"}
              fontSize={"0.875rem"}
              component={"span"}
            >
              {order}
            </Typography>
          </Stack>
        </TableCell>

        {Object.keys(row)?.map((key, index) => {
          const value = row[dataPage.colunas[index + 2]?.idC];
          if (!value) {
            return (
              <TableCell
                sx={{
                  whiteSpace: "nowrap",
                  fontWeight: 500,
                  bgcolor: !!bgcolor && bgcolor,
                }}
                key={key}
                component="th"
                scope="row"
              ></TableCell>
            );
          }

          return (
            <TableCell
              sx={{
                whiteSpace: "nowrap",
                fontWeight: 500,
                bgcolor: !!bgcolor && bgcolor,
              }}
              key={key}
              component="th"
              scope="row"
            >
              <Typography color={parseFloat(row[key]) < 0 && "red"}>
                {formatarNumero(value)}
              </Typography>
              {formula && (
                <Typography color={"GrayText"} variant="caption">
                  ({formula})
                </Typography>
              )}
            </TableCell>
          );
        })}
      </TableRow>
      {!!collapse && open && (
        <>
          {collapse?.map(({ contas, ...coll }) => {
            delete coll.codGrupo;

            return (
              <TableRow>
                <TableCell>{contas}</TableCell>
                {Object.keys(coll).map((chave, index) => {
                  const indice = dataPage.colunas[index + 2].id;
                  return <TableCell>{coll[indice]}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </>
      )}
    </>
  );
};
