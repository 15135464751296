import { toast } from "react-hot-toast";

export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast.success("Copiado para a área de transferência.");
    })
    .catch(() => {
      toast.error("Erro ao copiar para a área de transferência.");
    });
};
