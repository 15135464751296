import { Button } from "react-bootstrap";
import styled from "styled-components";

export const Section = styled.div`
  padding: 1rem;

  color: #828282;
  border: 1px solid;
  border-color: hsl(0, 0%, 80%);
  border-radius: 8px;
  max-width: 15rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
  span {
    display: flex;
    justify-content: space-between;
    p {
      padding-right: 1.5rem;
    }
    p.ate {
      padding-right: 1.23rem;
    }
    div {
      div {
        input {
          max-width: 9.8rem;
          border: 1px solid;
          border-color: hsl(0, 0%, 80%);
          border-radius: 4px;
        }
      }
    }
  }
`;

export const ButtonToday = styled(Button)`
  background-color: #828282;
  border-color: #828282;

  &:hover {
    background-color: #686868;
    border-color: #686868;
  }
  &:disabled {
    background-color: #828282de;
    border-color: #828282de;
    color: #b3b3b3;
    cursor: not-allowed;
  }
`;

export const ButtonFilter = styled(Button)`
  border-color: hsl(0, 0%, 80%);
  background-color: #fff;
  color: #828282;

  &:hover {
    background-color: #828282de;
    border-color: hsl(0, 0%, 80%);
  }
  &:disabled {
    background-color: #fff;
    border-color: #828282de;
    color: #b3b3b3;
    cursor: not-allowed;
  }
`;
