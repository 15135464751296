import { Box, Button, Stack } from "@mui/material";
import Title from "../../components/title";
import { Controller, useForm } from "react-hook-form";
import { api } from "../../services/api.v2";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { toast } from "react-hot-toast";
import { LoadingTable } from "../../components/Loading/LoadingTable";
import { FormularioPrincipal } from "../../components/componentsEditarClientes/FormularioPrincipal";
import { Dependentes } from "../../components/componentsEditarClientes/Dependentes";
import { Gestante } from "../../components/componentsEditarClientes/Gestante";
import { storageStoresGet } from "../../storage/storageStores";
dayjs.extend(advancedFormat);
dayjs.extend(utc);
export const EditarCliente = () => {
  const {
    register,
    getValues,
    reset,
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm();
  const params = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [availableStores, setAvailableStores] = useState([]);
  const getOldData = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    window.scrollTo(0, 0);
    const toastId = toast.loading("Esperando dados do RAD");
    // Obtém o valor do parâmetro 'id' da URL
    const base = searchParams.get("base");

    try {
      setIsLoading(true);
      const response = await api.get(`/customers/${params.id}`, {
        params: { codBase: base },
      });
      const data = response.data;
      const dadosDependentes = {};
      data.dadosDependentes.forEach((item) => {
        item = {
          ...item,
          nascimento: dayjs.utc(item.nascimento).format("YYYY-MM-DD"),
          datanascimentoprevisto: dayjs
            .utc(item.datanascimentoprevisto)
            .format("YYYY-MM-DD"),
        };
        dadosDependentes[item.id] = item;
      });

      let dadosGestante = {};
      if (data?.dadosGestante) {
        dadosGestante = {
          ...data.dadosGestante,
          datanascimentoprevisto: dayjs(
            data.dadosGestante.datanascimentoprevisto
          ).isValid()
            ? dayjs
                .utc(data.dadosGestante.datanascimentoprevisto)
                .format("YYYY-MM-DD")
            : null,
          nascimento: dayjs(data.dadosGestante.nascimento).isValid()
            ? dayjs.utc(data.dadosGestante.nascimento).format("YYYY-MM-DD")
            : null,
        };
      }
      reset({
        ...data,
        dataCadastro: dayjs.utc(data.dataCadastro).format("YYYY-MM-DD"),
        dataNascimento: dayjs.utc(data.dataNascimento).format("YYYY-MM-DD"),
        ultimaCompra: dayjs.utc(data.ultimaCompra).format("YYYY-MM-DD"),
        dadosDependentes,
        dadosGestante,
      });
      toast.success("Dados obtidos com sucesso", {
        id: toastId,
      });
    } catch (error) {
      if (
        (error?.response && error?.response?.status === 400) ||
        error?.response?.status === 404
      ) {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage, {
          id: toastId,
        });
      } else {
        toast.error("Ocorreu um erro ao carregar, por favor tente novamente", {
          id: toastId,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getOldData();
    const stores = storageStoresGet();
    if (!!stores) {
      setAvailableStores(
        stores.map((item) => ({
          name: `${item.sigla} - ${item.loja}`,
          id: item.id,
        }))
      );
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      const dadosDependentes = [];
      Object.keys(data.dadosDependentes).forEach((key) => {
        data.dadosDependentes[key].previsaoParto = dayjs(
          data.dadosDependentes[key]?.datanascimentoprevisto
        ).isValid()
          ? dayjs(data.dadosDependentes[key].datanascimentoprevisto).format(
              "YYYY-MM-DD"
            )
          : null;

        data.dadosDependentes[key].nascimento = dayjs(
          data.dadosDependentes[key]?.nascimento
        ).isValid()
          ? dayjs(data.nascimento).format("YYYY-MM-DD")
          : null;
        data.dadosDependentes[key].semanasGestacao =
          data.dadosDependentes[key].semanasgestacao;

        delete data.dadosDependentes[key].semanasgestacao;
        delete data.dadosDependentes[key].datanascimentoprevisto;
        dadosDependentes.push(data.dadosDependentes[key]);
      });

      const dadosGestante = {
        nome: data.dadosGestante.nome,
        sexo: data.dadosGestante.sexo,
        previsaoParto: dayjs(
          data.dadosGestante.datanascimentoprevisto
        ).isValid()
          ? dayjs(data.dadosGestante.datanascimentoprevisto).format(
              "YYYY-MM-DD"
            )
          : null,
        semanasGestacao: data.semanasgestacao,
        nascimento: dayjs(data.dadosGestante.nascimento).isValid()
          ? dayjs(data.dadosGestante.nascimento).format("YYYY-MM-DD")
          : null,
        parentesco: data.dadosGestante.grauParentesco,
      };

      const newData = {
        ...data,
        dadosDependentes,
        nome: data.nomeCliente,
        nascimento: dayjs(data.dataNascimento).format("YYYY-MM-DD"),
        telefone: data.fone1,
        dadosGestante,
        loja: data.codLoja,
        logradouro: data.endereco,
        numero: data.numeroEndereco,
        sexo: data.sexo === "F" ? 0 : data.sexo === "M" ? 1 : 2,
      };
      delete newData.codLoja;
      delete newData.nomeCliente;
      delete newData.dataNascimento;
      delete newData.fone1;
      delete newData.endereco;
      delete newData.numeroEndereco;
      await api.put("/customers", newData);
      history.push('/vendas/vendas-por-cliente');
      toast.success("Salvo com sucesso");
    } catch (error) {
      if (
        (error?.response && error?.response?.status === 400) ||
        error?.response?.status === 404
      ) {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      } else {
        toast.error("Erro ao tentar processar seus dados");
      }
    }
  };
  return (
    <>
      <Box mx={2}>
        <Title title="Editar cliente" />

        {isLoading ? (
          <>
            <LoadingTable />
          </>
        ) : (
          <Stack component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <FormularioPrincipal
              control={control}
              register={register}
              Controller={Controller}
              setValue={setValue}
              setError={setError}
              errors={errors}
              availableStores={availableStores}
            />
            {
              <Gestante
                data={getValues("dadosGestante")}
                control={control}
                reset={reset}
                getValues={getValues}
                register={register}
                Controller={Controller}
              />
            }
            {!!getValues("dadosDependentes") && (
              <Dependentes
                data={getValues("dadosDependentes")}
                control={control}
                reset={reset}
                getValues={getValues}
                register={register}
                Controller={Controller}
              />
            )}

            <Stack
              justifyContent={"flex-end"}
              mb={1}
              width={"100%"}
              direction={"row"}
              gap={1}
            >
              <Button
                onClick={() => history.push("/vendas/vendas-por-cliente/")}
                variant="outlined"
                color="aprincipal"
              >
                Voltar
              </Button>
              <Button variant="contained" color="aprincipal" type="submit">
                Salvar
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
    </>
  );
};
