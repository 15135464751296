import { metasService } from "../services/metasServices";
import { converterLoja, preencherPeriodo } from "./loja";

async function preencherPeriodoReferencia() {
  try {
    const result = await metasService.periodosDisponiveis();

    let periodos = [];
    if (
      result?.data?.success &&
      result?.data?.type &&
      result?.data?.data?.periodos?.length > 0
    ) {
      var dataAux = result?.data.data.periodos;
      var periodoAtual = "";
      let dataAtual = new Date();
      dataAux.map((item, index) => {
        let dataInicioTemp = new Date(item.periodoInicio);
        if (
          periodoAtual === "" &&
          dataAtual.getFullYear() === dataInicioTemp.getFullYear() &&
          dataAtual.getMonth() === dataInicioTemp.getMonth()
        ) {
          periodoAtual = item.id;
        }
        periodos.push({ periodo: item.periodo, id: item.id });
      });
      if (periodoAtual === "") {
        periodoAtual = dataAux[0].id;
      }
      return { periodoAtual };
    } else {
      return preencherPeriodosCasoErro();
    }
  } catch (error) {
    return preencherPeriodosCasoErro();
  }
}
export async function preencherDados(nivelAcesso, baseAcesso) {
  const { inicio: periodoInputInicio, fim: periodoInputFim } =
    preencherPeriodo();
  const { periodoAtual: periodoReferencia } =
    await preencherPeriodoReferencia();
  try {
    const { data } = await metasService.listarMetas({
      periodo: periodoReferencia,
      nivelAcesso: nivelAcesso,
      baseAcesso,
      periodoInputInicio,
      periodoInputFim,
    });
    if (!data?.data) {
      return;
    }
    // return;
    if (data?.success && data?.type) {
      let dataTodasLojas = [];
      let dataInicioTemp = "";
      let dataFimTemp = "";

      data.data.map((a) => {
        let respostaLojas = a.response;
        if (respostaLojas) {
          let dataRespostaLojas = respostaLojas.data?.realizadaPorLoja;
          dataInicioTemp =
            dataInicioTemp !== "" ? dataInicioTemp : respostaLojas.inicio;
          dataFimTemp = dataFimTemp !== "" ? dataFimTemp : respostaLojas.fim;
          if (dataRespostaLojas?.length > 0) {
            dataRespostaLojas.map((b) => {
              dataTodasLojas.push(b);
            });
          }
        }
      });

      return processaDadosLoja(dataTodasLojas, dataInicioTemp, dataFimTemp);
    }
  } catch (error) {
    console.log({ error });
  }
}

function preencherDiasUteis(
  inicio,
  fim,
  diasRestantes = false,
  diasRemovidos = false
) {
  const { inicio: periodoInputInicio, fim: periodoInputFim } =
    preencherPeriodo();
  if (diasRestantes && (!periodoInputInicio || !periodoInputFim)) {
    var dataInicio = new Date();
    var dataFim = new Date(fim);
  } else if (diasRestantes && periodoInputInicio && periodoInputFim) {
    var dataInicio = new Date(
      periodoInputFim.replace("-", "/").replace("-", "/").replace("-", "/")
    );
    var dataFim = new Date(fim);
  } else if (diasRestantes) {
    var dataInicio = new Date();
    var dataFim = new Date(fim);
  } else {
    if (periodoInputInicio && periodoInputFim) {
      var dataInicio = new Date(
        periodoInputInicio.replace("-", "/").replace("-", "/").replace("-", "/")
      );
      var dataFimAux = new Date(periodoInputFim);
    } else {
      var dataInicio = new Date(inicio);
      var dataFimAux = new Date(fim);
    }
    var dataFim = new Date();
    if (dataFim > dataFimAux) dataFim = dataFimAux;
    if (!periodoInputInicio || !periodoInputFim) {
      dataFim.setDate(dataFim.getDate() - 1);
    }
  }
  dataFim.setHours(23);
  dataFim.setMinutes(59);
  dataFim.setSeconds(59);
  var dataAtual = dataInicio;
  var diasUteis = 0;

  while (dataAtual <= dataFim) {
    // if (dataAtual.getDay() != 0) {
    //     diasUteis++
    // }
    let ano = dataAtual.getFullYear();
    let mes =
      dataAtual.getMonth() + 1 < 10
        ? "0" + (dataAtual.getMonth() + 1)
        : dataAtual.getMonth() + 1;
    let dia =
      dataAtual.getDate() < 10
        ? "0" + dataAtual.getDate()
        : dataAtual.getDate();
    let dataAtualTemp = ano + "" + mes + "" + dia;
    if (diasRemovidos && diasRemovidos.indexOf(dataAtualTemp) < 0) {
      diasUteis++;
    }
    dataAtual.setDate(dataAtual.getDate() + 1);
  }
  return diasUteis;
}

function processaDadosLoja(dataTodasLojas, dataInicioTemp, dataFimTemp) {
  let metaSugerida = [];
  let metaOuroSugerida = [];
  let metaDiamanteSugerida = [];
  let metaRealizada = [];
  let labelNomes = [];
  let labelNomesParaFiltro = [];
  let dadosTabela = [];

  let projetada = [];
  let desconto = [];
  let labelCod = [];
  let descontoParaGrafico = [];
  let totalMeta = 0;
  let totalRealizada = 0;

  let auxTotalRealizado = 0;
  var dataMetas = dataTodasLojas;
  dataMetas = dataMetas.sort((a, b) => a.CodLoja - b.CodLoja);
  //let realizadaPorLoja = data.data.data.realizadaPorLoja;
  dataMetas = dataMetas.sort((a, b) => a.CodLoja - b.CodLoja);
  if (dataMetas.length > 0) {
    dataMetas.map((item) => {
      auxTotalRealizado += parseFloat(item.MetaRealizada);
    });
    auxTotalRealizado = auxTotalRealizado > 0 ? auxTotalRealizado : 1;
    // setDataMetasFromApi(dataMetas);
    if (dataMetas && dataMetas.length > 0) {
      dataMetas = dataMetas.sort((a, b) => b.MetaRealizada - a.MetaRealizada);
      dataMetas.map(async (item) => {
        labelNomes.push(converterLoja(item.CodLoja));
        labelCod.push(item.CodLoja);
        metaSugerida.push(parseFloat(item.MetaProjetada));
        metaOuroSugerida.push(parseFloat(item.MetaProjetada) * 1.15);
        metaDiamanteSugerida.push(parseFloat(item.MetaProjetada) * 1.3);
        metaRealizada.push(item.MetaRealizada);
        let diasRemovidos = [];
        if (item.diasRemovidos) {
          diasRemovidos = item.diasRemovidos.split(",");
        }

        var metaSugeridaTabela = !isNaN(parseFloat(item.MetaProjetada))
          ? parseFloat(item.MetaProjetada)
          : 0;

        var metaRealizadaTabela = !isNaN(parseFloat(item.MetaRealizada))
          ? parseFloat(item.MetaRealizada)
          : 0;

        var metaProjetadaTabela = !isNaN(parseFloat(item.MetaProjetada))
          ? parseFloat(item.MetaProjetada)
          : 0;

        var porcentagemMeta =
          metaSugeridaTabela > 0
            ? ((metaRealizadaTabela / metaSugeridaTabela) * 100).toFixed(2)
            : "*";

        desconto.push(
          parseFloat(item.MetaRealizada) + parseFloat(item.DescontoAplicado)
        );
        descontoParaGrafico.push({
          loja: item.nomeLoja,
          valor: parseFloat(item.DescontoAplicado),
          codLoja: item.CodLoja,
        });
        totalMeta += metaSugeridaTabela;
        totalRealizada += metaRealizadaTabela;

        let diasUteis = preencherDiasUteis(
          dataInicioTemp,
          dataFimTemp,
          false,
          diasRemovidos
        );

        let diasUteisRestantes = preencherDiasUteis(
          0,
          dataFimTemp,
          true,
          diasRemovidos
        );
        let mediaAtual =
          diasUteis > 0 ? metaRealizadaTabela / diasUteis : metaRealizadaTabela;
        let projecaoFinal =
          mediaAtual && diasUteisRestantes > 0
            ? mediaAtual * diasUteisRestantes + metaRealizadaTabela
            : metaRealizadaTabela;
        let deficitMensal =
          metaSugeridaTabela - metaRealizadaTabela > 0
            ? metaSugeridaTabela - metaRealizadaTabela
            : 0;
        let deficitDiario =
          diasUteisRestantes > 0 ? deficitMensal / diasUteisRestantes : 0;
        let representatividade = parseFloat(
          ((metaRealizadaTabela / auxTotalRealizado) * 100).toFixed(2)
        );
        projetada.push(projecaoFinal);

        let metaAlcancada = (projecaoFinal / metaProjetadaTabela) * 100;
        let customStyles = "#fff";

        if (metaAlcancada < 100) {
          metaAlcancada = 0;
          customStyles = "#c4302b";
        } else if (metaAlcancada >= 100 && metaAlcancada < 115) {
          metaAlcancada = 1;
          customStyles = "#00b14f";
        } else if (metaAlcancada >= 115 && metaAlcancada < 130) {
          metaAlcancada = 2;
          customStyles = "#FFD700";
        } else if (metaAlcancada >= 130) {
          metaAlcancada = 3;
          customStyles = "#6395ec";
        }
        dadosTabela.push({
          loja: item.CodLoja,
          meta: metaSugeridaTabela,
          realizada: metaRealizadaTabela,
          porcentagem: porcentagemMeta,
          diasUteis: diasUteis,
          diasFalta: diasUteisRestantes,
          dMensal: deficitMensal,
          dDiario: deficitDiario,
          projecao: projecaoFinal,
          representatividade: representatividade,
          cor: customStyles,
        });
        labelNomesParaFiltro.push({
          codLoja: item.CodLoja,
          nomeLoja: item.nomeLoja,
          diasUteis: diasUteis,
          diasFalta: diasUteisRestantes,
          quantidadeVendedorasAbaixoMeta: [],
        });
      });

      return {
        totalMeta: totalMeta,
        totalRealizada: totalRealizada,
        dataTodasLojas,
        loading: false,
      };
    }
  } else {
    return {
      totalMeta: 0,
      totalRealizada: 0,
      loading: false,
    };
  }
}

function preencherPeriodosCasoErro() {
  var periodos = [];
  var dataAtual = new Date();
  for (let index = 0; index < 12; index++) {
    var mes = dataAtual.getMonth() + 1;
    var ano = dataAtual.getFullYear();
    if (mes < 10) {
      mes = "0" + mes;
    }
    var periodo = ano + "/" + mes;
    periodos.push({ periodo, id: index });
    dataAtual.setMonth(dataAtual.getMonth() - 1);
  }
  return { periodoAtual: periodos[0].id };
}
