import { storageStoresGet } from "../storage/storageStores";
import { converterLoja } from "../util/loja";

export const formatShopAvailable = (base, accessShop) => {
  if (!!!base) return;
  const allShop = storageStoresGet();
  const baseAvailable = base;
  const shopAvailableFilter = [];
  baseAvailable?.forEach((bas) => {
    allShop.forEach((item) => {
      if (+bas === +item.base) {
        shopAvailableFilter.push(item);
      }
    });
  });

  const options = [];

  shopAvailableFilter?.forEach((shop) => {
    accessShop.forEach((loja) => {
      if (+loja === +shop.id) {
        options.push({
          value: shop.id,
          label: converterLoja(shop.id),
        });
      }
    });
  });
  return options;
};
