import React, { useEffect, useState } from "react";
import { createTheme } from "react-data-table-component";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import Title from "../../components/title";

import { PermissionsPage } from "../../util/permissionsPage";
import { useUserData } from "../../hooks/useUserData";
import {
  Box,
  Paper,
  Skeleton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SelectFilterMonth } from "../../components/FilterLojaMetas/SelectMes";
import { SelectLoja } from "../../components/FilterLojaMetas/SelectLoja";
import ErrorToastHandler from "../../util/ErrorToastHandler";
import { ListSeller } from "../../components/componentsMetaVendedor/ListSeller/ListSeller";
import { dataChart, optionsChartBar } from "../../dataCharts/metas-vendedor";
import { SelectBases } from "../../components/SelectBases/SelectBases";
import { formatShopAvailable } from "../../formatdata/formatter-shop-available";
import { DatePikerFilter } from "../../components/DatePikerFilter/DatePikerFilter";
import dayjs from "dayjs";

import metasVendedorPeriodo from "../../services/metasVendedorPeriodo";
import { arrayBases } from "../../util/loja";

function compararPorMetaRealizada(a, b) {
  if (a.metaRealizada < b.metaRealizada) {
    return 1;
  } else if (a.metaRealizada > b.metaRealizada) {
    return -1;
  } else {
    return 0;
  }
}

function MetasVendedorPeriodo() {
  const theme = useTheme();
  const { userData } = useUserData();
  const defaultValues = {
    base: userData.baseAcesso[0],
    periodo: null,

    first_period: dayjs().startOf("month").format("YYYY-MM-DD"),
    final_period: dayjs().format("YYYY-MM-DD"),
  };
  const [paramsConfig, setParamsConfig] = useState(defaultValues);
  const isNoSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [availableStore, setAvailableStore] = useState();
  const [storesSelect, setStoreSelect] = useState(null);
  const [dataBackup, setDataBackup] = useState({});
  const [periodosDisponiveis, setPeriodosDisponiveis] = useState([]);
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataPage, setDataPage] = useState([]);

  const onChangePeriod = (value, arrayPeriods) => {
    const arrAux = arrayPeriods ? arrayPeriods : periodosDisponiveis;
    const periodoAux = arrAux.find((item) => +item.id === +value);
    const [dia, mes] = periodoAux.periodo.split("à ")[1].split("/");

    setParamsConfig((old) => ({
      ...old,
      periodo: value,
      first_period: dayjs(periodoAux.periodoInicio)
        .add(3, "hours")
        .format("YYYY-MM-DD"),
      final_period: dayjs(`${dayjs().format("YYYY")}-${mes}-${dia}`)
        .add(3, "hours")
        .format("YYYY-MM-DD"),
    }));
  };

  const getElementAtEvent = (element) => {
    if (!element.length) return;
    //const { _index: index, _xScale: eixoX } = element[0];
    //var nomeVendedora = eixoX.ticks[index];
    //if (nomeVendedora === vendedoraSelecionada) {
    //resetarGraficos();
    // }
  };

  createTheme("solarized", {
    divider: {
      default: "transparent",
    },
  });

  const getDataPage = async () => {
    try {
      setIsLoading(true);
      const response = await metasVendedorPeriodo.get({
        ...paramsConfig,
        periodoInputFim: paramsConfig.final_period,
        periodoInputInicio: paramsConfig.first_period,
      });
      setDataBackup(response.data?.metas);

      setDataPage(
        response.data?.metas[storesSelect.value].sort(compararPorMetaRealizada)
      );
    } catch (error) {
      console.log(error);
      new ErrorToastHandler(error).showErrorToast();
    } finally {
      setIsLoading(false);
    }
  };
  const getAvailablePeriods = async () => {
    try {
      const stores = loadAvailableStores(paramsConfig.base);
      setBasesDisponiveis(arrayBases(userData.baseAcesso));
      setStoreSelect(stores[0]);
      const response = await metasVendedorPeriodo.getAvailablePeriods();
      setPeriodosDisponiveis(response.data);
      onChangePeriod(response.data[0].id, response.data);
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
    }
  };
  const loadAvailableStores = (value) => {
    const shopsAvailableForBase = formatShopAvailable(
      [value],
      userData.nivelAcesso
    );
    setAvailableStore(shopsAvailableForBase);
    return shopsAvailableForBase;
  };

  const onChangeParamsConfigBase = (value) => {
    const stores = loadAvailableStores(value);
    setStoreSelect(stores[0]);
    setParamsConfig((old) => ({
      ...old,
      base: value,
    }));
  };
  const onChangeLojas = (data) => {
    setStoreSelect(data);
    Object.keys(dataBackup).forEach((key) => {
      if (+key === data.value) {
        setDataPage(dataBackup[key].sort(compararPorMetaRealizada));
      }
    });
  };
  const onChangeDatePicker = (value) => {
    setParamsConfig((old) => ({
      ...old,
      ...value,
    }));
  };

  useEffect(() => {
    if (!!paramsConfig.periodo) {
      getDataPage();
    }
  }, [paramsConfig]);

  useEffect(() => {
    getAvailablePeriods();
  }, []);

  return (
    <>
      {PermissionsPage("metasVendasVendedor") && (
        <>
          {isNoSm && <Title title="Metas de Vendedor por Periodo" />}
          {isNoSm && (
            <Box
              display={"flex"}
              flexDirection={["column", "row"]}
              width={"100%"}
            >
              <Box
                width={["100%", "auto"]}
                display={["flex", "block"]}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <DatePikerFilter
                  paramsConfig={paramsConfig}
                  onChange={onChangeDatePicker}
                  isLoading={isLoading}
                />
              </Box>
              <Box width={"100%"} pl={2}>
                <SelectBases
                  basesDisponiveis={basesDisponiveis}
                  onChange={onChangeParamsConfigBase}
                  paramsConfig={paramsConfig}
                  isLoading={isLoading}
                />

                <SelectLoja
                  options={availableStore}
                  isDisabled={isLoading}
                  onChange={onChangeLojas}
                  value={storesSelect}
                />
                <Box mt={1} width={"100%"}>
                  <SelectFilterMonth
                    isDisabled={isLoading}
                    periodoReferencia={paramsConfig.periodo}
                    periodosDisponiveis={periodosDisponiveis}
                    selecionarPeriodo={onChangePeriod}
                  />
                </Box>
              </Box>
            </Box>
          )}
          <>
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              m={2}
              overflow={"hidden"}
            >
              {!isLoading ? (
                <Box
                  my={[2, 0]}
                  pt={2}
                  maxWidth={"80vw"}
                  sx={{ overflowX: ["scroll", "auto"] }}
                  height={["auto"]}
                  component={Paper}
                  variant="outlined"
                  elevation={isNoSm ? 2 : 0}
                >
                  <Bar
                    height={isNoSm ? 500 : undefined}
                    width={isNoSm ? 600 : undefined}
                    data={dataChart({
                      isApp: !isNoSm,
                      dataPage,
                    })}
                    options={optionsChartBar({
                      dataPage,
                      isApp: !isNoSm,
                    })}
                    getElementAtEvent={getElementAtEvent}
                    redraw
                  />
                </Box>
              ) : (
                <Skeleton variant="rounded" width={600} height={500} />
              )}
            </Stack>
            <ListSeller dataPage={dataPage} isLoading={isLoading} />
          </>
        </>
      )}
    </>
  );
}

export default MetasVendedorPeriodo;
